import React, { Component } from "react";
// import ReactToPrint from "react-to-print";
import moment from "moment";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import DateRangePicker from "../../../components/DatePicker";
import { countries } from "../../../fixtures/dummyApi/countries";
import Input from "../../../components/InputBox/Input";
import ReactSelect from "../../../components/reactSelect/reactSelect";
import Modal from "../../../components/modal/modal";
import Pbutton from "../../../components/button/Button";
import { CSVLink } from "react-csv";
import Snackbar from "../../../components/snackbar/snackbar";
import { storeIdApiCall } from "../../../services/storeId";
import { salesPerformanceApi } from "../../../services/SalesPerformance";
import Pagination from "react-js-pagination";
import BarLoader from "../../../components/barLoader/index";

import MainGraph from "./MainGraph/mainGraph";
import TableChat from "./Table/tableChat";
import PieChat from "./PieChat/pieChat";

import "./index.scss";
import "../../../static/scss/globalComponent.scss";
import StoreIdDropDown from "../../../components/StoreIdDropdown";
import { getCurrencySymbol, getFromLocalStorage, setInLocalStorage } from "../../../lib/helper";
import Header from "../../../components/HeaderComponent/header";
import PrintAndExport from "../../../components/PrintAndExport";
import { XAxisFormatting } from "../ProductPerformance/formatting";

class SalesPerformance extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    //Timer
    this.state = {
      dimension: "Transaction ID",
      date: {
        startDate:
          localStorage.getItem("startDate") != null
            ? localStorage.getItem("startDate")
            : moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
        endDate:
          localStorage.getItem("endDate") != null
            ? localStorage.getItem("endDate")
            : moment().format("YYYY-MM-DD HH:mm:ss"),
      },
      pageEnd: 0,
      totalGroupBy: [
        { value: 0, label: "Hour" },
        { value: 1, label: "Day" },
        { value: 2, label: "Week" },
        { value: 3, label: "Month" },
        { value: 4, label: "Quarter" },
        // { value: 5, label: "Year" },
        { value: 6, label: "Hour Of Day" },
        { value: 7, label: "Day Of Week" },
      ],
      selected:
        localStorage.getItem("selectedGroupBy") != null
          ? JSON.parse(localStorage.getItem("selectedGroupBy"))
          : { value: 0, label: "Hour" } || { value: 0, label: "Hour" },
      selectData: [{ value: 0, label: "Hour" }],
      searchValue: "",
      component: true,
      currency: getFromLocalStorage("currency"),
      search: {
        label: "Search",
        input: {
          name: "search",
          type: "text",
          value: "",
          placeholder: "Product Search",
        },
      },
      apiInfo: {
        order: 0,
        sortBy: "Revenue",
        pageNo: 0,
        pageRow: 10,
        column: "Revenue",
        pieColumn: "Revenue",
        symbol: getCurrencySymbol(getFromLocalStorage("currency")),
        currency: getFromLocalStorage("currency"),
        groupBy: 1,
        dropDownValue: "product_revenue",
        searchValue: "",
      },
      pageRow: {
        label: "Page Row",
        input: {
          name: "pagerow",
          type: "text",
          value: "",
        },
      },
      count: 0, // totalNumber of Data
      lastPage: "",
      showPieArrow: true,
      showTableArrow: true,
      passTableOrder: 0,
      passPieOrder: 0,
      prePieLeftDropDown: "",
      prePieRightDropDown: "",
      currentLeft: "Revenue",
      currentRight: "Revenue",
      groupBy: "1",
      dataLoaded: false,
      loadingScreen: true,
      tableData: {},
      pieData: {},
      mainGraphData: {},
      preValue: "",
      current: "product_revenue",
      mainGraphLoading: true,
      mainGraphDataLoaded: false,
      logsData: [],
      modal: false,
      snackBar: false,
      snackbarMessage: "",
      storeIdValue: "0",
      storeData: [{ id: 0, name: "--No Data--" }],
      dataLoadedstoreId: false,
      filterData: [...countries],
      currencyFilter: {
        label: "Currency filter",
        input: {
          name: "currencyFilter",
          type: "text",
          value: "",
        },
      },
      skip: 0, //starting point
      page: 1, //pagination page,
      limit: 20, // pagination limit,
      dataToShow: 10, //10 0r 5 data to show
      typingTimeout: 0,
      loader: true,
      exportData: [],
    };
  }

  componentDidMount() {
    this.getAllStoreId();
    this.state.component ? this.apiCallTable() : this.apiCallPie();
    this.apiCallMainGraph();
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.state.date.startDate !== prevState.date.startDate ||
      this.state.date.endDate   !== prevState.date.endDate ||
      this.state.selected.value !== prevState.selected.value
    ){
      this.apiCallMainGraph()
      this.state.component ? this.apiCallTable() : this.apiCallPie();

    }
  }

  getAllStoreId = () => {
    storeIdApiCall()
      .then((res) => {
        this.setState({
          storeData: res.data.data,
          dataLoadedstoreId: true,
        });
      })
      .catch((err) => {
        console.log("error store id", err);
        this.setState({
          dataLoadedstoreId: false,
        });
      });
  };

  callFromPie = (sortBy, order) => {
    order = order ? 1 : 0;
    this.setState(
      {
        apiInfo: { ...this.state.apiInfo, order, sortBy },
        showPieArrow: false,
        loader: true,
      },
      () => this.apiCallPie()
    );
  };

  pieGraph = (pre, cur, whichOne, original) => {
    setInLocalStorage("currency", this.state.apiInfo.currency || "INR");
    if (whichOne === "left") {
      this.setState(
        {
          apiInfo: {
            ...this.state.apiInfo,
            sortBy: cur,
            column: cur,
            pieColumn: original,
          },
          prePieLeftDropDown: pre,
          loader: true,
        },
        () => this.apiCallPie()
      );
    } else {
      this.setState(
        {
          apiInfo: {
            ...this.state.apiInfo,
            sortBy: original,
            column: original,
            pieColumn: cur,
          },
          prePieRightDropDown: pre,
          loader: true,
        },
        () => this.apiCallPie()
      );
    }
  };

  apiCallPie = () => {
    const { apiInfo, dimension, component, date } = this.state;
    setInLocalStorage("currency", apiInfo.currency || "INR");
    salesPerformanceApi(
      apiInfo,
      dimension,
      component,
      date,
      "",
      "",
      "",
      this.state.storeIdValue,
      this.state.skip,
      this.state.limit
    )
      .then((res) => {
        this.setState(
          {
            currentLeft: this.state.apiInfo.column,
            currentRight: this.state.apiInfo.pieColumn,
            passPieOrder: this.state.apiInfo.order,
            showPieArrow: true,
            dataLoaded: true,
            loadingScreen: false,
            count: res.data.count,
            pieData: res.data,
            snackBar: false,
            logsData: [],
            loader: false,
          },
          () => this.endPageCondition()
        );
      })
      .catch((err) => {
        if (err && err.response && err.response.status === 422) {
          this.setState({ snackbarMessage: "unsupported sort by" });
        }
        this.setState({
          dataLoaded: false,
          loadingScreen: false,
          snackBar: true,
          loader: false,
        });
        console.log(err);
      });
  };

  callFromTable = (sortBy, order) => {
    order = order ? 1 : 0;
    this.setState(
      {
        apiInfo: { ...this.state.apiInfo, order, sortBy },
        showTableArrow: false,
        loader: true,
      },
      () => this.apiCallTable()
    );
  };

  apiCallTable = () => {
    const { apiInfo, dimension, component, date } = this.state;
    setInLocalStorage("currency", apiInfo.currency || "INR");
    salesPerformanceApi(
      apiInfo,
      dimension,
      component,
      date,
      "",
      "",
      "",
      this.state.storeIdValue,
      this.state.skip,
      this.state.limit
    )
      .then((res) => {
        this.setState(
          {
            passTableOrder: this.state.apiInfo.order,
            count: res.data.count,
            showTableArrow: true,
            dataLoaded: true,
            loadingScreen: false,
            tableData: res.data,
            pieData: res.data,
            snackBar: false,
            limit: this.state.limit,
            logsData: [],
            loader: false,
          },
          () => this.endPageCondition()
        );
      })
      .catch((err) => {
        if (err && err.response && err.response.status === 422) {
          this.setState({ snackbarMessage: "unsupported sort by" });
        }
        this.setState({
          dataLoaded: false,
          loadingScreen: false,
          snackBar: true,
          loader: false,
        });
        console.log(err);
      });
  };

  endPageCondition = () => {
    let condition =
      this.state.apiInfo.pageRow * (this.state.apiInfo.pageNo + 1);
    if (this.state.count <= condition) {
      this.setState({
        lastPage: "active",
      });
    }
  };

  apiCallMainGraph = () => {
    const { apiInfo, component, date, selected, current } = this.state;
    setInLocalStorage("currency", apiInfo.currency || "INR");
    salesPerformanceApi(
      apiInfo,
      "mainGraph",
      component,
      date,
      selected,
      current,
      "export",
      this.state.storeIdValue,
      this.state.skip,
      this.state.limit
    )
      .then((res) => {
        let data = XAxisFormatting(res, this.state.selected);
        let exportData =
          data.data.graph.xcat.map((date, index) => {
            return {
              dateTime: date,
              [data.data.graph.series[0].name]:
                data.data.graph.series[0].data[index],
            };
          }) || [];

        this.setState({
          exportData,
          mainGraphData: data.data.graph,
          mainGraphLoading: false,
          mainGraphDataLoaded: true,
          current: this.state.apiInfo.dropDownValue,
          loader: false,
        });
      })
      .catch((err) => {
        this.setState({
          mainGraphLoading: false,
          mainGraphDataLoaded: false,
          current: this.state.preValue,
          loader: false,
        });
      });
  };

  handleDateRange = (startDate, endDate, groupBy, selectedGroupBy) => {
    this.setState(
      {
        date: {
          startDate: startDate,
          endDate: endDate,
        },
        dataLoaded: false,
        selectData: groupBy,
        selected: selectedGroupBy,
        loadingScreen: true,
        mainGraphLoading: true,
        mainGraphDataLoaded: false,
        loader: true,
      },
      () => {
        // this.apiCallMainGraph();
        // this.state.component ? this.apiCallTable() : this.apiCallPie();
      }
    );
  };

  selectHandler = (option) => {
    this.setState(
      {
        selected: option,
        mainGraphLoading: true,
        mainGraphDataLoaded: false,
        loader: true,
      }
      // ,
      // () => this.apiCallMainGraph()
    );
  };

  currencySelected = (e) => {
    this.setState({ anchorEl: e.currentTarget });
  };

  selectCurrency = (symbol, currency) => {
    this.setState(
      {
        apiInfo: { ...this.state.apiInfo, symbol, currency },
        dataLoaded: false,
        loadingScreen: true,
        mainGraphLoading: true,
        mainGraphDataLoaded: false,
        currency,
      },
      () => {
        this.apiCallMainGraph();
        this.state.component ? this.apiCallTable() : this.apiCallPie();
      }
    );
    this.handleClose();
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  onInputChange = (e) => {
    const self = this;
    const value = e.target.value;
    this.setState({
      apiInfo: { ...this.state.apiInfo, searchValue: value },
    });

    this.setState({
      search: {
        ...this.state.search,
        input: {
          ...this.state.search.input,
          value: value,
        },
      },
    });

    if (self.state.typingTimeout) {
      clearTimeout(self.state.typingTimeout);
      this.setState({
        loader: false,
      });
    }

    self.setState({
      typingTimeout: setTimeout(() => {
        this.setState({ loader: true });
        this.state.component ? this.apiCallTable() : this.apiCallPie();
      }, 700),
    });
  };

  renderComponent = (value) => {
    let apiInfo = {
      order: 0,
      sortBy: "Revenue",
      pageNo: 0,
      pageRow: 10,
      column: "Revenue",
      pieColumn: "Revenue",
      symbol: "₹",
      currency: this.state.currency || "INR",
      groupBy: 1,
      dropDownValue: "product_revenue",
      searchValue: "",
    };
    setInLocalStorage("currency", apiInfo.currency);
    if (value) {
      this.setState(
        {
          component: value,
          dataLoaded: false,
          loadingScreen: true,
          apiInfo: { ...apiInfo },
          groupBy: "ID",
          loader: true,
        },
        () => this.apiCallTable()
      );
    } else {
      this.setState(
        {
          component: value,
          dataLoaded: false,
          loadingScreen: true,
          apiInfo: { ...apiInfo },
          groupBy: "ID",
          loader: true,
        },
        () => this.apiCallPie()
      );
    }
  };

  pageTurn = (direction) => {
    setInLocalStorage("currency", this.state.apiInfo || "INR");
    if (direction === "next") {
      this.setState(
        {
          apiInfo: {
            ...this.state.apiInfo,
            pageNo: this.state.apiInfo.pageNo + 1,
            pageRow: 10,
            loader: true,
          },
        },
        () => (this.state.component ? this.apiCallTable() : this.apiCallPie())
      );
    } else {
      this.setState(
        {
          apiInfo: {
            ...this.state.apiInfo,
            pageNo:
              this.state.apiInfo.pageNo === 0
                ? 0
                : this.state.apiInfo.pageNo - 1,
            pageRow: 10,
            loader: true,
          },
        },
        () => (this.state.component ? this.apiCallTable() : this.apiCallPie())
      );
    }
  };

  pageRow = (e) => {
    let pageRow = e.target.value;
    const self = this;
    if (self.state.typingTimeout) {
      clearTimeout(self.state.typingTimeout);
    }

    self.setState({
      limit: pageRow,
      typingTimeout: setTimeout(() => {
        this.setState({ loader: true });
        this.apiCallTable();
      }, 1000),
    });
  };

  groupBy = (e) => {
    this.setState(
      {
        groupBy: e.target.value,
        apiInfo: {
          ...this.state.apiInfo,
          groupBy: e.target.value,
          sortBy: "Revenue",
          column: "Revenue",
        },
        loader: true,
      },
      () => (this.state.component ? this.apiCallTable() : this.apiCallPie())
    );
  };

  dropDown = (pre, cur) => {
    const { apiInfo } = this.state;
    this.setState(
      {
        apiInfo: { ...apiInfo, dropDownValue: cur },
        preValue: pre,
        mainGraphLoading: true,
        mainGraphDataLoaded: false,
      },
      () => this.apiCallMainGraph()
    );
  };

  toggleModal = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  exportData = () => {
    const { apiInfo, dimension, component, date } = this.state;
    setInLocalStorage("currency", apiInfo.currency || "INR");
    salesPerformanceApi(
      apiInfo,
      dimension,
      component,
      date,
      "export",
      this.state.storeIdValue,
      this.state.skip,
      this.state.limit
    )
      .then((expoData) => {
        this.setState(
          {
            logsData: expoData.data.data,
          },
          () => this.toggleModal()
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  change = () => {
    setTimeout(() => {
      this.setState({ snackBar: false });
    }, 1000);
  };

  dropDownSelect = (e) => {
    this.setState(
      {
        storeIdValue: e.target.value,
        loader: true,
      },
      () => {
        this.apiCallMainGraph();
        this.state.component ? this.apiCallTable() : this.apiCallPie();
      }
    );
  };

  filterCurrency = (e) => {
    this.setState({
      currencyFilter: {
        ...this.state.currencyFilter,
        input: {
          ...this.state.currencyFilter.input,
          value: e.target.value,
        },
      },
    });
    // let filterData = countries.filter((item, index) => {
    //   return (
    //     item.alpha3Code.toLowerCase().indexOf(e.target.value.toLowerCase()) !==
    //       -1 ||
    //     item.name.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1
    //   );
    // });

    let filterData = countries.filter((item, index) => {
      return (
        item.currencies.findIndex(item => item.code && item.code.toLowerCase().includes(e.target.value.toLowerCase())) !== -1 ||
        item.name.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1
      );
    });
    
    this.setState({
      filterData,
    });
  };

  // change pagination page  respectively set SKIP and LIMIT
  changeActivePage = (page) => {
    let state = { ...this.state };
    state.page = page;
    state.loader = true;
    if (this.state.page !== page) {
      this.setState(
        {
          skip: page - 1,
          limit: this.state.limit,
          page: state.page,
          loader: state.loader,
        },
        () => this.apiCallTable()
      );
    }
  };

  render() {
    const { dimension } = this.state;
    if (this.state.snackBar === true) {
      this.change();
    }
    return (
      <div
        className="main-container"
        ref={this.myRef}
        style={{ left: 0, width: "100%" }}
      >
        <div className="inner-container">
          {
            <Snackbar
              open={this.state.snackBar}
              message={"No data for slected data range"}
            />
          }
          {/* <div className="title">Sales Performance</div> */}
          <Header heading="Sales Performance" />
          <PrintAndExport myRef={this.myRef} margin={"mt-0"} data={this.state.exportData} />
          <div className="modules d-flex justify-content-between align-items-center">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center globalFontSize">
                {/* <div style={{ marginRight: "10px" }}>Date Picker: </div> */}
                <DateRangePicker
                  id="datePicker"
                  text={true}
                  handleDateChange={this.handleDateRange}
                  getGroupBy={true}
                />
              </div>
              <div
                className="d-flex align-items-center globalFontSize"
                style={{ marginLeft: "10px" }}
              >
                <div style={{ marginRight: "10px" }}>Group By: </div>
                <ReactSelect
                  city={this.state.selectData}
                  change={this.selectHandler}
                  selected={this.state.selected}
                />
              </div>
              <div
                className="d-flex align-items-center globalFontSize"
                style={{ marginLeft: "10px" }}
              >
                <div style={{ marginRight: "10px" }}>Currency: </div>
                <div
                  className="currency"
                  onClick={(e) => this.currencySelected(e)}
                >
                  {this.state.currency}
                </div>
              </div>
              <Menu
                style={{ minHeight: "300px", maxHeight: "300px" }}
                anchorEl={this.state.anchorEl}
                open={Boolean(this.state.anchorEl)}
                onClose={this.handleClose}
              >
                <div className="currency-hover-class">
                  <MenuItem>
                    Currency:
                    <Input
                      id=""
                      className="filterCurrency"
                      {...this.state.currencyFilter.input}
                      onChange={(e) => this.filterCurrency(e)}
                    ></Input>
                  </MenuItem>
                  {this.state.filterData.map((item, index) => (
                    <MenuItem
                      key={index}
                      className=""
                      onClick={() =>
                        this.selectCurrency(
                          item.currencies[0].symbol,
                          item.currencies[0].code
                        )
                      }
                    >
                      <span>
                        <img
                          src={item.flags.svg}
                          height={25}
                          width={25}
                          alt="currency"
                        />
                      </span>
                      {/* <span>^</span> */}
                      <span className="pl-3 currencySymbol">
                        {item.currencies[0].symbol}
                      </span>
                      <span className="pl-3">
                        {item.name.length > 15
                          ? `${item.name.substring(0, 15)}....`
                          : item.name}
                      </span>
                    </MenuItem>
                  ))}
                </div>
              </Menu>
            </div>
            <div className="d-flex align-items-center globalFontSize">
              <StoreIdDropDown
                storeData={this.state.storeData}
                storeID={this.state.storeIdValue}
                dropDownSelect={this.dropDownSelect}
              />
            </div>
          </div>
          <div
            className="graph d-flex justify-content-center align-items-center"
            style={{ minHeight: "350px", width: "100%" }}
          >
            {this.state.mainGraphLoading ? (
              <h4 style={{ color: "gray" }}>Loading data...</h4>
            ) : this.state.mainGraphDataLoaded ? (
              <MainGraph
                mainGraphData={this.state.mainGraphData}
                dropDown={this.dropDown}
                dropDownValueGraph={this.state.current}
                symbol={this.state.apiInfo.symbol}
                title={this.state.selected.label}
              />
            ) : (
              <h4 style={{ color: "gray" }}>No data for selected date range</h4>
            )}
          </div>
          <div className="data">
            <div
              className=""
              style={{ display: this.state.loader ? "block" : "none" }}
            >
            </div>
            <div className="navBar" style={{ width: "400px" }}>
              <span className="widthTags fontsizeproduct">
                Primary Dimension :
              </span>
              <span
                className={`widthTags fontsizeproduct ${
                  dimension === "Transaction ID"
                    ? "selectedDimension"
                    : "notSelectedDimension"
                }`}
                // onClick={() => this.DimensionTogge("Transaction ID")}
              >
                Customer
              </span>
              {/* <span className={`widthTags ${dimension === "Date" ? "selectedDimension" : "notSelectedDimension"}`} onClick={() => this.DimensionTogge("Date")}>Date</span> */}
            </div>
            <div className="parts">
              <div
                className="fontsizeproduct d-flex align-items-center"
                style={{
                  height: "30px",
                  display: "flex",
                  justifyContent: "center",
                  marginRight: "30px",
                }}
              >
                <label style={{ marginBottom: 0 }} htmlFor="">
                  Group By :
                </label>
                <select
                  style={{
                    height: "30px",
                    marginLeft: "10px",
                    marginBottom: 0,
                  }}
                  className="dropDown fontsizeproduct"
                  value={this.state.groupBy}
                  name="dropDownValuePie"
                  onChange={(e) => this.groupBy(e)}
                >
                  <option value="1">Customer Name</option>
                  <option value="2">Country</option>
                  <option value="3">State</option>
                </select>
              </div>
              <div className="searchInput">
                <Input
                  id=""
                  className="searchField fontsizeproduct"
                  {...this.state.search.input}
                  onChange={(e) => this.onInputChange(e)}
                ></Input>
                {/* <div className="searchIcon" onClick={() => this.search()}>
                  <i className="fa fa-search" aria-hidden="true"></i>
                </div> */}
              </div>
              <div className="iconsDiv">
                <div
                  className="iconSwap borderRemove"
                  style={
                    this.state.component
                      ? { boxShadow: "inset 0px 0px 5px rgba(0, 0, 0, 0.5)" }
                      : {}
                  }
                >
                  <i
                    className="fa fa-table"
                    aria-hidden="true"
                    onClick={() => this.renderComponent(true)}
                  ></i>
                </div>
                <div
                  className="iconSwap"
                  style={
                    this.state.component === false
                      ? { boxShadow: "inset 0px 0px 5px rgba(0, 0, 0, 0.5)" }
                      : {}
                  }
                >
                  <i
                    className="fas fa-chart-pie"
                    aria-hidden="true"
                    onClick={() => this.renderComponent(false)}
                  ></i>
                </div>
              </div>
            </div>
            <div
              className="componentDiv"
              style={
                this.state.loadingScreen || this.state.dataLoaded === false
                  ? {
                      minHeight: "600px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }
                  : {}
              }
            >
              {this.state.loadingScreen ? (
                <h4 style={{ color: "gray" }}> Loading Data...</h4>
              ) : this.state.dataLoaded ? (
                this.state.component ? (
                  <TableChat
                    passOrder={this.state.passTableOrder}
                    apiInfo={this.state.apiInfo}
                    callTable={this.callFromTable}
                    array={this.state.array}
                    show={this.state.showTableArrow}
                    tableData={this.state.tableData}
                    symbol={this.state.apiInfo.symbol}
                    groupBy={this.state.apiInfo.groupBy}
                    page={this.state.page}
                    limit={this.state.limit}
                  />
                ) : (
                  <PieChat
                    apiInfo={this.state.apiInfo}
                    pieGraph={this.pieGraph}
                    dropDownValue={this.state.currentLeft}
                    dropDownValuePie={this.state.currentRight}
                    callPie={this.callFromPie}
                    passOrder={this.state.passPieOrder}
                    show={this.state.showPieArrow}
                    pieData={this.state.pieData}
                    pieDataGraph={this.state.pieData.graph}
                    symbol={this.state.apiInfo.symbol}
                    groupBy={this.state.apiInfo.groupBy}
                    page={this.state.page}
                    limit={this.state.limit}
                  />
                )
              ) : (
                <h4 style={{ color: "gray" }}>
                  No data for selected date range
                </h4>
              )}
            </div>

            <div className="parts margin">
              {this.state.dataLoaded ? (
                <div className="d-flex">
                  <div
                    className="fontsizeproduct d-flex align-items-center"
                    style={{ marginRight: "10px" }}
                  >
                    Number of rows:
                  </div>
                  <div className="bg_color">
                    <Input
                      id=""
                      className="pageRow"
                      value={this.state.limit}
                      onChange={(e) => this.pageRow(e)}
                    ></Input>
                  </div>
                  <Pagination
                    activePage={this.state.page}
                    itemsCountPerPage={this.state.limit}
                    totalItemsCount={this.state.count}
                    pageRangeDisplayed={5}
                    onChange={this.changeActivePage}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <Modal
            isOpen={this.state.modal}
            toggle={this.toggleModal}
            width={"35%"}
          >
            <div className="col-12 px-0">
              <div className="py-3 reportModal-header pl-3 border-bottom">
                Export your report
              </div>
              <div className="py-3 reportModal-subText pl-3 border-bottom">
                Report will be exported as a CSV (comma separated values) table.
              </div>
              <div className="py-3 col-12">
                <div className="row justify-content-end">
                  <Pbutton
                    onClick={this.toggleModal}
                    className="reportModal-cancelBtn"
                  >
                    Cancel
                  </Pbutton>
                  <CSVLink
                    onClick={() => this.toggleModal()}
                    data={this.state.logsData}
                    filename={"my-file.csv"}
                    className="reportModal-exportBtn"
                    target="_blank"
                  >
                    Export
                  </CSVLink>
                </div>
              </div>
            </div>
          </Modal>
        </div>
        <Snackbar
          open={this.state.snackBar}
          message={this.state.snackbarMessage}
        />
      </div>
    );
  }
}

export default SalesPerformance;
