import React, { Component } from 'react';
import Link from "../../../../components/Link/Link";
import TopBrandTable from "../table/topBrandTable";
import ReactSelect from '../../../../components/Select';
import { removeFromLocalStorage, setInLocalStorage } from '../../../../lib/helper';
import RevLoader from '../../../../components/loader/RevLoader';
// currencyFormatter.format(10000, {code: "USD"})


class TopBrands extends Component {

  constructor(props) {
    super()
    this.state = {
      sortBy: [
        {label: "Revenue", value: 1},
        {label: "Quantity", value: 2}
      ],
      selectedSortBy: {label: "Revenue", value: 1}
    }
  }

  componentDidMount() {
    removeFromLocalStorage("product")
  }

  selectHandler = (option) => {
    this.setState({
        selectedSortBy: option,
    }, () => this.props.sortByChangeHandler(option.value));
}

    render() {
        return (
            <div className="card">
              <div className="spaceBetween mt-3 ml-3 mr-3">
                <span className="label globalFontSize fontweightone">Top brands</span>
                <span className="link globalFontSize fontweightone" onClick={() => setInLocalStorage("product", "Product Brand")}>
                  <Link url={`product-performance`}>View Activity</Link>
                </span>
              </div>
              <div className="spaceBetween ml-3 mr-3">
                <span className="numericValue">
                  {this.props.loader ? <RevLoader /> : <></>}
                </span>
              </div>
              <div className="spaceBetween ml-3 mr-3">
                <span className="fontweightone" style={{alignSelf: "center", color: "#000000"}}>
                  <span className="globalFontSize">Sort By</span>
                </span>
                <span className="numericValue topBrandSelectBox">
                  <ReactSelect items={this.state.sortBy} handleChange={this.selectHandler} selected={this.state.selectedSortBy} />
                </span>
              </div>
              <div className="chart">
                <TopBrandTable className="top-n-table" table={this.props.table} loader={this.props.loader} currency={this.props.currency} />
              </div>
              
              
            </div>
        );
    }
}

export default TopBrands;