import { getForReport } from "../lib/request";
import moment from "moment";
import jstz from "jstz";
import { getFromLocalStorage } from "../lib/helper";

var timezone = jstz.determine();
let yourTimeZone = timezone.name();

// export const fetchReportSubData = (startDate = moment().unix(), endDate = moment().unix(), group_by) => {
// 13.127.140.33:8091

export const fetchReportSubData = (startDate, endDate, group_by) => {
  return getForReport(
    // `/reviewLogs?skip=${skip}&limit=${limit}`, { startDate: startDate, endDate: endDate }
    `/python-spark/totalSales/?start_timestamp=${startDate}&end_timestamp=${endDate}&group_by=${group_by}`
  );
};

// /python-spark/sales/descriptive-sales
export const fetchReportDescriptiveData = () => {
  return getForReport(
    // `/reviewLogs?skip=${skip}&limit=${limit}`, { startDate: startDate, endDate: endDate }
    `/python-spark/sales/descriptive-sales`
  );
};

export const totalRevenueDataBox = (startDate, endDate, storeId) => {
  let start = moment(startDate).unix();
  let end = moment(endDate).unix();
  return getForReport(
    `/python-spark/sales/report?start_timestamp=${start}&end_timestamp=${end}&store_id=${storeId}&compare_with=0&currency=${getFromLocalStorage("currency") || "INR"}`
  );
};

export const fetchEditColumnData = () => {
  return getForReport(`/python-spark/column-name`);
  // return getForReport(`http://13.127.140.33:8091/python-spark/column-name`);
};

// get column name from input in search box of (filter modal) // column Name should be integer and serarh
export const searchFilterWithColName = (colName, string) => {
  return getForReport(
    `/python-spark/column-search?column_name=${colName}&search=${string}`
  );
};

export const getCountriesData = () => {
  return getForReport(`/python-spark/currency`);
};

export const getFilteredData = (timeStamp1, timeStamp2, filterObj) => {
  let filteredObj = encodeURIComponent(JSON.stringify(filterObj));
  return getForReport(
    `/python-spark/filter-report?timezone=${yourTimeZone}&start_timestamp=${timeStamp1}&end_timestamp=${timeStamp2}&filter=${filteredObj}`
  );
  // return getForReport(`/python-spark/filter?filter=${filterObj}`);
};

export const getCurrentReportSubData = (
  startTime,
  endTime,
  groupBy,
  columnArray,
  currency,
  storeId,
  orderBy,
  ascending,
  pageSkip,
  limit
) => {
  if (true) {
    if (columnArray.length === 0) {
      return getForReport(
        `/python-spark/filter-report?timezone=${yourTimeZone}&start_timestamp=${startTime}&end_timestamp=${endTime}&group_by=${groupBy}&currency=${currency}&store_categories_id=${storeId}`
      );
    } else {
      return getForReport(
        `/python-spark/filter-report?timezone=${yourTimeZone}&start_timestamp=${startTime}&end_timestamp=${endTime}&column=${`[${columnArray}]`}&group_by=${groupBy}&currency=${currency}&store_categories_id=${storeId}`
      );
    }
  }
  // else if (
  //   moment.unix(endTime).format("MM/DD/YYYY") ===
  //     moment.unix(parseInt(new Date().getTime() / 1000)).format("MM/DD/YYYY") &&
  //   moment.unix(startTime).format("MM/DD/YYYY") !== moment.unix(endTime).format("MM/DD/YYYY")
  // ) {
  //   if (columnArray.length === 0) {
  //     return getForReport(
  //       `/python-spark/merge-report?timezone=${yourTimeZone}&start_timestamp=${startTime}&end_timestamp=${endTime}&group_by=${groupBy}&currency=${currency}`
  //     );
  //   } else {
  //     return getForReport(
  //       `/python-spark/merge-report?timezone=${yourTimeZone}&start_timestamp=${startTime}&end_timestamp=${endTime}&column=${`[${columnArray}]`}&group_by=${groupBy}&currency=${currency}`
  //     );
  //   }
  // } else if (
  //   moment.unix(startTime).format("MM/DD/YYYY") !==
  //     moment.unix(parseInt(new Date().getTime() / 1000)).format("MM/DD/YYYY") &&
  //   moment.unix(endTime).format("MM/DD/YYYY") !== moment.unix(parseInt(new Date().getTime() / 1000)).format("MM/DD/YYYY")
  // ) {
  //   if (columnArray.length === 0) {
  //     return getForReport(
  //       `/python-spark/historical-report?timezone=${yourTimeZone}&start_timestamp=${startTime}&end_timestamp=${endTime}&group_by=${groupBy}&currency=${currency}`
  //     );
  //   } else {
  //     return getForReport(
  //       `/python-spark/historical-report?timezone=${yourTimeZone}&start_timestamp=${startTime}&end_timestamp=${endTime}&column=${`[${columnArray}]`}&group_by=${groupBy}&currency=${currency}`
  //     );
  //   }
  // }
};
