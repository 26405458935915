import React, { Component } from "react";

// component import
import DatePickerComponent from "../DatePicker";
import GroupByComponent from "./GroupBy";
import CurrencyComponent from "./Currency";

class SelectionModule extends Component {
  state = {
    groupData: [{ value: 0, label: "Hour" }],
    selectedLabel: { value: 0, label: "Hour" },
  };

  groupByData = (startDate, endDate, groupBy, selectedGroupBy) => {
    this.setState({
      groupData: groupBy,
      selectedLabel: selectedGroupBy,
    });
    let date = {
      startDate,
      endDate,
    };
    this.props.selectedDate(date, selectedGroupBy);
  };

  selectHandler = (selectValue) => {
    this.setState(
      {
        selectedLabel: selectValue,
      },
      () => this.props.passGroupBy(selectValue)
    );
  };

  render() {
    const { groupData, selectedLabel } = this.state;
    const { DatePicker, Currency, GroupBy, currencyInfo } = this.props;

    return (
      <div className="d-flex">
        {DatePicker && (
          <DatePickerComponent
            text={true}
            getGroupBy={true}
            handleDateChange={this.groupByData}
          />
        )}
        {Currency && (
          <GroupByComponent
            groupData={groupData}
            selectedLabel={selectedLabel}
            selectHandler={this.selectHandler}
          />
        )}
        {GroupBy && (
          <CurrencyComponent
            currencyInfo={currencyInfo}
            setCurrency={this.props.setCurrency}
          />
        )}
      </div>
    );
  }
}

export default SelectionModule;
