import React, { Component } from "react";
import ReactSelect from "../../../../components/reactSelect/reactSelect";
import DateRangePicker from "../../../../components/DatePicker";

class index extends Component {
  constructor(props) {
    super();
    this.state = {
      selectedGroupByDevice: { value: 0, label: "All Devices" },
      selectedGroupByDeviceList: [
        { value: 0, label: "All Devices" },
        { value: 1, label: "Android" },
        { value: 2, label: "iOS" },
        { value: 3, label: "Web" },
      ],
      selectedGroupByPeriod: { value: "0", label: "Hour" },
      selectGroupByPeriodList: [{ value: 0, label: "Hour" }],
    };
  }

  selectGroupByDeviceHandler = (option) => {
    this.setState({
      selectedGroupByDevice: option,
    });
    this.props.setInParentComponent("device", option);
  };

  selectGroupByPeriodHandler = (
    startDate,
    endDate,
    groupBy,
    selectedGroupBy
  ) => {
    this.setState(
      {
        startDate: startDate,
        endDate: endDate,

        selectGroupByPeriodList: groupBy,
        selectedGroupByPeriod: selectedGroupBy,
      },
      () => {
        this.props.setInParentComponent("date", {
          startDate: startDate,
          endDate: endDate,
          selectedGroupBy: selectedGroupBy,
        });
      }
    );
  };

  selectGroupByHandler = (option) => {
    this.setState({ groupBy: option });
    this.props.setInParentComponent("groupBy", option);
  };

  render() {
    return (
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <div className="mt-2 mb-2 mr-5">
            <DateRangePicker
              text={true}
              handleDateChange={this.selectGroupByPeriodHandler}
              getGroupBy={true}
            />
          </div>

          <span className="mr-2 globalFontSize">Group By: </span>
          <div className="">
            <ReactSelect
              city={this.state.selectGroupByPeriodList}
              change={this.selectGroupByHandler}
              selected={this.props.groupBy}
            />
          </div>
          <span className="mr-2 ml-5 globalFontSize">Device By: </span>
          <div className="selectBody">
            <ReactSelect
              city={this.state.selectedGroupByDeviceList}
              change={this.selectGroupByDeviceHandler}
              selected={this.state.selectedGroupByDevice}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default index;
