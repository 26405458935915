import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import ClipLoader from "react-spinners/ClipLoader";

import './table.css'


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  // console.log('array',array)

  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}


function EnhancedTableHead(props) {
  const { columnName } = props
  // console.log("columnName", columnName)
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* {columnName ? columnName.slice().map((headCell) => { */}
        {columnName ? columnName.map((headCell) => {
          return (
            <TableCell
              key={headCell.id}
              // align={headCell.numeric ? 'right' : 'left'}
              align={headCell.id === "Shop Name" ? 'center' : 'center'}
              // align={headCell.id === "Shop Name" ? 'left' : 'center'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.id}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          )
        }) : <></>}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};


const useStyles = makeStyles((theme) => ({
  root: {
    padding: '1rem',
    width: '100%',
  },
  paper: {
    width: '100%',
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const SellerStasticsListData = (props) => {
  const { showpPagination, rows, totalData, columnName, tablerRowPerPage, tablePagination } = props
  // console.log("totalData")
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };


  const handleChangePage = (event, newPage) => {
    tablePagination(newPage)
    setPage(newPage);
    // console.log("handleChangePage", newPage, page)
  };

  const handleChangeRowsPerPage = (event) => {

    // console.log("handleChangeRowsPerPage", event.target.value)
    tablerRowPerPage(event.target.value)
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  console.log(
    // 'loader', loader,
    // "rowsPerPage",rowsPerPage,
    // "page",page
  )

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, totalData - page * rowsPerPage);
  return (
    <div className='sellerOverviewTable'>
      <div className={classes.root}>
        <Paper className={classes.paper}>

          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
              aria-label="enhanced table"
            >

              {/* <TableHead>
                <TableRow>
                  {Object.keys(rows[0]).map((key) => {
                    return <TableCell>
                      {key}
                    </TableCell>
                  })}
                </TableRow>
              </TableHead> */}
              <EnhancedTableHead
                columnName={columnName}
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={totalData}
              />
              {/* 
              {loader ? <div className="seller_statestic_loader ml-2 align-items-center justify-content-center">
                  <ClipLoader color="blue" size={40} />  </div> : */}
              <TableBody>

                {rows.map((elem) => {
                  return <TableRow>
                    {Object.keys(elem).map((rows) => {
                      return  rows !=="storeId" && <TableCell  align="center">
                        {elem[rows]}
                      </TableCell>
                    })}
                  </TableRow>
                })}
                {/* {rows ? stableSort(rows, getComparator(order, orderBy))
                    .map((row, index) => {
                      console.log("row", row)
                      return (
                        <TableRow
                          hover
                          key={row.name}
                        >
                          <TableCell align="left">{row['Shop Name']}</TableCell>
                          <TableCell align="center">{row['Total Products']}</TableCell>
                          <TableCell align="center">{row['Total Primary Products']}</TableCell>
                          <TableCell align="center">{row['storeId']}</TableCell>
                          <TableCell align="center">{row['Total Varient Products']}</TableCell>
                          <TableCell align="center">{row['Total Inventory']}</TableCell>
                        </TableRow>
                      );
                    }) : <></>} */}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
              </TableBody>
              {/* } */}
            </Table>
          </TableContainer>
          {/* {
            showpPagination ?
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={totalData ? totalData : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
              : <></>
          } */}
        </Paper>
      </div>
    </div>
  );
}

export default SellerStasticsListData;
