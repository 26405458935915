import React, { Component } from "react";
import "./index.scss";

import {
  fetchSessionAnalytics,
  fetchExportSessionTableData,
} from "../../../services/overview";
import ReactTable from "./table";
import moment from "moment";
import Snackbar from "../../../components/snackbar/snackbar";
import { config } from "./graphConfig/config";
import BarGraph from "./graph/barGraph";
import ReactToPrint from "react-to-print";
import Modal from "../../../components/modal/modal";
import Pbutton from "../../../components/button/Button";
import { withRouter } from "react-router-dom";
import { CSVLink } from "react-csv";
import Header from "./header";
import { storeIdApiCall } from "../../../services/storeId";
// import SvgIcon from "../../../static/images/icons/de878546f6a299a84423e9ac72b743af.svg";
class Overview extends Component {
  constructor() {
    super();
    // reference of the component
    this.myRef = React.createRef();
    this.state = {
      snackBarMessage: "",
      showSnackbar: false,
      focusedInput: "select a date range",
      focused: false,
      selectedDevice: {
        value: 1,
        label: "Android",
      },
      sortByDevice: 0,
      config: config,
      tableColumns: [],
      tableData: [],
      tableLoader: true,
      chartCategories: [],
      chartSeries: [],
      API_DATA: null,
      isExport: false,
      deviceName: "All Devices",
      isMoreThanThousand: false,
      daysOfWeek: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
      isDisplayChart: false,
      noDataText: "Loading Data ...",
      startDate:
        localStorage.getItem("startDate") != null
          ? localStorage.getItem("startDate")
          : moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
      endDate:
        localStorage.getItem("endDate") != null
          ? localStorage.getItem("endDate")
          : moment().format("YYYY-MM-DD HH:mm:ss"),
      groupBy: { value: 0, label: "Hour" },
      device: { value: 0, label: "All Devices" },
      storeIdValue: "0",
      storeData: [{ _id: 0, name: "--No Data--" }],
      dataLoaded: false,
    };
  }
  // this function calls one time in lifecyle
  componentDidMount() {
    this.getAllStoreId();
    let width = window.innerWidth;
    this.setState({ width });
    window.addEventListener("resize", this.heightHandler);

    /**
     * @author Satyam
     * @date 13 May, 2020
     * @sortBy 4,3,2,1,0 --> quarter, month, week, day, hour respectively send only epocTime - startTime-endTime
     * @sortByDevice 1,2,3 --> iOS , Web, Android respectively
     *
     */

    //  this.getData(
    //    this.state.startDate,
    //    this.state.endDate,
    //    this.state.groupBy.value,
    //    this.state.device.value
    //  );
  }

  getAllStoreId = () => {
    storeIdApiCall()
      .then((res) => {
        this.setState({
          storeData: res.data.data,
          dataLoaded: true,
        });
      })
      .catch((err) => {
        console.log("error store id", err);
        this.setState({
          dataLoaded: false,
        });
      });
  };
  // this function call whenever there is a update in the whole component
  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.device.value !== this.state.device.value ||
      prevState.storeIdValue !== this.state.storeIdValue
    ) {
      this.intermediate();
    }
  }

  intermediate = () => {
    this.setState({
      config: config,
      // tableLoader: true,
      isDisplayChart: false,
      noDataText: "Loading Data ...",
    });
    this.getData(
      this.state.startDate,
      this.state.endDate,
      this.state.groupBy.value,
      this.state.device.value
    );
  };

  sortByWeek = (response) => {
    let copyOfResponse = response;
    let series = [];
    let series2 = [];
    this.state.daysOfWeek.map((weekName, index) => {
      let indexOf = response.data.graph.xaxis.categories.indexOf(weekName);
      series.push(response.data.graph.series[0].data[indexOf]);
      return series2.push(response.data.graph.series[1].data[indexOf]);
    });
    copyOfResponse.data.graph.series[0].data = series;
    copyOfResponse.data.graph.series[1].data = series2;
    copyOfResponse.data.graph.xaxis.categories = this.state.daysOfWeek;
    let tableData = series.map((count, index) => {
      // let indexOfDay = _.findIndex(response.data.table, (dict) => dict["Day of week"] === this.state.daysOfWeek[index] )
      // "index_x": response.data.table[indexOfDay]["index_x"],
      // "index_y": response.data.table[indexOfDay]["index_y"],
      return {
        "Day Of Week": this.state.daysOfWeek[index],
        "Total Count": count,
        "Unique Count": series2[index],
      };
    });
    copyOfResponse.data.table = tableData;
    response = copyOfResponse;
    return response;
  };

  // This function call the user-activity api and map over the data and sets the chart configuration
  getData = (start_timestamp, end_timestamp, sortBy, device) => {
    fetchSessionAnalytics(
      moment(start_timestamp).unix(),
      moment(end_timestamp).unix(),
      sortBy,
      device,
      this.state.storeIdValue
    )
      .then((response) => {
        this.setState({ config: config });

        let newConfig = { ...this.state.config };
        newConfig.series = response.data.graph.series;

        let format = {
          Hour: "ll H:00",
          Day: "ll",
          Week: "ll",
          "Hour Of Day": "HH:00",
        };
        let xCategories;
        if (this.state.groupBy.label === "Hour") {
          xCategories = response.data.graph.xaxis.categories.map((dateTime) => {
            return moment(dateTime).format(format[this.state.groupBy.label]);
          });
        } else if (
          this.state.groupBy.label === "Day" ||
          this.state.groupBy.label === "Week" ||
          this.state.groupBy.label === "Hour Of Day"
        ) {
          xCategories = response.data.graph.xaxis.categories.map((dateTime) => {
            return moment(dateTime).format(format[this.state.groupBy.label]);
          });
        } else {
          xCategories = response.data.graph.xaxis.categories;
        }
        let isThousandData = response.data.show_message ? true : false;
        let numberOfDecimal =
          Math.max(...response.data.graph.series[0].data) <= 3 ? 1 : 0;

        newConfig.options = {
          ...newConfig.options,
          xaxis: {
            categories: xCategories,
            title: {
              text: response.data.graph.xaxis.title,
              rotate: -45,
              style: {
                fontSize: "11px",
                fontWeight: 700,
              },
            },
          },
          labels: xCategories,
          noData: { ...newConfig.options.noData, text: "" },
          yaxis: {
            labels: {
              formatter: function (val, index) {
                return val.toFixed(numberOfDecimal);
              },
            },
            title: {
              text: response.data.graph.yaxis.title,
              style: {
                fontSize: "11px",
                fontWeight: 700,
              },
            },
          },
          plotOptions: {
            ...newConfig.plotOptions,
            bar: {
              ...newConfig.plotOptions,
              columnWidth: "70%",
            },
          },
        };

        //  Decreasing the column width if the nummber of the data is less
        if (response.data.graph.xaxis.categories.length < 6) {
          newConfig.options = {
            ...newConfig.options,
            plotOptions: {
              ...newConfig.plotOptions,
              bar: {
                ...newConfig.plotOptions,
                columnWidth: "10%",
                dataLabels: {
                  hideOverflowingLabels: false,
                },
              },
            },
          };
        }
        if (response.data.graph.xaxis.categories.length >= 20) {
          newConfig.options = {
            ...newConfig.options,
            plotOptions: {
              ...newConfig.plotOptions,
              bar: {
                columnWidth: "100%",
                dataLabels: {
                  hideOverflowingLabels: false,
                },
              },
            },
          };
        }

        this.setState({
          config: newConfig,
          tableData: response.data.table,
          tableLoader: false,
          isMoreThanThousand: isThousandData,
          isDisplayChart: true,
        });
      })
      .catch((error) => {
        if (!this.state.startDate) {
          this.setState({
            showSnackbar: true,
            snackBarMessage: "Please select a date range",
          });
        } else {
          let message =
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
              ? error.response.data.message
              : "Data Not Found For the Given Date Range!";
          this.setState({
            tableLoader: false,
            config: {
              ...config,
              options: {
                ...config.options,
                xaxis: {
                  categories: [],
                  // labels: { show: false },
                  title: { text: "" },
                },
                noData: {
                  ...config.options.noData,
                  text: "No data found for the selected date range",
                },
              },
            },
            tableData: [],
            showSnackbar: true,
            snackBarMessage: message,
            noDataText: "No data for the selected date range",
          });
        }
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            tableLoader: false,
          });
        }, 1000);
        console.log("Api error", error);
      });
  };
  // ========= End of getData function ============ //

  fetchExportTableData = () => {
    fetchExportSessionTableData(
      moment(this.state.startDate).unix(),
      moment(this.state.endDate).unix(),
      this.state.groupBy.value,
      this.state.device.value,
      this.state.storeIdValue
    )
      .then((response) => {
        if (response) {
          this.setState({
            isExport: !this.state.isExport,
            tableData: response.data.table,
          });
        }
      })
      .catch((err) => {
        this.setState({});
      });
  };
  // this function return open the existing page in new tab
  // JSX PART

  headerChangeHandler = (state, value) => {
    if (state && value) {
      if (state === "groupBy") {
        this.setState({ groupBy: value }, () => this.intermediate());
      }
      if (state === "date") {
        this.setState(
          {
            startDate: value.startDate,
            endDate: value.endDate,
            groupBy: value.selectedGroupBy,
          },
          () => this.intermediate()
        );
      }
      if (state === "device") {
        this.setState({ device: value });
      }
    }
  };

  dropDownSelect = (e) => {
    this.setState({
      storeIdValue: e.target.value,
    });
  };

  render() {
    return (
      <div>
        <div className="reportWrapper" ref={this.myRef}>
          <div className="head">
            <div
              className="text-grey cursor-pointer globalFontSize"
              onClick={() => this.props.history.push("overview")}
            >
              <i className="fas fa-angle-left mr-2"></i>Reports
            </div>
            <div className="title">Session Analytics</div>
            <div className="d-flex text-grey mt-2 globalFontSize">
              <div className="mr-3 db_ptr">
                <ReactToPrint
                  trigger={() => (
                    <span>
                      <i className="fas fa-print mr-1"></i>Print
                    </span>
                  )}
                  content={() => this.myRef.current}
                />
              </div>
              <div
                className="mr-3 db_ptr"
                onClick={() =>
                  this.setState({ isExport: !this.state.isExport })
                }
              >
                <i className="fas fa-download mr-1"></i>Export
              </div>
            </div>
            <div
              className="moreThanThousand"
              style={{ display: this.state.isMoreThanThousand ? "" : "none" }}
            >
              <div className="iconDiv">
                <span className="iconSpan">
                  {/* <img className='exportImage' src={SvgIcon} /> */}
                </span>
              </div>
              <div className="bannerContent">
                This report shows up to 1,000 results. To see all results, you
                can
                <button
                  className="buttonExport"
                  onClick={this.fetchExportTableData}
                >
                  <span>export the report</span>
                </button>
              </div>
            </div>
            <Header
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              groupBy={this.state.groupBy}
              storeData={this.state.storeData}
              storeIdValue={this.state.storeIdValue}
              dropDownSelect={this.dropDownSelect}
              dataLoaded={this.state.dataLoaded}
              setInParentComponent={this.headerChangeHandler}
            />
          </div>
          <div className="overviewBody charts">
            <div className="chartAndHeading">
              <div
                className="m-3 mt-4 graphTitle"
                style={{
                  display: this.state.isDisplayChart ? "" : "none",
                  paddingTop: "20px",
                }}
              >
                <span>{this.state.device.label} Sessions</span>
              </div>
              <div
                className="chart"
                style={{ display: this.state.isDisplayChart ? "" : "none" }}
              >
                <BarGraph config={this.state.config} />
              </div>
              <div className="noData">{this.state.noDataText}</div>
            </div>
          </div>
          <div
            className="body tableWrapper"
            style={{ width: "100%", top: "20px" }}
          >
            <ReactTable
              loader={this.state.tableLoader}
              tableData={this.state.tableData}
              groupBy={this.state.groupBy.value}
            />
          </div>
          {/* Export Modal will only open when user click on export button then only this modal will to ask for export and cancel */}
          <Modal
            isOpen={this.state.isExport}
            toggle={() => this.setState({ isExport: !this.state.isExport })}
            width={"35%"}
          >
            <div className="col-12 px-0">
              <div className="py-3 reportModal-header pl-3 border-bottom">
                Export your Table Data
              </div>
              <div className="py-3 reportModal-subText pl-3 border-bottom">
                Table will be exported as a CSV (comma separated values).
              </div>
              <div className="py-3 col-12">
                <div className="row justify-content-end">
                  <Pbutton
                    onClick={() =>
                      this.setState({ isExport: !this.state.isExport })
                    }
                    className="reportModal-cancelBtn"
                  >
                    Cancel
                  </Pbutton>
                  <CSVLink
                    onClick={() =>
                      this.setState({ isExport: !this.state.isExport })
                    }
                    data={this.state.tableData}
                    filename={"my-file.csv"}
                    className="reportModal-exportBtn"
                    target="_blank"
                  >
                    Export
                  </CSVLink>
                </div>
              </div>
            </div>
          </Modal>
          <Snackbar
            open={this.state.showSnackbar}
            message={this.state.snackBarMessage}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(Overview);
