import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
// import moment from "moment";

// name.charAt(0).toUpperCase() + name.slice(1)  capetilize firstLetter

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 600,
  },
});

function StickyHeadTable(props) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // const formatDateTime = (dateTime) => {
  //   if (props.groupBy === 0) {
  //     return moment(dateTime).format("ll H:00");
  //   } else if (props.groupBy === 1) {
  //     return moment(dateTime).format("ll");
  //   } else if (props.groupBy === 2) {
  //     return moment(dateTime).format("ll");
  //   }
  //   // else if (props.groupBy === 3) {
  //   //    return moment(dateTime).format("MMMM YYYY");
  //   // }
  //   else if (props.groupBy === 6) {
  //     return moment(dateTime).format("H:00");
  //   } else {
  //     return dateTime;
  //   }
  // };
  const handleChangePage = (event, newPage) => {
    event.preventDefault();
    setPage(newPage);
    props.handlePagination("page", newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    //   setPage(0);
    props.handlePagination("rows", +event.target.value);
  };
  // const format = (value) => {
  //   return value.toFixed(2);
  // };
  let columns;
  if (!props.loader && props.tableData.length) {
    // { id: 'name', label: 'Name', minWidth: 170 }
    columns = Object.keys(props.tableData[0]);
    // columns = columns.slice(0, columns.length-1);
    columns = columns.map((columnName) => {
      return {
        id: columnName,
        label: columnName.charAt(0).toUpperCase() + columnName.slice(1), //capetilize firstLetter
      };
    });
  }

  if (props.loader) {
    return <div></div>;
  } else if (props.tableData.length === 0) {
    return <div></div>;
  }
  return (
    <Paper className={classes.root}>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((column, index) => {
                return (
                  <TableCell
                    style={{ fontSize: "14px", fontWeight: "600" }}
                    key={index}
                    align="center"
                  >
                    {column.label}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.tableData.map((row, index) => {
              return (
                <TableRow
                  hover
                  role="checkbox"
                  key={index}
                  //   style={
                  //      index % 2 == 0 ? { background: "#F6F6F6" } : { background: "#FFF" }
                  //   }
                >
                  {columns.map((column) => {
                    let value = row[column.id];
                    return (
                      <TableCell
                        style={{ fontSize: "12px" }}
                        // className={classes.data}
                        key={column.id}
                        align="center"
                      >
                        {value % 1 ? value.toFixed(2) : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={props.tableData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          // onChangePage={(e, newPage) => console.log("onchange", e, newPage)}
          // onChangeRowsPerPage={(e) => console.log("onChangeRowsPerPage", e)}
        />
      </TableContainer>
    </Paper>
  );
}

export default StickyHeadTable;
