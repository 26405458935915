import React, { Component } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';

import Row from "./row";

class Dropdown extends Component {
    constructor(props) {
        super(props)
        this.state = {
            rowsPerPage: 10,
            page: 0,
        };
    }

    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage,
        });
    };
    
    handleChangeRowsPerPage = (event) => {
        this.setState({
            rowsPerPage: +event.target.value,
        });
    };

    render() {
        return (
            <div style={{ width: "100%" }}>
                {/* <div className="activityShadowBox fontWeight d-flex justify-content-between align-items-center"
                    style={{ paddingRight: "200px" }}>
                    <span style={{ fontWeight: 700 }}>Make</span>
                    <span style={{ fontWeight: 700 }}>Total Count</span>
                    <span style={{ fontWeight: 700 }}>Unique Count</span>
                </div> */}
                <TableContainer component={Paper}>
                    <Table aria-label="collapsible table">
                        <TableHead style={{ backgroundColor: "whitesmoke"}}>
                            <TableRow>
                                <TableCell />
                                <TableCell style={{ fontWeight: 700, fontSize: "14px" }}>{parseInt(this.props.selection) === 1 ? 'Equipment Manufacturer' : 'Browser'}</TableCell>
                                <TableCell style={{ fontWeight: 700, fontSize: "14px" }} align="right">Total Count</TableCell>
                                <TableCell style={{ fontWeight: 700, fontSize: "14px" }} align="right">Unique Count</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.props.tableData.tabular_data.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((row, index) => (
                                <Row row={row} key={index} selection={this.props.selection}/>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    style={{ backgroundColor: "white" }}
                    rowsPerPageOptions={[5, 10, 15, 20, 25]}
                    id="backgroundShadow"
                    component="div"
                    count={this.props.tableData.tabular_data.length}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
            </div>
        );
    }
}

export default Dropdown;