import React, { Component } from "react";
import Link from "../../../../components/Link/Link";
import RevLoader from "../../../../components/loader/RevLoader";
import ApexCharts from "react-apexcharts";
import getSymbolFromCurrency from "currency-symbol-map";

// currencyFormatter.format(10000, {code: "USD"})

class AverageSales extends Component {
  render() {
    return (
      <div className="card">
        <div className="spaceBetween mt-3 ml-3 mr-3">
          <span className="label globalFontSize fontweightone">
            Average Sale
          </span>
          <span className="link globalFontSize fontweightone">
            <Link url={`sales-performance`}>View Activity</Link>
          </span>
        </div>
        <div className="spaceBetween ml-3 mr-3">
          <span className="numericValue globalFontSize">
            {this.props.loader ? (
              <RevLoader />
            ) : this.props.averageSale ? (
              `${getSymbolFromCurrency(this.props.currency)} ${
                this.props.averageSale
              }`
            ) : (
              `${getSymbolFromCurrency(this.props.currency)} ` + 0
            )}
          </span>
        </div>
        <div className="chart">
          <ApexCharts
            options={this.props.config.options}
            series={this.props.config.series}
            type="area"
            height={350}
          />
        </div>
      </div>
    );
  }
}

export default AverageSales;
