export const countries = [
  {
    name: "Afghanistan",
    topLevelDomain: [".af"],
    alpha2Code: "AF",
    alpha3Code: "AFG",
    callingCodes: ["93"],
    capital: "Kabul",
    altSpellings: ["AF", "Afġānistān"],
    subregion: "Southern Asia",
    region: "Asia",
    population: 38928341,
    latlng: [33, 65],
    demonym: "Afghan",
    area: 652230,
    timezones: ["UTC+04:30"],
    borders: ["IRN", "PAK", "TKM", "UZB", "TJK", "CHN"],
    nativeName: "افغانستان",
    numericCode: "004",
    flags: {
      svg: "https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_the_Taliban.svg",
      png: "https://upload.wikimedia.org/wikipedia/commons/thumb/5/5c/Flag_of_the_Taliban.svg/320px-Flag_of_the_Taliban.svg.png",
    },
    currencies: [
      {
        code: "AFN",
        name: "Afghan afghani",
        symbol: "؋",
      },
    ],
    languages: [
      {
        iso639_1: "ps",
        iso639_2: "pus",
        name: "Pashto",
        nativeName: "پښتو",
      },
      {
        iso639_1: "uz",
        iso639_2: "uzb",
        name: "Uzbek",
        nativeName: "Oʻzbek",
      },
      {
        iso639_1: "tk",
        iso639_2: "tuk",
        name: "Turkmen",
        nativeName: "Türkmen",
      },
    ],
    translations: {
      br: "Afeganistão",
      pt: "Afeganistão",
      nl: "Afghanistan",
      hr: "Afganistan",
      fa: "افغانستان",
      de: "Afghanistan",
      es: "Afganistán",
      fr: "Afghanistan",
      ja: "アフガニスタン",
      it: "Afghanistan",
      hu: "Afganisztán",
    },
    flag: "https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_the_Taliban.svg",
    regionalBlocs: [
      {
        acronym: "SAARC",
        name: "South Asian Association for Regional Cooperation",
      },
    ],
    cioc: "AFG",
    independent: true,
  },
  {
    name: "Åland Islands",
    topLevelDomain: [".ax"],
    alpha2Code: "AX",
    alpha3Code: "ALA",
    callingCodes: ["358"],
    capital: "Mariehamn",
    altSpellings: ["AX", "Aaland", "Aland", "Ahvenanmaa"],
    subregion: "Northern Europe",
    region: "Europe",
    population: 28875,
    latlng: [60.116667, 19.9],
    demonym: "Ålandish",
    area: 1580,
    timezones: ["UTC+02:00"],
    nativeName: "Åland",
    numericCode: "248",
    flags: {
      svg: "https://flagcdn.com/ax.svg",
      png: "https://flagcdn.com/w320/ax.png",
    },
    currencies: [
      {
        code: "EUR",
        name: "Euro",
        symbol: "€",
      },
    ],
    languages: [
      {
        iso639_1: "sv",
        iso639_2: "swe",
        name: "Swedish",
        nativeName: "svenska",
      },
    ],
    translations: {
      br: "Ilhas de Aland",
      pt: "Ilhas de Aland",
      nl: "Ålandeilanden",
      hr: "Ålandski otoci",
      fa: "جزایر الند",
      de: "Åland",
      es: "Alandia",
      fr: "Åland",
      ja: "オーランド諸島",
      it: "Isole Aland",
      hu: "Åland-szigetek",
    },
    flag: "https://flagcdn.com/ax.svg",
    regionalBlocs: [
      {
        acronym: "EU",
        name: "European Union",
      },
    ],
    independent: false,
  },
  {
    name: "Albania",
    topLevelDomain: [".al"],
    alpha2Code: "AL",
    alpha3Code: "ALB",
    callingCodes: ["355"],
    capital: "Tirana",
    altSpellings: ["AL", "Shqipëri", "Shqipëria", "Shqipnia"],
    subregion: "Southern Europe",
    region: "Europe",
    population: 2837743,
    latlng: [41, 20],
    demonym: "Albanian",
    area: 28748,
    gini: 33.2,
    timezones: ["UTC+01:00"],
    borders: ["MNE", "GRC", "MKD", "UNK"],
    nativeName: "Shqipëria",
    numericCode: "008",
    flags: {
      svg: "https://flagcdn.com/al.svg",
      png: "https://flagcdn.com/w320/al.png",
    },
    currencies: [
      {
        code: "ALL",
        name: "Albanian lek",
        symbol: "L",
      },
    ],
    languages: [
      {
        iso639_1: "sq",
        iso639_2: "sqi",
        name: "Albanian",
        nativeName: "Shqip",
      },
    ],
    translations: {
      br: "Albânia",
      pt: "Albânia",
      nl: "Albanië",
      hr: "Albanija",
      fa: "آلبانی",
      de: "Albanien",
      es: "Albania",
      fr: "Albanie",
      ja: "アルバニア",
      it: "Albania",
      hu: "Albánia",
    },
    flag: "https://flagcdn.com/al.svg",
    regionalBlocs: [
      {
        acronym: "CEFTA",
        name: "Central European Free Trade Agreement",
      },
    ],
    cioc: "ALB",
    independent: true,
  },
  {
    name: "Algeria",
    topLevelDomain: [".dz"],
    alpha2Code: "DZ",
    alpha3Code: "DZA",
    callingCodes: ["213"],
    capital: "Algiers",
    altSpellings: ["DZ", "Dzayer", "Algérie"],
    subregion: "Northern Africa",
    region: "Africa",
    population: 43851043,
    latlng: [28, 3],
    demonym: "Algerian",
    area: 2381741,
    gini: 27.6,
    timezones: ["UTC+01:00"],
    borders: ["TUN", "LBY", "NER", "ESH", "MRT", "MLI", "MAR"],
    nativeName: "الجزائر",
    numericCode: "012",
    flags: {
      svg: "https://flagcdn.com/dz.svg",
      png: "https://flagcdn.com/w320/dz.png",
    },
    currencies: [
      {
        code: "DZD",
        name: "Algerian dinar",
        symbol: "د.ج",
      },
    ],
    languages: [
      {
        iso639_1: "ar",
        iso639_2: "ara",
        name: "Arabic",
        nativeName: "العربية",
      },
    ],
    translations: {
      br: "Argélia",
      pt: "Argélia",
      nl: "Algerije",
      hr: "Alžir",
      fa: "الجزایر",
      de: "Algerien",
      es: "Argelia",
      fr: "Algérie",
      ja: "アルジェリア",
      it: "Algeria",
      hu: "Algéria",
    },
    flag: "https://flagcdn.com/dz.svg",
    regionalBlocs: [
      {
        acronym: "AU",
        name: "African Union",
        otherNames: ["الاتحاد الأفريقي", "Union africaine", "União Africana", "Unión Africana", "Umoja wa Afrika"],
      },
      {
        acronym: "AL",
        name: "Arab League",
        otherNames: ["جامعة الدول العربية", "Jāmiʻat ad-Duwal al-ʻArabīyah", "League of Arab States"],
      },
    ],
    cioc: "ALG",
    independent: true,
  },
  {
    name: "American Samoa",
    topLevelDomain: [".as"],
    alpha2Code: "AS",
    alpha3Code: "ASM",
    callingCodes: ["1"],
    capital: "Pago Pago",
    altSpellings: ["AS", "Amerika Sāmoa", "Amelika Sāmoa", "Sāmoa Amelika"],
    subregion: "Polynesia",
    region: "Oceania",
    population: 55197,
    latlng: [-14.33333333, -170],
    demonym: "American Samoan",
    area: 199,
    timezones: ["UTC-11:00"],
    nativeName: "American Samoa",
    numericCode: "016",
    flags: {
      svg: "https://flagcdn.com/as.svg",
      png: "https://flagcdn.com/w320/as.png",
    },
    currencies: [
      {
        code: "USD",
        name: "United States Dollar",
        symbol: "$",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
      {
        iso639_1: "sm",
        iso639_2: "smo",
        name: "Samoan",
        nativeName: "gagana fa'a Samoa",
      },
    ],
    translations: {
      br: "Samoa Americana",
      pt: "Samoa Americana",
      nl: "Amerikaans Samoa",
      hr: "Američka Samoa",
      fa: "ساموآی آمریکا",
      de: "Amerikanisch-Samoa",
      es: "Samoa Americana",
      fr: "Samoa américaines",
      ja: "アメリカ領サモア",
      it: "Samoa Americane",
      hu: "Amerikai Szamoa",
    },
    flag: "https://flagcdn.com/as.svg",
    cioc: "ASA",
    independent: false,
  },
  {
    name: "Andorra",
    topLevelDomain: [".ad"],
    alpha2Code: "AD",
    alpha3Code: "AND",
    callingCodes: ["376"],
    capital: "Andorra la Vella",
    altSpellings: ["AD", "Principality of Andorra", "Principat d'Andorra"],
    subregion: "Southern Europe",
    region: "Europe",
    population: 77265,
    latlng: [42.5, 1.5],
    demonym: "Andorran",
    area: 468,
    timezones: ["UTC+01:00"],
    borders: ["FRA", "ESP"],
    nativeName: "Andorra",
    numericCode: "020",
    flags: {
      svg: "https://flagcdn.com/ad.svg",
      png: "https://flagcdn.com/w320/ad.png",
    },
    currencies: [
      {
        code: "EUR",
        name: "Euro",
        symbol: "€",
      },
    ],
    languages: [
      {
        iso639_1: "ca",
        iso639_2: "cat",
        name: "Catalan",
        nativeName: "català",
      },
    ],
    translations: {
      br: "Andorra",
      pt: "Andorra",
      nl: "Andorra",
      hr: "Andora",
      fa: "آندورا",
      de: "Andorra",
      es: "Andorra",
      fr: "Andorre",
      ja: "アンドラ",
      it: "Andorra",
      hu: "Andorra",
    },
    flag: "https://flagcdn.com/ad.svg",
    cioc: "AND",
    independent: true,
  },
  {
    name: "Angola",
    topLevelDomain: [".ao"],
    alpha2Code: "AO",
    alpha3Code: "AGO",
    callingCodes: ["244"],
    capital: "Luanda",
    altSpellings: ["AO", "República de Angola", "ʁɛpublika de an'ɡɔla"],
    subregion: "Middle Africa",
    region: "Africa",
    population: 32866268,
    latlng: [-12.5, 18.5],
    demonym: "Angolan",
    area: 1246700,
    gini: 51.3,
    timezones: ["UTC+01:00"],
    borders: ["COG", "COD", "ZMB", "NAM"],
    nativeName: "Angola",
    numericCode: "024",
    flags: {
      svg: "https://flagcdn.com/ao.svg",
      png: "https://flagcdn.com/w320/ao.png",
    },
    currencies: [
      {
        code: "AOA",
        name: "Angolan kwanza",
        symbol: "Kz",
      },
    ],
    languages: [
      {
        iso639_1: "pt",
        iso639_2: "por",
        name: "Portuguese",
        nativeName: "Português",
      },
    ],
    translations: {
      br: "Angola",
      pt: "Angola",
      nl: "Angola",
      hr: "Angola",
      fa: "آنگولا",
      de: "Angola",
      es: "Angola",
      fr: "Angola",
      ja: "アンゴラ",
      it: "Angola",
      hu: "Angola",
    },
    flag: "https://flagcdn.com/ao.svg",
    regionalBlocs: [
      {
        acronym: "AU",
        name: "African Union",
        otherNames: ["الاتحاد الأفريقي", "Union africaine", "União Africana", "Unión Africana", "Umoja wa Afrika"],
      },
    ],
    cioc: "ANG",
    independent: true,
  },
  {
    name: "Anguilla",
    topLevelDomain: [".ai"],
    alpha2Code: "AI",
    alpha3Code: "AIA",
    callingCodes: ["1"],
    capital: "The Valley",
    altSpellings: ["AI"],
    subregion: "Caribbean",
    region: "Americas",
    population: 13452,
    latlng: [18.25, -63.16666666],
    demonym: "Anguillian",
    area: 91,
    timezones: ["UTC-04:00"],
    nativeName: "Anguilla",
    numericCode: "660",
    flags: {
      svg: "https://flagcdn.com/ai.svg",
      png: "https://flagcdn.com/w320/ai.png",
    },
    currencies: [
      {
        code: "XCD",
        name: "East Caribbean dollar",
        symbol: "$",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
    ],
    translations: {
      br: "Anguila",
      pt: "Anguila",
      nl: "Anguilla",
      hr: "Angvila",
      fa: "آنگویلا",
      de: "Anguilla",
      es: "Anguilla",
      fr: "Anguilla",
      ja: "アンギラ",
      it: "Anguilla",
      hu: "Anguilla",
    },
    flag: "https://flagcdn.com/ai.svg",
    independent: false,
  },
  {
    name: "Antigua and Barbuda",
    topLevelDomain: [".ag"],
    alpha2Code: "AG",
    alpha3Code: "ATG",
    callingCodes: ["1"],
    capital: "Saint John's",
    altSpellings: ["AG"],
    subregion: "Caribbean",
    region: "Americas",
    population: 97928,
    latlng: [17.05, -61.8],
    demonym: "Antiguan, Barbudan",
    area: 442,
    timezones: ["UTC-04:00"],
    nativeName: "Antigua and Barbuda",
    numericCode: "028",
    flags: {
      svg: "https://flagcdn.com/ag.svg",
      png: "https://flagcdn.com/w320/ag.png",
    },
    currencies: [
      {
        code: "XCD",
        name: "East Caribbean dollar",
        symbol: "$",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
    ],
    translations: {
      br: "Antígua e Barbuda",
      pt: "Antígua e Barbuda",
      nl: "Antigua en Barbuda",
      hr: "Antigva i Barbuda",
      fa: "آنتیگوا و باربودا",
      de: "Antigua und Barbuda",
      es: "Antigua y Barbuda",
      fr: "Antigua-et-Barbuda",
      ja: "アンティグア・バーブーダ",
      it: "Antigua e Barbuda",
      hu: "Antigua és Barbuda",
    },
    flag: "https://flagcdn.com/ag.svg",
    regionalBlocs: [
      {
        acronym: "CARICOM",
        name: "Caribbean Community",
        otherNames: ["Comunidad del Caribe", "Communauté Caribéenne", "Caribische Gemeenschap"],
      },
    ],
    cioc: "ANT",
    independent: true,
  },
  {
    name: "Argentina",
    topLevelDomain: [".ar"],
    alpha2Code: "AR",
    alpha3Code: "ARG",
    callingCodes: ["54"],
    capital: "Buenos Aires",
    altSpellings: ["AR", "Argentine Republic", "República Argentina"],
    subregion: "South America",
    region: "Americas",
    population: 45376763,
    latlng: [-34, -64],
    demonym: "Argentinean",
    area: 2780400,
    gini: 42.9,
    timezones: ["UTC-03:00"],
    borders: ["BOL", "BRA", "CHL", "PRY", "URY"],
    nativeName: "Argentina",
    numericCode: "032",
    flags: {
      svg: "https://flagcdn.com/ar.svg",
      png: "https://flagcdn.com/w320/ar.png",
    },
    currencies: [
      {
        code: "ARS",
        name: "Argentine peso",
        symbol: "$",
      },
    ],
    languages: [
      {
        iso639_1: "es",
        iso639_2: "spa",
        name: "Spanish",
        nativeName: "Español",
      },
      {
        iso639_1: "gn",
        iso639_2: "grn",
        name: "Guaraní",
        nativeName: "Avañe'ẽ",
      },
    ],
    translations: {
      br: "Argentina",
      pt: "Argentina",
      nl: "Argentinië",
      hr: "Argentina",
      fa: "آرژانتین",
      de: "Argentinien",
      es: "Argentina",
      fr: "Argentine",
      ja: "アルゼンチン",
      it: "Argentina",
      hu: "Argentína",
    },
    flag: "https://flagcdn.com/ar.svg",
    regionalBlocs: [
      {
        acronym: "USAN",
        name: "Union of South American Nations",
        otherAcronyms: ["UNASUR", "UNASUL", "UZAN"],
        otherNames: ["Unión de Naciones Suramericanas", "União de Nações Sul-Americanas", "Unie van Zuid-Amerikaanse Naties", "South American Union"],
      },
    ],
    cioc: "ARG",
    independent: true,
  },
  {
    name: "Armenia",
    topLevelDomain: [".am"],
    alpha2Code: "AM",
    alpha3Code: "ARM",
    callingCodes: ["374"],
    capital: "Yerevan",
    altSpellings: ["AM", "Hayastan", "Republic of Armenia", "Հայաստանի Հանրապետություն"],
    subregion: "Western Asia",
    region: "Asia",
    population: 2963234,
    latlng: [40, 45],
    demonym: "Armenian",
    area: 29743,
    gini: 29.9,
    timezones: ["UTC+04:00"],
    borders: ["AZE", "GEO", "IRN", "TUR"],
    nativeName: "Հայաստան",
    numericCode: "051",
    flags: {
      svg: "https://flagcdn.com/am.svg",
      png: "https://flagcdn.com/w320/am.png",
    },
    currencies: [
      {
        code: "AMD",
        name: "Armenian dram",
        symbol: "֏",
      },
    ],
    languages: [
      {
        iso639_1: "hy",
        iso639_2: "hye",
        name: "Armenian",
        nativeName: "Հայերեն",
      },
    ],
    translations: {
      br: "Armênia",
      pt: "Arménia",
      nl: "Armenië",
      hr: "Armenija",
      fa: "ارمنستان",
      de: "Armenien",
      es: "Armenia",
      fr: "Arménie",
      ja: "アルメニア",
      it: "Armenia",
      hu: "Örményország",
    },
    flag: "https://flagcdn.com/am.svg",
    regionalBlocs: [
      {
        acronym: "EEU",
        name: "Eurasian Economic Union",
        otherAcronyms: ["EAEU"],
      },
    ],
    cioc: "ARM",
    independent: true,
  },
  {
    name: "Aruba",
    topLevelDomain: [".aw"],
    alpha2Code: "AW",
    alpha3Code: "ABW",
    callingCodes: ["297"],
    capital: "Oranjestad",
    altSpellings: ["AW"],
    subregion: "Caribbean",
    region: "Americas",
    population: 106766,
    latlng: [12.5, -69.96666666],
    demonym: "Aruban",
    area: 180,
    timezones: ["UTC-04:00"],
    nativeName: "Aruba",
    numericCode: "533",
    flags: {
      svg: "https://flagcdn.com/aw.svg",
      png: "https://flagcdn.com/w320/aw.png",
    },
    currencies: [
      {
        code: "AWG",
        name: "Aruban florin",
        symbol: "ƒ",
      },
    ],
    languages: [
      {
        iso639_1: "nl",
        iso639_2: "nld",
        name: "Dutch",
        nativeName: "Nederlands",
      },
      {
        iso639_1: "pa",
        iso639_2: "pan",
        name: "(Eastern) Punjabi",
        nativeName: "ਪੰਜਾਬੀ",
      },
    ],
    translations: {
      br: "Aruba",
      pt: "Aruba",
      nl: "Aruba",
      hr: "Aruba",
      fa: "آروبا",
      de: "Aruba",
      es: "Aruba",
      fr: "Aruba",
      ja: "アルバ",
      it: "Aruba",
      hu: "Aruba",
    },
    flag: "https://flagcdn.com/aw.svg",
    cioc: "ARU",
    independent: true,
  },
  {
    name: "Australia",
    topLevelDomain: [".au"],
    alpha2Code: "AU",
    alpha3Code: "AUS",
    callingCodes: ["61"],
    capital: "Canberra",
    altSpellings: ["AU"],
    subregion: "Australia and New Zealand",
    region: "Oceania",
    population: 25687041,
    latlng: [-27, 133],
    demonym: "Australian",
    area: 7692024,
    gini: 34.4,
    timezones: ["UTC+05:00", "UTC+06:30", "UTC+07:00", "UTC+08:00", "UTC+09:30", "UTC+10:00", "UTC+10:30", "UTC+11:30"],
    nativeName: "Australia",
    numericCode: "036",
    flags: {
      svg: "https://flagcdn.com/au.svg",
      png: "https://flagcdn.com/w320/au.png",
    },
    currencies: [
      {
        code: "AUD",
        name: "Australian dollar",
        symbol: "$",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
    ],
    translations: {
      br: "Austrália",
      pt: "Austrália",
      nl: "Australië",
      hr: "Australija",
      fa: "استرالیا",
      de: "Australien",
      es: "Australia",
      fr: "Australie",
      ja: "オーストラリア",
      it: "Australia",
      hu: "Ausztrália",
    },
    flag: "https://flagcdn.com/au.svg",
    cioc: "AUS",
    independent: true,
  },
  {
    name: "Austria",
    topLevelDomain: [".at"],
    alpha2Code: "AT",
    alpha3Code: "AUT",
    callingCodes: ["43"],
    capital: "Vienna",
    altSpellings: ["AT", "Österreich", "Osterreich", "Oesterreich"],
    subregion: "Central Europe",
    region: "Europe",
    population: 8917205,
    latlng: [47.33333333, 13.33333333],
    demonym: "Austrian",
    area: 83871,
    gini: 30.8,
    timezones: ["UTC+01:00"],
    borders: ["CZE", "DEU", "HUN", "ITA", "LIE", "SVK", "SVN", "CHE"],
    nativeName: "Österreich",
    numericCode: "040",
    flags: {
      svg: "https://flagcdn.com/at.svg",
      png: "https://flagcdn.com/w320/at.png",
    },
    currencies: [
      {
        code: "EUR",
        name: "Euro",
        symbol: "€",
      },
    ],
    languages: [
      {
        iso639_1: "de",
        iso639_2: "deu",
        name: "German",
        nativeName: "Deutsch",
      },
    ],
    translations: {
      br: "Áustria",
      pt: "Áustria",
      nl: "Oostenrijk",
      hr: "Austrija",
      fa: "اتریش",
      de: "Österreich",
      es: "Austria",
      fr: "Autriche",
      ja: "オーストリア",
      it: "Austria",
      hu: "Ausztria",
    },
    flag: "https://flagcdn.com/at.svg",
    regionalBlocs: [
      {
        acronym: "EU",
        name: "European Union",
      },
    ],
    cioc: "AUT",
    independent: true,
  },
  {
    name: "Azerbaijan",
    topLevelDomain: [".az"],
    alpha2Code: "AZ",
    alpha3Code: "AZE",
    callingCodes: ["994"],
    capital: "Baku",
    altSpellings: ["AZ", "Republic of Azerbaijan", "Azərbaycan Respublikası"],
    subregion: "Western Asia",
    region: "Asia",
    population: 10110116,
    latlng: [40.5, 47.5],
    demonym: "Azerbaijani",
    area: 86600,
    gini: 26.6,
    timezones: ["UTC+04:00"],
    borders: ["ARM", "GEO", "IRN", "RUS", "TUR"],
    nativeName: "Azərbaycan",
    numericCode: "031",
    flags: {
      svg: "https://flagcdn.com/az.svg",
      png: "https://flagcdn.com/w320/az.png",
    },
    currencies: [
      {
        code: "AZN",
        name: "Azerbaijani manat",
        symbol: "₼",
      },
    ],
    languages: [
      {
        iso639_1: "az",
        iso639_2: "aze",
        name: "Azerbaijani",
        nativeName: "azərbaycan dili",
      },
    ],
    translations: {
      br: "Azerbaijão",
      pt: "Azerbaijão",
      nl: "Azerbeidzjan",
      hr: "Azerbajdžan",
      fa: "آذربایجان",
      de: "Aserbaidschan",
      es: "Azerbaiyán",
      fr: "Azerbaïdjan",
      ja: "アゼルバイジャン",
      it: "Azerbaijan",
      hu: "Azerbajdzsán",
    },
    flag: "https://flagcdn.com/az.svg",
    cioc: "AZE",
    independent: true,
  },
  {
    name: "Bahamas",
    topLevelDomain: [".bs"],
    alpha2Code: "BS",
    alpha3Code: "BHS",
    callingCodes: ["1"],
    capital: "Nassau",
    altSpellings: ["BS", "Commonwealth of the Bahamas"],
    subregion: "Caribbean",
    region: "Americas",
    population: 393248,
    latlng: [24.25, -76],
    demonym: "Bahamian",
    area: 13943,
    timezones: ["UTC-05:00"],
    nativeName: "Bahamas",
    numericCode: "044",
    flags: {
      svg: "https://flagcdn.com/bs.svg",
      png: "https://flagcdn.com/w320/bs.png",
    },
    currencies: [
      {
        code: "BSD",
        name: "Bahamian dollar",
        symbol: "$",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
    ],
    translations: {
      br: "Bahamas",
      pt: "Baamas",
      nl: "Bahama’s",
      hr: "Bahami",
      fa: "باهاما",
      de: "Bahamas",
      es: "Bahamas",
      fr: "Bahamas",
      ja: "バハマ",
      it: "Bahamas",
      hu: "Bahama-szigetek",
    },
    flag: "https://flagcdn.com/bs.svg",
    regionalBlocs: [
      {
        acronym: "CARICOM",
        name: "Caribbean Community",
        otherNames: ["Comunidad del Caribe", "Communauté Caribéenne", "Caribische Gemeenschap"],
      },
    ],
    cioc: "BAH",
    independent: true,
  },
  {
    name: "Bahrain",
    topLevelDomain: [".bh"],
    alpha2Code: "BH",
    alpha3Code: "BHR",
    callingCodes: ["973"],
    capital: "Manama",
    altSpellings: ["BH", "Kingdom of Bahrain", "Mamlakat al-Baḥrayn"],
    subregion: "Western Asia",
    region: "Asia",
    population: 1701583,
    latlng: [26, 50.55],
    demonym: "Bahraini",
    area: 765,
    timezones: ["UTC+03:00"],
    nativeName: "‏البحرين",
    numericCode: "048",
    flags: {
      svg: "https://flagcdn.com/bh.svg",
      png: "https://flagcdn.com/w320/bh.png",
    },
    currencies: [
      {
        code: "BHD",
        name: "Bahraini dinar",
        symbol: ".د.ب",
      },
    ],
    languages: [
      {
        iso639_1: "ar",
        iso639_2: "ara",
        name: "Arabic",
        nativeName: "العربية",
      },
    ],
    translations: {
      br: "Bahrein",
      pt: "Barém",
      nl: "Bahrein",
      hr: "Bahrein",
      fa: "بحرین",
      de: "Bahrain",
      es: "Bahrein",
      fr: "Bahreïn",
      ja: "バーレーン",
      it: "Bahrein",
      hu: "Bahrein",
    },
    flag: "https://flagcdn.com/bh.svg",
    regionalBlocs: [
      {
        acronym: "AL",
        name: "Arab League",
        otherNames: ["جامعة الدول العربية", "Jāmiʻat ad-Duwal al-ʻArabīyah", "League of Arab States"],
      },
    ],
    cioc: "BRN",
    independent: true,
  },
  {
    name: "Bangladesh",
    topLevelDomain: [".bd"],
    alpha2Code: "BD",
    alpha3Code: "BGD",
    callingCodes: ["880"],
    capital: "Dhaka",
    altSpellings: ["BD", "People's Republic of Bangladesh", "Gônôprôjatôntri Bangladesh"],
    subregion: "Southern Asia",
    region: "Asia",
    population: 164689383,
    latlng: [24, 90],
    demonym: "Bangladeshi",
    area: 147570,
    gini: 32.4,
    timezones: ["UTC+06:00"],
    borders: ["MMR", "IND"],
    nativeName: "Bangladesh",
    numericCode: "050",
    flags: {
      svg: "https://flagcdn.com/bd.svg",
      png: "https://flagcdn.com/w320/bd.png",
    },
    currencies: [
      {
        code: "BDT",
        name: "Bangladeshi taka",
        symbol: "৳",
      },
    ],
    languages: [
      {
        iso639_1: "bn",
        iso639_2: "ben",
        name: "Bengali",
        nativeName: "বাংলা",
      },
    ],
    translations: {
      br: "Bangladesh",
      pt: "Bangladeche",
      nl: "Bangladesh",
      hr: "Bangladeš",
      fa: "بنگلادش",
      de: "Bangladesch",
      es: "Bangladesh",
      fr: "Bangladesh",
      ja: "バングラデシュ",
      it: "Bangladesh",
      hu: "Banglades",
    },
    flag: "https://flagcdn.com/bd.svg",
    regionalBlocs: [
      {
        acronym: "SAARC",
        name: "South Asian Association for Regional Cooperation",
      },
    ],
    cioc: "BAN",
    independent: true,
  },
  {
    name: "Barbados",
    topLevelDomain: [".bb"],
    alpha2Code: "BB",
    alpha3Code: "BRB",
    callingCodes: ["1"],
    capital: "Bridgetown",
    altSpellings: ["BB"],
    subregion: "Caribbean",
    region: "Americas",
    population: 287371,
    latlng: [13.16666666, -59.53333333],
    demonym: "Barbadian",
    area: 430,
    timezones: ["UTC-04:00"],
    nativeName: "Barbados",
    numericCode: "052",
    flags: {
      svg: "https://flagcdn.com/bb.svg",
      png: "https://flagcdn.com/w320/bb.png",
    },
    currencies: [
      {
        code: "BBD",
        name: "Barbadian dollar",
        symbol: "$",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
    ],
    translations: {
      br: "Barbados",
      pt: "Barbados",
      nl: "Barbados",
      hr: "Barbados",
      fa: "باربادوس",
      de: "Barbados",
      es: "Barbados",
      fr: "Barbade",
      ja: "バルバドス",
      it: "Barbados",
      hu: "Barbados",
    },
    flag: "https://flagcdn.com/bb.svg",
    regionalBlocs: [
      {
        acronym: "CARICOM",
        name: "Caribbean Community",
        otherNames: ["Comunidad del Caribe", "Communauté Caribéenne", "Caribische Gemeenschap"],
      },
    ],
    cioc: "BAR",
    independent: true,
  },
  {
    name: "Belarus",
    topLevelDomain: [".by"],
    alpha2Code: "BY",
    alpha3Code: "BLR",
    callingCodes: ["375"],
    capital: "Minsk",
    altSpellings: ["BY", "Bielaruś", "Republic of Belarus", "Белоруссия", "Республика Беларусь", "Belorussiya", "Respublika Belarus’"],
    subregion: "Eastern Europe",
    region: "Europe",
    population: 9398861,
    latlng: [53, 28],
    demonym: "Belarusian",
    area: 207600,
    gini: 25.3,
    timezones: ["UTC+03:00"],
    borders: ["LVA", "LTU", "POL", "RUS", "UKR"],
    nativeName: "Белару́сь",
    numericCode: "112",
    flags: {
      svg: "https://flagcdn.com/by.svg",
      png: "https://flagcdn.com/w320/by.png",
    },
    currencies: [
      {
        code: "BYN",
        name: "New Belarusian ruble",
        symbol: "Br",
      },
      {
        code: "BYR",
        name: "Old Belarusian ruble",
        symbol: "Br",
      },
    ],
    languages: [
      {
        iso639_1: "be",
        iso639_2: "bel",
        name: "Belarusian",
        nativeName: "беларуская мова",
      },
      {
        iso639_1: "ru",
        iso639_2: "rus",
        name: "Russian",
        nativeName: "Русский",
      },
    ],
    translations: {
      br: "Bielorrússia",
      pt: "Bielorrússia",
      nl: "Wit-Rusland",
      hr: "Bjelorusija",
      fa: "بلاروس",
      de: "Weißrussland",
      es: "Bielorrusia",
      fr: "Biélorussie",
      ja: "ベラルーシ",
      it: "Bielorussia",
      hu: "Fehéroroszország",
    },
    flag: "https://flagcdn.com/by.svg",
    regionalBlocs: [
      {
        acronym: "EEU",
        name: "Eurasian Economic Union",
        otherAcronyms: ["EAEU"],
      },
    ],
    cioc: "BLR",
    independent: true,
  },
  {
    name: "Belgium",
    topLevelDomain: [".be"],
    alpha2Code: "BE",
    alpha3Code: "BEL",
    callingCodes: ["32"],
    capital: "Brussels",
    altSpellings: ["BE", "België", "Belgie", "Belgien", "Belgique", "Kingdom of Belgium", "Koninkrijk België", "Royaume de Belgique", "Königreich Belgien"],
    subregion: "Western Europe",
    region: "Europe",
    population: 11555997,
    latlng: [50.83333333, 4],
    demonym: "Belgian",
    area: 30528,
    gini: 27.2,
    timezones: ["UTC+01:00"],
    borders: ["FRA", "DEU", "LUX", "NLD"],
    nativeName: "België",
    numericCode: "056",
    flags: {
      svg: "https://flagcdn.com/be.svg",
      png: "https://flagcdn.com/w320/be.png",
    },
    currencies: [
      {
        code: "EUR",
        name: "Euro",
        symbol: "€",
      },
    ],
    languages: [
      {
        iso639_1: "nl",
        iso639_2: "nld",
        name: "Dutch",
        nativeName: "Nederlands",
      },
      {
        iso639_1: "fr",
        iso639_2: "fra",
        name: "French",
        nativeName: "français",
      },
      {
        iso639_1: "de",
        iso639_2: "deu",
        name: "German",
        nativeName: "Deutsch",
      },
    ],
    translations: {
      br: "Bélgica",
      pt: "Bélgica",
      nl: "België",
      hr: "Belgija",
      fa: "بلژیک",
      de: "Belgien",
      es: "Bélgica",
      fr: "Belgique",
      ja: "ベルギー",
      it: "Belgio",
      hu: "Belgium",
    },
    flag: "https://flagcdn.com/be.svg",
    regionalBlocs: [
      {
        acronym: "EU",
        name: "European Union",
      },
    ],
    cioc: "BEL",
    independent: true,
  },
  {
    name: "Belize",
    topLevelDomain: [".bz"],
    alpha2Code: "BZ",
    alpha3Code: "BLZ",
    callingCodes: ["501"],
    capital: "Belmopan",
    altSpellings: ["BZ"],
    subregion: "Central America",
    region: "Americas",
    population: 397621,
    latlng: [17.25, -88.75],
    demonym: "Belizean",
    area: 22966,
    gini: 53.3,
    timezones: ["UTC-06:00"],
    borders: ["GTM", "MEX"],
    nativeName: "Belize",
    numericCode: "084",
    flags: {
      svg: "https://flagcdn.com/bz.svg",
      png: "https://flagcdn.com/w320/bz.png",
    },
    currencies: [
      {
        code: "BZD",
        name: "Belize dollar",
        symbol: "$",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
      {
        iso639_1: "es",
        iso639_2: "spa",
        name: "Spanish",
        nativeName: "Español",
      },
    ],
    translations: {
      br: "Belize",
      pt: "Belize",
      nl: "Belize",
      hr: "Belize",
      fa: "بلیز",
      de: "Belize",
      es: "Belice",
      fr: "Belize",
      ja: "ベリーズ",
      it: "Belize",
      hu: "Belize",
    },
    flag: "https://flagcdn.com/bz.svg",
    regionalBlocs: [
      {
        acronym: "CARICOM",
        name: "Caribbean Community",
        otherNames: ["Comunidad del Caribe", "Communauté Caribéenne", "Caribische Gemeenschap"],
      },
      {
        acronym: "CAIS",
        name: "Central American Integration System",
        otherAcronyms: ["SICA"],
        otherNames: ["Sistema de la Integración Centroamericana,"],
      },
    ],
    cioc: "BIZ",
    independent: true,
  },
  {
    name: "Benin",
    topLevelDomain: [".bj"],
    alpha2Code: "BJ",
    alpha3Code: "BEN",
    callingCodes: ["229"],
    capital: "Porto-Novo",
    altSpellings: ["BJ", "Republic of Benin", "République du Bénin"],
    subregion: "Western Africa",
    region: "Africa",
    population: 12123198,
    latlng: [9.5, 2.25],
    demonym: "Beninese",
    area: 112622,
    gini: 47.8,
    timezones: ["UTC+01:00"],
    borders: ["BFA", "NER", "NGA", "TGO"],
    nativeName: "Bénin",
    numericCode: "204",
    flags: {
      svg: "https://flagcdn.com/bj.svg",
      png: "https://flagcdn.com/w320/bj.png",
    },
    currencies: [
      {
        code: "XOF",
        name: "West African CFA franc",
        symbol: "Fr",
      },
    ],
    languages: [
      {
        iso639_1: "fr",
        iso639_2: "fra",
        name: "French",
        nativeName: "français",
      },
    ],
    translations: {
      br: "Benin",
      pt: "Benim",
      nl: "Benin",
      hr: "Benin",
      fa: "بنین",
      de: "Benin",
      es: "Benín",
      fr: "Bénin",
      ja: "ベナン",
      it: "Benin",
      hu: "Benin",
    },
    flag: "https://flagcdn.com/bj.svg",
    regionalBlocs: [
      {
        acronym: "AU",
        name: "African Union",
        otherNames: ["الاتحاد الأفريقي", "Union africaine", "União Africana", "Unión Africana", "Umoja wa Afrika"],
      },
    ],
    cioc: "BEN",
    independent: true,
  },
  {
    name: "Bermuda",
    topLevelDomain: [".bm"],
    alpha2Code: "BM",
    alpha3Code: "BMU",
    callingCodes: ["1"],
    capital: "Hamilton",
    altSpellings: ["BM", "The Islands of Bermuda", "The Bermudas", "Somers Isles"],
    subregion: "Northern America",
    region: "Americas",
    population: 63903,
    latlng: [32.33333333, -64.75],
    demonym: "Bermudian",
    area: 54,
    timezones: ["UTC-04:00"],
    nativeName: "Bermuda",
    numericCode: "060",
    flags: {
      svg: "https://flagcdn.com/bm.svg",
      png: "https://flagcdn.com/w320/bm.png",
    },
    currencies: [
      {
        code: "BMD",
        name: "Bermudian dollar",
        symbol: "$",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
    ],
    translations: {
      br: "Bermudas",
      pt: "Bermudas",
      nl: "Bermuda",
      hr: "Bermudi",
      fa: "برمودا",
      de: "Bermuda",
      es: "Bermudas",
      fr: "Bermudes",
      ja: "バミューダ",
      it: "Bermuda",
      hu: "Bermuda",
    },
    flag: "https://flagcdn.com/bm.svg",
    cioc: "BER",
    independent: false,
  },
  {
    name: "Bhutan",
    topLevelDomain: [".bt"],
    alpha2Code: "BT",
    alpha3Code: "BTN",
    callingCodes: ["975"],
    capital: "Thimphu",
    altSpellings: ["BT", "Kingdom of Bhutan"],
    subregion: "Southern Asia",
    region: "Asia",
    population: 771612,
    latlng: [27.5, 90.5],
    demonym: "Bhutanese",
    area: 38394,
    gini: 37.4,
    timezones: ["UTC+06:00"],
    borders: ["CHN", "IND"],
    nativeName: "ʼbrug-yul",
    numericCode: "064",
    flags: {
      svg: "https://flagcdn.com/bt.svg",
      png: "https://flagcdn.com/w320/bt.png",
    },
    currencies: [
      {
        code: "BTN",
        name: "Bhutanese ngultrum",
        symbol: "Nu.",
      },
      {
        code: "INR",
        name: "Indian rupee",
        symbol: "₹",
      },
    ],
    languages: [
      {
        iso639_1: "dz",
        iso639_2: "dzo",
        name: "Dzongkha",
        nativeName: "རྫོང་ཁ",
      },
    ],
    translations: {
      br: "Butão",
      pt: "Butão",
      nl: "Bhutan",
      hr: "Butan",
      fa: "بوتان",
      de: "Bhutan",
      es: "Bután",
      fr: "Bhoutan",
      ja: "ブータン",
      it: "Bhutan",
      hu: "Bhután",
    },
    flag: "https://flagcdn.com/bt.svg",
    regionalBlocs: [
      {
        acronym: "SAARC",
        name: "South Asian Association for Regional Cooperation",
      },
    ],
    cioc: "BHU",
    independent: true,
  },
  {
    name: "Bolivia (Plurinational State of)",
    topLevelDomain: [".bo"],
    alpha2Code: "BO",
    alpha3Code: "BOL",
    callingCodes: ["591"],
    capital: "Sucre",
    altSpellings: [
      "BO",
      "Buliwya",
      "Wuliwya",
      "Plurinational State of Bolivia",
      "Estado Plurinacional de Bolivia",
      "Buliwya Mamallaqta",
      "Wuliwya Suyu",
      "Tetã Volívia",
    ],
    subregion: "South America",
    region: "Americas",
    population: 11673029,
    latlng: [-17, -65],
    demonym: "Bolivian",
    area: 1098581,
    gini: 41.6,
    timezones: ["UTC-04:00"],
    borders: ["ARG", "BRA", "CHL", "PRY", "PER"],
    nativeName: "Bolivia",
    numericCode: "068",
    flags: {
      svg: "https://flagcdn.com/bo.svg",
      png: "https://flagcdn.com/w320/bo.png",
    },
    currencies: [
      {
        code: "BOB",
        name: "Bolivian boliviano",
        symbol: "Bs.",
      },
    ],
    languages: [
      {
        iso639_1: "es",
        iso639_2: "spa",
        name: "Spanish",
        nativeName: "Español",
      },
      {
        iso639_1: "ay",
        iso639_2: "aym",
        name: "Aymara",
        nativeName: "aymar aru",
      },
      {
        iso639_1: "qu",
        iso639_2: "que",
        name: "Quechua",
        nativeName: "Runa Simi",
      },
    ],
    translations: {
      br: "Bolívia",
      pt: "Bolívia",
      nl: "Bolivia",
      hr: "Bolivija",
      fa: "بولیوی",
      de: "Bolivien",
      es: "Bolivia",
      fr: "Bolivie",
      ja: "ボリビア多民族国",
      it: "Bolivia",
      hu: "Bolívia",
    },
    flag: "https://flagcdn.com/bo.svg",
    regionalBlocs: [
      {
        acronym: "USAN",
        name: "Union of South American Nations",
        otherAcronyms: ["UNASUR", "UNASUL", "UZAN"],
        otherNames: ["Unión de Naciones Suramericanas", "União de Nações Sul-Americanas", "Unie van Zuid-Amerikaanse Naties", "South American Union"],
      },
    ],
    cioc: "BOL",
    independent: true,
  },
  {
    name: "Bonaire, Sint Eustatius and Saba",
    topLevelDomain: [".an", ".nl"],
    alpha2Code: "BQ",
    alpha3Code: "BES",
    callingCodes: ["599"],
    capital: "Kralendijk",
    altSpellings: ["BQ", "Boneiru"],
    subregion: "Caribbean",
    region: "Americas",
    population: 17408,
    latlng: [12.15, -68.266667],
    demonym: "Dutch",
    area: 294,
    timezones: ["UTC-04:00"],
    nativeName: "Bonaire",
    numericCode: "535",
    flags: {
      svg: "https://flagcdn.com/bq.svg",
      png: "https://flagcdn.com/w320/bq.png",
    },
    currencies: [
      {
        code: "USD",
        name: "United States dollar",
        symbol: "$",
      },
    ],
    languages: [
      {
        iso639_1: "nl",
        iso639_2: "nld",
        name: "Dutch",
        nativeName: "Nederlands",
      },
    ],
    translations: {
      br: "Bonaire",
      pt: "Bonaire",
      nl: "Caribisch Nederland",
      hr: "Bonaire, Sint Eustatius and Saba",
      fa: "بونیر",
      de: "Bonaire, Sint Eustatius und Saba",
      es: "Bonaire, Sint Eustatius and Saba",
      fr: "Bonaire, Saint-Eustache et Saba",
      ja: "Bonaire, Sint Eustatius and Saba",
      it: "Bonaire, Saint-Eustache e Saba",
      hu: "Bonaire",
    },
    flag: "https://flagcdn.com/bq.svg",
    independent: true,
  },
  {
    name: "Bosnia and Herzegovina",
    topLevelDomain: [".ba"],
    alpha2Code: "BA",
    alpha3Code: "BIH",
    callingCodes: ["387"],
    capital: "Sarajevo",
    altSpellings: ["BA", "Bosnia-Herzegovina", "Босна и Херцеговина"],
    subregion: "Southern Europe",
    region: "Europe",
    population: 3280815,
    latlng: [44, 18],
    demonym: "Bosnian, Herzegovinian",
    area: 51209,
    gini: 33,
    timezones: ["UTC+01:00"],
    borders: ["HRV", "MNE", "SRB"],
    nativeName: "Bosna i Hercegovina",
    numericCode: "070",
    flags: {
      svg: "https://flagcdn.com/ba.svg",
      png: "https://flagcdn.com/w320/ba.png",
    },
    currencies: [
      {
        code: "BAM",
        name: "Bosnia and Herzegovina convertible mark",
        symbol: "KM",
      },
    ],
    languages: [
      {
        iso639_1: "bs",
        iso639_2: "bos",
        name: "Bosnian",
        nativeName: "bosanski jezik",
      },
      {
        iso639_1: "hr",
        iso639_2: "hrv",
        name: "Croatian",
        nativeName: "hrvatski jezik",
      },
      {
        iso639_1: "sr",
        iso639_2: "srp",
        name: "Serbian",
        nativeName: "српски језик",
      },
    ],
    translations: {
      br: "Bósnia e Herzegovina",
      pt: "Bósnia e Herzegovina",
      nl: "Bosnië en Herzegovina",
      hr: "Bosna i Hercegovina",
      fa: "بوسنی و هرزگوین",
      de: "Bosnien und Herzegowina",
      es: "Bosnia y Herzegovina",
      fr: "Bosnie-Herzégovine",
      ja: "ボスニア・ヘルツェゴビナ",
      it: "Bosnia ed Erzegovina",
      hu: "Bosznia-Hercegovina",
    },
    flag: "https://flagcdn.com/ba.svg",
    regionalBlocs: [
      {
        acronym: "CEFTA",
        name: "Central European Free Trade Agreement",
      },
    ],
    cioc: "BIH",
    independent: true,
  },
  {
    name: "Botswana",
    topLevelDomain: [".bw"],
    alpha2Code: "BW",
    alpha3Code: "BWA",
    callingCodes: ["267"],
    capital: "Gaborone",
    altSpellings: ["BW", "Republic of Botswana", "Lefatshe la Botswana"],
    subregion: "Southern Africa",
    region: "Africa",
    population: 2351625,
    latlng: [-22, 24],
    demonym: "Motswana",
    area: 582000,
    gini: 53.3,
    timezones: ["UTC+02:00"],
    borders: ["NAM", "ZAF", "ZMB", "ZWE"],
    nativeName: "Botswana",
    numericCode: "072",
    flags: {
      svg: "https://flagcdn.com/bw.svg",
      png: "https://flagcdn.com/w320/bw.png",
    },
    currencies: [
      {
        code: "BWP",
        name: "Botswana pula",
        symbol: "P",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
      {
        iso639_1: "tn",
        iso639_2: "tsn",
        name: "Tswana",
        nativeName: "Setswana",
      },
    ],
    translations: {
      br: "Botsuana",
      pt: "Botsuana",
      nl: "Botswana",
      hr: "Bocvana",
      fa: "بوتسوانا",
      de: "Botswana",
      es: "Botswana",
      fr: "Botswana",
      ja: "ボツワナ",
      it: "Botswana",
      hu: "Botswana",
    },
    flag: "https://flagcdn.com/bw.svg",
    regionalBlocs: [
      {
        acronym: "AU",
        name: "African Union",
        otherNames: ["الاتحاد الأفريقي", "Union africaine", "União Africana", "Unión Africana", "Umoja wa Afrika"],
      },
    ],
    cioc: "BOT",
    independent: true,
  },
  {
    name: "Bouvet Island",
    topLevelDomain: [".bv"],
    alpha2Code: "BV",
    alpha3Code: "BVT",
    callingCodes: ["47"],
    altSpellings: ["BV", "Bouvetøya", "Bouvet-øya"],
    subregion: "South Antarctic Ocean",
    region: "Antarctic Ocean",
    population: 0,
    latlng: [-54.43333333, 3.4],
    demonym: "Norwegian",
    area: 49,
    timezones: ["UTC+01:00"],
    nativeName: "Bouvetøya",
    numericCode: "074",
    flags: {
      svg: "https://flagcdn.com/bv.svg",
      png: "https://flagcdn.com/w320/bv.png",
    },
    currencies: [
      {
        code: "NOK",
        name: "Norwegian krone",
        symbol: "kr",
      },
    ],
    languages: [
      {
        iso639_1: "no",
        iso639_2: "nor",
        name: "Norwegian",
        nativeName: "Norsk",
      },
      {
        iso639_1: "nb",
        iso639_2: "nob",
        name: "Norwegian Bokmål",
        nativeName: "Norsk bokmål",
      },
      {
        iso639_1: "nn",
        iso639_2: "nno",
        name: "Norwegian Nynorsk",
        nativeName: "Norsk nynorsk",
      },
    ],
    translations: {
      br: "Ilha Bouvet",
      pt: "Ilha Bouvet",
      nl: "Bouveteiland",
      hr: "Otok Bouvet",
      fa: "جزیره بووه",
      de: "Bouvetinsel",
      es: "Isla Bouvet",
      fr: "Île Bouvet",
      ja: "ブーベ島",
      it: "Isola Bouvet",
      hu: "Bouvet-sziget",
    },
    flag: "https://flagcdn.com/bv.svg",
    independent: false,
  },
  {
    name: "Brazil",
    topLevelDomain: [".br"],
    alpha2Code: "BR",
    alpha3Code: "BRA",
    callingCodes: ["55"],
    capital: "Brasília",
    altSpellings: ["BR", "Brasil", "Federative Republic of Brazil", "República Federativa do Brasil"],
    subregion: "South America",
    region: "Americas",
    population: 212559409,
    latlng: [-10, -55],
    demonym: "Brazilian",
    area: 8515767,
    gini: 53.4,
    timezones: ["UTC-05:00", "UTC-04:00", "UTC-03:00", "UTC-02:00"],
    borders: ["ARG", "BOL", "COL", "FRA", "GUF", "GUY", "PRY", "PER", "SUR", "URY", "VEN"],
    nativeName: "Brasil",
    numericCode: "076",
    flags: {
      svg: "https://flagcdn.com/br.svg",
      png: "https://flagcdn.com/w320/br.png",
    },
    currencies: [
      {
        code: "BRL",
        name: "Brazilian real",
        symbol: "R$",
      },
    ],
    languages: [
      {
        iso639_1: "pt",
        iso639_2: "por",
        name: "Portuguese",
        nativeName: "Português",
      },
    ],
    translations: {
      br: "Brasil",
      pt: "Brasil",
      nl: "Brazilië",
      hr: "Brazil",
      fa: "برزیل",
      de: "Brasilien",
      es: "Brasil",
      fr: "Brésil",
      ja: "ブラジル",
      it: "Brasile",
      hu: "Brazília",
    },
    flag: "https://flagcdn.com/br.svg",
    regionalBlocs: [
      {
        acronym: "USAN",
        name: "Union of South American Nations",
        otherAcronyms: ["UNASUR", "UNASUL", "UZAN"],
        otherNames: ["Unión de Naciones Suramericanas", "União de Nações Sul-Americanas", "Unie van Zuid-Amerikaanse Naties", "South American Union"],
      },
    ],
    cioc: "BRA",
    independent: true,
  },
  {
    name: "British Indian Ocean Territory",
    topLevelDomain: [".io"],
    alpha2Code: "IO",
    alpha3Code: "IOT",
    callingCodes: ["246"],
    capital: "Diego Garcia",
    altSpellings: ["IO"],
    subregion: "Eastern Africa",
    region: "Africa",
    population: 3000,
    latlng: [-6, 71.5],
    demonym: "Indian",
    area: 60,
    timezones: ["UTC+06:00"],
    nativeName: "British Indian Ocean Territory",
    numericCode: "086",
    flags: {
      svg: "https://flagcdn.com/io.svg",
      png: "https://flagcdn.com/w320/io.png",
    },
    currencies: [
      {
        code: "USD",
        name: "United States dollar",
        symbol: "$",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
    ],
    translations: {
      br: "Território Britânico do Oceano íÍdico",
      pt: "Território Britânico do Oceano Índico",
      nl: "Britse Gebieden in de Indische Oceaan",
      hr: "Britanski Indijskooceanski teritorij",
      fa: "قلمرو بریتانیا در اقیانوس هند",
      de: "Britisches Territorium im Indischen Ozean",
      es: "Territorio Británico del Océano Índico",
      fr: "Territoire britannique de l'océan Indien",
      ja: "イギリス領インド洋地域",
      it: "Territorio britannico dell'oceano indiano",
      hu: "Brit Indiai-óceáni Terület",
    },
    flag: "https://flagcdn.com/io.svg",
    regionalBlocs: [
      {
        acronym: "AU",
        name: "African Union",
        otherNames: ["الاتحاد الأفريقي", "Union africaine", "União Africana", "Unión Africana", "Umoja wa Afrika"],
      },
    ],
    independent: true,
  },
  {
    name: "United States Minor Outlying Islands",
    topLevelDomain: [".us"],
    alpha2Code: "UM",
    alpha3Code: "UMI",
    callingCodes: ["246"],
    altSpellings: ["UM"],
    subregion: "Northern America",
    region: "Americas",
    population: 300,
    demonym: "American",
    timezones: ["UTC-11:00", "UTC-10:00", "UTC+12:00"],
    nativeName: "United States Minor Outlying Islands",
    numericCode: "581",
    flags: {
      svg: "https://flagcdn.com/um.svg",
      png: "https://flagcdn.com/w320/um.png",
    },
    currencies: [
      {
        code: "GBP",
        name: "British pound",
        symbol: "£",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
    ],
    translations: {
      br: "Ilhas Menores Distantes dos Estados Unidos",
      pt: "Ilhas Menores Distantes dos Estados Unidos",
      nl: "Kleine afgelegen eilanden van de Verenigde Staten",
      hr: "Mali udaljeni otoci SAD-a",
      fa: "جزایر کوچک حاشیه‌ای ایالات متحده آمریکا",
      de: "Kleinere Inselbesitzungen der Vereinigten Staaten",
      es: "Islas Ultramarinas Menores de Estados Unidos",
      fr: "Îles mineures éloignées des États-Unis",
      ja: "合衆国領有小離島",
      it: "Isole minori esterne degli Stati Uniti d'America",
      hu: "Amerikai Egyesült Államok lakatlan külbirtokai",
    },
    flag: "https://flagcdn.com/um.svg",
    independent: false,
  },
  {
    name: "Virgin Islands (British)",
    topLevelDomain: [".vg"],
    alpha2Code: "VG",
    alpha3Code: "VGB",
    callingCodes: ["1"],
    capital: "Road Town",
    altSpellings: ["VG"],
    subregion: "Caribbean",
    region: "Americas",
    population: 30237,
    latlng: [18.431383, -64.62305],
    demonym: "Virgin Islander",
    area: 151,
    timezones: ["UTC-04:00"],
    nativeName: "British Virgin Islands",
    numericCode: "092",
    flags: {
      svg: "https://flagcdn.com/vg.svg",
      png: "https://flagcdn.com/w320/vg.png",
    },
    currencies: [
      {
        code: "USD",
        name: "United States dollar",
        symbol: "$",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
    ],
    translations: {
      br: "Ilhas Virgens Britânicas",
      pt: "Ilhas Virgens Britânicas",
      nl: "Britse Maagdeneilanden",
      hr: "Britanski Djevičanski Otoci",
      fa: "جزایر ویرجین بریتانیا",
      de: "Britische Jungferninseln",
      es: "Islas Vírgenes del Reino Unido",
      fr: "Îles Vierges britanniques",
      ja: "イギリス領ヴァージン諸島",
      it: "Isole Vergini Britanniche",
      hu: "Brit Virgin-szigetek",
    },
    flag: "https://flagcdn.com/vg.svg",
    cioc: "IVB",
    independent: false,
  },
  {
    name: "Virgin Islands (U.S.)",
    topLevelDomain: [".vi"],
    alpha2Code: "VI",
    alpha3Code: "VIR",
    callingCodes: ["1 340"],
    capital: "Charlotte Amalie",
    altSpellings: ["VI", "USVI", "American Virgin Islands", "U.S. Virgin Islands"],
    subregion: "Caribbean",
    region: "Americas",
    population: 106290,
    latlng: [18.34, -64.93],
    demonym: "Virgin Islander",
    area: 346.36,
    timezones: ["UTC-04:00"],
    nativeName: "Virgin Islands of the United States",
    numericCode: "850",
    flags: {
      svg: "https://flagcdn.com/vi.svg",
      png: "https://flagcdn.com/w320/vi.png",
    },
    currencies: [
      {
        code: "USD",
        name: "United States dollar",
        symbol: "$",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
    ],
    translations: {
      br: "Ilhas Virgens Americanas",
      pt: "Ilhas Virgens Americanas",
      nl: "Verenigde Staten Maagdeneilanden",
      hr: "Virgin Islands (U.S.)",
      fa: "جزایر ویرجین آمریکا",
      de: "Amerikanische Jungferninseln",
      es: "Islas Vírgenes de los Estados Unidos",
      fr: "Îles Vierges des États-Unis",
      ja: "アメリカ領ヴァージン諸島",
      it: "Isole Vergini americane",
      hu: "Amerikai Virgin-szigetek",
    },
    flag: "https://flagcdn.com/vi.svg",
    cioc: "ISV",
    independent: false,
  },
  {
    name: "Brunei Darussalam",
    topLevelDomain: [".bn"],
    alpha2Code: "BN",
    alpha3Code: "BRN",
    callingCodes: ["673"],
    capital: "Bandar Seri Begawan",
    altSpellings: ["BN", "Nation of Brunei", " the Abode of Peace"],
    subregion: "South-Eastern Asia",
    region: "Asia",
    population: 437483,
    latlng: [4.5, 114.66666666],
    demonym: "Bruneian",
    area: 5765,
    timezones: ["UTC+08:00"],
    borders: ["MYS"],
    nativeName: "Negara Brunei Darussalam",
    numericCode: "096",
    flags: {
      svg: "https://flagcdn.com/bn.svg",
      png: "https://flagcdn.com/w320/bn.png",
    },
    currencies: [
      {
        code: "BND",
        name: "Brunei dollar",
        symbol: "$",
      },
      {
        code: "SGD",
        name: "Singapore dollar",
        symbol: "$",
      },
    ],
    languages: [
      {
        iso639_1: "ms",
        iso639_2: "msa",
        name: "Malay",
        nativeName: "bahasa Melayu",
      },
    ],
    translations: {
      br: "Brunei",
      pt: "Brunei",
      nl: "Brunei",
      hr: "Brunej",
      fa: "برونئی",
      de: "Brunei",
      es: "Brunei",
      fr: "Brunei",
      ja: "ブルネイ・ダルサラーム",
      it: "Brunei",
      hu: "Brunei",
    },
    flag: "https://flagcdn.com/bn.svg",
    regionalBlocs: [
      {
        acronym: "ASEAN",
        name: "Association of Southeast Asian Nations",
      },
    ],
    cioc: "BRU",
    independent: true,
  },
  {
    name: "Bulgaria",
    topLevelDomain: [".bg"],
    alpha2Code: "BG",
    alpha3Code: "BGR",
    callingCodes: ["359"],
    capital: "Sofia",
    altSpellings: ["BG", "Republic of Bulgaria", "Република България"],
    subregion: "Eastern Europe",
    region: "Europe",
    population: 6927288,
    latlng: [43, 25],
    demonym: "Bulgarian",
    area: 110879,
    gini: 41.3,
    timezones: ["UTC+02:00"],
    borders: ["GRC", "MKD", "ROU", "SRB", "TUR"],
    nativeName: "България",
    numericCode: "100",
    flags: {
      svg: "https://flagcdn.com/bg.svg",
      png: "https://flagcdn.com/w320/bg.png",
    },
    currencies: [
      {
        code: "BGN",
        name: "Bulgarian lev",
        symbol: "лв",
      },
    ],
    languages: [
      {
        iso639_1: "bg",
        iso639_2: "bul",
        name: "Bulgarian",
        nativeName: "български език",
      },
    ],
    translations: {
      br: "Bulgária",
      pt: "Bulgária",
      nl: "Bulgarije",
      hr: "Bugarska",
      fa: "بلغارستان",
      de: "Bulgarien",
      es: "Bulgaria",
      fr: "Bulgarie",
      ja: "ブルガリア",
      it: "Bulgaria",
      hu: "Bulgária",
    },
    flag: "https://flagcdn.com/bg.svg",
    regionalBlocs: [
      {
        acronym: "EU",
        name: "European Union",
      },
    ],
    cioc: "BUL",
    independent: true,
  },
  {
    name: "Burkina Faso",
    topLevelDomain: [".bf"],
    alpha2Code: "BF",
    alpha3Code: "BFA",
    callingCodes: ["226"],
    capital: "Ouagadougou",
    altSpellings: ["BF"],
    subregion: "Western Africa",
    region: "Africa",
    population: 20903278,
    latlng: [13, -2],
    demonym: "Burkinabe",
    area: 272967,
    gini: 35.3,
    timezones: ["UTC"],
    borders: ["BEN", "CIV", "GHA", "MLI", "NER", "TGO"],
    nativeName: "Burkina Faso",
    numericCode: "854",
    flags: {
      svg: "https://flagcdn.com/bf.svg",
      png: "https://flagcdn.com/w320/bf.png",
    },
    currencies: [
      {
        code: "XOF",
        name: "West African CFA franc",
        symbol: "Fr",
      },
    ],
    languages: [
      {
        iso639_1: "fr",
        iso639_2: "fra",
        name: "French",
        nativeName: "français",
      },
      {
        iso639_1: "ff",
        iso639_2: "ful",
        name: "Fula",
        nativeName: "Fulfulde",
      },
    ],
    translations: {
      br: "Burkina Faso",
      pt: "Burquina Faso",
      nl: "Burkina Faso",
      hr: "Burkina Faso",
      fa: "بورکینافاسو",
      de: "Burkina Faso",
      es: "Burkina Faso",
      fr: "Burkina Faso",
      ja: "ブルキナファソ",
      it: "Burkina Faso",
      hu: "Burkina Faso",
    },
    flag: "https://flagcdn.com/bf.svg",
    regionalBlocs: [
      {
        acronym: "AU",
        name: "African Union",
        otherNames: ["الاتحاد الأفريقي", "Union africaine", "União Africana", "Unión Africana", "Umoja wa Afrika"],
      },
    ],
    cioc: "BUR",
    independent: true,
  },
  {
    name: "Burundi",
    topLevelDomain: [".bi"],
    alpha2Code: "BI",
    alpha3Code: "BDI",
    callingCodes: ["257"],
    capital: "Gitega",
    altSpellings: ["BI", "Republic of Burundi", "Republika y'Uburundi", "République du Burundi"],
    subregion: "Eastern Africa",
    region: "Africa",
    population: 11890781,
    latlng: [-3.5, 30],
    demonym: "Burundian",
    area: 27834,
    gini: 38.6,
    timezones: ["UTC+02:00"],
    borders: ["COD", "RWA", "TZA"],
    nativeName: "Burundi",
    numericCode: "108",
    flags: {
      svg: "https://flagcdn.com/bi.svg",
      png: "https://flagcdn.com/w320/bi.png",
    },
    currencies: [
      {
        code: "BIF",
        name: "Burundian franc",
        symbol: "Fr",
      },
    ],
    languages: [
      {
        iso639_1: "fr",
        iso639_2: "fra",
        name: "French",
        nativeName: "français",
      },
      {
        iso639_1: "rn",
        iso639_2: "run",
        name: "Kirundi",
        nativeName: "Ikirundi",
      },
    ],
    translations: {
      br: "Burundi",
      pt: "Burúndi",
      nl: "Burundi",
      hr: "Burundi",
      fa: "بوروندی",
      de: "Burundi",
      es: "Burundi",
      fr: "Burundi",
      ja: "ブルンジ",
      it: "Burundi",
      hu: "Burundi",
    },
    flag: "https://flagcdn.com/bi.svg",
    regionalBlocs: [
      {
        acronym: "AU",
        name: "African Union",
        otherNames: ["الاتحاد الأفريقي", "Union africaine", "União Africana", "Unión Africana", "Umoja wa Afrika"],
      },
    ],
    cioc: "BDI",
    independent: true,
  },
  {
    name: "Cambodia",
    topLevelDomain: [".kh"],
    alpha2Code: "KH",
    alpha3Code: "KHM",
    callingCodes: ["855"],
    capital: "Phnom Penh",
    altSpellings: ["KH", "Kingdom of Cambodia"],
    subregion: "South-Eastern Asia",
    region: "Asia",
    population: 16718971,
    latlng: [13, 105],
    demonym: "Cambodian",
    area: 181035,
    timezones: ["UTC+07:00"],
    borders: ["LAO", "THA", "VNM"],
    nativeName: "Kâmpŭchéa",
    numericCode: "116",
    flags: {
      svg: "https://flagcdn.com/kh.svg",
      png: "https://flagcdn.com/w320/kh.png",
    },
    currencies: [
      {
        code: "KHR",
        name: "Cambodian riel",
        symbol: "៛",
      },
      {
        code: "USD",
        name: "United States dollar",
        symbol: "$",
      },
    ],
    languages: [
      {
        iso639_1: "km",
        iso639_2: "khm",
        name: "Khmer",
        nativeName: "ខ្មែរ",
      },
    ],
    translations: {
      br: "Camboja",
      pt: "Camboja",
      nl: "Cambodja",
      hr: "Kambodža",
      fa: "کامبوج",
      de: "Kambodscha",
      es: "Camboya",
      fr: "Cambodge",
      ja: "カンボジア",
      it: "Cambogia",
      hu: "Kambodzsa",
    },
    flag: "https://flagcdn.com/kh.svg",
    regionalBlocs: [
      {
        acronym: "ASEAN",
        name: "Association of Southeast Asian Nations",
      },
    ],
    cioc: "CAM",
    independent: true,
  },
  {
    name: "Cameroon",
    topLevelDomain: [".cm"],
    alpha2Code: "CM",
    alpha3Code: "CMR",
    callingCodes: ["237"],
    capital: "Yaoundé",
    altSpellings: ["CM", "Republic of Cameroon", "République du Cameroun"],
    subregion: "Middle Africa",
    region: "Africa",
    population: 26545864,
    latlng: [6, 12],
    demonym: "Cameroonian",
    area: 475442,
    gini: 46.6,
    timezones: ["UTC+01:00"],
    borders: ["CAF", "TCD", "COG", "GNQ", "GAB", "NGA"],
    nativeName: "Cameroon",
    numericCode: "120",
    flags: {
      svg: "https://flagcdn.com/cm.svg",
      png: "https://flagcdn.com/w320/cm.png",
    },
    currencies: [
      {
        code: "XAF",
        name: "Central African CFA franc",
        symbol: "Fr",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
      {
        iso639_1: "fr",
        iso639_2: "fra",
        name: "French",
        nativeName: "français",
      },
    ],
    translations: {
      br: "Camarões",
      pt: "Camarões",
      nl: "Kameroen",
      hr: "Kamerun",
      fa: "کامرون",
      de: "Kamerun",
      es: "Camerún",
      fr: "Cameroun",
      ja: "カメルーン",
      it: "Camerun",
      hu: "Kamerun",
    },
    flag: "https://flagcdn.com/cm.svg",
    regionalBlocs: [
      {
        acronym: "AU",
        name: "African Union",
        otherNames: ["الاتحاد الأفريقي", "Union africaine", "União Africana", "Unión Africana", "Umoja wa Afrika"],
      },
    ],
    cioc: "CMR",
    independent: true,
  },
  {
    name: "Canada",
    topLevelDomain: [".ca"],
    alpha2Code: "CA",
    alpha3Code: "CAN",
    callingCodes: ["1"],
    capital: "Ottawa",
    altSpellings: ["CA"],
    subregion: "Northern America",
    region: "Americas",
    population: 38005238,
    latlng: [60, -95],
    demonym: "Canadian",
    area: 9984670,
    gini: 33.3,
    timezones: ["UTC-08:00", "UTC-07:00", "UTC-06:00", "UTC-05:00", "UTC-04:00", "UTC-03:30"],
    borders: ["USA"],
    nativeName: "Canada",
    numericCode: "124",
    flags: {
      svg: "https://flagcdn.com/ca.svg",
      png: "https://flagcdn.com/w320/ca.png",
    },
    currencies: [
      {
        code: "CAD",
        name: "Canadian dollar",
        symbol: "$",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
      {
        iso639_1: "fr",
        iso639_2: "fra",
        name: "French",
        nativeName: "français",
      },
    ],
    translations: {
      br: "Canadá",
      pt: "Canadá",
      nl: "Canada",
      hr: "Kanada",
      fa: "کانادا",
      de: "Kanada",
      es: "Canadá",
      fr: "Canada",
      ja: "カナダ",
      it: "Canada",
      hu: "Kanada",
    },
    flag: "https://flagcdn.com/ca.svg",
    regionalBlocs: [
      {
        acronym: "NAFTA",
        name: "North American Free Trade Agreement",
        otherNames: ["Tratado de Libre Comercio de América del Norte", "Accord de Libre-échange Nord-Américain"],
      },
    ],
    cioc: "CAN",
    independent: true,
  },
  {
    name: "Cabo Verde",
    topLevelDomain: [".cv"],
    alpha2Code: "CV",
    alpha3Code: "CPV",
    callingCodes: ["238"],
    capital: "Praia",
    altSpellings: ["CV", "Republic of Cabo Verde", "República de Cabo Verde"],
    subregion: "Western Africa",
    region: "Africa",
    population: 555988,
    latlng: [16, -24],
    demonym: "Cape Verdian",
    area: 4033,
    gini: 42.4,
    timezones: ["UTC-01:00"],
    nativeName: "Cabo Verde",
    numericCode: "132",
    flags: {
      svg: "https://flagcdn.com/cv.svg",
      png: "https://flagcdn.com/w320/cv.png",
    },
    currencies: [
      {
        code: "CVE",
        name: "Cape Verdean escudo",
        symbol: "Esc",
      },
    ],
    languages: [
      {
        iso639_1: "pt",
        iso639_2: "por",
        name: "Portuguese",
        nativeName: "Português",
      },
    ],
    translations: {
      br: "Cabo Verde",
      pt: "Cabo Verde",
      nl: "Kaapverdië",
      hr: "Zelenortska Republika",
      fa: "کیپ ورد",
      de: "Kap Verde",
      es: "Cabo Verde",
      fr: "Cap Vert",
      ja: "カーボベルデ",
      it: "Capo Verde",
      hu: "Zöld-foki Köztársaság",
    },
    flag: "https://flagcdn.com/cv.svg",
    regionalBlocs: [
      {
        acronym: "AU",
        name: "African Union",
        otherNames: ["الاتحاد الأفريقي", "Union africaine", "União Africana", "Unión Africana", "Umoja wa Afrika"],
      },
    ],
    cioc: "CPV",
    independent: true,
  },
  {
    name: "Cayman Islands",
    topLevelDomain: [".ky"],
    alpha2Code: "KY",
    alpha3Code: "CYM",
    callingCodes: ["1"],
    capital: "George Town",
    altSpellings: ["KY"],
    subregion: "Caribbean",
    region: "Americas",
    population: 65720,
    latlng: [19.5, -80.5],
    demonym: "Caymanian",
    area: 264,
    timezones: ["UTC-05:00"],
    nativeName: "Cayman Islands",
    numericCode: "136",
    flags: {
      svg: "https://flagcdn.com/ky.svg",
      png: "https://flagcdn.com/w320/ky.png",
    },
    currencies: [
      {
        code: "KYD",
        name: "Cayman Islands dollar",
        symbol: "$",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
    ],
    translations: {
      br: "Ilhas Cayman",
      pt: "Ilhas Caimão",
      nl: "Caymaneilanden",
      hr: "Kajmanski otoci",
      fa: "جزایر کیمن",
      de: "Kaimaninseln",
      es: "Islas Caimán",
      fr: "Îles Caïmans",
      ja: "ケイマン諸島",
      it: "Isole Cayman",
      hu: "Kajmán-szigetek",
    },
    flag: "https://flagcdn.com/ky.svg",
    cioc: "CAY",
    independent: false,
  },
  {
    name: "Central African Republic",
    topLevelDomain: [".cf"],
    alpha2Code: "CF",
    alpha3Code: "CAF",
    callingCodes: ["236"],
    capital: "Bangui",
    altSpellings: ["CF", "Central African Republic", "République centrafricaine"],
    subregion: "Middle Africa",
    region: "Africa",
    population: 4829764,
    latlng: [7, 21],
    demonym: "Central African",
    area: 622984,
    gini: 56.2,
    timezones: ["UTC+01:00"],
    borders: ["CMR", "TCD", "COD", "COG", "SSD", "SDN"],
    nativeName: "Ködörösêse tî Bêafrîka",
    numericCode: "140",
    flags: {
      svg: "https://flagcdn.com/cf.svg",
      png: "https://flagcdn.com/w320/cf.png",
    },
    currencies: [
      {
        code: "XAF",
        name: "Central African CFA franc",
        symbol: "Fr",
      },
    ],
    languages: [
      {
        iso639_1: "fr",
        iso639_2: "fra",
        name: "French",
        nativeName: "français",
      },
      {
        iso639_1: "sg",
        iso639_2: "sag",
        name: "Sango",
        nativeName: "yângâ tî sängö",
      },
    ],
    translations: {
      br: "República Centro-Africana",
      pt: "República Centro-Africana",
      nl: "Centraal-Afrikaanse Republiek",
      hr: "Srednjoafrička Republika",
      fa: "جمهوری آفریقای مرکزی",
      de: "Zentralafrikanische Republik",
      es: "República Centroafricana",
      fr: "République centrafricaine",
      ja: "中央アフリカ共和国",
      it: "Repubblica Centrafricana",
      hu: "Közép-afrikai Köztársaság",
    },
    flag: "https://flagcdn.com/cf.svg",
    regionalBlocs: [
      {
        acronym: "AU",
        name: "African Union",
        otherNames: ["الاتحاد الأفريقي", "Union africaine", "União Africana", "Unión Africana", "Umoja wa Afrika"],
      },
    ],
    cioc: "CAF",
    independent: true,
  },
  {
    name: "Chad",
    topLevelDomain: [".td"],
    alpha2Code: "TD",
    alpha3Code: "TCD",
    callingCodes: ["235"],
    capital: "N'Djamena",
    altSpellings: ["TD", "Tchad", "Republic of Chad", "République du Tchad"],
    subregion: "Middle Africa",
    region: "Africa",
    population: 16425859,
    latlng: [15, 19],
    demonym: "Chadian",
    area: 1284000,
    gini: 43.3,
    timezones: ["UTC+01:00"],
    borders: ["CMR", "CAF", "LBY", "NER", "NGA", "SDN"],
    nativeName: "Tchad",
    numericCode: "148",
    flags: {
      svg: "https://flagcdn.com/td.svg",
      png: "https://flagcdn.com/w320/td.png",
    },
    currencies: [
      {
        code: "XAF",
        name: "Central African CFA franc",
        symbol: "Fr",
      },
    ],
    languages: [
      {
        iso639_1: "fr",
        iso639_2: "fra",
        name: "French",
        nativeName: "français",
      },
      {
        iso639_1: "ar",
        iso639_2: "ara",
        name: "Arabic",
        nativeName: "العربية",
      },
    ],
    translations: {
      br: "Chade",
      pt: "Chade",
      nl: "Tsjaad",
      hr: "Čad",
      fa: "چاد",
      de: "Tschad",
      es: "Chad",
      fr: "Tchad",
      ja: "チャド",
      it: "Ciad",
      hu: "Csád",
    },
    flag: "https://flagcdn.com/td.svg",
    regionalBlocs: [
      {
        acronym: "AU",
        name: "African Union",
        otherNames: ["الاتحاد الأفريقي", "Union africaine", "União Africana", "Unión Africana", "Umoja wa Afrika"],
      },
    ],
    cioc: "CHA",
    independent: true,
  },
  {
    name: "Chile",
    topLevelDomain: [".cl"],
    alpha2Code: "CL",
    alpha3Code: "CHL",
    callingCodes: ["56"],
    capital: "Santiago",
    altSpellings: ["CL", "Republic of Chile", "República de Chile"],
    subregion: "South America",
    region: "Americas",
    population: 19116209,
    latlng: [-30, -71],
    demonym: "Chilean",
    area: 756102,
    gini: 44.4,
    timezones: ["UTC-06:00", "UTC-04:00"],
    borders: ["ARG", "BOL", "PER"],
    nativeName: "Chile",
    numericCode: "152",
    flags: {
      svg: "https://flagcdn.com/cl.svg",
      png: "https://flagcdn.com/w320/cl.png",
    },
    currencies: [
      {
        code: "CLP",
        name: "Chilean peso",
        symbol: "$",
      },
    ],
    languages: [
      {
        iso639_1: "es",
        iso639_2: "spa",
        name: "Spanish",
        nativeName: "Español",
      },
    ],
    translations: {
      br: "Chile",
      pt: "Chile",
      nl: "Chili",
      hr: "Čile",
      fa: "شیلی",
      de: "Chile",
      es: "Chile",
      fr: "Chili",
      ja: "チリ",
      it: "Cile",
      hu: "Chile",
    },
    flag: "https://flagcdn.com/cl.svg",
    regionalBlocs: [
      {
        acronym: "PA",
        name: "Pacific Alliance",
        otherNames: ["Alianza del Pacífico"],
      },
      {
        acronym: "USAN",
        name: "Union of South American Nations",
        otherAcronyms: ["UNASUR", "UNASUL", "UZAN"],
        otherNames: ["Unión de Naciones Suramericanas", "União de Nações Sul-Americanas", "Unie van Zuid-Amerikaanse Naties", "South American Union"],
      },
    ],
    cioc: "CHI",
    independent: true,
  },
  {
    name: "China",
    topLevelDomain: [".cn"],
    alpha2Code: "CN",
    alpha3Code: "CHN",
    callingCodes: ["86"],
    capital: "Beijing",
    altSpellings: ["CN", "Zhōngguó", "Zhongguo", "Zhonghua", "People's Republic of China", "中华人民共和国", "Zhōnghuá Rénmín Gònghéguó"],
    subregion: "Eastern Asia",
    region: "Asia",
    population: 1402112000,
    latlng: [35, 105],
    demonym: "Chinese",
    area: 9640011,
    gini: 38.5,
    timezones: ["UTC+08:00"],
    borders: ["AFG", "BTN", "MMR", "HKG", "IND", "KAZ", "PRK", "KGZ", "LAO", "MAC", "MNG", "PAK", "RUS", "TJK", "VNM", "NPL"],
    nativeName: "中国",
    numericCode: "156",
    flags: {
      svg: "https://flagcdn.com/cn.svg",
      png: "https://flagcdn.com/w320/cn.png",
    },
    currencies: [
      {
        code: "CNY",
        name: "Chinese yuan",
        symbol: "¥",
      },
    ],
    languages: [
      {
        iso639_1: "zh",
        iso639_2: "zho",
        name: "Chinese",
        nativeName: "中文 (Zhōngwén)",
      },
    ],
    translations: {
      br: "China",
      pt: "China",
      nl: "China",
      hr: "Kina",
      fa: "چین",
      de: "China",
      es: "China",
      fr: "Chine",
      ja: "中国",
      it: "Cina",
      hu: "Kína",
    },
    flag: "https://flagcdn.com/cn.svg",
    cioc: "CHN",
    independent: true,
  },
  {
    name: "Christmas Island",
    topLevelDomain: [".cx"],
    alpha2Code: "CX",
    alpha3Code: "CXR",
    callingCodes: ["61"],
    capital: "Flying Fish Cove",
    altSpellings: ["CX", "Territory of Christmas Island"],
    subregion: "Australia and New Zealand",
    region: "Oceania",
    population: 2072,
    latlng: [-10.5, 105.66666666],
    demonym: "Christmas Island",
    area: 135,
    timezones: ["UTC+07:00"],
    nativeName: "Christmas Island",
    numericCode: "162",
    flags: {
      svg: "https://flagcdn.com/cx.svg",
      png: "https://flagcdn.com/w320/cx.png",
    },
    currencies: [
      {
        code: "AUD",
        name: "Australian dollar",
        symbol: "$",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
    ],
    translations: {
      br: "Ilha Christmas",
      pt: "Ilha do Natal",
      nl: "Christmaseiland",
      hr: "Božićni otok",
      fa: "جزیره کریسمس",
      de: "Weihnachtsinsel",
      es: "Isla de Navidad",
      fr: "Île Christmas",
      ja: "クリスマス島",
      it: "Isola di Natale",
      hu: "Karácsony-sziget",
    },
    flag: "https://flagcdn.com/cx.svg",
    independent: false,
  },
  {
    name: "Cocos (Keeling) Islands",
    topLevelDomain: [".cc"],
    alpha2Code: "CC",
    alpha3Code: "CCK",
    callingCodes: ["61"],
    capital: "West Island",
    altSpellings: ["CC", "Territory of the Cocos (Keeling) Islands", "Keeling Islands"],
    subregion: "Australia and New Zealand",
    region: "Oceania",
    population: 550,
    latlng: [-12.5, 96.83333333],
    demonym: "Cocos Islander",
    area: 14,
    timezones: ["UTC+06:30"],
    nativeName: "Cocos (Keeling) Islands",
    numericCode: "166",
    flags: {
      svg: "https://flagcdn.com/cc.svg",
      png: "https://flagcdn.com/w320/cc.png",
    },
    currencies: [
      {
        code: "AUD",
        name: "Australian dollar",
        symbol: "$",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
    ],
    translations: {
      br: "Ilhas Cocos",
      pt: "Ilhas dos Cocos",
      nl: "Cocoseilanden",
      hr: "Kokosovi Otoci",
      fa: "جزایر کوکوس",
      de: "Kokosinseln",
      es: "Islas Cocos o Islas Keeling",
      fr: "Îles Cocos",
      ja: "ココス（キーリング）諸島",
      it: "Isole Cocos e Keeling",
      hu: "Kókusz-szigetek",
    },
    flag: "https://flagcdn.com/cc.svg",
    independent: false,
  },
  {
    name: "Colombia",
    topLevelDomain: [".co"],
    alpha2Code: "CO",
    alpha3Code: "COL",
    callingCodes: ["57"],
    capital: "Bogotá",
    altSpellings: ["CO", "Republic of Colombia", "República de Colombia"],
    subregion: "South America",
    region: "Americas",
    population: 50882884,
    latlng: [4, -72],
    demonym: "Colombian",
    area: 1141748,
    gini: 51.3,
    timezones: ["UTC-05:00"],
    borders: ["BRA", "ECU", "PAN", "PER", "VEN"],
    nativeName: "Colombia",
    numericCode: "170",
    flags: {
      svg: "https://flagcdn.com/co.svg",
      png: "https://flagcdn.com/w320/co.png",
    },
    currencies: [
      {
        code: "COP",
        name: "Colombian peso",
        symbol: "$",
      },
    ],
    languages: [
      {
        iso639_1: "es",
        iso639_2: "spa",
        name: "Spanish",
        nativeName: "Español",
      },
    ],
    translations: {
      br: "Colômbia",
      pt: "Colômbia",
      nl: "Colombia",
      hr: "Kolumbija",
      fa: "کلمبیا",
      de: "Kolumbien",
      es: "Colombia",
      fr: "Colombie",
      ja: "コロンビア",
      it: "Colombia",
      hu: "Kolumbia",
    },
    flag: "https://flagcdn.com/co.svg",
    regionalBlocs: [
      {
        acronym: "PA",
        name: "Pacific Alliance",
        otherNames: ["Alianza del Pacífico"],
      },
      {
        acronym: "USAN",
        name: "Union of South American Nations",
        otherAcronyms: ["UNASUR", "UNASUL", "UZAN"],
        otherNames: ["Unión de Naciones Suramericanas", "União de Nações Sul-Americanas", "Unie van Zuid-Amerikaanse Naties", "South American Union"],
      },
    ],
    cioc: "COL",
    independent: true,
  },
  {
    name: "Comoros",
    topLevelDomain: [".km"],
    alpha2Code: "KM",
    alpha3Code: "COM",
    callingCodes: ["269"],
    capital: "Moroni",
    altSpellings: ["KM", "Union of the Comoros", "Union des Comores", "Udzima wa Komori", "al-Ittiḥād al-Qumurī"],
    subregion: "Eastern Africa",
    region: "Africa",
    population: 869595,
    latlng: [-12.16666666, 44.25],
    demonym: "Comoran",
    area: 1862,
    gini: 45.3,
    timezones: ["UTC+03:00"],
    nativeName: "Komori",
    numericCode: "174",
    flags: {
      svg: "https://flagcdn.com/km.svg",
      png: "https://flagcdn.com/w320/km.png",
    },
    currencies: [
      {
        code: "KMF",
        name: "Comorian franc",
        symbol: "Fr",
      },
    ],
    languages: [
      {
        iso639_1: "ar",
        iso639_2: "ara",
        name: "Arabic",
        nativeName: "العربية",
      },
      {
        iso639_1: "fr",
        iso639_2: "fra",
        name: "French",
        nativeName: "français",
      },
    ],
    translations: {
      br: "Comores",
      pt: "Comores",
      nl: "Comoren",
      hr: "Komori",
      fa: "کومور",
      de: "Union der Komoren",
      es: "Comoras",
      fr: "Comores",
      ja: "コモロ",
      it: "Comore",
      hu: "Comore-szigetek",
    },
    flag: "https://flagcdn.com/km.svg",
    regionalBlocs: [
      {
        acronym: "AU",
        name: "African Union",
        otherNames: ["الاتحاد الأفريقي", "Union africaine", "União Africana", "Unión Africana", "Umoja wa Afrika"],
      },
      {
        acronym: "AL",
        name: "Arab League",
        otherNames: ["جامعة الدول العربية", "Jāmiʻat ad-Duwal al-ʻArabīyah", "League of Arab States"],
      },
    ],
    cioc: "COM",
    independent: true,
  },
  {
    name: "Congo",
    topLevelDomain: [".cg"],
    alpha2Code: "CG",
    alpha3Code: "COG",
    callingCodes: ["242"],
    capital: "Brazzaville",
    altSpellings: ["CG", "Congo-Brazzaville"],
    subregion: "Middle Africa",
    region: "Africa",
    population: 5518092,
    latlng: [-1, 15],
    demonym: "Congolese",
    area: 342000,
    gini: 48.9,
    timezones: ["UTC+01:00"],
    borders: ["AGO", "CMR", "CAF", "COD", "GAB"],
    nativeName: "République du Congo",
    numericCode: "178",
    flags: {
      svg: "https://flagcdn.com/cg.svg",
      png: "https://flagcdn.com/w320/cg.png",
    },
    currencies: [
      {
        code: "XAF",
        name: "Central African CFA franc",
        symbol: "Fr",
      },
    ],
    languages: [
      {
        iso639_1: "fr",
        iso639_2: "fra",
        name: "French",
        nativeName: "français",
      },
      {
        iso639_1: "ln",
        iso639_2: "lin",
        name: "Lingala",
        nativeName: "Lingála",
      },
    ],
    translations: {
      br: "Congo",
      pt: "Congo",
      nl: "Congo [Republiek]",
      hr: "Kongo",
      fa: "کنگو",
      de: "Kongo",
      es: "Congo",
      fr: "Congo",
      ja: "コンゴ共和国",
      it: "Congo",
      hu: "Kongói Kóztársaság",
    },
    flag: "https://flagcdn.com/cg.svg",
    regionalBlocs: [
      {
        acronym: "AU",
        name: "African Union",
        otherNames: ["الاتحاد الأفريقي", "Union africaine", "União Africana", "Unión Africana", "Umoja wa Afrika"],
      },
    ],
    cioc: "CGO",
    independent: true,
  },
  {
    name: "Congo (Democratic Republic of the)",
    topLevelDomain: [".cd"],
    alpha2Code: "CD",
    alpha3Code: "COD",
    callingCodes: ["243"],
    capital: "Kinshasa",
    altSpellings: ["CD", "DR Congo", "Congo-Kinshasa", "DRC"],
    subregion: "Middle Africa",
    region: "Africa",
    population: 89561404,
    latlng: [0, 25],
    demonym: "Congolese",
    area: 2344858,
    gini: 42.1,
    timezones: ["UTC+01:00", "UTC+02:00"],
    borders: ["AGO", "BDI", "CAF", "COG", "RWA", "SSD", "TZA", "UGA", "ZMB"],
    nativeName: "République démocratique du Congo",
    numericCode: "180",
    flags: {
      svg: "https://flagcdn.com/cd.svg",
      png: "https://flagcdn.com/w320/cd.png",
    },
    currencies: [
      {
        code: "CDF",
        name: "Congolese franc",
        symbol: "Fr",
      },
    ],
    languages: [
      {
        iso639_1: "fr",
        iso639_2: "fra",
        name: "French",
        nativeName: "français",
      },
      {
        iso639_1: "ln",
        iso639_2: "lin",
        name: "Lingala",
        nativeName: "Lingála",
      },
      {
        iso639_1: "kg",
        iso639_2: "kon",
        name: "Kongo",
        nativeName: "Kikongo",
      },
      {
        iso639_1: "sw",
        iso639_2: "swa",
        name: "Swahili",
        nativeName: "Kiswahili",
      },
      {
        iso639_1: "lu",
        iso639_2: "lub",
        name: "Luba-Katanga",
        nativeName: "Tshiluba",
      },
    ],
    translations: {
      br: "RD Congo",
      pt: "RD Congo",
      nl: "Congo [DRC]",
      hr: "Kongo, Demokratska Republika",
      fa: "جمهوری کنگو",
      de: "Kongo (Dem. Rep.)",
      es: "Congo (Rep. Dem.)",
      fr: "Congo (Rép. dém.)",
      ja: "コンゴ民主共和国",
      it: "Congo (Rep. Dem.)",
      hu: "Kongói Demokratikus Köztársaság",
    },
    flag: "https://flagcdn.com/cd.svg",
    regionalBlocs: [
      {
        acronym: "AU",
        name: "African Union",
        otherNames: ["الاتحاد الأفريقي", "Union africaine", "União Africana", "Unión Africana", "Umoja wa Afrika"],
      },
    ],
    cioc: "COD",
    independent: true,
  },
  {
    name: "Cook Islands",
    topLevelDomain: [".ck"],
    alpha2Code: "CK",
    alpha3Code: "COK",
    callingCodes: ["682"],
    capital: "Avarua",
    altSpellings: ["CK", "Kūki 'Āirani"],
    subregion: "Polynesia",
    region: "Oceania",
    population: 18100,
    latlng: [-21.23333333, -159.76666666],
    demonym: "Cook Islander",
    area: 236,
    timezones: ["UTC-10:00"],
    nativeName: "Cook Islands",
    numericCode: "184",
    flags: {
      svg: "https://flagcdn.com/ck.svg",
      png: "https://flagcdn.com/w320/ck.png",
    },
    currencies: [
      {
        code: "NZD",
        name: "New Zealand dollar",
        symbol: "$",
      },
      {
        code: "CKD",
        name: "Cook Islands dollar",
        symbol: "$",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
      {
        iso639_2: "rar",
        name: "Cook Islands Māori",
        nativeName: "Māori",
      },
    ],
    translations: {
      br: "Ilhas Cook",
      pt: "Ilhas Cook",
      nl: "Cookeilanden",
      hr: "Cookovo Otočje",
      fa: "جزایر کوک",
      de: "Cookinseln",
      es: "Islas Cook",
      fr: "Îles Cook",
      ja: "クック諸島",
      it: "Isole Cook",
      hu: "Cook-szigetek",
    },
    flag: "https://flagcdn.com/ck.svg",
    cioc: "COK",
    independent: true,
  },
  {
    name: "Costa Rica",
    topLevelDomain: [".cr"],
    alpha2Code: "CR",
    alpha3Code: "CRI",
    callingCodes: ["506"],
    capital: "San José",
    altSpellings: ["CR", "Republic of Costa Rica", "República de Costa Rica"],
    subregion: "Central America",
    region: "Americas",
    population: 5094114,
    latlng: [10, -84],
    demonym: "Costa Rican",
    area: 51100,
    gini: 48.2,
    timezones: ["UTC-06:00"],
    borders: ["NIC", "PAN"],
    nativeName: "Costa Rica",
    numericCode: "188",
    flags: {
      svg: "https://flagcdn.com/cr.svg",
      png: "https://flagcdn.com/w320/cr.png",
    },
    currencies: [
      {
        code: "CRC",
        name: "Costa Rican colón",
        symbol: "₡",
      },
    ],
    languages: [
      {
        iso639_1: "es",
        iso639_2: "spa",
        name: "Spanish",
        nativeName: "Español",
      },
    ],
    translations: {
      br: "Costa Rica",
      pt: "Costa Rica",
      nl: "Costa Rica",
      hr: "Kostarika",
      fa: "کاستاریکا",
      de: "Costa Rica",
      es: "Costa Rica",
      fr: "Costa Rica",
      ja: "コスタリカ",
      it: "Costa Rica",
      hu: "Costa Rica",
    },
    flag: "https://flagcdn.com/cr.svg",
    regionalBlocs: [
      {
        acronym: "CAIS",
        name: "Central American Integration System",
        otherAcronyms: ["SICA"],
        otherNames: ["Sistema de la Integración Centroamericana,"],
      },
    ],
    cioc: "CRC",
    independent: true,
  },
  {
    name: "Croatia",
    topLevelDomain: [".hr"],
    alpha2Code: "HR",
    alpha3Code: "HRV",
    callingCodes: ["385"],
    capital: "Zagreb",
    altSpellings: ["HR", "Hrvatska", "Republic of Croatia", "Republika Hrvatska"],
    subregion: "Southern Europe",
    region: "Europe",
    population: 4047200,
    latlng: [45.16666666, 15.5],
    demonym: "Croatian",
    area: 56594,
    gini: 29.7,
    timezones: ["UTC+01:00"],
    borders: ["BIH", "HUN", "MNE", "SRB", "SVN"],
    nativeName: "Hrvatska",
    numericCode: "191",
    flags: {
      svg: "https://flagcdn.com/hr.svg",
      png: "https://flagcdn.com/w320/hr.png",
    },
    currencies: [
      {
        code: "HRK",
        name: "Croatian kuna",
        symbol: "kn",
      },
    ],
    languages: [
      {
        iso639_1: "hr",
        iso639_2: "hrv",
        name: "Croatian",
        nativeName: "hrvatski jezik",
      },
    ],
    translations: {
      br: "Croácia",
      pt: "Croácia",
      nl: "Kroatië",
      hr: "Hrvatska",
      fa: "کرواسی",
      de: "Kroatien",
      es: "Croacia",
      fr: "Croatie",
      ja: "クロアチア",
      it: "Croazia",
      hu: "Horvátország",
    },
    flag: "https://flagcdn.com/hr.svg",
    regionalBlocs: [
      {
        acronym: "EU",
        name: "European Union",
      },
    ],
    cioc: "CRO",
    independent: true,
  },
  {
    name: "Cuba",
    topLevelDomain: [".cu"],
    alpha2Code: "CU",
    alpha3Code: "CUB",
    callingCodes: ["53"],
    capital: "Havana",
    altSpellings: ["CU", "Republic of Cuba", "República de Cuba"],
    subregion: "Caribbean",
    region: "Americas",
    population: 11326616,
    latlng: [21.5, -80],
    demonym: "Cuban",
    area: 109884,
    timezones: ["UTC-05:00"],
    nativeName: "Cuba",
    numericCode: "192",
    flags: {
      svg: "https://flagcdn.com/cu.svg",
      png: "https://flagcdn.com/w320/cu.png",
    },
    currencies: [
      {
        code: "CUC",
        name: "Cuban convertible peso",
        symbol: "$",
      },
      {
        code: "CUP",
        name: "Cuban peso",
        symbol: "$",
      },
    ],
    languages: [
      {
        iso639_1: "es",
        iso639_2: "spa",
        name: "Spanish",
        nativeName: "Español",
      },
    ],
    translations: {
      br: "Cuba",
      pt: "Cuba",
      nl: "Cuba",
      hr: "Kuba",
      fa: "کوبا",
      de: "Kuba",
      es: "Cuba",
      fr: "Cuba",
      ja: "キューバ",
      it: "Cuba",
      hu: "Kuba",
    },
    flag: "https://flagcdn.com/cu.svg",
    cioc: "CUB",
    independent: true,
  },
  {
    name: "Curaçao",
    topLevelDomain: [".cw"],
    alpha2Code: "CW",
    alpha3Code: "CUW",
    callingCodes: ["599"],
    capital: "Willemstad",
    altSpellings: ["CW", "Curacao", "Kòrsou", "Country of Curaçao", "Land Curaçao", "Pais Kòrsou"],
    subregion: "Caribbean",
    region: "Americas",
    population: 155014,
    latlng: [12.116667, -68.933333],
    demonym: "Dutch",
    area: 444,
    timezones: ["UTC-04:00"],
    nativeName: "Curaçao",
    numericCode: "531",
    flags: {
      svg: "https://flagcdn.com/cw.svg",
      png: "https://flagcdn.com/w320/cw.png",
    },
    currencies: [
      {
        code: "ANG",
        name: "Netherlands Antillean guilder",
        symbol: "ƒ",
      },
    ],
    languages: [
      {
        iso639_1: "nl",
        iso639_2: "nld",
        name: "Dutch",
        nativeName: "Nederlands",
      },
      {
        iso639_1: "pa",
        iso639_2: "pan",
        name: "(Eastern) Punjabi",
        nativeName: "ਪੰਜਾਬੀ",
      },
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
    ],
    translations: {
      br: "Curaçao",
      pt: "Curaçao",
      nl: "Curaçao",
      hr: "Curaçao",
      fa: "کوراسائو",
      de: "Curaçao",
      es: "Curaçao",
      fr: "Curaçao",
      ja: "Curaçao",
      it: "Curaçao",
      hu: "Cura~ao",
    },
    flag: "https://flagcdn.com/cw.svg",
    independent: false,
  },
  {
    name: "Cyprus",
    topLevelDomain: [".cy"],
    alpha2Code: "CY",
    alpha3Code: "CYP",
    callingCodes: ["357"],
    capital: "Nicosia",
    altSpellings: ["CY", "Kýpros", "Kıbrıs", "Republic of Cyprus", "Κυπριακή Δημοκρατία", "Kıbrıs Cumhuriyeti"],
    subregion: "Southern Europe",
    region: "Europe",
    population: 1207361,
    latlng: [35, 33],
    demonym: "Cypriot",
    area: 9251,
    gini: 32.7,
    timezones: ["UTC+02:00"],
    borders: ["GBR"],
    nativeName: "Κύπρος",
    numericCode: "196",
    flags: {
      svg: "https://flagcdn.com/cy.svg",
      png: "https://flagcdn.com/w320/cy.png",
    },
    currencies: [
      {
        code: "EUR",
        name: "Euro",
        symbol: "€",
      },
    ],
    languages: [
      {
        iso639_1: "el",
        iso639_2: "ell",
        name: "Greek (modern)",
        nativeName: "ελληνικά",
      },
      {
        iso639_1: "tr",
        iso639_2: "tur",
        name: "Turkish",
        nativeName: "Türkçe",
      },
      {
        iso639_1: "hy",
        iso639_2: "hye",
        name: "Armenian",
        nativeName: "Հայերեն",
      },
    ],
    translations: {
      br: "Chipre",
      pt: "Chipre",
      nl: "Cyprus",
      hr: "Cipar",
      fa: "قبرس",
      de: "Zypern",
      es: "Chipre",
      fr: "Chypre",
      ja: "キプロス",
      it: "Cipro",
      hu: "Ciprus",
    },
    flag: "https://flagcdn.com/cy.svg",
    regionalBlocs: [
      {
        acronym: "EU",
        name: "European Union",
      },
    ],
    cioc: "CYP",
    independent: true,
  },
  {
    name: "Czech Republic",
    topLevelDomain: [".cz"],
    alpha2Code: "CZ",
    alpha3Code: "CZE",
    callingCodes: ["420"],
    capital: "Prague",
    altSpellings: ["CZ", "Česká republika", "Česko"],
    subregion: "Central Europe",
    region: "Europe",
    population: 10698896,
    latlng: [49.75, 15.5],
    demonym: "Czech",
    area: 78865,
    gini: 25,
    timezones: ["UTC+01:00"],
    nativeName: "Česká republika",
    numericCode: "203",
    flags: {
      svg: "https://flagcdn.com/cz.svg",
      png: "https://flagcdn.com/w320/cz.png",
    },
    currencies: [
      {
        code: "CZK",
        name: "Czech koruna",
        symbol: "Kč",
      },
    ],
    languages: [
      {
        iso639_1: "cs",
        iso639_2: "ces",
        name: "Czech",
        nativeName: "čeština",
      },
      {
        iso639_1: "sk",
        iso639_2: "slk",
        name: "Slovak",
        nativeName: "slovenčina",
      },
    ],
    translations: {
      br: "República Tcheca",
      pt: "República Checa",
      nl: "Tsjechië",
      hr: "Češka",
      fa: "جمهوری چک",
      de: "Tschechische Republik",
      es: "República Checa",
      fr: "République tchèque",
      ja: "チェコ",
      it: "Repubblica Ceca",
      hu: "Csehország",
    },
    flag: "https://flagcdn.com/cz.svg",
    regionalBlocs: [
      {
        acronym: "EU",
        name: "European Union",
      },
    ],
    cioc: "CZE",
    independent: true,
  },
  {
    name: "Denmark",
    topLevelDomain: [".dk"],
    alpha2Code: "DK",
    alpha3Code: "DNK",
    callingCodes: ["45"],
    capital: "Copenhagen",
    altSpellings: ["DK", "Danmark", "Kingdom of Denmark", "Kongeriget Danmark"],
    subregion: "Northern Europe",
    region: "Europe",
    population: 5831404,
    latlng: [56, 10],
    demonym: "Danish",
    area: 43094,
    gini: 28.2,
    timezones: ["UTC-04:00", "UTC-03:00", "UTC-01:00", "UTC", "UTC+01:00"],
    borders: ["DEU"],
    nativeName: "Danmark",
    numericCode: "208",
    flags: {
      svg: "https://flagcdn.com/dk.svg",
      png: "https://flagcdn.com/w320/dk.png",
    },
    currencies: [
      {
        code: "DKK",
        name: "Danish krone",
        symbol: "kr",
      },
    ],
    languages: [
      {
        iso639_1: "da",
        iso639_2: "dan",
        name: "Danish",
        nativeName: "dansk",
      },
    ],
    translations: {
      br: "Dinamarca",
      pt: "Dinamarca",
      nl: "Denemarken",
      hr: "Danska",
      fa: "دانمارک",
      de: "Dänemark",
      es: "Dinamarca",
      fr: "Danemark",
      ja: "デンマーク",
      it: "Danimarca",
      hu: "Dánia",
    },
    flag: "https://flagcdn.com/dk.svg",
    regionalBlocs: [
      {
        acronym: "EU",
        name: "European Union",
      },
    ],
    cioc: "DEN",
    independent: true,
  },
  {
    name: "Djibouti",
    topLevelDomain: [".dj"],
    alpha2Code: "DJ",
    alpha3Code: "DJI",
    callingCodes: ["253"],
    capital: "Djibouti",
    altSpellings: ["DJ", "Jabuuti", "Gabuuti", "Republic of Djibouti", "République de Djibouti", "Gabuutih Ummuuno", "Jamhuuriyadda Jabuuti"],
    subregion: "Eastern Africa",
    region: "Africa",
    population: 988002,
    latlng: [11.5, 43],
    demonym: "Djibouti",
    area: 23200,
    gini: 41.6,
    timezones: ["UTC+03:00"],
    borders: ["ERI", "ETH", "SOM"],
    nativeName: "Djibouti",
    numericCode: "262",
    flags: {
      svg: "https://flagcdn.com/dj.svg",
      png: "https://flagcdn.com/w320/dj.png",
    },
    currencies: [
      {
        code: "DJF",
        name: "Djiboutian franc",
        symbol: "Fr",
      },
    ],
    languages: [
      {
        iso639_1: "fr",
        iso639_2: "fra",
        name: "French",
        nativeName: "français",
      },
      {
        iso639_1: "ar",
        iso639_2: "ara",
        name: "Arabic",
        nativeName: "العربية",
      },
    ],
    translations: {
      br: "Djibuti",
      pt: "Djibuti",
      nl: "Djibouti",
      hr: "Džibuti",
      fa: "جیبوتی",
      de: "Dschibuti",
      es: "Yibuti",
      fr: "Djibouti",
      ja: "ジブチ",
      it: "Gibuti",
      hu: "Dzsibuti",
    },
    flag: "https://flagcdn.com/dj.svg",
    regionalBlocs: [
      {
        acronym: "AU",
        name: "African Union",
        otherNames: ["الاتحاد الأفريقي", "Union africaine", "União Africana", "Unión Africana", "Umoja wa Afrika"],
      },
      {
        acronym: "AL",
        name: "Arab League",
        otherNames: ["جامعة الدول العربية", "Jāmiʻat ad-Duwal al-ʻArabīyah", "League of Arab States"],
      },
    ],
    cioc: "DJI",
    independent: true,
  },
  {
    name: "Dominica",
    topLevelDomain: [".dm"],
    alpha2Code: "DM",
    alpha3Code: "DMA",
    callingCodes: ["1"],
    capital: "Roseau",
    altSpellings: ["DM", "Dominique", "Wai‘tu kubuli", "Commonwealth of Dominica"],
    subregion: "Caribbean",
    region: "Americas",
    population: 71991,
    latlng: [15.41666666, -61.33333333],
    demonym: "Dominican",
    area: 751,
    timezones: ["UTC-04:00"],
    nativeName: "Dominica",
    numericCode: "212",
    flags: {
      svg: "https://flagcdn.com/dm.svg",
      png: "https://flagcdn.com/w320/dm.png",
    },
    currencies: [
      {
        code: "XCD",
        name: "East Caribbean dollar",
        symbol: "$",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
    ],
    translations: {
      br: "Dominica",
      pt: "Dominica",
      nl: "Dominica",
      hr: "Dominika",
      fa: "دومینیکا",
      de: "Dominica",
      es: "Dominica",
      fr: "Dominique",
      ja: "ドミニカ国",
      it: "Dominica",
      hu: "Dominikai Közösség",
    },
    flag: "https://flagcdn.com/dm.svg",
    cioc: "DMA",
    independent: true,
  },
  {
    name: "Dominican Republic",
    topLevelDomain: [".do"],
    alpha2Code: "DO",
    alpha3Code: "DOM",
    callingCodes: ["1"],
    capital: "Santo Domingo",
    altSpellings: ["DO"],
    subregion: "Caribbean",
    region: "Americas",
    population: 10847904,
    latlng: [19, -70.66666666],
    demonym: "Dominican",
    area: 48671,
    gini: 41.9,
    timezones: ["UTC-04:00"],
    borders: ["HTI"],
    nativeName: "República Dominicana",
    numericCode: "214",
    flags: {
      svg: "https://flagcdn.com/do.svg",
      png: "https://flagcdn.com/w320/do.png",
    },
    currencies: [
      {
        code: "DOP",
        name: "Dominican peso",
        symbol: "$",
      },
    ],
    languages: [
      {
        iso639_1: "es",
        iso639_2: "spa",
        name: "Spanish",
        nativeName: "Español",
      },
    ],
    translations: {
      br: "República Dominicana",
      pt: "República Dominicana",
      nl: "Dominicaanse Republiek",
      hr: "Dominikanska Republika",
      fa: "جمهوری دومینیکن",
      de: "Dominikanische Republik",
      es: "República Dominicana",
      fr: "République dominicaine",
      ja: "ドミニカ共和国",
      it: "Repubblica Dominicana",
      hu: "Dominikai Köztársaság",
    },
    flag: "https://flagcdn.com/do.svg",
    regionalBlocs: [
      {
        acronym: "CARICOM",
        name: "Caribbean Community",
        otherNames: ["Comunidad del Caribe", "Communauté Caribéenne", "Caribische Gemeenschap"],
      },
      {
        acronym: "CAIS",
        name: "Central American Integration System",
        otherAcronyms: ["SICA"],
        otherNames: ["Sistema de la Integración Centroamericana,"],
      },
    ],
    cioc: "DOM",
    independent: true,
  },
  {
    name: "Ecuador",
    topLevelDomain: [".ec"],
    alpha2Code: "EC",
    alpha3Code: "ECU",
    callingCodes: ["593"],
    capital: "Quito",
    altSpellings: ["EC", "Republic of Ecuador", "República del Ecuador"],
    subregion: "South America",
    region: "Americas",
    population: 17643060,
    latlng: [-2, -77.5],
    demonym: "Ecuadorean",
    area: 276841,
    gini: 45.7,
    timezones: ["UTC-06:00", "UTC-05:00"],
    borders: ["COL", "PER"],
    nativeName: "Ecuador",
    numericCode: "218",
    flags: {
      svg: "https://flagcdn.com/ec.svg",
      png: "https://flagcdn.com/w320/ec.png",
    },
    currencies: [
      {
        code: "USD",
        name: "United States dollar",
        symbol: "$",
      },
    ],
    languages: [
      {
        iso639_1: "es",
        iso639_2: "spa",
        name: "Spanish",
        nativeName: "Español",
      },
    ],
    translations: {
      br: "Equador",
      pt: "Equador",
      nl: "Ecuador",
      hr: "Ekvador",
      fa: "اکوادور",
      de: "Ecuador",
      es: "Ecuador",
      fr: "Équateur",
      ja: "エクアドル",
      it: "Ecuador",
      hu: "Ecuador",
    },
    flag: "https://flagcdn.com/ec.svg",
    regionalBlocs: [
      {
        acronym: "USAN",
        name: "Union of South American Nations",
        otherAcronyms: ["UNASUR", "UNASUL", "UZAN"],
        otherNames: ["Unión de Naciones Suramericanas", "União de Nações Sul-Americanas", "Unie van Zuid-Amerikaanse Naties", "South American Union"],
      },
    ],
    cioc: "ECU",
    independent: true,
  },
  {
    name: "Egypt",
    topLevelDomain: [".eg"],
    alpha2Code: "EG",
    alpha3Code: "EGY",
    callingCodes: ["20"],
    capital: "Cairo",
    altSpellings: ["EG", "Arab Republic of Egypt"],
    subregion: "Northern Africa",
    region: "Africa",
    population: 102334403,
    latlng: [27, 30],
    demonym: "Egyptian",
    area: 1002450,
    gini: 31.5,
    timezones: ["UTC+02:00"],
    borders: ["ISR", "LBY", "SDN"],
    nativeName: "مصر‎",
    numericCode: "818",
    flags: {
      svg: "https://flagcdn.com/eg.svg",
      png: "https://flagcdn.com/w320/eg.png",
    },
    currencies: [
      {
        code: "EGP",
        name: "Egyptian pound",
        symbol: "£",
      },
    ],
    languages: [
      {
        iso639_1: "ar",
        iso639_2: "ara",
        name: "Arabic",
        nativeName: "العربية",
      },
    ],
    translations: {
      br: "Egito",
      pt: "Egipto",
      nl: "Egypte",
      hr: "Egipat",
      fa: "مصر",
      de: "Ägypten",
      es: "Egipto",
      fr: "Égypte",
      ja: "エジプト",
      it: "Egitto",
      hu: "Egyiptom",
    },
    flag: "https://flagcdn.com/eg.svg",
    regionalBlocs: [
      {
        acronym: "AU",
        name: "African Union",
        otherNames: ["الاتحاد الأفريقي", "Union africaine", "União Africana", "Unión Africana", "Umoja wa Afrika"],
      },
      {
        acronym: "AL",
        name: "Arab League",
        otherNames: ["جامعة الدول العربية", "Jāmiʻat ad-Duwal al-ʻArabīyah", "League of Arab States"],
      },
    ],
    cioc: "EGY",
    independent: true,
  },
  {
    name: "El Salvador",
    topLevelDomain: [".sv"],
    alpha2Code: "SV",
    alpha3Code: "SLV",
    callingCodes: ["503"],
    capital: "San Salvador",
    altSpellings: ["SV", "Republic of El Salvador", "República de El Salvador"],
    subregion: "Central America",
    region: "Americas",
    population: 6486201,
    latlng: [13.83333333, -88.91666666],
    demonym: "Salvadoran",
    area: 21041,
    gini: 38.8,
    timezones: ["UTC-06:00"],
    borders: ["GTM", "HND"],
    nativeName: "El Salvador",
    numericCode: "222",
    flags: {
      svg: "https://flagcdn.com/sv.svg",
      png: "https://flagcdn.com/w320/sv.png",
    },
    currencies: [
      {
        code: "USD",
        name: "United States dollar",
        symbol: "$",
      },
    ],
    languages: [
      {
        iso639_1: "es",
        iso639_2: "spa",
        name: "Spanish",
        nativeName: "Español",
      },
    ],
    translations: {
      br: "El Salvador",
      pt: "El Salvador",
      nl: "El Salvador",
      hr: "Salvador",
      fa: "السالوادور",
      de: "El Salvador",
      es: "El Salvador",
      fr: "Salvador",
      ja: "エルサルバドル",
      it: "El Salvador",
      hu: "Salvador",
    },
    flag: "https://flagcdn.com/sv.svg",
    regionalBlocs: [
      {
        acronym: "CAIS",
        name: "Central American Integration System",
        otherAcronyms: ["SICA"],
        otherNames: ["Sistema de la Integración Centroamericana,"],
      },
    ],
    cioc: "ESA",
    independent: true,
  },
  {
    name: "Equatorial Guinea",
    topLevelDomain: [".gq"],
    alpha2Code: "GQ",
    alpha3Code: "GNQ",
    callingCodes: ["240"],
    capital: "Malabo",
    altSpellings: [
      "GQ",
      "Republic of Equatorial Guinea",
      "República de Guinea Ecuatorial",
      "République de Guinée équatoriale",
      "República da Guiné Equatorial",
    ],
    subregion: "Middle Africa",
    region: "Africa",
    population: 1402985,
    latlng: [2, 10],
    demonym: "Equatorial Guinean",
    area: 28051,
    timezones: ["UTC+01:00"],
    borders: ["CMR", "GAB"],
    nativeName: "Guinea Ecuatorial",
    numericCode: "226",
    flags: {
      svg: "https://flagcdn.com/gq.svg",
      png: "https://flagcdn.com/w320/gq.png",
    },
    currencies: [
      {
        code: "XAF",
        name: "Central African CFA franc",
        symbol: "Fr",
      },
    ],
    languages: [
      {
        iso639_1: "es",
        iso639_2: "spa",
        name: "Spanish",
        nativeName: "Español",
      },
      {
        iso639_1: "fr",
        iso639_2: "fra",
        name: "French",
        nativeName: "français",
      },
      {
        iso639_1: "pt",
        iso639_2: "por",
        name: "Portuguese",
        nativeName: "Português",
      },
      {
        iso639_2: "fan",
        name: "Fang",
        nativeName: "Fang",
      },
    ],
    translations: {
      br: "Guiné Equatorial",
      pt: "Guiné Equatorial",
      nl: "Equatoriaal-Guinea",
      hr: "Ekvatorijalna Gvineja",
      fa: "گینه استوایی",
      de: "Äquatorial-Guinea",
      es: "Guinea Ecuatorial",
      fr: "Guinée-Équatoriale",
      ja: "赤道ギニア",
      it: "Guinea Equatoriale",
      hu: "Egyenlítői-Guinea",
    },
    flag: "https://flagcdn.com/gq.svg",
    regionalBlocs: [
      {
        acronym: "AU",
        name: "African Union",
        otherNames: ["الاتحاد الأفريقي", "Union africaine", "União Africana", "Unión Africana", "Umoja wa Afrika"],
      },
    ],
    cioc: "GEQ",
    independent: true,
  },
  {
    name: "Eritrea",
    topLevelDomain: [".er"],
    alpha2Code: "ER",
    alpha3Code: "ERI",
    callingCodes: ["291"],
    capital: "Asmara",
    altSpellings: ["ER", "State of Eritrea", "ሃገረ ኤርትራ", "Dawlat Iritriyá", "ʾErtrā", "Iritriyā", ""],
    subregion: "Eastern Africa",
    region: "Africa",
    population: 5352000,
    latlng: [15, 39],
    demonym: "Eritrean",
    area: 117600,
    timezones: ["UTC+03:00"],
    borders: ["DJI", "ETH", "SDN"],
    nativeName: "ኤርትራ",
    numericCode: "232",
    flags: {
      svg: "https://flagcdn.com/er.svg",
      png: "https://flagcdn.com/w320/er.png",
    },
    currencies: [
      {
        code: "ERN",
        name: "Eritrean nakfa",
        symbol: "Nfk",
      },
    ],
    languages: [
      {
        iso639_1: "ti",
        iso639_2: "tir",
        name: "Tigrinya",
        nativeName: "ትግርኛ",
      },
      {
        iso639_1: "ar",
        iso639_2: "ara",
        name: "Arabic",
        nativeName: "العربية",
      },
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
      {
        iso639_2: "tig",
        name: "Tigre",
        nativeName: "ትግረ",
      },
      {
        iso639_2: "kun",
        name: "Kunama",
        nativeName: "Kunama",
      },
      {
        iso639_2: "ssy",
        name: "Saho",
        nativeName: "Saho",
      },
      {
        iso639_2: "byn",
        name: "Bilen",
        nativeName: "ብሊና",
      },
      {
        iso639_2: "nrb",
        name: "Nara",
        nativeName: "Nara",
      },
      {
        iso639_1: "aa",
        iso639_2: "aar",
        name: "Afar",
        nativeName: "Afar",
      },
    ],
    translations: {
      br: "Eritreia",
      pt: "Eritreia",
      nl: "Eritrea",
      hr: "Eritreja",
      fa: "اریتره",
      de: "Eritrea",
      es: "Eritrea",
      fr: "Érythrée",
      ja: "エリトリア",
      it: "Eritrea",
      hu: "Eritrea",
    },
    flag: "https://flagcdn.com/er.svg",
    regionalBlocs: [
      {
        acronym: "AU",
        name: "African Union",
        otherNames: ["الاتحاد الأفريقي", "Union africaine", "União Africana", "Unión Africana", "Umoja wa Afrika"],
      },
    ],
    cioc: "ERI",
    independent: true,
  },
  {
    name: "Estonia",
    topLevelDomain: [".ee"],
    alpha2Code: "EE",
    alpha3Code: "EST",
    callingCodes: ["372"],
    capital: "Tallinn",
    altSpellings: ["EE", "Eesti", "Republic of Estonia", "Eesti Vabariik"],
    subregion: "Northern Europe",
    region: "Europe",
    population: 1331057,
    latlng: [59, 26],
    demonym: "Estonian",
    area: 45227,
    gini: 30.3,
    timezones: ["UTC+02:00"],
    borders: ["LVA", "RUS"],
    nativeName: "Eesti",
    numericCode: "233",
    flags: {
      svg: "https://flagcdn.com/ee.svg",
      png: "https://flagcdn.com/w320/ee.png",
    },
    currencies: [
      {
        code: "EUR",
        name: "Euro",
        symbol: "€",
      },
    ],
    languages: [
      {
        iso639_1: "et",
        iso639_2: "est",
        name: "Estonian",
        nativeName: "eesti",
      },
    ],
    translations: {
      br: "Estônia",
      pt: "Estónia",
      nl: "Estland",
      hr: "Estonija",
      fa: "استونی",
      de: "Estland",
      es: "Estonia",
      fr: "Estonie",
      ja: "エストニア",
      it: "Estonia",
      hu: "Észtország",
    },
    flag: "https://flagcdn.com/ee.svg",
    regionalBlocs: [
      {
        acronym: "EU",
        name: "European Union",
      },
    ],
    cioc: "EST",
    independent: true,
  },
  {
    name: "Ethiopia",
    topLevelDomain: [".et"],
    alpha2Code: "ET",
    alpha3Code: "ETH",
    callingCodes: ["251"],
    capital: "Addis Ababa",
    altSpellings: ["ET", "ʾĪtyōṗṗyā", "Federal Democratic Republic of Ethiopia", "የኢትዮጵያ ፌዴራላዊ ዲሞክራሲያዊ ሪፐብሊክ"],
    subregion: "Eastern Africa",
    region: "Africa",
    population: 114963583,
    latlng: [8, 38],
    demonym: "Ethiopian",
    area: 1104300,
    gini: 35,
    timezones: ["UTC+03:00"],
    borders: ["DJI", "ERI", "KEN", "SOM", "SSD", "SDN"],
    nativeName: "ኢትዮጵያ",
    numericCode: "231",
    flags: {
      svg: "https://flagcdn.com/et.svg",
      png: "https://flagcdn.com/w320/et.png",
    },
    currencies: [
      {
        code: "ETB",
        name: "Ethiopian birr",
        symbol: "Br",
      },
    ],
    languages: [
      {
        iso639_1: "am",
        iso639_2: "amh",
        name: "Amharic",
        nativeName: "አማርኛ",
      },
    ],
    translations: {
      br: "Etiópia",
      pt: "Etiópia",
      nl: "Ethiopië",
      hr: "Etiopija",
      fa: "اتیوپی",
      de: "Äthiopien",
      es: "Etiopía",
      fr: "Éthiopie",
      ja: "エチオピア",
      it: "Etiopia",
      hu: "Etiópia",
    },
    flag: "https://flagcdn.com/et.svg",
    regionalBlocs: [
      {
        acronym: "AU",
        name: "African Union",
        otherNames: ["الاتحاد الأفريقي", "Union africaine", "União Africana", "Unión Africana", "Umoja wa Afrika"],
      },
    ],
    cioc: "ETH",
    independent: true,
  },
  {
    name: "Falkland Islands (Malvinas)",
    topLevelDomain: [".fk"],
    alpha2Code: "FK",
    alpha3Code: "FLK",
    callingCodes: ["500"],
    capital: "Stanley",
    altSpellings: ["FK", "Islas Malvinas"],
    subregion: "South America",
    region: "Americas",
    population: 2563,
    latlng: [-51.75, -59],
    demonym: "Falkland Islander",
    area: 12173,
    timezones: ["UTC-04:00"],
    nativeName: "Falkland Islands",
    numericCode: "238",
    flags: {
      svg: "https://flagcdn.com/fk.svg",
      png: "https://flagcdn.com/w320/fk.png",
    },
    currencies: [
      {
        code: "FKP",
        name: "Falkland Islands pound",
        symbol: "£",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
    ],
    translations: {
      br: "Ilhas Malvinas",
      pt: "Ilhas Falkland",
      nl: "Falklandeilanden [Islas Malvinas]",
      hr: "Falklandski Otoci",
      fa: "جزایر فالکلند",
      de: "Falklandinseln",
      es: "Islas Malvinas",
      fr: "Îles Malouines",
      ja: "フォークランド（マルビナス）諸島",
      it: "Isole Falkland o Isole Malvine",
      hu: "Falkland-szigetek",
    },
    flag: "https://flagcdn.com/fk.svg",
    regionalBlocs: [
      {
        acronym: "USAN",
        name: "Union of South American Nations",
        otherAcronyms: ["UNASUR", "UNASUL", "UZAN"],
        otherNames: ["Unión de Naciones Suramericanas", "União de Nações Sul-Americanas", "Unie van Zuid-Amerikaanse Naties", "South American Union"],
      },
    ],
    independent: false,
  },
  {
    name: "Faroe Islands",
    topLevelDomain: [".fo"],
    alpha2Code: "FO",
    alpha3Code: "FRO",
    callingCodes: ["298"],
    capital: "Tórshavn",
    altSpellings: ["FO", "Føroyar", "Færøerne"],
    subregion: "Northern Europe",
    region: "Europe",
    population: 48865,
    latlng: [62, -7],
    demonym: "Faroese",
    area: 1393,
    timezones: ["UTC+00:00"],
    nativeName: "Føroyar",
    numericCode: "234",
    flags: {
      svg: "https://flagcdn.com/fo.svg",
      png: "https://flagcdn.com/w320/fo.png",
    },
    currencies: [
      {
        code: "DKK",
        name: "Danish krone",
        symbol: "kr",
      },
      {
        code: "FOK",
        name: "Faroese króna",
        symbol: "kr",
      },
    ],
    languages: [
      {
        iso639_1: "fo",
        iso639_2: "fao",
        name: "Faroese",
        nativeName: "føroyskt",
      },
    ],
    translations: {
      br: "Ilhas Faroé",
      pt: "Ilhas Faroé",
      nl: "Faeröer",
      hr: "Farski Otoci",
      fa: "جزایر فارو",
      de: "Färöer-Inseln",
      es: "Islas Faroe",
      fr: "Îles Féroé",
      ja: "フェロー諸島",
      it: "Isole Far Oer",
      hu: "Feröer",
    },
    flag: "https://flagcdn.com/fo.svg",
    independent: false,
  },
  {
    name: "Fiji",
    topLevelDomain: [".fj"],
    alpha2Code: "FJ",
    alpha3Code: "FJI",
    callingCodes: ["679"],
    capital: "Suva",
    altSpellings: ["FJ", "Viti", "Republic of Fiji", "Matanitu ko Viti", "Fijī Gaṇarājya"],
    subregion: "Melanesia",
    region: "Oceania",
    population: 896444,
    latlng: [-18, 175],
    demonym: "Fijian",
    area: 18272,
    gini: 36.7,
    timezones: ["UTC+12:00"],
    nativeName: "Fiji",
    numericCode: "242",
    flags: {
      svg: "https://flagcdn.com/fj.svg",
      png: "https://flagcdn.com/w320/fj.png",
    },
    currencies: [
      {
        code: "FJD",
        name: "Fijian dollar",
        symbol: "$",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
      {
        iso639_1: "fj",
        iso639_2: "fij",
        name: "Fijian",
        nativeName: "vosa Vakaviti",
      },
      {
        iso639_2: "hif",
        name: "Fiji Hindi",
        nativeName: "फ़िजी बात",
      },
      {
        iso639_2: "rtm",
        name: "Rotuman",
        nativeName: "Fäeag Rotuma",
      },
    ],
    translations: {
      br: "Fiji",
      pt: "Fiji",
      nl: "Fiji",
      hr: "Fiđi",
      fa: "فیجی",
      de: "Fidschi",
      es: "Fiyi",
      fr: "Fidji",
      ja: "フィジー",
      it: "Figi",
      hu: "Fidzsi-szigetek",
    },
    flag: "https://flagcdn.com/fj.svg",
    cioc: "FIJ",
    independent: true,
  },
  {
    name: "Finland",
    topLevelDomain: [".fi"],
    alpha2Code: "FI",
    alpha3Code: "FIN",
    callingCodes: ["358"],
    capital: "Helsinki",
    altSpellings: ["FI", "Suomi", "Republic of Finland", "Suomen tasavalta", "Republiken Finland"],
    subregion: "Northern Europe",
    region: "Europe",
    population: 5530719,
    latlng: [64, 26],
    demonym: "Finnish",
    area: 338424,
    gini: 27.3,
    timezones: ["UTC+02:00"],
    borders: ["NOR", "SWE", "RUS"],
    nativeName: "Suomi",
    numericCode: "246",
    flags: {
      svg: "https://flagcdn.com/fi.svg",
      png: "https://flagcdn.com/w320/fi.png",
    },
    currencies: [
      {
        code: "EUR",
        name: "Euro",
        symbol: "€",
      },
    ],
    languages: [
      {
        iso639_1: "fi",
        iso639_2: "fin",
        name: "Finnish",
        nativeName: "suomi",
      },
      {
        iso639_1: "sv",
        iso639_2: "swe",
        name: "Swedish",
        nativeName: "svenska",
      },
    ],
    translations: {
      br: "Finlândia",
      pt: "Finlândia",
      nl: "Finland",
      hr: "Finska",
      fa: "فنلاند",
      de: "Finnland",
      es: "Finlandia",
      fr: "Finlande",
      ja: "フィンランド",
      it: "Finlandia",
      hu: "Finnország",
    },
    flag: "https://flagcdn.com/fi.svg",
    regionalBlocs: [
      {
        acronym: "EU",
        name: "European Union",
      },
    ],
    cioc: "FIN",
    independent: true,
  },
  {
    name: "France",
    topLevelDomain: [".fr"],
    alpha2Code: "FR",
    alpha3Code: "FRA",
    callingCodes: ["33"],
    capital: "Paris",
    altSpellings: ["FR", "French Republic", "République française"],
    subregion: "Western Europe",
    region: "Europe",
    population: 67391582,
    latlng: [46, 2],
    demonym: "French",
    area: 640679,
    gini: 32.4,
    timezones: [
      "UTC-10:00",
      "UTC-09:30",
      "UTC-09:00",
      "UTC-08:00",
      "UTC-04:00",
      "UTC-03:00",
      "UTC+01:00",
      "UTC+02:00",
      "UTC+03:00",
      "UTC+04:00",
      "UTC+05:00",
      "UTC+10:00",
      "UTC+11:00",
      "UTC+12:00",
    ],
    borders: ["AND", "BEL", "DEU", "ITA", "LUX", "MCO", "ESP", "CHE"],
    nativeName: "France",
    numericCode: "250",
    flags: {
      svg: "https://flagcdn.com/fr.svg",
      png: "https://flagcdn.com/w320/fr.png",
    },
    currencies: [
      {
        code: "EUR",
        name: "Euro",
        symbol: "€",
      },
    ],
    languages: [
      {
        iso639_1: "fr",
        iso639_2: "fra",
        name: "French",
        nativeName: "français",
      },
    ],
    translations: {
      br: "França",
      pt: "França",
      nl: "Frankrijk",
      hr: "Francuska",
      fa: "فرانسه",
      de: "Frankreich",
      es: "Francia",
      fr: "France",
      ja: "フランス",
      it: "Francia",
      hu: "Franciaország",
    },
    flag: "https://flagcdn.com/fr.svg",
    regionalBlocs: [
      {
        acronym: "EU",
        name: "European Union",
      },
    ],
    cioc: "FRA",
    independent: true,
  },
  {
    name: "French Guiana",
    topLevelDomain: [".gf"],
    alpha2Code: "GF",
    alpha3Code: "GUF",
    callingCodes: ["594"],
    capital: "Cayenne",
    altSpellings: ["GF", "Guiana", "Guyane"],
    subregion: "South America",
    region: "Americas",
    population: 254541,
    latlng: [4, -53],
    demonym: "French Guianan",
    timezones: ["UTC-03:00"],
    borders: ["BRA", "SUR"],
    nativeName: "Guyane française",
    numericCode: "254",
    flags: {
      svg: "https://flagcdn.com/gf.svg",
      png: "https://flagcdn.com/w320/gf.png",
    },
    currencies: [
      {
        code: "EUR",
        name: "Euro",
        symbol: "€",
      },
    ],
    languages: [
      {
        iso639_1: "fr",
        iso639_2: "fra",
        name: "French",
        nativeName: "français",
      },
    ],
    translations: {
      br: "Guiana Francesa",
      pt: "Guiana Francesa",
      nl: "Frans-Guyana",
      hr: "Francuska Gvajana",
      fa: "گویان فرانسه",
      de: "Französisch Guyana",
      es: "Guayana Francesa",
      fr: "Guayane",
      ja: "フランス領ギアナ",
      it: "Guyana francese",
      hu: "Francia Guyana",
    },
    flag: "https://flagcdn.com/gf.svg",
    regionalBlocs: [
      {
        acronym: "USAN",
        name: "Union of South American Nations",
        otherAcronyms: ["UNASUR", "UNASUL", "UZAN"],
        otherNames: ["Unión de Naciones Suramericanas", "União de Nações Sul-Americanas", "Unie van Zuid-Amerikaanse Naties", "South American Union"],
      },
      {
        acronym: "EU",
        name: "European Union",
      },
    ],
    independent: false,
  },
  {
    name: "French Polynesia",
    topLevelDomain: [".pf"],
    alpha2Code: "PF",
    alpha3Code: "PYF",
    callingCodes: ["689"],
    capital: "Papeetē",
    altSpellings: ["PF", "Polynésie française", "French Polynesia", "Pōrīnetia Farāni"],
    subregion: "Polynesia",
    region: "Oceania",
    population: 280904,
    latlng: [-15, -140],
    demonym: "French Polynesian",
    area: 4167,
    timezones: ["UTC-10:00", "UTC-09:30", "UTC-09:00"],
    nativeName: "Polynésie française",
    numericCode: "258",
    flags: {
      svg: "https://flagcdn.com/pf.svg",
      png: "https://flagcdn.com/w320/pf.png",
    },
    currencies: [
      {
        code: "XPF",
        name: "CFP franc",
        symbol: "Fr",
      },
    ],
    languages: [
      {
        iso639_1: "fr",
        iso639_2: "fra",
        name: "French",
        nativeName: "français",
      },
    ],
    translations: {
      br: "Polinésia Francesa",
      pt: "Polinésia Francesa",
      nl: "Frans-Polynesië",
      hr: "Francuska Polinezija",
      fa: "پلی‌نزی فرانسه",
      de: "Französisch-Polynesien",
      es: "Polinesia Francesa",
      fr: "Polynésie française",
      ja: "フランス領ポリネシア",
      it: "Polinesia Francese",
      hu: "Francia Polinézia",
    },
    flag: "https://flagcdn.com/pf.svg",
    independent: false,
  },
  {
    name: "French Southern Territories",
    topLevelDomain: [".tf"],
    alpha2Code: "TF",
    alpha3Code: "ATF",
    callingCodes: ["262"],
    capital: "Port-aux-Français",
    altSpellings: ["TF"],
    subregion: "Southern Africa",
    region: "Africa",
    population: 140,
    latlng: [-49.25, 69.167],
    demonym: "French",
    area: 7747,
    timezones: ["UTC+05:00"],
    nativeName: "Territoire des Terres australes et antarctiques françaises",
    numericCode: "260",
    flags: {
      svg: "https://flagcdn.com/tf.svg",
      png: "https://flagcdn.com/w320/tf.png",
    },
    currencies: [
      {
        code: "EUR",
        name: "Euro",
        symbol: "€",
      },
    ],
    languages: [
      {
        iso639_1: "fr",
        iso639_2: "fra",
        name: "French",
        nativeName: "français",
      },
    ],
    translations: {
      br: "Terras Austrais e Antárticas Francesas",
      pt: "Terras Austrais e Antárticas Francesas",
      nl: "Franse Gebieden in de zuidelijke Indische Oceaan",
      hr: "Francuski južni i antarktički teritoriji",
      fa: "سرزمین‌های جنوبی و جنوبگانی فرانسه",
      de: "Französische Süd- und Antarktisgebiete",
      es: "Tierras Australes y Antárticas Francesas",
      fr: "Terres australes et antarctiques françaises",
      ja: "フランス領南方・南極地域",
      it: "Territori Francesi del Sud",
      hu: "Francia déli és antarktiszi területek",
    },
    flag: "https://flagcdn.com/tf.svg",
    regionalBlocs: [
      {
        acronym: "AU",
        name: "African Union",
        otherNames: ["الاتحاد الأفريقي", "Union africaine", "União Africana", "Unión Africana", "Umoja wa Afrika"],
      },
    ],
    independent: false,
  },
  {
    name: "Gabon",
    topLevelDomain: [".ga"],
    alpha2Code: "GA",
    alpha3Code: "GAB",
    callingCodes: ["241"],
    capital: "Libreville",
    altSpellings: ["GA", "Gabonese Republic", "République Gabonaise"],
    subregion: "Middle Africa",
    region: "Africa",
    population: 2225728,
    latlng: [-1, 11.75],
    demonym: "Gabonese",
    area: 267668,
    gini: 38,
    timezones: ["UTC+01:00"],
    borders: ["CMR", "COG", "GNQ"],
    nativeName: "Gabon",
    numericCode: "266",
    flags: {
      svg: "https://flagcdn.com/ga.svg",
      png: "https://flagcdn.com/w320/ga.png",
    },
    currencies: [
      {
        code: "XAF",
        name: "Central African CFA franc",
        symbol: "Fr",
      },
    ],
    languages: [
      {
        iso639_1: "fr",
        iso639_2: "fra",
        name: "French",
        nativeName: "français",
      },
    ],
    translations: {
      br: "Gabão",
      pt: "Gabão",
      nl: "Gabon",
      hr: "Gabon",
      fa: "گابن",
      de: "Gabun",
      es: "Gabón",
      fr: "Gabon",
      ja: "ガボン",
      it: "Gabon",
      hu: "Gabon",
    },
    flag: "https://flagcdn.com/ga.svg",
    regionalBlocs: [
      {
        acronym: "AU",
        name: "African Union",
        otherNames: ["الاتحاد الأفريقي", "Union africaine", "União Africana", "Unión Africana", "Umoja wa Afrika"],
      },
    ],
    cioc: "GAB",
    independent: true,
  },
  {
    name: "Gambia",
    topLevelDomain: [".gm"],
    alpha2Code: "GM",
    alpha3Code: "GMB",
    callingCodes: ["220"],
    capital: "Banjul",
    altSpellings: ["GM", "Republic of the Gambia"],
    subregion: "Western Africa",
    region: "Africa",
    population: 2416664,
    latlng: [13.46666666, -16.56666666],
    demonym: "Gambian",
    area: 11295,
    gini: 35.9,
    timezones: ["UTC+00:00"],
    borders: ["SEN"],
    nativeName: "Gambia",
    numericCode: "270",
    flags: {
      svg: "https://flagcdn.com/gm.svg",
      png: "https://flagcdn.com/w320/gm.png",
    },
    currencies: [
      {
        code: "GMD",
        name: "Gambian dalasi",
        symbol: "D",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
    ],
    translations: {
      br: "Gâmbia",
      pt: "Gâmbia",
      nl: "Gambia",
      hr: "Gambija",
      fa: "گامبیا",
      de: "Gambia",
      es: "Gambia",
      fr: "Gambie",
      ja: "ガンビア",
      it: "Gambia",
      hu: "Gambia",
    },
    flag: "https://flagcdn.com/gm.svg",
    regionalBlocs: [
      {
        acronym: "AU",
        name: "African Union",
        otherNames: ["الاتحاد الأفريقي", "Union africaine", "União Africana", "Unión Africana", "Umoja wa Afrika"],
      },
    ],
    cioc: "GAM",
    independent: true,
  },
  {
    name: "Georgia",
    topLevelDomain: [".ge"],
    alpha2Code: "GE",
    alpha3Code: "GEO",
    callingCodes: ["995"],
    capital: "Tbilisi",
    altSpellings: ["GE", "Sakartvelo"],
    subregion: "Western Asia",
    region: "Asia",
    population: 3714000,
    latlng: [42, 43.5],
    demonym: "Georgian",
    area: 69700,
    gini: 35.9,
    timezones: ["UTC-04:00"],
    borders: ["ARM", "AZE", "RUS", "TUR"],
    nativeName: "საქართველო",
    numericCode: "268",
    flags: {
      svg: "https://flagcdn.com/ge.svg",
      png: "https://flagcdn.com/w320/ge.png",
    },
    currencies: [
      {
        code: "GEL",
        name: "Georgian Lari",
        symbol: "ლ",
      },
    ],
    languages: [
      {
        iso639_1: "ka",
        iso639_2: "kat",
        name: "Georgian",
        nativeName: "ქართული",
      },
    ],
    translations: {
      br: "Geórgia",
      pt: "Geórgia",
      nl: "Georgië",
      hr: "Gruzija",
      fa: "گرجستان",
      de: "Georgien",
      es: "Georgia",
      fr: "Géorgie",
      ja: "グルジア",
      it: "Georgia",
      hu: "Grúzia",
    },
    flag: "https://flagcdn.com/ge.svg",
    cioc: "GEO",
    independent: true,
  },
  {
    name: "Germany",
    topLevelDomain: [".de"],
    alpha2Code: "DE",
    alpha3Code: "DEU",
    callingCodes: ["49"],
    capital: "Berlin",
    altSpellings: ["DE", "Federal Republic of Germany", "Bundesrepublik Deutschland"],
    subregion: "Central Europe",
    region: "Europe",
    population: 83240525,
    latlng: [51, 9],
    demonym: "German",
    area: 357114,
    gini: 31.9,
    timezones: ["UTC+01:00"],
    borders: ["AUT", "BEL", "CZE", "DNK", "FRA", "LUX", "NLD", "POL", "CHE"],
    nativeName: "Deutschland",
    numericCode: "276",
    flags: {
      svg: "https://flagcdn.com/de.svg",
      png: "https://flagcdn.com/w320/de.png",
    },
    currencies: [
      {
        code: "EUR",
        name: "Euro",
        symbol: "€",
      },
    ],
    languages: [
      {
        iso639_1: "de",
        iso639_2: "deu",
        name: "German",
        nativeName: "Deutsch",
      },
    ],
    translations: {
      br: "Alemanha",
      pt: "Alemanha",
      nl: "Duitsland",
      hr: "Njemačka",
      fa: "آلمان",
      de: "Deutschland",
      es: "Alemania",
      fr: "Allemagne",
      ja: "ドイツ",
      it: "Germania",
      hu: "Grúzia",
    },
    flag: "https://flagcdn.com/de.svg",
    regionalBlocs: [
      {
        acronym: "EU",
        name: "European Union",
      },
    ],
    cioc: "GER",
    independent: true,
  },
  {
    name: "Ghana",
    topLevelDomain: [".gh"],
    alpha2Code: "GH",
    alpha3Code: "GHA",
    callingCodes: ["233"],
    capital: "Accra",
    altSpellings: ["GH"],
    subregion: "Western Africa",
    region: "Africa",
    population: 31072945,
    latlng: [8, -2],
    demonym: "Ghanaian",
    area: 238533,
    gini: 43.5,
    timezones: ["UTC"],
    borders: ["BFA", "CIV", "TGO"],
    nativeName: "Ghana",
    numericCode: "288",
    flags: {
      svg: "https://flagcdn.com/gh.svg",
      png: "https://flagcdn.com/w320/gh.png",
    },
    currencies: [
      {
        code: "GHS",
        name: "Ghanaian cedi",
        symbol: "₵",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
    ],
    translations: {
      br: "Gana",
      pt: "Gana",
      nl: "Ghana",
      hr: "Gana",
      fa: "غنا",
      de: "Ghana",
      es: "Ghana",
      fr: "Ghana",
      ja: "ガーナ",
      it: "Ghana",
      hu: "Ghána",
    },
    flag: "https://flagcdn.com/gh.svg",
    regionalBlocs: [
      {
        acronym: "AU",
        name: "African Union",
        otherNames: ["الاتحاد الأفريقي", "Union africaine", "União Africana", "Unión Africana", "Umoja wa Afrika"],
      },
    ],
    cioc: "GHA",
    independent: true,
  },
  {
    name: "Gibraltar",
    topLevelDomain: [".gi"],
    alpha2Code: "GI",
    alpha3Code: "GIB",
    callingCodes: ["350"],
    capital: "Gibraltar",
    altSpellings: ["GI"],
    subregion: "Southern Europe",
    region: "Europe",
    population: 33691,
    latlng: [36.13333333, -5.35],
    demonym: "Gibraltar",
    area: 6,
    timezones: ["UTC+01:00"],
    borders: ["ESP"],
    nativeName: "Gibraltar",
    numericCode: "292",
    flags: {
      svg: "https://flagcdn.com/gi.svg",
      png: "https://flagcdn.com/w320/gi.png",
    },
    currencies: [
      {
        code: "GIP",
        name: "Gibraltar pound",
        symbol: "£",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
    ],
    translations: {
      br: "Gibraltar",
      pt: "Gibraltar",
      nl: "Gibraltar",
      hr: "Gibraltar",
      fa: "جبل‌طارق",
      de: "Gibraltar",
      es: "Gibraltar",
      fr: "Gibraltar",
      ja: "ジブラルタル",
      it: "Gibilterra",
      hu: "Gibraltár",
    },
    flag: "https://flagcdn.com/gi.svg",
    regionalBlocs: [
      {
        acronym: "EU",
        name: "European Union",
      },
    ],
    independent: false,
  },
  {
    name: "Greece",
    topLevelDomain: [".gr"],
    alpha2Code: "GR",
    alpha3Code: "GRC",
    callingCodes: ["30"],
    capital: "Athens",
    altSpellings: ["GR", "Elláda", "Hellenic Republic", "Ελληνική Δημοκρατία"],
    subregion: "Southern Europe",
    region: "Europe",
    population: 10715549,
    latlng: [39, 22],
    demonym: "Greek",
    area: 131990,
    gini: 32.9,
    timezones: ["UTC+02:00"],
    borders: ["ALB", "BGR", "TUR", "MKD"],
    nativeName: "Ελλάδα",
    numericCode: "300",
    flags: {
      svg: "https://flagcdn.com/gr.svg",
      png: "https://flagcdn.com/w320/gr.png",
    },
    currencies: [
      {
        code: "EUR",
        name: "Euro",
        symbol: "€",
      },
    ],
    languages: [
      {
        iso639_1: "el",
        iso639_2: "ell",
        name: "Greek (modern)",
        nativeName: "ελληνικά",
      },
    ],
    translations: {
      br: "Grécia",
      pt: "Grécia",
      nl: "Griekenland",
      hr: "Grčka",
      fa: "یونان",
      de: "Griechenland",
      es: "Grecia",
      fr: "Grèce",
      ja: "ギリシャ",
      it: "Grecia",
      hu: "Görögország",
    },
    flag: "https://flagcdn.com/gr.svg",
    regionalBlocs: [
      {
        acronym: "EU",
        name: "European Union",
      },
    ],
    cioc: "GRE",
    independent: true,
  },
  {
    name: "Greenland",
    topLevelDomain: [".gl"],
    alpha2Code: "GL",
    alpha3Code: "GRL",
    callingCodes: ["299"],
    capital: "Nuuk",
    altSpellings: ["GL", "Grønland"],
    subregion: "Northern America",
    region: "Americas",
    population: 56367,
    latlng: [72, -40],
    demonym: "Greenlandic",
    area: 2166086,
    timezones: ["UTC-04:00", "UTC-03:00", "UTC-01:00", "UTC+00:00"],
    nativeName: "Kalaallit Nunaat",
    numericCode: "304",
    flags: {
      svg: "https://flagcdn.com/gl.svg",
      png: "https://flagcdn.com/w320/gl.png",
    },
    currencies: [
      {
        code: "DKK",
        name: "Danish krone",
        symbol: "kr",
      },
    ],
    languages: [
      {
        iso639_1: "kl",
        iso639_2: "kal",
        name: "Kalaallisut",
        nativeName: "kalaallisut",
      },
    ],
    translations: {
      br: "Groelândia",
      pt: "Gronelândia",
      nl: "Groenland",
      hr: "Grenland",
      fa: "گرینلند",
      de: "Grönland",
      es: "Groenlandia",
      fr: "Groenland",
      ja: "グリーンランド",
      it: "Groenlandia",
      hu: "Grönland",
    },
    flag: "https://flagcdn.com/gl.svg",
    independent: false,
  },
  {
    name: "Grenada",
    topLevelDomain: [".gd"],
    alpha2Code: "GD",
    alpha3Code: "GRD",
    callingCodes: ["1"],
    capital: "St. George's",
    altSpellings: ["GD"],
    subregion: "Caribbean",
    region: "Americas",
    population: 112519,
    latlng: [12.11666666, -61.66666666],
    demonym: "Grenadian",
    area: 344,
    timezones: ["UTC-04:00"],
    nativeName: "Grenada",
    numericCode: "308",
    flags: {
      svg: "https://flagcdn.com/gd.svg",
      png: "https://flagcdn.com/w320/gd.png",
    },
    currencies: [
      {
        code: "XCD",
        name: "East Caribbean dollar",
        symbol: "$",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
    ],
    translations: {
      br: "Granada",
      pt: "Granada",
      nl: "Grenada",
      hr: "Grenada",
      fa: "گرنادا",
      de: "Grenada",
      es: "Grenada",
      fr: "Grenade",
      ja: "グレナダ",
      it: "Grenada",
      hu: "Grenada",
    },
    flag: "https://flagcdn.com/gd.svg",
    regionalBlocs: [
      {
        acronym: "CARICOM",
        name: "Caribbean Community",
        otherNames: ["Comunidad del Caribe", "Communauté Caribéenne", "Caribische Gemeenschap"],
      },
    ],
    cioc: "GRN",
    independent: true,
  },
  {
    name: "Guadeloupe",
    topLevelDomain: [".gp"],
    alpha2Code: "GP",
    alpha3Code: "GLP",
    callingCodes: ["590"],
    capital: "Basse-Terre",
    altSpellings: ["GP", "Gwadloup"],
    subregion: "Caribbean",
    region: "Americas",
    population: 400132,
    latlng: [16.25, -61.583333],
    demonym: "Guadeloupian",
    timezones: ["UTC-04:00"],
    nativeName: "Guadeloupe",
    numericCode: "312",
    flags: {
      svg: "https://flagcdn.com/gp.svg",
      png: "https://flagcdn.com/w320/gp.png",
    },
    currencies: [
      {
        code: "EUR",
        name: "Euro",
        symbol: "€",
      },
    ],
    languages: [
      {
        iso639_1: "fr",
        iso639_2: "fra",
        name: "French",
        nativeName: "français",
      },
    ],
    translations: {
      br: "Guadalupe",
      pt: "Guadalupe",
      nl: "Guadeloupe",
      hr: "Gvadalupa",
      fa: "جزیره گوادلوپ",
      de: "Guadeloupe",
      es: "Guadalupe",
      fr: "Guadeloupe",
      ja: "グアドループ",
      it: "Guadeloupa",
      hu: "Guadeloupe",
    },
    flag: "https://flagcdn.com/gp.svg",
    independent: false,
  },
  {
    name: "Guam",
    topLevelDomain: [".gu"],
    alpha2Code: "GU",
    alpha3Code: "GUM",
    callingCodes: ["1"],
    capital: "Hagåtña",
    altSpellings: ["GU", "Guåhån"],
    subregion: "Micronesia",
    region: "Oceania",
    population: 168783,
    latlng: [13.46666666, 144.78333333],
    demonym: "Guamanian",
    area: 549,
    timezones: ["UTC+10:00"],
    nativeName: "Guam",
    numericCode: "316",
    flags: {
      svg: "https://flagcdn.com/gu.svg",
      png: "https://flagcdn.com/w320/gu.png",
    },
    currencies: [
      {
        code: "USD",
        name: "United States dollar",
        symbol: "$",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
      {
        iso639_1: "ch",
        iso639_2: "cha",
        name: "Chamorro",
        nativeName: "Chamoru",
      },
      {
        iso639_1: "es",
        iso639_2: "spa",
        name: "Spanish",
        nativeName: "Español",
      },
    ],
    translations: {
      br: "Guam",
      pt: "Guame",
      nl: "Guam",
      hr: "Guam",
      fa: "گوام",
      de: "Guam",
      es: "Guam",
      fr: "Guam",
      ja: "グアム",
      it: "Guam",
      hu: "Guam",
    },
    flag: "https://flagcdn.com/gu.svg",
    cioc: "GUM",
    independent: false,
  },
  {
    name: "Guatemala",
    topLevelDomain: [".gt"],
    alpha2Code: "GT",
    alpha3Code: "GTM",
    callingCodes: ["502"],
    capital: "Guatemala City",
    altSpellings: ["GT"],
    subregion: "Central America",
    region: "Americas",
    population: 16858333,
    latlng: [15.5, -90.25],
    demonym: "Guatemalan",
    area: 108889,
    gini: 48.3,
    timezones: ["UTC-06:00"],
    borders: ["BLZ", "SLV", "HND", "MEX"],
    nativeName: "Guatemala",
    numericCode: "320",
    flags: {
      svg: "https://flagcdn.com/gt.svg",
      png: "https://flagcdn.com/w320/gt.png",
    },
    currencies: [
      {
        code: "GTQ",
        name: "Guatemalan quetzal",
        symbol: "Q",
      },
    ],
    languages: [
      {
        iso639_1: "es",
        iso639_2: "spa",
        name: "Spanish",
        nativeName: "Español",
      },
    ],
    translations: {
      br: "Guatemala",
      pt: "Guatemala",
      nl: "Guatemala",
      hr: "Gvatemala",
      fa: "گواتمالا",
      de: "Guatemala",
      es: "Guatemala",
      fr: "Guatemala",
      ja: "グアテマラ",
      it: "Guatemala",
      hu: "Guatemala",
    },
    flag: "https://flagcdn.com/gt.svg",
    regionalBlocs: [
      {
        acronym: "CAIS",
        name: "Central American Integration System",
        otherAcronyms: ["SICA"],
        otherNames: ["Sistema de la Integración Centroamericana,"],
      },
    ],
    cioc: "GUA",
    independent: true,
  },
  {
    name: "Guernsey",
    topLevelDomain: [".gg"],
    alpha2Code: "GG",
    alpha3Code: "GGY",
    callingCodes: ["44"],
    capital: "St. Peter Port",
    altSpellings: ["GG", "Bailiwick of Guernsey", "Bailliage de Guernesey"],
    subregion: "Northern Europe",
    region: "Europe",
    population: 62999,
    latlng: [49.46666666, -2.58333333],
    demonym: "Channel Islander",
    area: 78,
    timezones: ["UTC+00:00"],
    nativeName: "Guernsey",
    numericCode: "831",
    flags: {
      svg: "https://flagcdn.com/gg.svg",
      png: "https://flagcdn.com/w320/gg.png",
    },
    currencies: [
      {
        code: "GBP",
        name: "British pound",
        symbol: "£",
      },
      {
        code: "GGP",
        name: "Guernsey pound",
        symbol: "£",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
      {
        iso639_1: "fr",
        iso639_2: "fra",
        name: "French",
        nativeName: "français",
      },
    ],
    translations: {
      br: "Guernsey",
      pt: "Guernsey",
      nl: "Guernsey",
      hr: "Guernsey",
      fa: "گرنزی",
      de: "Guernsey",
      es: "Guernsey",
      fr: "Guernesey",
      ja: "ガーンジー",
      it: "Guernsey",
      hu: "Guernsey",
    },
    flag: "https://flagcdn.com/gg.svg",
    independent: false,
  },
  {
    name: "Guinea",
    topLevelDomain: [".gn"],
    alpha2Code: "GN",
    alpha3Code: "GIN",
    callingCodes: ["224"],
    capital: "Conakry",
    altSpellings: ["GN", "Republic of Guinea", "République de Guinée"],
    subregion: "Western Africa",
    region: "Africa",
    population: 13132792,
    latlng: [11, -10],
    demonym: "Guinean",
    area: 245857,
    gini: 33.7,
    timezones: ["UTC"],
    borders: ["CIV", "GNB", "LBR", "MLI", "SEN", "SLE"],
    nativeName: "Guinée",
    numericCode: "324",
    flags: {
      svg: "https://flagcdn.com/gn.svg",
      png: "https://flagcdn.com/w320/gn.png",
    },
    currencies: [
      {
        code: "GNF",
        name: "Guinean franc",
        symbol: "Fr",
      },
    ],
    languages: [
      {
        iso639_1: "fr",
        iso639_2: "fra",
        name: "French",
        nativeName: "français",
      },
      {
        iso639_1: "ff",
        iso639_2: "ful",
        name: "Fula",
        nativeName: "Fulfulde",
      },
    ],
    translations: {
      br: "Guiné",
      pt: "Guiné",
      nl: "Guinee",
      hr: "Gvineja",
      fa: "گینه",
      de: "Guinea",
      es: "Guinea",
      fr: "Guinée",
      ja: "ギニア",
      it: "Guinea",
      hu: "Guinea",
    },
    flag: "https://flagcdn.com/gn.svg",
    regionalBlocs: [
      {
        acronym: "AU",
        name: "African Union",
        otherNames: ["الاتحاد الأفريقي", "Union africaine", "União Africana", "Unión Africana", "Umoja wa Afrika"],
      },
    ],
    cioc: "GUI",
    independent: true,
  },
  {
    name: "Guinea-Bissau",
    topLevelDomain: [".gw"],
    alpha2Code: "GW",
    alpha3Code: "GNB",
    callingCodes: ["245"],
    capital: "Bissau",
    altSpellings: ["GW", "Republic of Guinea-Bissau", "República da Guiné-Bissau"],
    subregion: "Western Africa",
    region: "Africa",
    population: 1967998,
    latlng: [12, -15],
    demonym: "Guinea-Bissauan",
    area: 36125,
    gini: 50.7,
    timezones: ["UTC"],
    borders: ["GIN", "SEN"],
    nativeName: "Guiné-Bissau",
    numericCode: "624",
    flags: {
      svg: "https://flagcdn.com/gw.svg",
      png: "https://flagcdn.com/w320/gw.png",
    },
    currencies: [
      {
        code: "XOF",
        name: "West African CFA franc",
        symbol: "Fr",
      },
    ],
    languages: [
      {
        iso639_1: "pt",
        iso639_2: "por",
        name: "Portuguese",
        nativeName: "Português",
      },
    ],
    translations: {
      br: "Guiné-Bissau",
      pt: "Guiné-Bissau",
      nl: "Guinee-Bissau",
      hr: "Gvineja Bisau",
      fa: "گینه بیسائو",
      de: "Guinea-Bissau",
      es: "Guinea-Bisáu",
      fr: "Guinée-Bissau",
      ja: "ギニアビサウ",
      it: "Guinea-Bissau",
      hu: "Bissau-Guinea",
    },
    flag: "https://flagcdn.com/gw.svg",
    regionalBlocs: [
      {
        acronym: "AU",
        name: "African Union",
        otherNames: ["الاتحاد الأفريقي", "Union africaine", "União Africana", "Unión Africana", "Umoja wa Afrika"],
      },
    ],
    cioc: "GBS",
    independent: true,
  },
  {
    name: "Guyana",
    topLevelDomain: [".gy"],
    alpha2Code: "GY",
    alpha3Code: "GUY",
    callingCodes: ["592"],
    capital: "Georgetown",
    altSpellings: ["GY", "Co-operative Republic of Guyana"],
    subregion: "South America",
    region: "Americas",
    population: 786559,
    latlng: [5, -59],
    demonym: "Guyanese",
    area: 214969,
    gini: 45.1,
    timezones: ["UTC-04:00"],
    borders: ["BRA", "SUR", "VEN"],
    nativeName: "Guyana",
    numericCode: "328",
    flags: {
      svg: "https://flagcdn.com/gy.svg",
      png: "https://flagcdn.com/w320/gy.png",
    },
    currencies: [
      {
        code: "GYD",
        name: "Guyanese dollar",
        symbol: "$",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
    ],
    translations: {
      br: "Guiana",
      pt: "Guiana",
      nl: "Guyana",
      hr: "Gvajana",
      fa: "گویان",
      de: "Guyana",
      es: "Guyana",
      fr: "Guyane",
      ja: "ガイアナ",
      it: "Guyana",
      hu: "Guyana",
    },
    flag: "https://flagcdn.com/gy.svg",
    regionalBlocs: [
      {
        acronym: "CARICOM",
        name: "Caribbean Community",
        otherNames: ["Comunidad del Caribe", "Communauté Caribéenne", "Caribische Gemeenschap"],
      },
      {
        acronym: "USAN",
        name: "Union of South American Nations",
        otherAcronyms: ["UNASUR", "UNASUL", "UZAN"],
        otherNames: ["Unión de Naciones Suramericanas", "União de Nações Sul-Americanas", "Unie van Zuid-Amerikaanse Naties", "South American Union"],
      },
    ],
    cioc: "GUY",
    independent: true,
  },
  {
    name: "Haiti",
    topLevelDomain: [".ht"],
    alpha2Code: "HT",
    alpha3Code: "HTI",
    callingCodes: ["509"],
    capital: "Port-au-Prince",
    altSpellings: ["HT", "Republic of Haiti", "République d'Haïti", "Repiblik Ayiti"],
    subregion: "Caribbean",
    region: "Americas",
    population: 11402533,
    latlng: [19, -72.41666666],
    demonym: "Haitian",
    area: 27750,
    gini: 41.1,
    timezones: ["UTC-05:00"],
    borders: ["DOM"],
    nativeName: "Haïti",
    numericCode: "332",
    flags: {
      svg: "https://flagcdn.com/ht.svg",
      png: "https://flagcdn.com/w320/ht.png",
    },
    currencies: [
      {
        code: "HTG",
        name: "Haitian gourde",
        symbol: "G",
      },
    ],
    languages: [
      {
        iso639_1: "fr",
        iso639_2: "fra",
        name: "French",
        nativeName: "français",
      },
      {
        iso639_1: "ht",
        iso639_2: "hat",
        name: "Haitian",
        nativeName: "Kreyòl ayisyen",
      },
    ],
    translations: {
      br: "Haiti",
      pt: "Haiti",
      nl: "Haïti",
      hr: "Haiti",
      fa: "هائیتی",
      de: "Haiti",
      es: "Haiti",
      fr: "Haïti",
      ja: "ハイチ",
      it: "Haiti",
      hu: "Haiti",
    },
    flag: "https://flagcdn.com/ht.svg",
    regionalBlocs: [
      {
        acronym: "CARICOM",
        name: "Caribbean Community",
        otherNames: ["Comunidad del Caribe", "Communauté Caribéenne", "Caribische Gemeenschap"],
      },
    ],
    cioc: "HAI",
    independent: true,
  },
  {
    name: "Heard Island and McDonald Islands",
    topLevelDomain: [".hm", ".aq"],
    alpha2Code: "HM",
    alpha3Code: "HMD",
    callingCodes: ["672"],
    altSpellings: ["HM"],
    subregion: "Antarctic",
    region: "Antarctic",
    population: 0,
    latlng: [-53.1, 72.51666666],
    demonym: "Heard and McDonald Islander",
    area: 412,
    timezones: ["UTC+05:00"],
    nativeName: "Heard Island and McDonald Islands",
    numericCode: "334",
    flags: {
      svg: "https://flagcdn.com/hm.svg",
      png: "https://flagcdn.com/w320/hm.png",
    },
    currencies: [
      {
        code: "AUD",
        name: "Australian dollar",
        symbol: "$",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
    ],
    translations: {
      br: "Ilha Heard e Ilhas McDonald",
      pt: "Ilha Heard e Ilhas McDonald",
      nl: "Heard- en McDonaldeilanden",
      hr: "Otok Heard i otočje McDonald",
      fa: "جزیره هرد و جزایر مک‌دونالد",
      de: "Heard und die McDonaldinseln",
      es: "Islas Heard y McDonald",
      fr: "Îles Heard-et-MacDonald",
      ja: "ハード島とマクドナルド諸島",
      it: "Isole Heard e McDonald",
      hu: "Heard-sziget és McDonald-szigetek",
    },
    flag: "https://flagcdn.com/hm.svg",
    independent: false,
  },
  {
    name: "Vatican City",
    topLevelDomain: [".va"],
    alpha2Code: "VA",
    alpha3Code: "VAT",
    callingCodes: ["379"],
    capital: "Vatican City",
    altSpellings: ["Vatican", "The Vatican"],
    subregion: "Southern Europe",
    region: "Europe",
    population: 451,
    latlng: [41.9, 12.45],
    demonym: "Vatican",
    area: 0.44,
    timezones: ["UTC+01:00"],
    borders: ["ITA"],
    nativeName: "Status Civitatis Vaticanae",
    numericCode: "336",
    flags: {
      svg: "https://flagcdn.com/va.svg",
      png: "https://flagcdn.com/w320/va.png",
    },
    currencies: [
      {
        code: "EUR",
        name: "Euro",
        symbol: "€",
      },
    ],
    languages: [
      {
        iso639_1: "la",
        iso639_2: "lat",
        name: "Latin",
        nativeName: "latine",
      },
      {
        iso639_1: "it",
        iso639_2: "ita",
        name: "Italian",
        nativeName: "Italiano",
      },
      {
        iso639_1: "fr",
        iso639_2: "fra",
        name: "French",
        nativeName: "Français",
      },
      {
        iso639_1: "de",
        iso639_2: "deu",
        name: "German",
        nativeName: "Deutsch",
      },
    ],
    translations: {
      br: "Vaticano",
      pt: "Vaticano",
      nl: "Heilige Stoel",
      hr: "Sveta Stolica",
      fa: "سریر مقدس",
      de: "Heiliger Stuhl",
      es: "Santa Sede",
      fr: "Saint-Siège",
      ja: "聖座",
      it: "Santa Sede",
      hu: "Vatikán",
    },
    flag: "https://flagcdn.com/va.svg",
    independent: true,
  },
  {
    name: "Honduras",
    topLevelDomain: [".hn"],
    alpha2Code: "HN",
    alpha3Code: "HND",
    callingCodes: ["504"],
    capital: "Tegucigalpa",
    altSpellings: ["HN", "Republic of Honduras", "República de Honduras"],
    subregion: "Central America",
    region: "Americas",
    population: 9904608,
    latlng: [15, -86.5],
    demonym: "Honduran",
    area: 112492,
    gini: 48.2,
    timezones: ["UTC-06:00"],
    borders: ["GTM", "SLV", "NIC"],
    nativeName: "Honduras",
    numericCode: "340",
    flags: {
      svg: "https://flagcdn.com/hn.svg",
      png: "https://flagcdn.com/w320/hn.png",
    },
    currencies: [
      {
        code: "HNL",
        name: "Honduran lempira",
        symbol: "L",
      },
    ],
    languages: [
      {
        iso639_1: "es",
        iso639_2: "spa",
        name: "Spanish",
        nativeName: "Español",
      },
    ],
    translations: {
      br: "Honduras",
      pt: "Honduras",
      nl: "Honduras",
      hr: "Honduras",
      fa: "هندوراس",
      de: "Honduras",
      es: "Honduras",
      fr: "Honduras",
      ja: "ホンジュラス",
      it: "Honduras",
      hu: "Honduras",
    },
    flag: "https://flagcdn.com/hn.svg",
    regionalBlocs: [
      {
        acronym: "CAIS",
        name: "Central American Integration System",
        otherAcronyms: ["SICA"],
        otherNames: ["Sistema de la Integración Centroamericana,"],
      },
    ],
    cioc: "HON",
    independent: true,
  },
  {
    name: "Hungary",
    topLevelDomain: [".hu"],
    alpha2Code: "HU",
    alpha3Code: "HUN",
    callingCodes: ["36"],
    capital: "Budapest",
    altSpellings: ["HU"],
    subregion: "Central Europe",
    region: "Europe",
    population: 9749763,
    latlng: [47, 20],
    demonym: "Hungarian",
    area: 93028,
    gini: 29.6,
    timezones: ["UTC+01:00"],
    borders: ["AUT", "HRV", "ROU", "SRB", "SVK", "SVN", "UKR"],
    nativeName: "Magyarország",
    numericCode: "348",
    flags: {
      svg: "https://flagcdn.com/hu.svg",
      png: "https://flagcdn.com/w320/hu.png",
    },
    currencies: [
      {
        code: "HUF",
        name: "Hungarian forint",
        symbol: "Ft",
      },
    ],
    languages: [
      {
        iso639_1: "hu",
        iso639_2: "hun",
        name: "Hungarian",
        nativeName: "magyar",
      },
    ],
    translations: {
      br: "Hungria",
      pt: "Hungria",
      nl: "Hongarije",
      hr: "Mađarska",
      fa: "مجارستان",
      de: "Ungarn",
      es: "Hungría",
      fr: "Hongrie",
      ja: "ハンガリー",
      it: "Ungheria",
      hu: "Magyarország",
    },
    flag: "https://flagcdn.com/hu.svg",
    regionalBlocs: [
      {
        acronym: "EU",
        name: "European Union",
      },
    ],
    cioc: "HUN",
    independent: true,
  },
  {
    name: "Hong Kong",
    topLevelDomain: [".hk"],
    alpha2Code: "HK",
    alpha3Code: "HKG",
    callingCodes: ["852"],
    capital: "City of Victoria",
    altSpellings: ["HK", "香港"],
    subregion: "Eastern Asia",
    region: "Asia",
    population: 7481800,
    latlng: [22.25, 114.16666666],
    demonym: "Chinese",
    area: 1104,
    timezones: ["UTC+08:00"],
    borders: ["CHN"],
    nativeName: "香港",
    numericCode: "344",
    flags: {
      svg: "https://flagcdn.com/hk.svg",
      png: "https://flagcdn.com/w320/hk.png",
    },
    currencies: [
      {
        code: "HKD",
        name: "Hong Kong dollar",
        symbol: "$",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
      {
        iso639_1: "zh",
        iso639_2: "zho",
        name: "Chinese",
        nativeName: "中文 (Zhōngwén)",
      },
    ],
    translations: {
      br: "Hong Kong",
      pt: "Hong Kong",
      nl: "Hongkong",
      hr: "Hong Kong",
      fa: "هنگ‌کنگ",
      de: "Hong Kong",
      es: "Hong Kong",
      fr: "Hong Kong",
      ja: "香港",
      it: "Hong Kong",
      hu: "Hong Kong",
    },
    flag: "https://flagcdn.com/hk.svg",
    cioc: "HKG",
    independent: false,
  },
  {
    name: "Iceland",
    topLevelDomain: [".is"],
    alpha2Code: "IS",
    alpha3Code: "ISL",
    callingCodes: ["354"],
    capital: "Reykjavík",
    altSpellings: ["IS", "Island", "Republic of Iceland", "Lýðveldið Ísland"],
    subregion: "Northern Europe",
    region: "Europe",
    population: 366425,
    latlng: [65, -18],
    demonym: "Icelander",
    area: 103000,
    gini: 26.1,
    timezones: ["UTC"],
    nativeName: "Ísland",
    numericCode: "352",
    flags: {
      svg: "https://flagcdn.com/is.svg",
      png: "https://flagcdn.com/w320/is.png",
    },
    currencies: [
      {
        code: "ISK",
        name: "Icelandic króna",
        symbol: "kr",
      },
    ],
    languages: [
      {
        iso639_1: "is",
        iso639_2: "isl",
        name: "Icelandic",
        nativeName: "Íslenska",
      },
    ],
    translations: {
      br: "Islândia",
      pt: "Islândia",
      nl: "IJsland",
      hr: "Island",
      fa: "ایسلند",
      de: "Island",
      es: "Islandia",
      fr: "Islande",
      ja: "アイスランド",
      it: "Islanda",
      hu: "Izland",
    },
    flag: "https://flagcdn.com/is.svg",
    regionalBlocs: [
      {
        acronym: "EFTA",
        name: "European Free Trade Association",
      },
    ],
    cioc: "ISL",
    independent: true,
  },
  {
    name: "India",
    topLevelDomain: [".in"],
    alpha2Code: "IN",
    alpha3Code: "IND",
    callingCodes: ["91"],
    capital: "New Delhi",
    altSpellings: ["IN", "Bhārat", "Republic of India", "Bharat Ganrajya"],
    subregion: "Southern Asia",
    region: "Asia",
    population: 1380004385,
    latlng: [20, 77],
    demonym: "Indian",
    area: 3287590,
    gini: 35.7,
    timezones: ["UTC+05:30"],
    borders: ["AFG", "BGD", "BTN", "MMR", "CHN", "NPL", "PAK", "LKA"],
    nativeName: "भारत",
    numericCode: "356",
    flags: {
      svg: "https://flagcdn.com/in.svg",
      png: "https://flagcdn.com/w320/in.png",
    },
    currencies: [
      {
        code: "INR",
        name: "Indian rupee",
        symbol: "₹",
      },
    ],
    languages: [
      {
        iso639_1: "hi",
        iso639_2: "hin",
        name: "Hindi",
        nativeName: "हिन्दी",
      },
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
    ],
    translations: {
      br: "Índia",
      pt: "Índia",
      nl: "India",
      hr: "Indija",
      fa: "هند",
      de: "Indien",
      es: "India",
      fr: "Inde",
      ja: "インド",
      it: "India",
      hu: "India",
    },
    flag: "https://flagcdn.com/in.svg",
    regionalBlocs: [
      {
        acronym: "SAARC",
        name: "South Asian Association for Regional Cooperation",
      },
    ],
    cioc: "IND",
    independent: true,
  },
  {
    name: "Indonesia",
    topLevelDomain: [".id"],
    alpha2Code: "ID",
    alpha3Code: "IDN",
    callingCodes: ["62"],
    capital: "Jakarta",
    altSpellings: ["ID", "Republic of Indonesia", "Republik Indonesia"],
    subregion: "South-Eastern Asia",
    region: "Asia",
    population: 273523621,
    latlng: [-5, 120],
    demonym: "Indonesian",
    area: 1904569,
    gini: 38.2,
    timezones: ["UTC+07:00", "UTC+08:00", "UTC+09:00"],
    borders: ["TLS", "MYS", "PNG"],
    nativeName: "Indonesia",
    numericCode: "360",
    flags: {
      svg: "https://flagcdn.com/id.svg",
      png: "https://flagcdn.com/w320/id.png",
    },
    currencies: [
      {
        code: "IDR",
        name: "Indonesian rupiah",
        symbol: "Rp",
      },
    ],
    languages: [
      {
        iso639_1: "id",
        iso639_2: "ind",
        name: "Indonesian",
        nativeName: "Bahasa Indonesia",
      },
    ],
    translations: {
      br: "Indonésia",
      pt: "Indonésia",
      nl: "Indonesië",
      hr: "Indonezija",
      fa: "اندونزی",
      de: "Indonesien",
      es: "Indonesia",
      fr: "Indonésie",
      ja: "インドネシア",
      it: "Indonesia",
      hu: "Indonézia",
    },
    flag: "https://flagcdn.com/id.svg",
    regionalBlocs: [
      {
        acronym: "ASEAN",
        name: "Association of Southeast Asian Nations",
      },
    ],
    cioc: "INA",
    independent: true,
  },
  {
    name: "Ivory Coast",
    topLevelDomain: [".ci"],
    alpha2Code: "CI",
    alpha3Code: "CIV",
    callingCodes: ["225"],
    capital: "Yamoussoukro",
    altSpellings: ["CI", "Ivory Coast", "Republic of Côte d'Ivoire", "République de Côte d'Ivoire"],
    subregion: "Western Africa",
    region: "Africa",
    population: 26378275,
    latlng: [8, -5],
    demonym: "Ivorian",
    area: 322463,
    gini: 41.5,
    timezones: ["UTC"],
    borders: ["BFA", "GHA", "GIN", "LBR", "MLI"],
    nativeName: "Côte d'Ivoire",
    numericCode: "384",
    flags: {
      svg: "https://flagcdn.com/ci.svg",
      png: "https://flagcdn.com/w320/ci.png",
    },
    currencies: [
      {
        code: "XOF",
        name: "West African CFA franc",
        symbol: "Fr",
      },
    ],
    languages: [
      {
        iso639_1: "fr",
        iso639_2: "fra",
        name: "French",
        nativeName: "français",
      },
    ],
    translations: {
      br: "Costa do Marfim",
      pt: "Costa do Marfim",
      nl: "Ivoorkust",
      hr: "Obala Bjelokosti",
      fa: "ساحل عاج",
      de: "Elfenbeinküste",
      es: "Costa de Marfil",
      fr: "Côte d'Ivoire",
      ja: "コートジボワール",
      it: "Costa D'Avorio",
      hu: "Elefántcsontpart",
    },
    flag: "https://flagcdn.com/ci.svg",
    regionalBlocs: [
      {
        acronym: "AU",
        name: "African Union",
        otherNames: ["الاتحاد الأفريقي", "Union africaine", "União Africana", "Unión Africana", "Umoja wa Afrika"],
      },
    ],
    cioc: "CIV",
    independent: true,
  },
  {
    name: "Iran (Islamic Republic of)",
    topLevelDomain: [".ir"],
    alpha2Code: "IR",
    alpha3Code: "IRN",
    callingCodes: ["98"],
    capital: "Tehran",
    altSpellings: ["IR", "Islamic Republic of Iran", "Jomhuri-ye Eslāmi-ye Irān"],
    subregion: "Southern Asia",
    region: "Asia",
    population: 83992953,
    latlng: [32, 53],
    demonym: "Iranian",
    area: 1648195,
    gini: 42,
    timezones: ["UTC+03:30"],
    borders: ["AFG", "ARM", "AZE", "IRQ", "PAK", "TUR", "TKM"],
    nativeName: "ایران",
    numericCode: "364",
    flags: {
      svg: "https://flagcdn.com/ir.svg",
      png: "https://flagcdn.com/w320/ir.png",
    },
    currencies: [
      {
        code: "IRR",
        name: "Iranian rial",
        symbol: "﷼",
      },
    ],
    languages: [
      {
        iso639_1: "fa",
        iso639_2: "fas",
        name: "Persian (Farsi)",
        nativeName: "فارسی",
      },
    ],
    translations: {
      br: "Irã",
      pt: "Irão",
      nl: "Iran",
      hr: "Iran",
      fa: "ایران",
      de: "Iran",
      es: "Iran",
      fr: "Iran",
      ja: "イラン・イスラム共和国",
      it: "Iran (Islamic Republic of)",
      hu: "Irán",
    },
    flag: "https://flagcdn.com/ir.svg",
    cioc: "IRI",
    independent: true,
  },
  {
    name: "Iraq",
    topLevelDomain: [".iq"],
    alpha2Code: "IQ",
    alpha3Code: "IRQ",
    callingCodes: ["964"],
    capital: "Baghdad",
    altSpellings: ["IQ", "Republic of Iraq", "Jumhūriyyat al-‘Irāq"],
    subregion: "Western Asia",
    region: "Asia",
    population: 40222503,
    latlng: [33, 44],
    demonym: "Iraqi",
    area: 438317,
    gini: 29.5,
    timezones: ["UTC+03:00"],
    borders: ["IRN", "JOR", "KWT", "SAU", "SYR", "TUR"],
    nativeName: "العراق",
    numericCode: "368",
    flags: {
      svg: "https://flagcdn.com/iq.svg",
      png: "https://flagcdn.com/w320/iq.png",
    },
    currencies: [
      {
        code: "IQD",
        name: "Iraqi dinar",
        symbol: "ع.د",
      },
    ],
    languages: [
      {
        iso639_1: "ar",
        iso639_2: "ara",
        name: "Arabic",
        nativeName: "العربية",
      },
      {
        iso639_1: "ku",
        iso639_2: "kur",
        name: "Kurdish",
        nativeName: "Kurdî",
      },
    ],
    translations: {
      br: "Iraque",
      pt: "Iraque",
      nl: "Irak",
      hr: "Irak",
      fa: "عراق",
      de: "Irak",
      es: "Irak",
      fr: "Irak",
      ja: "イラク",
      it: "Iraq",
      hu: "Irak",
    },
    flag: "https://flagcdn.com/iq.svg",
    regionalBlocs: [
      {
        acronym: "AL",
        name: "Arab League",
        otherNames: ["جامعة الدول العربية", "Jāmiʻat ad-Duwal al-ʻArabīyah", "League of Arab States"],
      },
    ],
    cioc: "IRQ",
    independent: true,
  },
  {
    name: "Ireland",
    topLevelDomain: [".ie"],
    alpha2Code: "IE",
    alpha3Code: "IRL",
    callingCodes: ["353"],
    capital: "Dublin",
    altSpellings: ["IE", "Éire", "Republic of Ireland", "Poblacht na hÉireann"],
    subregion: "Northern Europe",
    region: "Europe",
    population: 4994724,
    latlng: [53, -8],
    demonym: "Irish",
    area: 70273,
    gini: 31.4,
    timezones: ["UTC"],
    borders: ["GBR"],
    nativeName: "Éire",
    numericCode: "372",
    flags: {
      svg: "https://flagcdn.com/ie.svg",
      png: "https://flagcdn.com/w320/ie.png",
    },
    currencies: [
      {
        code: "EUR",
        name: "Euro",
        symbol: "€",
      },
    ],
    languages: [
      {
        iso639_1: "ga",
        iso639_2: "gle",
        name: "Irish",
        nativeName: "Gaeilge",
      },
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
    ],
    translations: {
      br: "Irlanda",
      pt: "Irlanda",
      nl: "Ierland",
      hr: "Irska",
      fa: "ایرلند",
      de: "Irland",
      es: "Irlanda",
      fr: "Irlande",
      ja: "アイルランド",
      it: "Irlanda",
      hu: "Írország",
    },
    flag: "https://flagcdn.com/ie.svg",
    regionalBlocs: [
      {
        acronym: "EU",
        name: "European Union",
      },
    ],
    cioc: "IRL",
    independent: true,
  },
  {
    name: "Isle of Man",
    topLevelDomain: [".im"],
    alpha2Code: "IM",
    alpha3Code: "IMN",
    callingCodes: ["44"],
    capital: "Douglas",
    altSpellings: ["IM", "Ellan Vannin", "Mann", "Mannin"],
    subregion: "Northern Europe",
    region: "Europe",
    population: 85032,
    latlng: [54.25, -4.5],
    demonym: "Manx",
    area: 572,
    timezones: ["UTC+00:00"],
    nativeName: "Isle of Man",
    numericCode: "833",
    flags: {
      svg: "https://flagcdn.com/im.svg",
      png: "https://flagcdn.com/w320/im.png",
    },
    currencies: [
      {
        code: "GBP",
        name: "British pound",
        symbol: "£",
      },
      {
        code: "IMP[G]",
        name: "Manx pound",
        symbol: "£",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
      {
        iso639_1: "gv",
        iso639_2: "glv",
        name: "Manx",
        nativeName: "Gaelg",
      },
    ],
    translations: {
      br: "Ilha de Man",
      pt: "Ilha de Man",
      nl: "Isle of Man",
      hr: "Otok Man",
      fa: "جزیره من",
      de: "Insel Man",
      es: "Isla de Man",
      fr: "Île de Man",
      ja: "マン島",
      it: "Isola di Man",
      hu: "Man",
    },
    flag: "https://flagcdn.com/im.svg",
    regionalBlocs: [
      {
        acronym: "EU",
        name: "European Union",
      },
    ],
    independent: false,
  },
  {
    name: "Israel",
    topLevelDomain: [".il"],
    alpha2Code: "IL",
    alpha3Code: "ISR",
    callingCodes: ["972"],
    capital: "Jerusalem",
    altSpellings: ["IL", "State of Israel", "Medīnat Yisrā'el"],
    subregion: "Western Asia",
    region: "Asia",
    population: 9216900,
    latlng: [31.5, 34.75],
    demonym: "Israeli",
    area: 20770,
    gini: 39,
    timezones: ["UTC+02:00"],
    borders: ["EGY", "JOR", "LBN", "SYR"],
    nativeName: "יִשְׂרָאֵל",
    numericCode: "376",
    flags: {
      svg: "https://flagcdn.com/il.svg",
      png: "https://flagcdn.com/w320/il.png",
    },
    currencies: [
      {
        code: "ILS",
        name: "Israeli new shekel",
        symbol: "₪",
      },
    ],
    languages: [
      {
        iso639_1: "he",
        iso639_2: "heb",
        name: "Hebrew (modern)",
        nativeName: "עברית",
      },
      {
        iso639_1: "ar",
        iso639_2: "ara",
        name: "Arabic",
        nativeName: "العربية",
      },
    ],
    translations: {
      br: "Israel",
      pt: "Israel",
      nl: "Israël",
      hr: "Izrael",
      fa: "اسرائیل",
      de: "Israel",
      es: "Israel",
      fr: "Israël",
      ja: "イスラエル",
      it: "Israele",
      hu: "Izrael",
    },
    flag: "https://flagcdn.com/il.svg",
    cioc: "ISR",
    independent: true,
  },
  {
    name: "Italy",
    topLevelDomain: [".it"],
    alpha2Code: "IT",
    alpha3Code: "ITA",
    callingCodes: ["39"],
    capital: "Rome",
    altSpellings: ["IT", "Italian Republic", "Repubblica italiana"],
    subregion: "Southern Europe",
    region: "Europe",
    population: 59554023,
    latlng: [42.83333333, 12.83333333],
    demonym: "Italian",
    area: 301336,
    gini: 35.9,
    timezones: ["UTC+01:00"],
    borders: ["AUT", "FRA", "SMR", "SVN", "CHE", "VAT"],
    nativeName: "Italia",
    numericCode: "380",
    flags: {
      svg: "https://flagcdn.com/it.svg",
      png: "https://flagcdn.com/w320/it.png",
    },
    currencies: [
      {
        code: "EUR",
        name: "Euro",
        symbol: "€",
      },
    ],
    languages: [
      {
        iso639_1: "it",
        iso639_2: "ita",
        name: "Italian",
        nativeName: "Italiano",
      },
    ],
    translations: {
      br: "Itália",
      pt: "Itália",
      nl: "Italië",
      hr: "Italija",
      fa: "ایتالیا",
      de: "Italien",
      es: "Italia",
      fr: "Italie",
      ja: "イタリア",
      it: "Italia",
      hu: "Olaszország",
    },
    flag: "https://flagcdn.com/it.svg",
    regionalBlocs: [
      {
        acronym: "EU",
        name: "European Union",
      },
    ],
    cioc: "ITA",
    independent: true,
  },
  {
    name: "Jamaica",
    topLevelDomain: [".jm"],
    alpha2Code: "JM",
    alpha3Code: "JAM",
    callingCodes: ["1"],
    capital: "Kingston",
    altSpellings: ["JM"],
    subregion: "Caribbean",
    region: "Americas",
    population: 2961161,
    latlng: [18.25, -77.5],
    demonym: "Jamaican",
    area: 10991,
    gini: 45.5,
    timezones: ["UTC-05:00"],
    nativeName: "Jamaica",
    numericCode: "388",
    flags: {
      svg: "https://flagcdn.com/jm.svg",
      png: "https://flagcdn.com/w320/jm.png",
    },
    currencies: [
      {
        code: "JMD",
        name: "Jamaican dollar",
        symbol: "$",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
    ],
    translations: {
      br: "Jamaica",
      pt: "Jamaica",
      nl: "Jamaica",
      hr: "Jamajka",
      fa: "جامائیکا",
      de: "Jamaika",
      es: "Jamaica",
      fr: "Jamaïque",
      ja: "ジャマイカ",
      it: "Giamaica",
      hu: "Jamaica",
    },
    flag: "https://flagcdn.com/jm.svg",
    regionalBlocs: [
      {
        acronym: "CARICOM",
        name: "Caribbean Community",
        otherNames: ["Comunidad del Caribe", "Communauté Caribéenne", "Caribische Gemeenschap"],
      },
    ],
    cioc: "JAM",
    independent: true,
  },
  {
    name: "Japan",
    topLevelDomain: [".jp"],
    alpha2Code: "JP",
    alpha3Code: "JPN",
    callingCodes: ["81"],
    capital: "Tokyo",
    altSpellings: ["JP", "Nippon", "Nihon"],
    subregion: "Eastern Asia",
    region: "Asia",
    population: 125836021,
    latlng: [36, 138],
    demonym: "Japanese",
    area: 377930,
    gini: 32.9,
    timezones: ["UTC+09:00"],
    nativeName: "日本",
    numericCode: "392",
    flags: {
      svg: "https://flagcdn.com/jp.svg",
      png: "https://flagcdn.com/w320/jp.png",
    },
    currencies: [
      {
        code: "JPY",
        name: "Japanese yen",
        symbol: "¥",
      },
    ],
    languages: [
      {
        iso639_1: "ja",
        iso639_2: "jpn",
        name: "Japanese",
        nativeName: "日本語 (にほんご)",
      },
    ],
    translations: {
      br: "Japão",
      pt: "Japão",
      nl: "Japan",
      hr: "Japan",
      fa: "ژاپن",
      de: "Japan",
      es: "Japón",
      fr: "Japon",
      ja: "日本",
      it: "Giappone",
      hu: "Japán",
    },
    flag: "https://flagcdn.com/jp.svg",
    cioc: "JPN",
    independent: true,
  },
  {
    name: "Jersey",
    topLevelDomain: [".je"],
    alpha2Code: "JE",
    alpha3Code: "JEY",
    callingCodes: ["44"],
    capital: "Saint Helier",
    altSpellings: ["JE", "Bailiwick of Jersey", "Bailliage de Jersey", "Bailliage dé Jèrri"],
    subregion: "Northern Europe",
    region: "Europe",
    population: 100800,
    latlng: [49.25, -2.16666666],
    demonym: "Channel Islander",
    area: 116,
    timezones: ["UTC+01:00"],
    nativeName: "Jersey",
    numericCode: "832",
    flags: {
      svg: "https://flagcdn.com/je.svg",
      png: "https://flagcdn.com/w320/je.png",
    },
    currencies: [
      {
        code: "GBP",
        name: "British pound",
        symbol: "£",
      },
      {
        code: "JEP[G]",
        name: "Jersey pound",
        symbol: "£",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
      {
        iso639_1: "fr",
        iso639_2: "fra",
        name: "French",
        nativeName: "français",
      },
    ],
    translations: {
      br: "Jersey",
      pt: "Jersey",
      nl: "Jersey",
      hr: "Jersey",
      fa: "جرزی",
      de: "Jersey",
      es: "Jersey",
      fr: "Jersey",
      ja: "ジャージー",
      it: "Isola di Jersey",
      hu: "Jersey",
    },
    flag: "https://flagcdn.com/je.svg",
    independent: false,
  },
  {
    name: "Jordan",
    topLevelDomain: [".jo"],
    alpha2Code: "JO",
    alpha3Code: "JOR",
    callingCodes: ["962"],
    capital: "Amman",
    altSpellings: ["JO", "Hashemite Kingdom of Jordan", "al-Mamlakah al-Urdunīyah al-Hāshimīyah"],
    subregion: "Western Asia",
    region: "Asia",
    population: 10203140,
    latlng: [31, 36],
    demonym: "Jordanian",
    area: 89342,
    gini: 33.7,
    timezones: ["UTC+03:00"],
    borders: ["IRQ", "ISR", "SAU", "SYR"],
    nativeName: "الأردن",
    numericCode: "400",
    flags: {
      svg: "https://flagcdn.com/jo.svg",
      png: "https://flagcdn.com/w320/jo.png",
    },
    currencies: [
      {
        code: "JOD",
        name: "Jordanian dinar",
        symbol: "د.ا",
      },
    ],
    languages: [
      {
        iso639_1: "ar",
        iso639_2: "ara",
        name: "Arabic",
        nativeName: "العربية",
      },
    ],
    translations: {
      br: "Jordânia",
      pt: "Jordânia",
      nl: "Jordanië",
      hr: "Jordan",
      fa: "اردن",
      de: "Jordanien",
      es: "Jordania",
      fr: "Jordanie",
      ja: "ヨルダン",
      it: "Giordania",
      hu: "Jordánia",
    },
    flag: "https://flagcdn.com/jo.svg",
    regionalBlocs: [
      {
        acronym: "AL",
        name: "Arab League",
        otherNames: ["جامعة الدول العربية", "Jāmiʻat ad-Duwal al-ʻArabīyah", "League of Arab States"],
      },
    ],
    cioc: "JOR",
    independent: true,
  },
  {
    name: "Kazakhstan",
    topLevelDomain: [".kz", ".қаз"],
    alpha2Code: "KZ",
    alpha3Code: "KAZ",
    callingCodes: ["76", "77"],
    capital: "Nur-Sultan",
    altSpellings: [
      "KZ",
      "Qazaqstan",
      "Казахстан",
      "Republic of Kazakhstan",
      "Қазақстан Республикасы",
      "Qazaqstan Respublïkası",
      "Республика Казахстан",
      "Respublika Kazakhstan",
    ],
    subregion: "Central Asia",
    region: "Asia",
    population: 18754440,
    latlng: [48, 68],
    demonym: "Kazakhstani",
    area: 2724900,
    gini: 27.8,
    timezones: ["UTC+05:00", "UTC+06:00"],
    borders: ["CHN", "KGZ", "RUS", "TKM", "UZB"],
    nativeName: "Қазақстан",
    numericCode: "398",
    flags: {
      svg: "https://flagcdn.com/kz.svg",
      png: "https://flagcdn.com/w320/kz.png",
    },
    currencies: [
      {
        code: "KZT",
        name: "Kazakhstani tenge",
        symbol: "₸",
      },
    ],
    languages: [
      {
        iso639_1: "kk",
        iso639_2: "kaz",
        name: "Kazakh",
        nativeName: "қазақ тілі",
      },
      {
        iso639_1: "ru",
        iso639_2: "rus",
        name: "Russian",
        nativeName: "Русский",
      },
    ],
    translations: {
      br: "Cazaquistão",
      pt: "Cazaquistão",
      nl: "Kazachstan",
      hr: "Kazahstan",
      fa: "قزاقستان",
      de: "Kasachstan",
      es: "Kazajistán",
      fr: "Kazakhstan",
      ja: "カザフスタン",
      it: "Kazakistan",
      hu: "Kazahsztán",
    },
    flag: "https://flagcdn.com/kz.svg",
    regionalBlocs: [
      {
        acronym: "EEU",
        name: "Eurasian Economic Union",
        otherAcronyms: ["EAEU"],
      },
    ],
    cioc: "KAZ",
    independent: false,
  },
  {
    name: "Kenya",
    topLevelDomain: [".ke"],
    alpha2Code: "KE",
    alpha3Code: "KEN",
    callingCodes: ["254"],
    capital: "Nairobi",
    altSpellings: ["KE", "Republic of Kenya", "Jamhuri ya Kenya"],
    subregion: "Eastern Africa",
    region: "Africa",
    population: 53771300,
    latlng: [1, 38],
    demonym: "Kenyan",
    area: 580367,
    gini: 40.8,
    timezones: ["UTC+03:00"],
    borders: ["ETH", "SOM", "SSD", "TZA", "UGA"],
    nativeName: "Kenya",
    numericCode: "404",
    flags: {
      svg: "https://flagcdn.com/ke.svg",
      png: "https://flagcdn.com/w320/ke.png",
    },
    currencies: [
      {
        code: "KES",
        name: "Kenyan shilling",
        symbol: "Sh",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
      {
        iso639_1: "sw",
        iso639_2: "swa",
        name: "Swahili",
        nativeName: "Kiswahili",
      },
    ],
    translations: {
      br: "Quênia",
      pt: "Quénia",
      nl: "Kenia",
      hr: "Kenija",
      fa: "کنیا",
      de: "Kenia",
      es: "Kenia",
      fr: "Kenya",
      ja: "ケニア",
      it: "Kenya",
      hu: "Kenya",
    },
    flag: "https://flagcdn.com/ke.svg",
    regionalBlocs: [
      {
        acronym: "AU",
        name: "African Union",
        otherNames: ["الاتحاد الأفريقي", "Union africaine", "União Africana", "Unión Africana", "Umoja wa Afrika"],
      },
    ],
    cioc: "KEN",
    independent: true,
  },
  {
    name: "Kiribati",
    topLevelDomain: [".ki"],
    alpha2Code: "KI",
    alpha3Code: "KIR",
    callingCodes: ["686"],
    capital: "South Tarawa",
    altSpellings: ["KI", "Republic of Kiribati", "Ribaberiki Kiribati"],
    subregion: "Micronesia",
    region: "Oceania",
    population: 119446,
    latlng: [1.41666666, 173],
    demonym: "I-Kiribati",
    area: 811,
    gini: 37,
    timezones: ["UTC+12:00", "UTC+13:00", "UTC+14:00"],
    nativeName: "Kiribati",
    numericCode: "296",
    flags: {
      svg: "https://flagcdn.com/ki.svg",
      png: "https://flagcdn.com/w320/ki.png",
    },
    currencies: [
      {
        code: "AUD",
        name: "Australian dollar",
        symbol: "$",
      },
      {
        code: "KID",
        name: "Kiribati dollar",
        symbol: "$",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
    ],
    translations: {
      br: "Kiribati",
      pt: "Quiribáti",
      nl: "Kiribati",
      hr: "Kiribati",
      fa: "کیریباتی",
      de: "Kiribati",
      es: "Kiribati",
      fr: "Kiribati",
      ja: "キリバス",
      it: "Kiribati",
      hu: "Kiribati",
    },
    flag: "https://flagcdn.com/ki.svg",
    cioc: "KIR",
    independent: true,
  },
  {
    name: "Kuwait",
    topLevelDomain: [".kw"],
    alpha2Code: "KW",
    alpha3Code: "KWT",
    callingCodes: ["965"],
    capital: "Kuwait City",
    altSpellings: ["KW", "State of Kuwait", "Dawlat al-Kuwait"],
    subregion: "Western Asia",
    region: "Asia",
    population: 4270563,
    latlng: [29.5, 45.75],
    demonym: "Kuwaiti",
    area: 17818,
    timezones: ["UTC+03:00"],
    borders: ["IRQ", "SAU"],
    nativeName: "الكويت",
    numericCode: "414",
    flags: {
      svg: "https://flagcdn.com/kw.svg",
      png: "https://flagcdn.com/w320/kw.png",
    },
    currencies: [
      {
        code: "KWD",
        name: "Kuwaiti dinar",
        symbol: "د.ك",
      },
    ],
    languages: [
      {
        iso639_1: "ar",
        iso639_2: "ara",
        name: "Arabic",
        nativeName: "العربية",
      },
    ],
    translations: {
      br: "Kuwait",
      pt: "Kuwait",
      nl: "Koeweit",
      hr: "Kuvajt",
      fa: "کویت",
      de: "Kuwait",
      es: "Kuwait",
      fr: "Koweït",
      ja: "クウェート",
      it: "Kuwait",
      hu: "Kuvait",
    },
    flag: "https://flagcdn.com/kw.svg",
    regionalBlocs: [
      {
        acronym: "AL",
        name: "Arab League",
        otherNames: ["جامعة الدول العربية", "Jāmiʻat ad-Duwal al-ʻArabīyah", "League of Arab States"],
      },
    ],
    cioc: "KUW",
    independent: true,
  },
  {
    name: "Kyrgyzstan",
    topLevelDomain: [".kg"],
    alpha2Code: "KG",
    alpha3Code: "KGZ",
    callingCodes: ["996"],
    capital: "Bishkek",
    altSpellings: ["KG", "Киргизия", "Kyrgyz Republic", "Кыргыз Республикасы", "Kyrgyz Respublikasy"],
    subregion: "Central Asia",
    region: "Asia",
    population: 6591600,
    latlng: [41, 75],
    demonym: "Kirghiz",
    area: 199951,
    gini: 29.7,
    timezones: ["UTC+06:00"],
    borders: ["CHN", "KAZ", "TJK", "UZB"],
    nativeName: "Кыргызстан",
    numericCode: "417",
    flags: {
      svg: "https://flagcdn.com/kg.svg",
      png: "https://flagcdn.com/w320/kg.png",
    },
    currencies: [
      {
        code: "KGS",
        name: "Kyrgyzstani som",
        symbol: "с",
      },
    ],
    languages: [
      {
        iso639_1: "ky",
        iso639_2: "kir",
        name: "Kyrgyz",
        nativeName: "Кыргызча",
      },
      {
        iso639_1: "ru",
        iso639_2: "rus",
        name: "Russian",
        nativeName: "Русский",
      },
    ],
    translations: {
      br: "Quirguistão",
      pt: "Quirguizistão",
      nl: "Kirgizië",
      hr: "Kirgistan",
      fa: "قرقیزستان",
      de: "Kirgisistan",
      es: "Kirguizistán",
      fr: "Kirghizistan",
      ja: "キルギス",
      it: "Kirghizistan",
      hu: "Kirgizisztán",
    },
    flag: "https://flagcdn.com/kg.svg",
    regionalBlocs: [
      {
        acronym: "EEU",
        name: "Eurasian Economic Union",
        otherAcronyms: ["EAEU"],
      },
    ],
    cioc: "KGZ",
    independent: true,
  },
  {
    name: "Lao People's Democratic Republic",
    topLevelDomain: [".la"],
    alpha2Code: "LA",
    alpha3Code: "LAO",
    callingCodes: ["856"],
    capital: "Vientiane",
    altSpellings: ["LA", "Lao", "Laos", "Lao People's Democratic Republic", "Sathalanalat Paxathipatai Paxaxon Lao"],
    subregion: "South-Eastern Asia",
    region: "Asia",
    population: 7275556,
    latlng: [18, 105],
    demonym: "Laotian",
    area: 236800,
    gini: 38.8,
    timezones: ["UTC+07:00"],
    borders: ["MMR", "KHM", "CHN", "THA", "VNM"],
    nativeName: "ສາທາລະນະລັດ ປະຊາທິປະໄຕ ປະຊາຊົນລາວ",
    numericCode: "418",
    flags: {
      svg: "https://flagcdn.com/la.svg",
      png: "https://flagcdn.com/w320/la.png",
    },
    currencies: [
      {
        code: "LAK",
        name: "Lao kip",
        symbol: "₭",
      },
    ],
    languages: [
      {
        iso639_1: "lo",
        iso639_2: "lao",
        name: "Lao",
        nativeName: "ພາສາລາວ",
      },
    ],
    translations: {
      br: "Laos",
      pt: "Laos",
      nl: "Laos",
      hr: "Laos",
      fa: "لائوس",
      de: "Laos",
      es: "Laos",
      fr: "Laos",
      ja: "ラオス人民民主共和国",
      it: "Laos",
      hu: "Laosz",
    },
    flag: "https://flagcdn.com/la.svg",
    regionalBlocs: [
      {
        acronym: "ASEAN",
        name: "Association of Southeast Asian Nations",
      },
    ],
    cioc: "LAO",
    independent: true,
  },
  {
    name: "Latvia",
    topLevelDomain: [".lv"],
    alpha2Code: "LV",
    alpha3Code: "LVA",
    callingCodes: ["371"],
    capital: "Riga",
    altSpellings: ["LV", "Republic of Latvia", "Latvijas Republika"],
    subregion: "Northern Europe",
    region: "Europe",
    population: 1901548,
    latlng: [57, 25],
    demonym: "Latvian",
    area: 64559,
    gini: 35.1,
    timezones: ["UTC+02:00"],
    borders: ["BLR", "EST", "LTU", "RUS"],
    nativeName: "Latvija",
    numericCode: "428",
    flags: {
      svg: "https://flagcdn.com/lv.svg",
      png: "https://flagcdn.com/w320/lv.png",
    },
    currencies: [
      {
        code: "EUR",
        name: "Euro",
        symbol: "€",
      },
    ],
    languages: [
      {
        iso639_1: "lv",
        iso639_2: "lav",
        name: "Latvian",
        nativeName: "latviešu valoda",
      },
    ],
    translations: {
      br: "Letônia",
      pt: "Letónia",
      nl: "Letland",
      hr: "Latvija",
      fa: "لتونی",
      de: "Lettland",
      es: "Letonia",
      fr: "Lettonie",
      ja: "ラトビア",
      it: "Lettonia",
      hu: "Lettország",
    },
    flag: "https://flagcdn.com/lv.svg",
    regionalBlocs: [
      {
        acronym: "EU",
        name: "European Union",
      },
    ],
    cioc: "LAT",
    independent: true,
  },
  {
    name: "Lebanon",
    topLevelDomain: [".lb"],
    alpha2Code: "LB",
    alpha3Code: "LBN",
    callingCodes: ["961"],
    capital: "Beirut",
    altSpellings: ["LB", "Lebanese Republic", "Al-Jumhūrīyah Al-Libnānīyah"],
    subregion: "Western Asia",
    region: "Asia",
    population: 6825442,
    latlng: [33.83333333, 35.83333333],
    demonym: "Lebanese",
    area: 10452,
    gini: 31.8,
    timezones: ["UTC+02:00"],
    borders: ["ISR", "SYR"],
    nativeName: "لبنان",
    numericCode: "422",
    flags: {
      svg: "https://flagcdn.com/lb.svg",
      png: "https://flagcdn.com/w320/lb.png",
    },
    currencies: [
      {
        code: "LBP",
        name: "Lebanese pound",
        symbol: "ل.ل",
      },
    ],
    languages: [
      {
        iso639_1: "ar",
        iso639_2: "ara",
        name: "Arabic",
        nativeName: "العربية",
      },
      {
        iso639_1: "fr",
        iso639_2: "fra",
        name: "French",
        nativeName: "français",
      },
    ],
    translations: {
      br: "Líbano",
      pt: "Líbano",
      nl: "Libanon",
      hr: "Libanon",
      fa: "لبنان",
      de: "Libanon",
      es: "Líbano",
      fr: "Liban",
      ja: "レバノン",
      it: "Libano",
      hu: "Libanon",
    },
    flag: "https://flagcdn.com/lb.svg",
    regionalBlocs: [
      {
        acronym: "AL",
        name: "Arab League",
        otherNames: ["جامعة الدول العربية", "Jāmiʻat ad-Duwal al-ʻArabīyah", "League of Arab States"],
      },
    ],
    cioc: "LIB",
    independent: true,
  },
  {
    name: "Lesotho",
    topLevelDomain: [".ls"],
    alpha2Code: "LS",
    alpha3Code: "LSO",
    callingCodes: ["266"],
    capital: "Maseru",
    altSpellings: ["LS", "Kingdom of Lesotho", "Muso oa Lesotho"],
    subregion: "Southern Africa",
    region: "Africa",
    population: 2142252,
    latlng: [-29.5, 28.5],
    demonym: "Mosotho",
    area: 30355,
    gini: 44.9,
    timezones: ["UTC+02:00"],
    borders: ["ZAF"],
    nativeName: "Lesotho",
    numericCode: "426",
    flags: {
      svg: "https://flagcdn.com/ls.svg",
      png: "https://flagcdn.com/w320/ls.png",
    },
    currencies: [
      {
        code: "LSL",
        name: "Lesotho loti",
        symbol: "L",
      },
      {
        code: "ZAR",
        name: "South African rand",
        symbol: "R",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
      {
        iso639_1: "st",
        iso639_2: "sot",
        name: "Southern Sotho",
        nativeName: "Sesotho",
      },
    ],
    translations: {
      br: "Lesoto",
      pt: "Lesoto",
      nl: "Lesotho",
      hr: "Lesoto",
      fa: "لسوتو",
      de: "Lesotho",
      es: "Lesotho",
      fr: "Lesotho",
      ja: "レソト",
      it: "Lesotho",
      hu: "Lesotho",
    },
    flag: "https://flagcdn.com/ls.svg",
    regionalBlocs: [
      {
        acronym: "AU",
        name: "African Union",
        otherNames: ["الاتحاد الأفريقي", "Union africaine", "União Africana", "Unión Africana", "Umoja wa Afrika"],
      },
    ],
    cioc: "LES",
    independent: true,
  },
  {
    name: "Liberia",
    topLevelDomain: [".lr"],
    alpha2Code: "LR",
    alpha3Code: "LBR",
    callingCodes: ["231"],
    capital: "Monrovia",
    altSpellings: ["LR", "Republic of Liberia"],
    subregion: "Western Africa",
    region: "Africa",
    population: 5057677,
    latlng: [6.5, -9.5],
    demonym: "Liberian",
    area: 111369,
    gini: 35.3,
    timezones: ["UTC"],
    borders: ["GIN", "CIV", "SLE"],
    nativeName: "Liberia",
    numericCode: "430",
    flags: {
      svg: "https://flagcdn.com/lr.svg",
      png: "https://flagcdn.com/w320/lr.png",
    },
    currencies: [
      {
        code: "LRD",
        name: "Liberian dollar",
        symbol: "$",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
    ],
    translations: {
      br: "Libéria",
      pt: "Libéria",
      nl: "Liberia",
      hr: "Liberija",
      fa: "لیبریا",
      de: "Liberia",
      es: "Liberia",
      fr: "Liberia",
      ja: "リベリア",
      it: "Liberia",
      hu: "Libéria",
    },
    flag: "https://flagcdn.com/lr.svg",
    regionalBlocs: [
      {
        acronym: "AU",
        name: "African Union",
        otherNames: ["الاتحاد الأفريقي", "Union africaine", "União Africana", "Unión Africana", "Umoja wa Afrika"],
      },
    ],
    cioc: "LBR",
    independent: true,
  },
  {
    name: "Libya",
    topLevelDomain: [".ly"],
    alpha2Code: "LY",
    alpha3Code: "LBY",
    callingCodes: ["218"],
    capital: "Tripoli",
    altSpellings: ["LY", "State of Libya", "Dawlat Libya"],
    subregion: "Northern Africa",
    region: "Africa",
    population: 6871287,
    latlng: [25, 17],
    demonym: "Libyan",
    area: 1759540,
    timezones: ["UTC+01:00"],
    borders: ["DZA", "TCD", "EGY", "NER", "SDN", "TUN"],
    nativeName: "‏ليبيا",
    numericCode: "434",
    flags: {
      svg: "https://flagcdn.com/ly.svg",
      png: "https://flagcdn.com/w320/ly.png",
    },
    currencies: [
      {
        code: "LYD",
        name: "Libyan dinar",
        symbol: "ل.د",
      },
    ],
    languages: [
      {
        iso639_1: "ar",
        iso639_2: "ara",
        name: "Arabic",
        nativeName: "العربية",
      },
    ],
    translations: {
      br: "Líbia",
      pt: "Líbia",
      nl: "Libië",
      hr: "Libija",
      fa: "لیبی",
      de: "Libyen",
      es: "Libia",
      fr: "Libye",
      ja: "リビア",
      it: "Libia",
      hu: "Líbia",
    },
    flag: "https://flagcdn.com/ly.svg",
    regionalBlocs: [
      {
        acronym: "AU",
        name: "African Union",
        otherNames: ["الاتحاد الأفريقي", "Union africaine", "União Africana", "Unión Africana", "Umoja wa Afrika"],
      },
      {
        acronym: "AL",
        name: "Arab League",
        otherNames: ["جامعة الدول العربية", "Jāmiʻat ad-Duwal al-ʻArabīyah", "League of Arab States"],
      },
    ],
    cioc: "LBA",
    independent: true,
  },
  {
    name: "Liechtenstein",
    topLevelDomain: [".li"],
    alpha2Code: "LI",
    alpha3Code: "LIE",
    callingCodes: ["423"],
    capital: "Vaduz",
    altSpellings: ["LI", "Principality of Liechtenstein", "Fürstentum Liechtenstein"],
    subregion: "Central Europe",
    region: "Europe",
    population: 38137,
    latlng: [47.26666666, 9.53333333],
    demonym: "Liechtensteiner",
    area: 160,
    timezones: ["UTC+01:00"],
    borders: ["AUT", "CHE"],
    nativeName: "Liechtenstein",
    numericCode: "438",
    flags: {
      svg: "https://flagcdn.com/li.svg",
      png: "https://flagcdn.com/w320/li.png",
    },
    currencies: [
      {
        code: "CHF",
        name: "Swiss franc",
        symbol: "Fr",
      },
    ],
    languages: [
      {
        iso639_1: "de",
        iso639_2: "deu",
        name: "German",
        nativeName: "Deutsch",
      },
    ],
    translations: {
      br: "Liechtenstein",
      pt: "Listenstaine",
      nl: "Liechtenstein",
      hr: "Lihtenštajn",
      fa: "لیختن‌اشتاین",
      de: "Liechtenstein",
      es: "Liechtenstein",
      fr: "Liechtenstein",
      ja: "リヒテンシュタイン",
      it: "Liechtenstein",
      hu: "Liechtenstein",
    },
    flag: "https://flagcdn.com/li.svg",
    regionalBlocs: [
      {
        acronym: "EFTA",
        name: "European Free Trade Association",
      },
    ],
    cioc: "LIE",
    independent: true,
  },
  {
    name: "Lithuania",
    topLevelDomain: [".lt"],
    alpha2Code: "LT",
    alpha3Code: "LTU",
    callingCodes: ["370"],
    capital: "Vilnius",
    altSpellings: ["LT", "Republic of Lithuania", "Lietuvos Respublika"],
    subregion: "Northern Europe",
    region: "Europe",
    population: 2794700,
    latlng: [56, 24],
    demonym: "Lithuanian",
    area: 65300,
    gini: 35.7,
    timezones: ["UTC+02:00"],
    borders: ["BLR", "LVA", "POL", "RUS"],
    nativeName: "Lietuva",
    numericCode: "440",
    flags: {
      svg: "https://flagcdn.com/lt.svg",
      png: "https://flagcdn.com/w320/lt.png",
    },
    currencies: [
      {
        code: "EUR",
        name: "Euro",
        symbol: "€",
      },
    ],
    languages: [
      {
        iso639_1: "lt",
        iso639_2: "lit",
        name: "Lithuanian",
        nativeName: "lietuvių kalba",
      },
    ],
    translations: {
      br: "Lituânia",
      pt: "Lituânia",
      nl: "Litouwen",
      hr: "Litva",
      fa: "لیتوانی",
      de: "Litauen",
      es: "Lituania",
      fr: "Lituanie",
      ja: "リトアニア",
      it: "Lituania",
      hu: "Litvánia",
    },
    flag: "https://flagcdn.com/lt.svg",
    regionalBlocs: [
      {
        acronym: "EU",
        name: "European Union",
      },
    ],
    cioc: "LTU",
    independent: true,
  },
  {
    name: "Luxembourg",
    topLevelDomain: [".lu"],
    alpha2Code: "LU",
    alpha3Code: "LUX",
    callingCodes: ["352"],
    capital: "Luxembourg",
    altSpellings: ["LU", "Grand Duchy of Luxembourg", "Grand-Duché de Luxembourg", "Großherzogtum Luxemburg", "Groussherzogtum Lëtzebuerg"],
    subregion: "Western Europe",
    region: "Europe",
    population: 632275,
    latlng: [49.75, 6.16666666],
    demonym: "Luxembourger",
    area: 2586,
    gini: 35.4,
    timezones: ["UTC+01:00"],
    borders: ["BEL", "FRA", "DEU"],
    nativeName: "Lëtzebuerg",
    numericCode: "442",
    flags: {
      svg: "https://flagcdn.com/lu.svg",
      png: "https://flagcdn.com/w320/lu.png",
    },
    currencies: [
      {
        code: "EUR",
        name: "Euro",
        symbol: "€",
      },
    ],
    languages: [
      {
        iso639_1: "fr",
        iso639_2: "fra",
        name: "French",
        nativeName: "français",
      },
      {
        iso639_1: "de",
        iso639_2: "deu",
        name: "German",
        nativeName: "Deutsch",
      },
      {
        iso639_1: "lb",
        iso639_2: "ltz",
        name: "Luxembourgish",
        nativeName: "Lëtzebuergesch",
      },
    ],
    translations: {
      br: "Luxemburgo",
      pt: "Luxemburgo",
      nl: "Luxemburg",
      hr: "Luksemburg",
      fa: "لوکزامبورگ",
      de: "Luxemburg",
      es: "Luxemburgo",
      fr: "Luxembourg",
      ja: "ルクセンブルク",
      it: "Lussemburgo",
      hu: "Luxemburg",
    },
    flag: "https://flagcdn.com/lu.svg",
    regionalBlocs: [
      {
        acronym: "EU",
        name: "European Union",
      },
    ],
    cioc: "LUX",
    independent: true,
  },
  {
    name: "Macao",
    topLevelDomain: [".mo"],
    alpha2Code: "MO",
    alpha3Code: "MAC",
    callingCodes: ["853"],
    altSpellings: [
      "MO",
      "澳门",
      "Macao Special Administrative Region of the People's Republic of China",
      "中華人民共和國澳門特別行政區",
      "Região Administrativa Especial de Macau da República Popular da China",
    ],
    subregion: "Eastern Asia",
    region: "Asia",
    population: 649342,
    latlng: [22.16666666, 113.55],
    demonym: "Chinese",
    area: 30,
    timezones: ["UTC+08:00"],
    borders: ["CHN"],
    nativeName: "澳門",
    numericCode: "446",
    flags: {
      svg: "https://flagcdn.com/mo.svg",
      png: "https://flagcdn.com/w320/mo.png",
    },
    currencies: [
      {
        code: "MOP",
        name: "Macanese pataca",
        symbol: "P",
      },
    ],
    languages: [
      {
        iso639_1: "zh",
        iso639_2: "zho",
        name: "Chinese",
        nativeName: "中文 (Zhōngwén)",
      },
      {
        iso639_1: "pt",
        iso639_2: "por",
        name: "Portuguese",
        nativeName: "Português",
      },
    ],
    translations: {
      br: "Macau",
      pt: "Macau",
      nl: "Macao",
      hr: "Makao",
      fa: "مکائو",
      de: "Macao",
      es: "Macao",
      fr: "Macao",
      ja: "マカオ",
      it: "Macao",
      hu: "Makaó",
    },
    flag: "https://flagcdn.com/mo.svg",
    independent: false,
  },
  {
    name: "North Macedonia",
    topLevelDomain: [".mk"],
    alpha2Code: "MK",
    alpha3Code: "MKD",
    callingCodes: ["389"],
    capital: "Skopje",
    altSpellings: ["MK", "Republic of Macedonia", "Република Македонија"],
    subregion: "Southern Europe",
    region: "Europe",
    population: 2083380,
    latlng: [41.83333333, 22],
    demonym: "Macedonian",
    area: 25713,
    gini: 33,
    timezones: ["UTC+01:00"],
    borders: ["ALB", "BGR", "GRC", "UNK", "SRB"],
    nativeName: "Македонија",
    numericCode: "807",
    flags: {
      svg: "https://flagcdn.com/mk.svg",
      png: "https://flagcdn.com/w320/mk.png",
    },
    currencies: [
      {
        code: "MKD",
        name: "Macedonian denar",
        symbol: "ден",
      },
    ],
    languages: [
      {
        iso639_1: "mk",
        iso639_2: "mkd",
        name: "Macedonian",
        nativeName: "македонски јазик",
      },
    ],
    translations: {
      br: "Macedônia",
      pt: "Macedónia",
      nl: "Macedonië",
      hr: "Makedonija",
      de: "Mazedonien",
      es: "Macedonia",
      fr: "Macédoine",
      ja: "マケドニア旧ユーゴスラビア共和国",
      it: "Macedonia",
      hu: "Macedónia",
    },
    flag: "https://flagcdn.com/mk.svg",
    regionalBlocs: [
      {
        acronym: "CEFTA",
        name: "Central European Free Trade Agreement",
      },
    ],
    cioc: "MKD",
    independent: true,
  },
  {
    name: "Madagascar",
    topLevelDomain: [".mg"],
    alpha2Code: "MG",
    alpha3Code: "MDG",
    callingCodes: ["261"],
    capital: "Antananarivo",
    altSpellings: ["MG", "Republic of Madagascar", "Repoblikan'i Madagasikara", "République de Madagascar"],
    subregion: "Eastern Africa",
    region: "Africa",
    population: 27691019,
    latlng: [-20, 47],
    demonym: "Malagasy",
    area: 587041,
    gini: 42.6,
    timezones: ["UTC+03:00"],
    nativeName: "Madagasikara",
    numericCode: "450",
    flags: {
      svg: "https://flagcdn.com/mg.svg",
      png: "https://flagcdn.com/w320/mg.png",
    },
    currencies: [
      {
        code: "MGA",
        name: "Malagasy ariary",
        symbol: "Ar",
      },
    ],
    languages: [
      {
        iso639_1: "fr",
        iso639_2: "fra",
        name: "French",
        nativeName: "français",
      },
      {
        iso639_1: "mg",
        iso639_2: "mlg",
        name: "Malagasy",
        nativeName: "fiteny malagasy",
      },
    ],
    translations: {
      br: "Madagascar",
      pt: "Madagáscar",
      nl: "Madagaskar",
      hr: "Madagaskar",
      fa: "ماداگاسکار",
      de: "Madagaskar",
      es: "Madagascar",
      fr: "Madagascar",
      ja: "マダガスカル",
      it: "Madagascar",
      hu: "Madagaszkár",
    },
    flag: "https://flagcdn.com/mg.svg",
    regionalBlocs: [
      {
        acronym: "AU",
        name: "African Union",
        otherNames: ["الاتحاد الأفريقي", "Union africaine", "União Africana", "Unión Africana", "Umoja wa Afrika"],
      },
    ],
    cioc: "MAD",
    independent: true,
  },
  {
    name: "Malawi",
    topLevelDomain: [".mw"],
    alpha2Code: "MW",
    alpha3Code: "MWI",
    callingCodes: ["265"],
    capital: "Lilongwe",
    altSpellings: ["MW", "Republic of Malawi"],
    subregion: "Eastern Africa",
    region: "Africa",
    population: 19129955,
    latlng: [-13.5, 34],
    demonym: "Malawian",
    area: 118484,
    gini: 44.7,
    timezones: ["UTC+02:00"],
    borders: ["MOZ", "TZA", "ZMB"],
    nativeName: "Malawi",
    numericCode: "454",
    flags: {
      svg: "https://flagcdn.com/mw.svg",
      png: "https://flagcdn.com/w320/mw.png",
    },
    currencies: [
      {
        code: "MWK",
        name: "Malawian kwacha",
        symbol: "MK",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
      {
        iso639_1: "ny",
        iso639_2: "nya",
        name: "Chichewa",
        nativeName: "chiCheŵa",
      },
    ],
    translations: {
      br: "Malawi",
      pt: "Malávi",
      nl: "Malawi",
      hr: "Malavi",
      fa: "مالاوی",
      de: "Malawi",
      es: "Malawi",
      fr: "Malawi",
      ja: "マラウイ",
      it: "Malawi",
      hu: "Malawi",
    },
    flag: "https://flagcdn.com/mw.svg",
    regionalBlocs: [
      {
        acronym: "AU",
        name: "African Union",
        otherNames: ["الاتحاد الأفريقي", "Union africaine", "União Africana", "Unión Africana", "Umoja wa Afrika"],
      },
    ],
    cioc: "MAW",
    independent: true,
  },
  {
    name: "Malaysia",
    topLevelDomain: [".my"],
    alpha2Code: "MY",
    alpha3Code: "MYS",
    callingCodes: ["60"],
    capital: "Kuala Lumpur",
    altSpellings: ["MY"],
    subregion: "South-Eastern Asia",
    region: "Asia",
    population: 32365998,
    latlng: [2.5, 112.5],
    demonym: "Malaysian",
    area: 330803,
    gini: 41.1,
    timezones: ["UTC+08:00"],
    borders: ["BRN", "IDN", "THA"],
    nativeName: "Malaysia",
    numericCode: "458",
    flags: {
      svg: "https://flagcdn.com/my.svg",
      png: "https://flagcdn.com/w320/my.png",
    },
    currencies: [
      {
        code: "MYR",
        name: "Malaysian ringgit",
        symbol: "RM",
      },
    ],
    languages: [
      {
        iso639_1: "ms",
        iso639_2: "zsm",
        name: "Malaysian",
        nativeName: "بهاس مليسيا",
      },
    ],
    translations: {
      br: "Malásia",
      pt: "Malásia",
      nl: "Maleisië",
      hr: "Malezija",
      fa: "مالزی",
      de: "Malaysia",
      es: "Malasia",
      fr: "Malaisie",
      ja: "マレーシア",
      it: "Malesia",
      hu: "Malajzia",
    },
    flag: "https://flagcdn.com/my.svg",
    regionalBlocs: [
      {
        acronym: "ASEAN",
        name: "Association of Southeast Asian Nations",
      },
    ],
    cioc: "MAS",
    independent: true,
  },
  {
    name: "Maldives",
    topLevelDomain: [".mv"],
    alpha2Code: "MV",
    alpha3Code: "MDV",
    callingCodes: ["960"],
    capital: "Malé",
    altSpellings: ["MV", "Maldive Islands", "Republic of the Maldives", "Dhivehi Raajjeyge Jumhooriyya"],
    subregion: "Southern Asia",
    region: "Asia",
    population: 540542,
    latlng: [3.25, 73],
    demonym: "Maldivan",
    area: 300,
    gini: 31.3,
    timezones: ["UTC+05:00"],
    nativeName: "Maldives",
    numericCode: "462",
    flags: {
      svg: "https://flagcdn.com/mv.svg",
      png: "https://flagcdn.com/w320/mv.png",
    },
    currencies: [
      {
        code: "MVR",
        name: "Maldivian rufiyaa",
        symbol: ".ރ",
      },
    ],
    languages: [
      {
        iso639_1: "dv",
        iso639_2: "div",
        name: "Divehi",
        nativeName: "ދިވެހި",
      },
    ],
    translations: {
      br: "Maldivas",
      pt: "Maldivas",
      nl: "Maldiven",
      hr: "Maldivi",
      fa: "مالدیو",
      de: "Malediven",
      es: "Maldivas",
      fr: "Maldives",
      ja: "モルディブ",
      it: "Maldive",
      hu: "Maldív-szigetek",
    },
    flag: "https://flagcdn.com/mv.svg",
    regionalBlocs: [
      {
        acronym: "SAARC",
        name: "South Asian Association for Regional Cooperation",
      },
    ],
    cioc: "MDV",
    independent: true,
  },
  {
    name: "Mali",
    topLevelDomain: [".ml"],
    alpha2Code: "ML",
    alpha3Code: "MLI",
    callingCodes: ["223"],
    capital: "Bamako",
    altSpellings: ["ML", "Republic of Mali", "République du Mali"],
    subregion: "Western Africa",
    region: "Africa",
    population: 20250834,
    latlng: [17, -4],
    demonym: "Malian",
    area: 1240192,
    gini: 33,
    timezones: ["UTC"],
    borders: ["DZA", "BFA", "GIN", "CIV", "MRT", "NER", "SEN"],
    nativeName: "Mali",
    numericCode: "466",
    flags: {
      svg: "https://flagcdn.com/ml.svg",
      png: "https://flagcdn.com/w320/ml.png",
    },
    currencies: [
      {
        code: "XOF",
        name: "West African CFA franc",
        symbol: "Fr",
      },
    ],
    languages: [
      {
        iso639_1: "fr",
        iso639_2: "fra",
        name: "French",
        nativeName: "français",
      },
    ],
    translations: {
      br: "Mali",
      pt: "Mali",
      nl: "Mali",
      hr: "Mali",
      fa: "مالی",
      de: "Mali",
      es: "Mali",
      fr: "Mali",
      ja: "マリ",
      it: "Mali",
      hu: "Mali",
    },
    flag: "https://flagcdn.com/ml.svg",
    regionalBlocs: [
      {
        acronym: "AU",
        name: "African Union",
        otherNames: ["الاتحاد الأفريقي", "Union africaine", "União Africana", "Unión Africana", "Umoja wa Afrika"],
      },
    ],
    cioc: "MLI",
    independent: true,
  },
  {
    name: "Malta",
    topLevelDomain: [".mt"],
    alpha2Code: "MT",
    alpha3Code: "MLT",
    callingCodes: ["356"],
    capital: "Valletta",
    altSpellings: ["MT", "Republic of Malta", "Repubblika ta' Malta"],
    subregion: "Southern Europe",
    region: "Europe",
    population: 525285,
    latlng: [35.83333333, 14.58333333],
    demonym: "Maltese",
    area: 316,
    gini: 28.7,
    timezones: ["UTC+01:00"],
    nativeName: "Malta",
    numericCode: "470",
    flags: {
      svg: "https://flagcdn.com/mt.svg",
      png: "https://flagcdn.com/w320/mt.png",
    },
    currencies: [
      {
        code: "EUR",
        name: "Euro",
        symbol: "€",
      },
    ],
    languages: [
      {
        iso639_1: "mt",
        iso639_2: "mlt",
        name: "Maltese",
        nativeName: "Malti",
      },
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
    ],
    translations: {
      br: "Malta",
      pt: "Malta",
      nl: "Malta",
      hr: "Malta",
      fa: "مالت",
      de: "Malta",
      es: "Malta",
      fr: "Malte",
      ja: "マルタ",
      it: "Malta",
      hu: "Málta",
    },
    flag: "https://flagcdn.com/mt.svg",
    regionalBlocs: [
      {
        acronym: "EU",
        name: "European Union",
      },
    ],
    cioc: "MLT",
    independent: true,
  },
  {
    name: "Marshall Islands",
    topLevelDomain: [".mh"],
    alpha2Code: "MH",
    alpha3Code: "MHL",
    callingCodes: ["692"],
    capital: "Majuro",
    altSpellings: ["MH", "Republic of the Marshall Islands", "Aolepān Aorōkin M̧ajeļ"],
    subregion: "Micronesia",
    region: "Oceania",
    population: 59194,
    latlng: [9, 168],
    demonym: "Marshallese",
    area: 181,
    timezones: ["UTC+12:00"],
    nativeName: "M̧ajeļ",
    numericCode: "584",
    flags: {
      svg: "https://flagcdn.com/mh.svg",
      png: "https://flagcdn.com/w320/mh.png",
    },
    currencies: [
      {
        code: "USD",
        name: "United States dollar",
        symbol: "$",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
      {
        iso639_1: "mh",
        iso639_2: "mah",
        name: "Marshallese",
        nativeName: "Kajin M̧ajeļ",
      },
    ],
    translations: {
      br: "Ilhas Marshall",
      pt: "Ilhas Marshall",
      nl: "Marshalleilanden",
      hr: "Maršalovi Otoci",
      fa: "جزایر مارشال",
      de: "Marshallinseln",
      es: "Islas Marshall",
      fr: "Îles Marshall",
      ja: "マーシャル諸島",
      it: "Isole Marshall",
      hu: "Marshall-szigetek",
    },
    flag: "https://flagcdn.com/mh.svg",
    cioc: "MHL",
    independent: true,
  },
  {
    name: "Martinique",
    topLevelDomain: [".mq"],
    alpha2Code: "MQ",
    alpha3Code: "MTQ",
    callingCodes: ["596"],
    capital: "Fort-de-France",
    altSpellings: ["MQ"],
    subregion: "Caribbean",
    region: "Americas",
    population: 378243,
    latlng: [14.666667, -61],
    demonym: "French",
    timezones: ["UTC-04:00"],
    nativeName: "Martinique",
    numericCode: "474",
    flags: {
      svg: "https://flagcdn.com/mq.svg",
      png: "https://flagcdn.com/w320/mq.png",
    },
    currencies: [
      {
        code: "EUR",
        name: "Euro",
        symbol: "€",
      },
    ],
    languages: [
      {
        iso639_1: "fr",
        iso639_2: "fra",
        name: "French",
        nativeName: "français",
      },
    ],
    translations: {
      br: "Martinica",
      pt: "Martinica",
      nl: "Martinique",
      hr: "Martinique",
      fa: "مونتسرات",
      de: "Martinique",
      es: "Martinica",
      fr: "Martinique",
      ja: "マルティニーク",
      it: "Martinica",
      hu: "Martinique",
    },
    flag: "https://flagcdn.com/mq.svg",
    independent: false,
  },
  {
    name: "Mauritania",
    topLevelDomain: [".mr"],
    alpha2Code: "MR",
    alpha3Code: "MRT",
    callingCodes: ["222"],
    capital: "Nouakchott",
    altSpellings: ["MR", "Islamic Republic of Mauritania", "al-Jumhūriyyah al-ʾIslāmiyyah al-Mūrītāniyyah"],
    subregion: "Western Africa",
    region: "Africa",
    population: 4649660,
    latlng: [20, -12],
    demonym: "Mauritanian",
    area: 1030700,
    gini: 32.6,
    timezones: ["UTC"],
    borders: ["DZA", "MLI", "SEN", "ESH"],
    nativeName: "موريتانيا",
    numericCode: "478",
    flags: {
      svg: "https://flagcdn.com/mr.svg",
      png: "https://flagcdn.com/w320/mr.png",
    },
    currencies: [
      {
        code: "MRO",
        name: "Mauritanian ouguiya",
        symbol: "UM",
      },
    ],
    languages: [
      {
        iso639_1: "ar",
        iso639_2: "ara",
        name: "Arabic",
        nativeName: "العربية",
      },
    ],
    translations: {
      br: "Mauritânia",
      pt: "Mauritânia",
      nl: "Mauritanië",
      hr: "Mauritanija",
      fa: "موریتانی",
      de: "Mauretanien",
      es: "Mauritania",
      fr: "Mauritanie",
      ja: "モーリタニア",
      it: "Mauritania",
      hu: "Mauritánia",
    },
    flag: "https://flagcdn.com/mr.svg",
    regionalBlocs: [
      {
        acronym: "AU",
        name: "African Union",
        otherNames: ["الاتحاد الأفريقي", "Union africaine", "União Africana", "Unión Africana", "Umoja wa Afrika"],
      },
      {
        acronym: "AL",
        name: "Arab League",
        otherNames: ["جامعة الدول العربية", "Jāmiʻat ad-Duwal al-ʻArabīyah", "League of Arab States"],
      },
    ],
    cioc: "MTN",
    independent: true,
  },
  {
    name: "Mauritius",
    topLevelDomain: [".mu"],
    alpha2Code: "MU",
    alpha3Code: "MUS",
    callingCodes: ["230"],
    capital: "Port Louis",
    altSpellings: ["MU", "Republic of Mauritius", "République de Maurice"],
    subregion: "Eastern Africa",
    region: "Africa",
    population: 1265740,
    latlng: [-20.28333333, 57.55],
    demonym: "Mauritian",
    area: 2040,
    gini: 36.8,
    timezones: ["UTC+04:00"],
    nativeName: "Maurice",
    numericCode: "480",
    flags: {
      svg: "https://flagcdn.com/mu.svg",
      png: "https://flagcdn.com/w320/mu.png",
    },
    currencies: [
      {
        code: "MUR",
        name: "Mauritian rupee",
        symbol: "₨",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
    ],
    translations: {
      br: "Maurício",
      pt: "Maurícia",
      nl: "Mauritius",
      hr: "Mauricijus",
      fa: "موریس",
      de: "Mauritius",
      es: "Mauricio",
      fr: "Île Maurice",
      ja: "モーリシャス",
      it: "Mauritius",
      hu: "Mauritius",
    },
    flag: "https://flagcdn.com/mu.svg",
    regionalBlocs: [
      {
        acronym: "AU",
        name: "African Union",
        otherNames: ["الاتحاد الأفريقي", "Union africaine", "União Africana", "Unión Africana", "Umoja wa Afrika"],
      },
    ],
    cioc: "MRI",
    independent: true,
  },
  {
    name: "Mayotte",
    topLevelDomain: [".yt"],
    alpha2Code: "YT",
    alpha3Code: "MYT",
    callingCodes: ["262"],
    capital: "Mamoudzou",
    altSpellings: ["YT", "Department of Mayotte", "Département de Mayotte"],
    subregion: "Eastern Africa",
    region: "Africa",
    population: 226915,
    latlng: [-12.83333333, 45.16666666],
    demonym: "French",
    timezones: ["UTC+03:00"],
    nativeName: "Mayotte",
    numericCode: "175",
    flags: {
      svg: "https://flagcdn.com/yt.svg",
      png: "https://flagcdn.com/w320/yt.png",
    },
    currencies: [
      {
        code: "EUR",
        name: "Euro",
        symbol: "€",
      },
    ],
    languages: [
      {
        iso639_1: "fr",
        iso639_2: "fra",
        name: "French",
        nativeName: "français",
      },
    ],
    translations: {
      br: "Mayotte",
      pt: "Mayotte",
      nl: "Mayotte",
      hr: "Mayotte",
      fa: "مایوت",
      de: "Mayotte",
      es: "Mayotte",
      fr: "Mayotte",
      ja: "マヨット",
      it: "Mayotte",
      hu: "Mayotte",
    },
    flag: "https://flagcdn.com/yt.svg",
    regionalBlocs: [
      {
        acronym: "AU",
        name: "African Union",
        otherNames: ["الاتحاد الأفريقي", "Union africaine", "União Africana", "Unión Africana", "Umoja wa Afrika"],
      },
    ],
    independent: false,
  },
  {
    name: "Mexico",
    topLevelDomain: [".mx"],
    alpha2Code: "MX",
    alpha3Code: "MEX",
    callingCodes: ["52"],
    capital: "Mexico City",
    altSpellings: ["MX", "Mexicanos", "United Mexican States", "Estados Unidos Mexicanos"],
    subregion: "North America",
    region: "Americas",
    population: 128932753,
    latlng: [23, -102],
    demonym: "Mexican",
    area: 1964375,
    gini: 45.4,
    timezones: ["UTC-08:00", "UTC-07:00", "UTC-06:00"],
    borders: ["BLZ", "GTM", "USA"],
    nativeName: "México",
    numericCode: "484",
    flags: {
      svg: "https://flagcdn.com/mx.svg",
      png: "https://flagcdn.com/w320/mx.png",
    },
    currencies: [
      {
        code: "MXN",
        name: "Mexican peso",
        symbol: "$",
      },
    ],
    languages: [
      {
        iso639_1: "es",
        iso639_2: "spa",
        name: "Spanish",
        nativeName: "Español",
      },
    ],
    translations: {
      br: "México",
      pt: "México",
      nl: "Mexico",
      hr: "Meksiko",
      fa: "مکزیک",
      de: "Mexiko",
      es: "México",
      fr: "Mexique",
      ja: "メキシコ",
      it: "Messico",
      hu: "Mexikó",
    },
    flag: "https://flagcdn.com/mx.svg",
    regionalBlocs: [
      {
        acronym: "PA",
        name: "Pacific Alliance",
        otherNames: ["Alianza del Pacífico"],
      },
      {
        acronym: "NAFTA",
        name: "North American Free Trade Agreement",
        otherNames: ["Tratado de Libre Comercio de América del Norte", "Accord de Libre-échange Nord-Américain"],
      },
    ],
    cioc: "MEX",
    independent: true,
  },
  {
    name: "Micronesia (Federated States of)",
    topLevelDomain: [".fm"],
    alpha2Code: "FM",
    alpha3Code: "FSM",
    callingCodes: ["691"],
    capital: "Palikir",
    altSpellings: ["FM", "Federated States of Micronesia"],
    subregion: "Micronesia",
    region: "Oceania",
    population: 115021,
    latlng: [6.91666666, 158.25],
    demonym: "Micronesian",
    area: 702,
    gini: 40.1,
    timezones: ["UTC+10:00", "UTC+11"],
    nativeName: "Micronesia",
    numericCode: "583",
    flags: {
      svg: "https://flagcdn.com/fm.svg",
      png: "https://flagcdn.com/w320/fm.png",
    },
    currencies: [
      {
        code: "USD",
        name: "United States dollar",
        symbol: "$",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
    ],
    translations: {
      br: "Micronésia",
      pt: "Micronésia",
      nl: "Micronesië",
      hr: "Mikronezija",
      fa: "ایالات فدرال میکرونزی",
      de: "Mikronesien",
      es: "Micronesia",
      fr: "Micronésie",
      ja: "ミクロネシア連邦",
      it: "Micronesia",
      hu: "Mikronézia",
    },
    flag: "https://flagcdn.com/fm.svg",
    cioc: "FSM",
    independent: true,
  },
  {
    name: "Moldova (Republic of)",
    topLevelDomain: [".md"],
    alpha2Code: "MD",
    alpha3Code: "MDA",
    callingCodes: ["373"],
    capital: "Chișinău",
    altSpellings: ["MD", "Republic of Moldova", "Republica Moldova"],
    subregion: "Eastern Europe",
    region: "Europe",
    population: 2617820,
    latlng: [47, 29],
    demonym: "Moldovan",
    area: 33846,
    gini: 25.7,
    timezones: ["UTC+02:00"],
    borders: ["ROU", "UKR"],
    nativeName: "Moldova",
    numericCode: "498",
    flags: {
      svg: "https://flagcdn.com/md.svg",
      png: "https://flagcdn.com/w320/md.png",
    },
    currencies: [
      {
        code: "MDL",
        name: "Moldovan leu",
        symbol: "L",
      },
    ],
    languages: [
      {
        iso639_1: "ro",
        iso639_2: "ron",
        name: "Romanian",
        nativeName: "Română",
      },
    ],
    translations: {
      br: "Moldávia",
      pt: "Moldávia",
      nl: "Moldavië",
      hr: "Moldova",
      fa: "مولداوی",
      de: "Moldawie",
      es: "Moldavia",
      fr: "Moldavie",
      ja: "モルドバ共和国",
      it: "Moldavia",
      hu: "Moldova",
    },
    flag: "https://flagcdn.com/md.svg",
    regionalBlocs: [
      {
        acronym: "CEFTA",
        name: "Central European Free Trade Agreement",
      },
    ],
    cioc: "MDA",
    independent: true,
  },
  {
    name: "Monaco",
    topLevelDomain: [".mc"],
    alpha2Code: "MC",
    alpha3Code: "MCO",
    callingCodes: ["377"],
    capital: "Monaco",
    altSpellings: ["MC", "Principality of Monaco", "Principauté de Monaco"],
    subregion: "Western Europe",
    region: "Europe",
    population: 39244,
    latlng: [43.73333333, 7.4],
    demonym: "Monegasque",
    area: 2.02,
    timezones: ["UTC+01:00"],
    borders: ["FRA"],
    nativeName: "Monaco",
    numericCode: "492",
    flags: {
      svg: "https://flagcdn.com/mc.svg",
      png: "https://flagcdn.com/w320/mc.png",
    },
    currencies: [
      {
        code: "EUR",
        name: "Euro",
        symbol: "€",
      },
    ],
    languages: [
      {
        iso639_1: "fr",
        iso639_2: "fra",
        name: "French",
        nativeName: "français",
      },
    ],
    translations: {
      br: "Mônaco",
      pt: "Mónaco",
      nl: "Monaco",
      hr: "Monako",
      fa: "موناکو",
      de: "Monaco",
      es: "Mónaco",
      fr: "Monaco",
      ja: "モナコ",
      it: "Principato di Monaco",
      hu: "Monaco",
    },
    flag: "https://flagcdn.com/mc.svg",
    cioc: "MON",
    independent: true,
  },
  {
    name: "Mongolia",
    topLevelDomain: [".mn"],
    alpha2Code: "MN",
    alpha3Code: "MNG",
    callingCodes: ["976"],
    capital: "Ulan Bator",
    altSpellings: ["MN"],
    subregion: "Eastern Asia",
    region: "Asia",
    population: 3278292,
    latlng: [46, 105],
    demonym: "Mongolian",
    area: 1564110,
    gini: 32.7,
    timezones: ["UTC+07:00", "UTC+08:00"],
    borders: ["CHN", "RUS"],
    nativeName: "Монгол улс",
    numericCode: "496",
    flags: {
      svg: "https://flagcdn.com/mn.svg",
      png: "https://flagcdn.com/w320/mn.png",
    },
    currencies: [
      {
        code: "MNT",
        name: "Mongolian tögrög",
        symbol: "₮",
      },
    ],
    languages: [
      {
        iso639_1: "mn",
        iso639_2: "mon",
        name: "Mongolian",
        nativeName: "Монгол хэл",
      },
    ],
    translations: {
      br: "Mongólia",
      pt: "Mongólia",
      nl: "Mongolië",
      hr: "Mongolija",
      fa: "مغولستان",
      de: "Mongolei",
      es: "Mongolia",
      fr: "Mongolie",
      ja: "モンゴル",
      it: "Mongolia",
      hu: "Mongólia",
    },
    flag: "https://flagcdn.com/mn.svg",
    cioc: "MGL",
    independent: true,
  },
  {
    name: "Montenegro",
    topLevelDomain: [".me"],
    alpha2Code: "ME",
    alpha3Code: "MNE",
    callingCodes: ["382"],
    capital: "Podgorica",
    altSpellings: ["ME", "Crna Gora"],
    subregion: "Southern Europe",
    region: "Europe",
    population: 621718,
    latlng: [42.5, 19.3],
    demonym: "Montenegrin",
    area: 13812,
    gini: 38.5,
    timezones: ["UTC+01:00"],
    borders: ["ALB", "BIH", "HRV", "UNK", "SRB"],
    nativeName: "Црна Гора",
    numericCode: "499",
    flags: {
      svg: "https://flagcdn.com/me.svg",
      png: "https://flagcdn.com/w320/me.png",
    },
    currencies: [
      {
        code: "EUR",
        name: "Euro",
        symbol: "€",
      },
    ],
    languages: [
      {
        iso639_1: "sr",
        iso639_2: "srp",
        name: "Serbian",
        nativeName: "српски језик",
      },
      {
        iso639_1: "bs",
        iso639_2: "bos",
        name: "Bosnian",
        nativeName: "bosanski jezik",
      },
      {
        iso639_1: "sq",
        iso639_2: "sqi",
        name: "Albanian",
        nativeName: "Shqip",
      },
      {
        iso639_1: "hr",
        iso639_2: "hrv",
        name: "Croatian",
        nativeName: "hrvatski jezik",
      },
    ],
    translations: {
      br: "Montenegro",
      pt: "Montenegro",
      nl: "Montenegro",
      hr: "Crna Gora",
      fa: "مونته‌نگرو",
      de: "Montenegro",
      es: "Montenegro",
      fr: "Monténégro",
      ja: "モンテネグロ",
      it: "Montenegro",
      hu: "Montenegró",
    },
    flag: "https://flagcdn.com/me.svg",
    regionalBlocs: [
      {
        acronym: "CEFTA",
        name: "Central European Free Trade Agreement",
      },
    ],
    cioc: "MNE",
    independent: true,
  },
  {
    name: "Montserrat",
    topLevelDomain: [".ms"],
    alpha2Code: "MS",
    alpha3Code: "MSR",
    callingCodes: ["1"],
    capital: "Plymouth",
    altSpellings: ["MS"],
    subregion: "Caribbean",
    region: "Americas",
    population: 4922,
    latlng: [16.75, -62.2],
    demonym: "Montserratian",
    area: 102,
    timezones: ["UTC-04:00"],
    nativeName: "Montserrat",
    numericCode: "500",
    flags: {
      svg: "https://flagcdn.com/ms.svg",
      png: "https://flagcdn.com/w320/ms.png",
    },
    currencies: [
      {
        code: "XCD",
        name: "East Caribbean dollar",
        symbol: "$",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
    ],
    translations: {
      br: "Montserrat",
      pt: "Monserrate",
      nl: "Montserrat",
      hr: "Montserrat",
      fa: "مایوت",
      de: "Montserrat",
      es: "Montserrat",
      fr: "Montserrat",
      ja: "モントセラト",
      it: "Montserrat",
      hu: "Montserrat",
    },
    flag: "https://flagcdn.com/ms.svg",
    regionalBlocs: [
      {
        acronym: "CARICOM",
        name: "Caribbean Community",
        otherNames: ["Comunidad del Caribe", "Communauté Caribéenne", "Caribische Gemeenschap"],
      },
    ],
    independent: false,
  },
  {
    name: "Morocco",
    topLevelDomain: [".ma"],
    alpha2Code: "MA",
    alpha3Code: "MAR",
    callingCodes: ["212"],
    capital: "Rabat",
    altSpellings: ["MA", "Kingdom of Morocco", "Al-Mamlakah al-Maġribiyah"],
    subregion: "Northern Africa",
    region: "Africa",
    population: 36910558,
    latlng: [32, -5],
    demonym: "Moroccan",
    area: 446550,
    gini: 39.5,
    timezones: ["UTC"],
    borders: ["DZA", "ESH", "ESP"],
    nativeName: "المغرب",
    numericCode: "504",
    flags: {
      svg: "https://flagcdn.com/ma.svg",
      png: "https://flagcdn.com/w320/ma.png",
    },
    currencies: [
      {
        code: "MAD",
        name: "Moroccan dirham",
        symbol: "د.م.",
      },
    ],
    languages: [
      {
        iso639_1: "ar",
        iso639_2: "ara",
        name: "Arabic",
        nativeName: "العربية",
      },
    ],
    translations: {
      br: "Marrocos",
      pt: "Marrocos",
      nl: "Marokko",
      hr: "Maroko",
      fa: "المغرب",
      de: "Marokko",
      es: "Marruecos",
      fr: "Maroc",
      ja: "モロッコ",
      it: "Marocco",
      hu: "Marokkó",
    },
    flag: "https://flagcdn.com/ma.svg",
    regionalBlocs: [
      {
        acronym: "AU",
        name: "African Union",
        otherNames: ["الاتحاد الأفريقي", "Union africaine", "União Africana", "Unión Africana", "Umoja wa Afrika"],
      },
      {
        acronym: "AL",
        name: "Arab League",
        otherNames: ["جامعة الدول العربية", "Jāmiʻat ad-Duwal al-ʻArabīyah", "League of Arab States"],
      },
    ],
    cioc: "MAR",
    independent: true,
  },
  {
    name: "Mozambique",
    topLevelDomain: [".mz"],
    alpha2Code: "MZ",
    alpha3Code: "MOZ",
    callingCodes: ["258"],
    capital: "Maputo",
    altSpellings: ["MZ", "Republic of Mozambique", "República de Moçambique"],
    subregion: "Eastern Africa",
    region: "Africa",
    population: 31255435,
    latlng: [-18.25, 35],
    demonym: "Mozambican",
    area: 801590,
    gini: 54,
    timezones: ["UTC+02:00"],
    borders: ["MWI", "ZAF", "SWZ", "TZA", "ZMB", "ZWE"],
    nativeName: "Moçambique",
    numericCode: "508",
    flags: {
      svg: "https://flagcdn.com/mz.svg",
      png: "https://flagcdn.com/w320/mz.png",
    },
    currencies: [
      {
        code: "MZN",
        name: "Mozambican metical",
        symbol: "MT",
      },
    ],
    languages: [
      {
        iso639_1: "pt",
        iso639_2: "por",
        name: "Portuguese",
        nativeName: "Português",
      },
    ],
    translations: {
      br: "Moçambique",
      pt: "Moçambique",
      nl: "Mozambique",
      hr: "Mozambik",
      fa: "موزامبیک",
      de: "Mosambik",
      es: "Mozambique",
      fr: "Mozambique",
      ja: "モザンビーク",
      it: "Mozambico",
      hu: "Mozambik",
    },
    flag: "https://flagcdn.com/mz.svg",
    regionalBlocs: [
      {
        acronym: "AU",
        name: "African Union",
        otherNames: ["الاتحاد الأفريقي", "Union africaine", "União Africana", "Unión Africana", "Umoja wa Afrika"],
      },
    ],
    cioc: "MOZ",
    independent: true,
  },
  {
    name: "Myanmar",
    topLevelDomain: [".mm"],
    alpha2Code: "MM",
    alpha3Code: "MMR",
    callingCodes: ["95"],
    capital: "Naypyidaw",
    altSpellings: ["MM", "Burma", "Republic of the Union of Myanmar", "Pyidaunzu Thanmăda Myăma Nainngandaw"],
    subregion: "South-Eastern Asia",
    region: "Asia",
    population: 54409794,
    latlng: [22, 98],
    demonym: "Burmese",
    area: 676578,
    gini: 30.7,
    timezones: ["UTC+06:30"],
    borders: ["BGD", "CHN", "IND", "LAO", "THA"],
    nativeName: "Myanma",
    numericCode: "104",
    flags: {
      svg: "https://flagcdn.com/mm.svg",
      png: "https://flagcdn.com/w320/mm.png",
    },
    currencies: [
      {
        code: "MMK",
        name: "Burmese kyat",
        symbol: "Ks",
      },
    ],
    languages: [
      {
        iso639_1: "my",
        iso639_2: "mya",
        name: "Burmese",
        nativeName: "ဗမာစာ",
      },
    ],
    translations: {
      br: "Myanmar",
      pt: "Myanmar",
      nl: "Myanmar",
      hr: "Mijanmar",
      fa: "میانمار",
      de: "Myanmar",
      es: "Myanmar",
      fr: "Myanmar",
      ja: "ミャンマー",
      it: "Birmania",
      hu: "Mianmar",
    },
    flag: "https://flagcdn.com/mm.svg",
    regionalBlocs: [
      {
        acronym: "ASEAN",
        name: "Association of Southeast Asian Nations",
      },
    ],
    cioc: "MYA",
    independent: true,
  },
  {
    name: "Namibia",
    topLevelDomain: [".na"],
    alpha2Code: "NA",
    alpha3Code: "NAM",
    callingCodes: ["264"],
    capital: "Windhoek",
    altSpellings: ["NA", "Namibië", "Republic of Namibia"],
    subregion: "Southern Africa",
    region: "Africa",
    population: 2540916,
    latlng: [-22, 17],
    demonym: "Namibian",
    area: 825615,
    gini: 59.1,
    timezones: ["UTC+01:00"],
    borders: ["AGO", "BWA", "ZAF", "ZMB"],
    nativeName: "Namibia",
    numericCode: "516",
    flags: {
      svg: "https://flagcdn.com/na.svg",
      png: "https://flagcdn.com/w320/na.png",
    },
    currencies: [
      {
        code: "NAD",
        name: "Namibian dollar",
        symbol: "$",
      },
      {
        code: "ZAR",
        name: "South African rand",
        symbol: "R",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
      {
        iso639_1: "af",
        iso639_2: "afr",
        name: "Afrikaans",
        nativeName: "Afrikaans",
      },
    ],
    translations: {
      br: "Namíbia",
      pt: "Namíbia",
      nl: "Namibië",
      hr: "Namibija",
      fa: "نامیبیا",
      de: "Namibia",
      es: "Namibia",
      fr: "Namibie",
      ja: "ナミビア",
      it: "Namibia",
      hu: "Namíbia",
    },
    flag: "https://flagcdn.com/na.svg",
    regionalBlocs: [
      {
        acronym: "AU",
        name: "African Union",
        otherNames: ["الاتحاد الأفريقي", "Union africaine", "União Africana", "Unión Africana", "Umoja wa Afrika"],
      },
    ],
    cioc: "NAM",
    independent: true,
  },
  {
    name: "Nauru",
    topLevelDomain: [".nr"],
    alpha2Code: "NR",
    alpha3Code: "NRU",
    callingCodes: ["674"],
    capital: "Yaren",
    altSpellings: ["NR", "Naoero", "Pleasant Island", "Republic of Nauru", "Ripublik Naoero"],
    subregion: "Micronesia",
    region: "Oceania",
    population: 10834,
    latlng: [-0.53333333, 166.91666666],
    demonym: "Nauruan",
    area: 21,
    gini: 34.8,
    timezones: ["UTC+12:00"],
    nativeName: "Nauru",
    numericCode: "520",
    flags: {
      svg: "https://flagcdn.com/nr.svg",
      png: "https://flagcdn.com/w320/nr.png",
    },
    currencies: [
      {
        code: "AUD",
        name: "Australian dollar",
        symbol: "$",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
      {
        iso639_1: "na",
        iso639_2: "nau",
        name: "Nauruan",
        nativeName: "Dorerin Naoero",
      },
    ],
    translations: {
      br: "Nauru",
      pt: "Nauru",
      nl: "Nauru",
      hr: "Nauru",
      fa: "نائورو",
      de: "Nauru",
      es: "Nauru",
      fr: "Nauru",
      ja: "ナウル",
      it: "Nauru",
      hu: "Nauru",
    },
    flag: "https://flagcdn.com/nr.svg",
    cioc: "NRU",
    independent: true,
  },
  {
    name: "Nepal",
    topLevelDomain: [".np"],
    alpha2Code: "NP",
    alpha3Code: "NPL",
    callingCodes: ["977"],
    capital: "Kathmandu",
    altSpellings: ["NP", "Federal Democratic Republic of Nepal", "Loktāntrik Ganatantra Nepāl"],
    subregion: "Southern Asia",
    region: "Asia",
    population: 29136808,
    latlng: [28, 84],
    demonym: "Nepalese",
    area: 147181,
    gini: 32.8,
    timezones: ["UTC+05:45"],
    borders: ["CHN", "IND"],
    nativeName: "नेपाल",
    numericCode: "524",
    flags: {
      svg: "https://flagcdn.com/np.svg",
      png: "https://flagcdn.com/w320/np.png",
    },
    currencies: [
      {
        code: "NPR",
        name: "Nepalese rupee",
        symbol: "₨",
      },
    ],
    languages: [
      {
        iso639_1: "ne",
        iso639_2: "nep",
        name: "Nepali",
        nativeName: "नेपाली",
      },
    ],
    translations: {
      br: "Nepal",
      pt: "Nepal",
      nl: "Nepal",
      hr: "Nepal",
      fa: "نپال",
      de: "Népal",
      es: "Nepal",
      fr: "Népal",
      ja: "ネパール",
      it: "Nepal",
      hu: "Nepál",
    },
    flag: "https://flagcdn.com/np.svg",
    regionalBlocs: [
      {
        acronym: "SAARC",
        name: "South Asian Association for Regional Cooperation",
      },
    ],
    cioc: "NEP",
    independent: true,
  },
  {
    name: "Netherlands",
    topLevelDomain: [".nl"],
    alpha2Code: "NL",
    alpha3Code: "NLD",
    callingCodes: ["31"],
    capital: "Amsterdam",
    altSpellings: ["NL", "Holland", "Nederland"],
    subregion: "Western Europe",
    region: "Europe",
    population: 17441139,
    latlng: [52.5, 5.75],
    demonym: "Dutch",
    area: 41850,
    gini: 28.1,
    timezones: ["UTC-04:00", "UTC+01:00"],
    borders: ["BEL", "DEU"],
    nativeName: "Nederland",
    numericCode: "528",
    flags: {
      svg: "https://flagcdn.com/nl.svg",
      png: "https://flagcdn.com/w320/nl.png",
    },
    currencies: [
      {
        code: "EUR",
        name: "Euro",
        symbol: "€",
      },
    ],
    languages: [
      {
        iso639_1: "nl",
        iso639_2: "nld",
        name: "Dutch",
        nativeName: "Nederlands",
      },
    ],
    translations: {
      br: "Holanda",
      pt: "Países Baixos",
      nl: "Nederland",
      hr: "Nizozemska",
      fa: "پادشاهی هلند",
      de: "Niederlande",
      es: "Países Bajos",
      fr: "Pays-Bas",
      ja: "オランダ",
      it: "Paesi Bassi",
      hu: "Hollandia",
    },
    flag: "https://flagcdn.com/nl.svg",
    regionalBlocs: [
      {
        acronym: "EU",
        name: "European Union",
      },
    ],
    cioc: "NED",
    independent: true,
  },
  {
    name: "New Caledonia",
    topLevelDomain: [".nc"],
    alpha2Code: "NC",
    alpha3Code: "NCL",
    callingCodes: ["687"],
    capital: "Nouméa",
    altSpellings: ["NC"],
    subregion: "Melanesia",
    region: "Oceania",
    population: 271960,
    latlng: [-21.5, 165.5],
    demonym: "New Caledonian",
    area: 18575,
    timezones: ["UTC+11:00"],
    nativeName: "Nouvelle-Calédonie",
    numericCode: "540",
    flags: {
      svg: "https://flagcdn.com/nc.svg",
      png: "https://flagcdn.com/w320/nc.png",
    },
    currencies: [
      {
        code: "XPF",
        name: "CFP franc",
        symbol: "Fr",
      },
    ],
    languages: [
      {
        iso639_1: "fr",
        iso639_2: "fra",
        name: "French",
        nativeName: "français",
      },
    ],
    translations: {
      br: "Nova Caledônia",
      pt: "Nova Caledónia",
      nl: "Nieuw-Caledonië",
      hr: "Nova Kaledonija",
      fa: "کالدونیای جدید",
      de: "Neukaledonien",
      es: "Nueva Caledonia",
      fr: "Nouvelle-Calédonie",
      ja: "ニューカレドニア",
      it: "Nuova Caledonia",
      hu: "Új-Kaledónia",
    },
    flag: "https://flagcdn.com/nc.svg",
    independent: false,
  },
  {
    name: "New Zealand",
    topLevelDomain: [".nz"],
    alpha2Code: "NZ",
    alpha3Code: "NZL",
    callingCodes: ["64"],
    capital: "Wellington",
    altSpellings: ["NZ", "Aotearoa"],
    subregion: "Australia and New Zealand",
    region: "Oceania",
    population: 5084300,
    latlng: [-41, 174],
    demonym: "New Zealander",
    area: 270467,
    timezones: ["UTC-11:00", "UTC-10:00", "UTC+12:00", "UTC+12:45", "UTC+13:00"],
    nativeName: "New Zealand",
    numericCode: "554",
    flags: {
      svg: "https://flagcdn.com/nz.svg",
      png: "https://flagcdn.com/w320/nz.png",
    },
    currencies: [
      {
        code: "NZD",
        name: "New Zealand dollar",
        symbol: "$",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
      {
        iso639_1: "mi",
        iso639_2: "mri",
        name: "Māori",
        nativeName: "te reo Māori",
      },
    ],
    translations: {
      br: "Nova Zelândia",
      pt: "Nova Zelândia",
      nl: "Nieuw-Zeeland",
      hr: "Novi Zeland",
      fa: "نیوزیلند",
      de: "Neuseeland",
      es: "Nueva Zelanda",
      fr: "Nouvelle-Zélande",
      ja: "ニュージーランド",
      it: "Nuova Zelanda",
      hu: "Új-Zéland",
    },
    flag: "https://flagcdn.com/nz.svg",
    cioc: "NZL",
    independent: true,
  },
  {
    name: "Nicaragua",
    topLevelDomain: [".ni"],
    alpha2Code: "NI",
    alpha3Code: "NIC",
    callingCodes: ["505"],
    capital: "Managua",
    altSpellings: ["NI", "Republic of Nicaragua", "República de Nicaragua"],
    subregion: "Central America",
    region: "Americas",
    population: 6624554,
    latlng: [13, -85],
    demonym: "Nicaraguan",
    area: 130373,
    gini: 46.2,
    timezones: ["UTC-06:00"],
    borders: ["CRI", "HND"],
    nativeName: "Nicaragua",
    numericCode: "558",
    flags: {
      svg: "https://flagcdn.com/ni.svg",
      png: "https://flagcdn.com/w320/ni.png",
    },
    currencies: [
      {
        code: "NIO",
        name: "Nicaraguan córdoba",
        symbol: "C$",
      },
    ],
    languages: [
      {
        iso639_1: "es",
        iso639_2: "spa",
        name: "Spanish",
        nativeName: "Español",
      },
    ],
    translations: {
      br: "Nicarágua",
      pt: "Nicarágua",
      nl: "Nicaragua",
      hr: "Nikaragva",
      fa: "نیکاراگوئه",
      de: "Nicaragua",
      es: "Nicaragua",
      fr: "Nicaragua",
      ja: "ニカラグア",
      it: "Nicaragua",
      hu: "Nicaragua",
    },
    flag: "https://flagcdn.com/ni.svg",
    regionalBlocs: [
      {
        acronym: "CAIS",
        name: "Central American Integration System",
        otherAcronyms: ["SICA"],
        otherNames: ["Sistema de la Integración Centroamericana,"],
      },
    ],
    cioc: "NCA",
    independent: true,
  },
  {
    name: "Niger",
    topLevelDomain: [".ne"],
    alpha2Code: "NE",
    alpha3Code: "NER",
    callingCodes: ["227"],
    capital: "Niamey",
    altSpellings: ["NE", "Nijar", "Republic of Niger", "République du Niger"],
    subregion: "Western Africa",
    region: "Africa",
    population: 24206636,
    latlng: [16, 8],
    demonym: "Nigerien",
    area: 1267000,
    gini: 34.3,
    timezones: ["UTC+01:00"],
    borders: ["DZA", "BEN", "BFA", "TCD", "LBY", "MLI", "NGA"],
    nativeName: "Niger",
    numericCode: "562",
    flags: {
      svg: "https://flagcdn.com/ne.svg",
      png: "https://flagcdn.com/w320/ne.png",
    },
    currencies: [
      {
        code: "XOF",
        name: "West African CFA franc",
        symbol: "Fr",
      },
    ],
    languages: [
      {
        iso639_1: "fr",
        iso639_2: "fra",
        name: "French",
        nativeName: "français",
      },
    ],
    translations: {
      br: "Níger",
      pt: "Níger",
      nl: "Niger",
      hr: "Niger",
      fa: "نیجر",
      de: "Niger",
      es: "Níger",
      fr: "Niger",
      ja: "ニジェール",
      it: "Niger",
      hu: "Niger",
    },
    flag: "https://flagcdn.com/ne.svg",
    regionalBlocs: [
      {
        acronym: "AU",
        name: "African Union",
        otherNames: ["الاتحاد الأفريقي", "Union africaine", "União Africana", "Unión Africana", "Umoja wa Afrika"],
      },
    ],
    cioc: "NIG",
    independent: true,
  },
  {
    name: "Nigeria",
    topLevelDomain: [".ng"],
    alpha2Code: "NG",
    alpha3Code: "NGA",
    callingCodes: ["234"],
    capital: "Abuja",
    altSpellings: ["NG", "Nijeriya", "Naíjíríà", "Federal Republic of Nigeria"],
    subregion: "Western Africa",
    region: "Africa",
    population: 206139587,
    latlng: [10, 8],
    demonym: "Nigerian",
    area: 923768,
    gini: 35.1,
    timezones: ["UTC+01:00"],
    borders: ["BEN", "CMR", "TCD", "NER"],
    nativeName: "Nigeria",
    numericCode: "566",
    flags: {
      svg: "https://flagcdn.com/ng.svg",
      png: "https://flagcdn.com/w320/ng.png",
    },
    currencies: [
      {
        code: "NGN",
        name: "Nigerian naira",
        symbol: "₦",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
    ],
    translations: {
      br: "Nigéria",
      pt: "Nigéria",
      nl: "Nigeria",
      hr: "Nigerija",
      fa: "نیجریه",
      de: "Nigeria",
      es: "Nigeria",
      fr: "Nigéria",
      ja: "ナイジェリア",
      it: "Nigeria",
      hu: "Nigéria",
    },
    flag: "https://flagcdn.com/ng.svg",
    regionalBlocs: [
      {
        acronym: "AU",
        name: "African Union",
        otherNames: ["الاتحاد الأفريقي", "Union africaine", "União Africana", "Unión Africana", "Umoja wa Afrika"],
      },
    ],
    cioc: "NGR",
    independent: true,
  },
  {
    name: "Niue",
    topLevelDomain: [".nu"],
    alpha2Code: "NU",
    alpha3Code: "NIU",
    callingCodes: ["683"],
    capital: "Alofi",
    altSpellings: ["NU"],
    subregion: "Polynesia",
    region: "Oceania",
    population: 1470,
    latlng: [-19.03333333, -169.86666666],
    demonym: "Niuean",
    area: 260,
    timezones: ["UTC-11:00"],
    nativeName: "Niuē",
    numericCode: "570",
    flags: {
      svg: "https://flagcdn.com/nu.svg",
      png: "https://flagcdn.com/w320/nu.png",
    },
    currencies: [
      {
        code: "NZD",
        name: "New Zealand dollar",
        symbol: "$",
      },
      {
        code: "NZD",
        name: "Niue dollar",
        symbol: "$",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
    ],
    translations: {
      br: "Niue",
      pt: "Niue",
      nl: "Niue",
      hr: "Niue",
      fa: "نیووی",
      de: "Niue",
      es: "Niue",
      fr: "Niue",
      ja: "ニウエ",
      it: "Niue",
      hu: "Niue",
    },
    flag: "https://flagcdn.com/nu.svg",
    independent: true,
  },
  {
    name: "Norfolk Island",
    topLevelDomain: [".nf"],
    alpha2Code: "NF",
    alpha3Code: "NFK",
    callingCodes: ["672"],
    capital: "Kingston",
    altSpellings: ["NF", "Territory of Norfolk Island", "Teratri of Norf'k Ailen"],
    subregion: "Australia and New Zealand",
    region: "Oceania",
    population: 2302,
    latlng: [-29.03333333, 167.95],
    demonym: "Norfolk Islander",
    area: 36,
    timezones: ["UTC+11:30"],
    nativeName: "Norfolk Island",
    numericCode: "574",
    flags: {
      svg: "https://flagcdn.com/nf.svg",
      png: "https://flagcdn.com/w320/nf.png",
    },
    currencies: [
      {
        code: "AUD",
        name: "Australian dollar",
        symbol: "$",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
    ],
    translations: {
      br: "Ilha Norfolk",
      pt: "Ilha Norfolk",
      nl: "Norfolkeiland",
      hr: "Otok Norfolk",
      fa: "جزیره نورفک",
      de: "Norfolkinsel",
      es: "Isla de Norfolk",
      fr: "Île de Norfolk",
      ja: "ノーフォーク島",
      it: "Isola Norfolk",
      hu: "Norfolk-sziget",
    },
    flag: "https://flagcdn.com/nf.svg",
    independent: false,
  },
  {
    name: "Korea (Democratic People's Republic of)",
    topLevelDomain: [".kp"],
    alpha2Code: "KP",
    alpha3Code: "PRK",
    callingCodes: ["850"],
    capital: "Pyongyang",
    altSpellings: ["KP", "Democratic People's Republic of Korea", "조선민주주의인민공화국", "Chosŏn Minjujuŭi Inmin Konghwaguk"],
    subregion: "Eastern Asia",
    region: "Asia",
    population: 25778815,
    latlng: [40, 127],
    demonym: "North Korean",
    area: 120538,
    timezones: ["UTC+09:00"],
    borders: ["CHN", "KOR", "RUS"],
    nativeName: "북한",
    numericCode: "408",
    flags: {
      svg: "https://flagcdn.com/kp.svg",
      png: "https://flagcdn.com/w320/kp.png",
    },
    currencies: [
      {
        code: "KPW",
        name: "North Korean won",
        symbol: "₩",
      },
    ],
    languages: [
      {
        iso639_1: "ko",
        iso639_2: "kor",
        name: "Korean",
        nativeName: "한국어",
      },
    ],
    translations: {
      br: "Coreia do Norte",
      pt: "Coreia do Norte",
      nl: "Noord-Korea",
      hr: "Sjeverna Koreja",
      fa: "کره جنوبی",
      de: "Nordkorea",
      es: "Corea del Norte",
      fr: "Corée du Nord",
      ja: "朝鮮民主主義人民共和国",
      it: "Corea del Nord",
      hu: "Észak-Korea",
    },
    flag: "https://flagcdn.com/kp.svg",
    cioc: "PRK",
    independent: true,
  },
  {
    name: "Northern Mariana Islands",
    topLevelDomain: [".mp"],
    alpha2Code: "MP",
    alpha3Code: "MNP",
    callingCodes: ["1"],
    capital: "Saipan",
    altSpellings: ["MP", "Commonwealth of the Northern Mariana Islands", "Sankattan Siha Na Islas Mariånas"],
    subregion: "Micronesia",
    region: "Oceania",
    population: 57557,
    latlng: [15.2, 145.75],
    demonym: "American",
    area: 464,
    timezones: ["UTC+10:00"],
    nativeName: "Northern Mariana Islands",
    numericCode: "580",
    flags: {
      svg: "https://flagcdn.com/mp.svg",
      png: "https://flagcdn.com/w320/mp.png",
    },
    currencies: [
      {
        code: "USD",
        name: "United States dollar",
        symbol: "$",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
      {
        iso639_1: "ch",
        iso639_2: "cha",
        name: "Chamorro",
        nativeName: "Chamoru",
      },
    ],
    translations: {
      br: "Ilhas Marianas",
      pt: "Ilhas Marianas",
      nl: "Noordelijke Marianeneilanden",
      hr: "Sjevernomarijanski otoci",
      fa: "جزایر ماریانای شمالی",
      de: "Nördliche Marianen",
      es: "Islas Marianas del Norte",
      fr: "Îles Mariannes du Nord",
      ja: "北マリアナ諸島",
      it: "Isole Marianne Settentrionali",
      hu: "Északi-Mariana-szigetek",
    },
    flag: "https://flagcdn.com/mp.svg",
    independent: false,
  },
  {
    name: "Norway",
    topLevelDomain: [".no"],
    alpha2Code: "NO",
    alpha3Code: "NOR",
    callingCodes: ["47"],
    capital: "Oslo",
    altSpellings: ["NO", "Norge", "Noreg", "Kingdom of Norway", "Kongeriket Norge", "Kongeriket Noreg"],
    subregion: "Northern Europe",
    region: "Europe",
    population: 5379475,
    latlng: [62, 10],
    demonym: "Norwegian",
    area: 323802,
    gini: 27.6,
    timezones: ["UTC+01:00"],
    borders: ["FIN", "SWE", "RUS"],
    nativeName: "Norge",
    numericCode: "578",
    flags: {
      svg: "https://flagcdn.com/no.svg",
      png: "https://flagcdn.com/w320/no.png",
    },
    currencies: [
      {
        code: "NOK",
        name: "Norwegian krone",
        symbol: "kr",
      },
    ],
    languages: [
      {
        iso639_1: "no",
        iso639_2: "nor",
        name: "Norwegian",
        nativeName: "Norsk",
      },
      {
        iso639_1: "nb",
        iso639_2: "nob",
        name: "Norwegian Bokmål",
        nativeName: "Norsk bokmål",
      },
      {
        iso639_1: "nn",
        iso639_2: "nno",
        name: "Norwegian Nynorsk",
        nativeName: "Norsk nynorsk",
      },
    ],
    translations: {
      br: "Noruega",
      pt: "Noruega",
      nl: "Noorwegen",
      hr: "Norveška",
      fa: "نروژ",
      de: "Norwegen",
      es: "Noruega",
      fr: "Norvège",
      ja: "ノルウェー",
      it: "Norvegia",
      hu: "Norvégia",
    },
    flag: "https://flagcdn.com/no.svg",
    regionalBlocs: [
      {
        acronym: "EFTA",
        name: "European Free Trade Association",
      },
    ],
    cioc: "NOR",
    independent: true,
  },
  {
    name: "Oman",
    topLevelDomain: [".om"],
    alpha2Code: "OM",
    alpha3Code: "OMN",
    callingCodes: ["968"],
    capital: "Muscat",
    altSpellings: ["OM", "Sultanate of Oman", "Salṭanat ʻUmān"],
    subregion: "Western Asia",
    region: "Asia",
    population: 5106622,
    latlng: [21, 57],
    demonym: "Omani",
    area: 309500,
    timezones: ["UTC+04:00"],
    borders: ["SAU", "ARE", "YEM"],
    nativeName: "عمان",
    numericCode: "512",
    flags: {
      svg: "https://flagcdn.com/om.svg",
      png: "https://flagcdn.com/w320/om.png",
    },
    currencies: [
      {
        code: "OMR",
        name: "Omani rial",
        symbol: "ر.ع.",
      },
    ],
    languages: [
      {
        iso639_1: "ar",
        iso639_2: "ara",
        name: "Arabic",
        nativeName: "العربية",
      },
    ],
    translations: {
      br: "Omã",
      pt: "Omã",
      nl: "Oman",
      hr: "Oman",
      fa: "عمان",
      de: "Oman",
      es: "Omán",
      fr: "Oman",
      ja: "オマーン",
      it: "oman",
      hu: "Omán",
    },
    flag: "https://flagcdn.com/om.svg",
    regionalBlocs: [
      {
        acronym: "AL",
        name: "Arab League",
        otherNames: ["جامعة الدول العربية", "Jāmiʻat ad-Duwal al-ʻArabīyah", "League of Arab States"],
      },
    ],
    cioc: "OMA",
    independent: true,
  },
  {
    name: "Pakistan",
    topLevelDomain: [".pk"],
    alpha2Code: "PK",
    alpha3Code: "PAK",
    callingCodes: ["92"],
    capital: "Islamabad",
    altSpellings: ["PK", "Pākistān", "Islamic Republic of Pakistan", "Islāmī Jumhūriya'eh Pākistān"],
    subregion: "Southern Asia",
    region: "Asia",
    population: 220892331,
    latlng: [30, 70],
    demonym: "Pakistani",
    area: 881912,
    gini: 31.6,
    timezones: ["UTC+05:00"],
    borders: ["AFG", "CHN", "IND", "IRN"],
    nativeName: "Pakistan",
    numericCode: "586",
    flags: {
      svg: "https://flagcdn.com/pk.svg",
      png: "https://flagcdn.com/w320/pk.png",
    },
    currencies: [
      {
        code: "PKR",
        name: "Pakistani rupee",
        symbol: "₨",
      },
    ],
    languages: [
      {
        iso639_1: "ur",
        iso639_2: "urd",
        name: "Urdu",
        nativeName: "اردو",
      },
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
    ],
    translations: {
      br: "Paquistão",
      pt: "Paquistão",
      nl: "Pakistan",
      hr: "Pakistan",
      fa: "پاکستان",
      de: "Pakistan",
      es: "Pakistán",
      fr: "Pakistan",
      ja: "パキスタン",
      it: "Pakistan",
      hu: "Pakisztán",
    },
    flag: "https://flagcdn.com/pk.svg",
    regionalBlocs: [
      {
        acronym: "SAARC",
        name: "South Asian Association for Regional Cooperation",
      },
    ],
    cioc: "PAK",
    independent: true,
  },
  {
    name: "Palau",
    topLevelDomain: [".pw"],
    alpha2Code: "PW",
    alpha3Code: "PLW",
    callingCodes: ["680"],
    capital: "Ngerulmud",
    altSpellings: ["PW", "Republic of Palau", "Beluu er a Belau"],
    subregion: "Micronesia",
    region: "Oceania",
    population: 18092,
    latlng: [7.5, 134.5],
    demonym: "Palauan",
    area: 459,
    timezones: ["UTC+09:00"],
    nativeName: "Palau",
    numericCode: "585",
    flags: {
      svg: "https://flagcdn.com/pw.svg",
      png: "https://flagcdn.com/w320/pw.png",
    },
    currencies: [
      {
        code: "USD",
        name: "United States dollar",
        symbol: "$",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
    ],
    translations: {
      br: "Palau",
      pt: "Palau",
      nl: "Palau",
      hr: "Palau",
      fa: "پالائو",
      de: "Palau",
      es: "Palau",
      fr: "Palaos",
      ja: "パラオ",
      it: "Palau",
      hu: "Palau",
    },
    flag: "https://flagcdn.com/pw.svg",
    cioc: "PLW",
    independent: true,
  },
  {
    name: "Palestine, State of",
    topLevelDomain: [".ps"],
    alpha2Code: "PS",
    alpha3Code: "PSE",
    callingCodes: ["970"],
    capital: "Ramallah",
    altSpellings: ["PS", "State of Palestine", "Dawlat Filasṭin"],
    subregion: "Western Asia",
    region: "Asia",
    population: 4803269,
    latlng: [31.9, 35.2],
    demonym: "Palestinian",
    gini: 33.7,
    timezones: ["UTC+02:00"],
    borders: ["ISR", "EGY", "JOR"],
    nativeName: "فلسطين",
    numericCode: "275",
    flags: {
      svg: "https://flagcdn.com/ps.svg",
      png: "https://flagcdn.com/w320/ps.png",
    },
    currencies: [
      {
        code: "EGP",
        name: "Egyptian pound",
        symbol: "E£",
      },
      {
        code: "ILS",
        name: "Israeli new shekel",
        symbol: "₪",
      },
      {
        code: "JOD",
        name: "Jordanian dinar",
        symbol: "د.أ",
      },
    ],
    languages: [
      {
        iso639_1: "ar",
        iso639_2: "ara",
        name: "Arabic",
        nativeName: "العربية",
      },
    ],
    translations: {
      br: "Palestina",
      pt: "Palestina",
      nl: "Palestijnse gebieden",
      hr: "Palestina",
      fa: "فلسطین",
      de: "Palästina",
      es: "Palestina",
      fr: "Palestine",
      ja: "パレスチナ",
      it: "Palestina",
      hu: "Palesztina",
    },
    flag: "https://flagcdn.com/ps.svg",
    regionalBlocs: [
      {
        acronym: "AL",
        name: "Arab League",
        otherNames: ["جامعة الدول العربية", "Jāmiʻat ad-Duwal al-ʻArabīyah", "League of Arab States"],
      },
    ],
    cioc: "PLE",
    independent: true,
  },
  {
    name: "Panama",
    topLevelDomain: [".pa"],
    alpha2Code: "PA",
    alpha3Code: "PAN",
    callingCodes: ["507"],
    capital: "Panama City",
    altSpellings: ["PA", "Republic of Panama", "República de Panamá"],
    subregion: "Central America",
    region: "Americas",
    population: 4314768,
    latlng: [9, -80],
    demonym: "Panamanian",
    area: 75417,
    gini: 49.8,
    timezones: ["UTC-05:00"],
    borders: ["COL", "CRI"],
    nativeName: "Panamá",
    numericCode: "591",
    flags: {
      svg: "https://flagcdn.com/pa.svg",
      png: "https://flagcdn.com/w320/pa.png",
    },
    currencies: [
      {
        code: "PAB",
        name: "Panamanian balboa",
        symbol: "B/.",
      },
      {
        code: "USD",
        name: "United States dollar",
        symbol: "$",
      },
    ],
    languages: [
      {
        iso639_1: "es",
        iso639_2: "spa",
        name: "Spanish",
        nativeName: "Español",
      },
    ],
    translations: {
      br: "Panamá",
      pt: "Panamá",
      nl: "Panama",
      hr: "Panama",
      fa: "پاناما",
      de: "Panama",
      es: "Panamá",
      fr: "Panama",
      ja: "パナマ",
      it: "Panama",
      hu: "Panama",
    },
    flag: "https://flagcdn.com/pa.svg",
    regionalBlocs: [
      {
        acronym: "CAIS",
        name: "Central American Integration System",
        otherAcronyms: ["SICA"],
        otherNames: ["Sistema de la Integración Centroamericana,"],
      },
    ],
    cioc: "PAN",
    independent: true,
  },
  {
    name: "Papua New Guinea",
    topLevelDomain: [".pg"],
    alpha2Code: "PG",
    alpha3Code: "PNG",
    callingCodes: ["675"],
    capital: "Port Moresby",
    altSpellings: ["PG", "Independent State of Papua New Guinea", "Independen Stet bilong Papua Niugini"],
    subregion: "Melanesia",
    region: "Oceania",
    population: 8947027,
    latlng: [-6, 147],
    demonym: "Papua New Guinean",
    area: 462840,
    gini: 41.9,
    timezones: ["UTC+10:00"],
    borders: ["IDN"],
    nativeName: "Papua Niugini",
    numericCode: "598",
    flags: {
      svg: "https://flagcdn.com/pg.svg",
      png: "https://flagcdn.com/w320/pg.png",
    },
    currencies: [
      {
        code: "PGK",
        name: "Papua New Guinean kina",
        symbol: "K",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
    ],
    translations: {
      br: "Papua Nova Guiné",
      pt: "Papua Nova Guiné",
      nl: "Papoea-Nieuw-Guinea",
      hr: "Papua Nova Gvineja",
      fa: "پاپوآ گینه نو",
      de: "Papua-Neuguinea",
      es: "Papúa Nueva Guinea",
      fr: "Papouasie-Nouvelle-Guinée",
      ja: "パプアニューギニア",
      it: "Papua Nuova Guinea",
      hu: "Pápua Új-Guinea",
    },
    flag: "https://flagcdn.com/pg.svg",
    cioc: "PNG",
    independent: true,
  },
  {
    name: "Paraguay",
    topLevelDomain: [".py"],
    alpha2Code: "PY",
    alpha3Code: "PRY",
    callingCodes: ["595"],
    capital: "Asunción",
    altSpellings: ["PY", "Republic of Paraguay", "República del Paraguay", "Tetã Paraguái"],
    subregion: "South America",
    region: "Americas",
    population: 7132530,
    latlng: [-23, -58],
    demonym: "Paraguayan",
    area: 406752,
    gini: 45.7,
    timezones: ["UTC-04:00"],
    borders: ["ARG", "BOL", "BRA"],
    nativeName: "Paraguay",
    numericCode: "600",
    flags: {
      svg: "https://flagcdn.com/py.svg",
      png: "https://flagcdn.com/w320/py.png",
    },
    currencies: [
      {
        code: "PYG",
        name: "Paraguayan guaraní",
        symbol: "₲",
      },
    ],
    languages: [
      {
        iso639_1: "es",
        iso639_2: "spa",
        name: "Spanish",
        nativeName: "Español",
      },
      {
        iso639_1: "gn",
        iso639_2: "grn",
        name: "Guaraní",
        nativeName: "Avañe'ẽ",
      },
    ],
    translations: {
      br: "Paraguai",
      pt: "Paraguai",
      nl: "Paraguay",
      hr: "Paragvaj",
      fa: "پاراگوئه",
      de: "Paraguay",
      es: "Paraguay",
      fr: "Paraguay",
      ja: "パラグアイ",
      it: "Paraguay",
      hu: "Paraguay",
    },
    flag: "https://flagcdn.com/py.svg",
    regionalBlocs: [
      {
        acronym: "USAN",
        name: "Union of South American Nations",
        otherAcronyms: ["UNASUR", "UNASUL", "UZAN"],
        otherNames: ["Unión de Naciones Suramericanas", "União de Nações Sul-Americanas", "Unie van Zuid-Amerikaanse Naties", "South American Union"],
      },
    ],
    cioc: "PAR",
    independent: true,
  },
  {
    name: "Peru",
    topLevelDomain: [".pe"],
    alpha2Code: "PE",
    alpha3Code: "PER",
    callingCodes: ["51"],
    capital: "Lima",
    altSpellings: ["PE", "Republic of Peru", " República del Perú"],
    subregion: "South America",
    region: "Americas",
    population: 32971846,
    latlng: [-10, -76],
    demonym: "Peruvian",
    area: 1285216,
    gini: 41.5,
    timezones: ["UTC-05:00"],
    borders: ["BOL", "BRA", "CHL", "COL", "ECU"],
    nativeName: "Perú",
    numericCode: "604",
    flags: {
      svg: "https://flagcdn.com/pe.svg",
      png: "https://flagcdn.com/w320/pe.png",
    },
    currencies: [
      {
        code: "PEN",
        name: "Peruvian sol",
        symbol: "S/.",
      },
    ],
    languages: [
      {
        iso639_1: "es",
        iso639_2: "spa",
        name: "Spanish",
        nativeName: "Español",
      },
    ],
    translations: {
      br: "Peru",
      pt: "Peru",
      nl: "Peru",
      hr: "Peru",
      fa: "پرو",
      de: "Peru",
      es: "Perú",
      fr: "Pérou",
      ja: "ペルー",
      it: "Perù",
      hu: "Peru",
    },
    flag: "https://flagcdn.com/pe.svg",
    regionalBlocs: [
      {
        acronym: "PA",
        name: "Pacific Alliance",
        otherNames: ["Alianza del Pacífico"],
      },
      {
        acronym: "USAN",
        name: "Union of South American Nations",
        otherAcronyms: ["UNASUR", "UNASUL", "UZAN"],
        otherNames: ["Unión de Naciones Suramericanas", "União de Nações Sul-Americanas", "Unie van Zuid-Amerikaanse Naties", "South American Union"],
      },
    ],
    cioc: "PER",
    independent: true,
  },
  {
    name: "Philippines",
    topLevelDomain: [".ph"],
    alpha2Code: "PH",
    alpha3Code: "PHL",
    callingCodes: ["63"],
    capital: "Manila",
    altSpellings: ["PH", "Republic of the Philippines", "Repúblika ng Pilipinas"],
    subregion: "South-Eastern Asia",
    region: "Asia",
    population: 109581085,
    latlng: [13, 122],
    demonym: "Filipino",
    area: 342353,
    gini: 42.3,
    timezones: ["UTC+08:00"],
    nativeName: "Pilipinas",
    numericCode: "608",
    flags: {
      svg: "https://flagcdn.com/ph.svg",
      png: "https://flagcdn.com/w320/ph.png",
    },
    currencies: [
      {
        code: "PHP",
        name: "Philippine peso",
        symbol: "₱",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
    ],
    translations: {
      br: "Filipinas",
      pt: "Filipinas",
      nl: "Filipijnen",
      hr: "Filipini",
      fa: "جزایر الندفیلیپین",
      de: "Philippinen",
      es: "Filipinas",
      fr: "Philippines",
      ja: "フィリピン",
      it: "Filippine",
      hu: "Fülöp-szigetek",
    },
    flag: "https://flagcdn.com/ph.svg",
    regionalBlocs: [
      {
        acronym: "ASEAN",
        name: "Association of Southeast Asian Nations",
      },
    ],
    cioc: "PHI",
    independent: true,
  },
  {
    name: "Pitcairn",
    topLevelDomain: [".pn"],
    alpha2Code: "PN",
    alpha3Code: "PCN",
    callingCodes: ["64"],
    capital: "Adamstown",
    altSpellings: ["PN", "Pitcairn Henderson Ducie and Oeno Islands"],
    subregion: "Polynesia",
    region: "Oceania",
    population: 56,
    latlng: [-25.06666666, -130.1],
    demonym: "Pitcairn Islander",
    area: 47,
    timezones: ["UTC-08:00"],
    nativeName: "Pitcairn Islands",
    numericCode: "612",
    flags: {
      svg: "https://flagcdn.com/pn.svg",
      png: "https://flagcdn.com/w320/pn.png",
    },
    currencies: [
      {
        code: "NZD",
        name: "New Zealand dollar",
        symbol: "$",
      },
      {
        code: "PND",
        name: "Pitcairn Islands dollar",
        symbol: "$",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
    ],
    translations: {
      br: "Ilhas Pitcairn",
      pt: "Ilhas Picárnia",
      nl: "Pitcairneilanden",
      hr: "Pitcairnovo otočje",
      fa: "پیتکرن",
      de: "Pitcairn",
      es: "Islas Pitcairn",
      fr: "Îles Pitcairn",
      ja: "ピトケアン",
      it: "Isole Pitcairn",
      hu: "Pitcairn-szigetek",
    },
    flag: "https://flagcdn.com/pn.svg",
    independent: true,
  },
  {
    name: "Poland",
    topLevelDomain: [".pl"],
    alpha2Code: "PL",
    alpha3Code: "POL",
    callingCodes: ["48"],
    capital: "Warsaw",
    altSpellings: ["PL", "Republic of Poland", "Rzeczpospolita Polska"],
    subregion: "Central Europe",
    region: "Europe",
    population: 37950802,
    latlng: [52, 20],
    demonym: "Polish",
    area: 312679,
    gini: 30.2,
    timezones: ["UTC+01:00"],
    borders: ["BLR", "CZE", "DEU", "LTU", "RUS", "SVK", "UKR"],
    nativeName: "Polska",
    numericCode: "616",
    flags: {
      svg: "https://flagcdn.com/pl.svg",
      png: "https://flagcdn.com/w320/pl.png",
    },
    currencies: [
      {
        code: "PLN",
        name: "Polish złoty",
        symbol: "zł",
      },
    ],
    languages: [
      {
        iso639_1: "pl",
        iso639_2: "pol",
        name: "Polish",
        nativeName: "język polski",
      },
    ],
    translations: {
      br: "Polônia",
      pt: "Polónia",
      nl: "Polen",
      hr: "Poljska",
      fa: "لهستان",
      de: "Polen",
      es: "Polonia",
      fr: "Pologne",
      ja: "ポーランド",
      it: "Polonia",
      hu: "Lengyelország",
    },
    flag: "https://flagcdn.com/pl.svg",
    regionalBlocs: [
      {
        acronym: "EU",
        name: "European Union",
      },
    ],
    cioc: "POL",
    independent: true,
  },
  {
    name: "Portugal",
    topLevelDomain: [".pt"],
    alpha2Code: "PT",
    alpha3Code: "PRT",
    callingCodes: ["351"],
    capital: "Lisbon",
    altSpellings: ["PT", "Portuguesa", "Portuguese Republic", "República Portuguesa"],
    subregion: "Southern Europe",
    region: "Europe",
    population: 10305564,
    latlng: [39.5, -8],
    demonym: "Portuguese",
    area: 92090,
    gini: 33.5,
    timezones: ["UTC-01:00", "UTC"],
    borders: ["ESP"],
    nativeName: "Portugal",
    numericCode: "620",
    flags: {
      svg: "https://flagcdn.com/pt.svg",
      png: "https://flagcdn.com/w320/pt.png",
    },
    currencies: [
      {
        code: "EUR",
        name: "Euro",
        symbol: "€",
      },
    ],
    languages: [
      {
        iso639_1: "pt",
        iso639_2: "por",
        name: "Portuguese",
        nativeName: "Português",
      },
    ],
    translations: {
      br: "Portugal",
      pt: "Portugal",
      nl: "Portugal",
      hr: "Portugal",
      fa: "پرتغال",
      de: "Portugal",
      es: "Portugal",
      fr: "Portugal",
      ja: "ポルトガル",
      it: "Portogallo",
      hu: "Portugália",
    },
    flag: "https://flagcdn.com/pt.svg",
    regionalBlocs: [
      {
        acronym: "EU",
        name: "European Union",
      },
    ],
    cioc: "POR",
    independent: true,
  },
  {
    name: "Puerto Rico",
    topLevelDomain: [".pr"],
    alpha2Code: "PR",
    alpha3Code: "PRI",
    callingCodes: ["1"],
    capital: "San Juan",
    altSpellings: ["PR", "Commonwealth of Puerto Rico", "Estado Libre Asociado de Puerto Rico"],
    subregion: "Caribbean",
    region: "Americas",
    population: 3194034,
    latlng: [18.25, -66.5],
    demonym: "Puerto Rican",
    area: 8870,
    timezones: ["UTC-04:00"],
    nativeName: "Puerto Rico",
    numericCode: "630",
    flags: {
      svg: "https://flagcdn.com/pr.svg",
      png: "https://flagcdn.com/w320/pr.png",
    },
    currencies: [
      {
        code: "USD",
        name: "United States dollar",
        symbol: "$",
      },
    ],
    languages: [
      {
        iso639_1: "es",
        iso639_2: "spa",
        name: "Spanish",
        nativeName: "Español",
      },
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
    ],
    translations: {
      br: "Porto Rico",
      pt: "Porto Rico",
      nl: "Puerto Rico",
      hr: "Portoriko",
      fa: "پورتو ریکو",
      de: "Puerto Rico",
      es: "Puerto Rico",
      fr: "Porto Rico",
      ja: "プエルトリコ",
      it: "Porto Rico",
      hu: "Puerto Rico",
    },
    flag: "https://flagcdn.com/pr.svg",
    cioc: "PUR",
    independent: false,
  },
  {
    name: "Qatar",
    topLevelDomain: [".qa"],
    alpha2Code: "QA",
    alpha3Code: "QAT",
    callingCodes: ["974"],
    capital: "Doha",
    altSpellings: ["QA", "State of Qatar", "Dawlat Qaṭar"],
    subregion: "Western Asia",
    region: "Asia",
    population: 2881060,
    latlng: [25.5, 51.25],
    demonym: "Qatari",
    area: 11586,
    timezones: ["UTC+03:00"],
    borders: ["SAU"],
    nativeName: "قطر",
    numericCode: "634",
    flags: {
      svg: "https://flagcdn.com/qa.svg",
      png: "https://flagcdn.com/w320/qa.png",
    },
    currencies: [
      {
        code: "QAR",
        name: "Qatari riyal",
        symbol: "ر.ق",
      },
    ],
    languages: [
      {
        iso639_1: "ar",
        iso639_2: "ara",
        name: "Arabic",
        nativeName: "العربية",
      },
    ],
    translations: {
      br: "Catar",
      pt: "Catar",
      nl: "Qatar",
      hr: "Katar",
      fa: "قطر",
      de: "Katar",
      es: "Catar",
      fr: "Qatar",
      ja: "カタール",
      it: "Qatar",
      hu: "Katar",
    },
    flag: "https://flagcdn.com/qa.svg",
    regionalBlocs: [
      {
        acronym: "AL",
        name: "Arab League",
        otherNames: ["جامعة الدول العربية", "Jāmiʻat ad-Duwal al-ʻArabīyah", "League of Arab States"],
      },
    ],
    cioc: "QAT",
    independent: true,
  },
  {
    name: "Republic of Kosovo",
    topLevelDomain: [""],
    alpha2Code: "XK",
    alpha3Code: "UNK",
    callingCodes: ["383"],
    capital: "Pristina",
    altSpellings: ["XK", "Република Косово"],
    subregion: "Eastern Europe",
    region: "Europe",
    population: 1775378,
    latlng: [42.666667, 21.166667],
    demonym: "Kosovar",
    area: 10908,
    gini: 29,
    timezones: ["UTC+01:00"],
    borders: ["ALB", "MKD", "MNE", "SRB"],
    nativeName: "Republika e Kosovës",
    numericCode: "926",
    flags: {
      svg: "https://flagcdn.com/xk.svg",
      png: "https://flagcdn.com/w320/xk.png",
    },
    currencies: [
      {
        code: "EUR",
        name: "Euro",
        symbol: "€",
      },
    ],
    languages: [
      {
        iso639_1: "sq",
        iso639_2: "sqi",
        name: "Albanian",
        nativeName: "Shqip",
      },
      {
        iso639_1: "sr",
        iso639_2: "srp",
        name: "Serbian",
        nativeName: "српски језик",
      },
    ],
    translations: {
      br: "Kosovo",
      pt: "Kosovo",
      nl: "Republiek van Kosovo",
      hr: "Kosovo",
      fa: "کوزوو",
      de: "Republic of Kosovo",
      es: "Kosovo",
      fr: "Kosovo",
      ja: "Republic of Kosovo",
      it: "Republic of Kosovo",
      hu: "Koszovó",
    },
    flag: "https://flagcdn.com/xk.svg",
    regionalBlocs: [
      {
        acronym: "CEFTA",
        name: "Central European Free Trade Agreement",
      },
    ],
    independent: true,
  },
  {
    name: "Réunion",
    topLevelDomain: [".re"],
    alpha2Code: "RE",
    alpha3Code: "REU",
    callingCodes: ["262"],
    capital: "Saint-Denis",
    altSpellings: ["RE", "Reunion"],
    subregion: "Eastern Africa",
    region: "Africa",
    population: 840974,
    latlng: [-21.15, 55.5],
    demonym: "French",
    timezones: ["UTC+04:00"],
    nativeName: "La Réunion",
    numericCode: "638",
    flags: {
      svg: "https://flagcdn.com/re.svg",
      png: "https://flagcdn.com/w320/re.png",
    },
    currencies: [
      {
        code: "EUR",
        name: "Euro",
        symbol: "€",
      },
    ],
    languages: [
      {
        iso639_1: "fr",
        iso639_2: "fra",
        name: "French",
        nativeName: "français",
      },
    ],
    translations: {
      br: "Reunião",
      pt: "Reunião",
      nl: "Réunion",
      hr: "Réunion",
      fa: "رئونیون",
      de: "Réunion",
      es: "Reunión",
      fr: "Réunion",
      ja: "レユニオン",
      it: "Riunione",
      hu: "Réunion",
    },
    flag: "https://flagcdn.com/re.svg",
    regionalBlocs: [
      {
        acronym: "AU",
        name: "African Union",
        otherNames: ["الاتحاد الأفريقي", "Union africaine", "União Africana", "Unión Africana", "Umoja wa Afrika"],
      },
    ],
    independent: false,
  },
  {
    name: "Romania",
    topLevelDomain: [".ro"],
    alpha2Code: "RO",
    alpha3Code: "ROU",
    callingCodes: ["40"],
    capital: "Bucharest",
    altSpellings: ["RO", "Rumania", "Roumania", "România"],
    subregion: "Eastern Europe",
    region: "Europe",
    population: 19286123,
    latlng: [46, 25],
    demonym: "Romanian",
    area: 238391,
    gini: 35.8,
    timezones: ["UTC+02:00"],
    borders: ["BGR", "HUN", "MDA", "SRB", "UKR"],
    nativeName: "România",
    numericCode: "642",
    flags: {
      svg: "https://flagcdn.com/ro.svg",
      png: "https://flagcdn.com/w320/ro.png",
    },
    currencies: [
      {
        code: "RON",
        name: "Romanian leu",
        symbol: "lei",
      },
    ],
    languages: [
      {
        iso639_1: "ro",
        iso639_2: "ron",
        name: "Romanian",
        nativeName: "Română",
      },
    ],
    translations: {
      br: "Romênia",
      pt: "Roménia",
      nl: "Roemenië",
      hr: "Rumunjska",
      fa: "رومانی",
      de: "Rumänien",
      es: "Rumania",
      fr: "Roumanie",
      ja: "ルーマニア",
      it: "Romania",
      hu: "Románia",
    },
    flag: "https://flagcdn.com/ro.svg",
    regionalBlocs: [
      {
        acronym: "EU",
        name: "European Union",
      },
    ],
    cioc: "ROU",
    independent: true,
  },
  {
    name: "Russian Federation",
    topLevelDomain: [".ru"],
    alpha2Code: "RU",
    alpha3Code: "RUS",
    callingCodes: ["7"],
    capital: "Moscow",
    altSpellings: ["RU", "Rossiya", "Russian Federation", "Российская Федерация", "Rossiyskaya Federatsiya"],
    subregion: "Eastern Europe",
    region: "Europe",
    population: 144104080,
    latlng: [60, 100],
    demonym: "Russian",
    area: 17124442,
    gini: 37.5,
    timezones: ["UTC+03:00", "UTC+04:00", "UTC+06:00", "UTC+07:00", "UTC+08:00", "UTC+09:00", "UTC+10:00", "UTC+11:00", "UTC+12:00"],
    borders: ["AZE", "BLR", "CHN", "EST", "FIN", "GEO", "KAZ", "PRK", "LVA", "LTU", "MNG", "NOR", "POL", "UKR"],
    nativeName: "Россия",
    numericCode: "643",
    flags: {
      svg: "https://flagcdn.com/ru.svg",
      png: "https://flagcdn.com/w320/ru.png",
    },
    currencies: [
      {
        code: "RUB",
        name: "Russian ruble",
        symbol: "₽",
      },
    ],
    languages: [
      {
        iso639_1: "ru",
        iso639_2: "rus",
        name: "Russian",
        nativeName: "Русский",
      },
    ],
    translations: {
      br: "Rússia",
      pt: "Rússia",
      nl: "Rusland",
      hr: "Rusija",
      fa: "روسیه",
      de: "Russland",
      es: "Rusia",
      fr: "Russie",
      ja: "ロシア連邦",
      it: "Russia",
      hu: "Oroszország",
    },
    flag: "https://flagcdn.com/ru.svg",
    regionalBlocs: [
      {
        acronym: "EEU",
        name: "Eurasian Economic Union",
        otherAcronyms: ["EAEU"],
      },
    ],
    cioc: "RUS",
    independent: true,
  },
  {
    name: "Rwanda",
    topLevelDomain: [".rw"],
    alpha2Code: "RW",
    alpha3Code: "RWA",
    callingCodes: ["250"],
    capital: "Kigali",
    altSpellings: ["RW", "Republic of Rwanda", "Repubulika y'u Rwanda", "République du Rwanda"],
    subregion: "Eastern Africa",
    region: "Africa",
    population: 12952209,
    latlng: [-2, 30],
    demonym: "Rwandan",
    area: 26338,
    gini: 43.7,
    timezones: ["UTC+02:00"],
    borders: ["BDI", "COD", "TZA", "UGA"],
    nativeName: "Rwanda",
    numericCode: "646",
    flags: {
      svg: "https://flagcdn.com/rw.svg",
      png: "https://flagcdn.com/w320/rw.png",
    },
    currencies: [
      {
        code: "RWF",
        name: "Rwandan franc",
        symbol: "Fr",
      },
    ],
    languages: [
      {
        iso639_1: "rw",
        iso639_2: "kin",
        name: "Kinyarwanda",
        nativeName: "Ikinyarwanda",
      },
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
      {
        iso639_1: "fr",
        iso639_2: "fra",
        name: "French",
        nativeName: "français",
      },
    ],
    translations: {
      br: "Ruanda",
      pt: "Ruanda",
      nl: "Rwanda",
      hr: "Ruanda",
      fa: "رواندا",
      de: "Ruanda",
      es: "Ruanda",
      fr: "Rwanda",
      ja: "ルワンダ",
      it: "Ruanda",
      hu: "Ruanda",
    },
    flag: "https://flagcdn.com/rw.svg",
    regionalBlocs: [
      {
        acronym: "AU",
        name: "African Union",
        otherNames: ["الاتحاد الأفريقي", "Union africaine", "União Africana", "Unión Africana", "Umoja wa Afrika"],
      },
    ],
    cioc: "RWA",
    independent: true,
  },
  {
    name: "Saint Barthélemy",
    topLevelDomain: [".bl"],
    alpha2Code: "BL",
    alpha3Code: "BLM",
    callingCodes: ["590"],
    capital: "Gustavia",
    altSpellings: ["BL", "St. Barthelemy", "Collectivity of Saint Barthélemy", "Collectivité de Saint-Barthélemy"],
    subregion: "Caribbean",
    region: "Americas",
    population: 9417,
    latlng: [18.5, -63.41666666],
    demonym: "Saint Barthélemy Islander",
    area: 21,
    timezones: ["UTC-04:00"],
    nativeName: "Saint-Barthélemy",
    numericCode: "652",
    flags: {
      svg: "https://flagcdn.com/bl.svg",
      png: "https://flagcdn.com/w320/bl.png",
    },
    currencies: [
      {
        code: "EUR",
        name: "Euro",
        symbol: "€",
      },
    ],
    languages: [
      {
        iso639_1: "fr",
        iso639_2: "fra",
        name: "French",
        nativeName: "français",
      },
    ],
    translations: {
      br: "São Bartolomeu",
      pt: "São Bartolomeu",
      nl: "Saint Barthélemy",
      hr: "Saint Barthélemy",
      fa: "سن-بارتلمی",
      de: "Saint-Barthélemy",
      es: "San Bartolomé",
      fr: "Saint-Barthélemy",
      ja: "サン・バルテルミー",
      it: "Antille Francesi",
      hu: "Saint-Barthélemy",
    },
    flag: "https://flagcdn.com/bl.svg",
    independent: false,
  },
  {
    name: "Saint Helena, Ascension and Tristan da Cunha",
    topLevelDomain: [".sh"],
    alpha2Code: "SH",
    alpha3Code: "SHN",
    callingCodes: ["290"],
    capital: "Jamestown",
    altSpellings: ["SH"],
    subregion: "Western Africa",
    region: "Africa",
    population: 4255,
    latlng: [-15.95, -5.7],
    demonym: "Saint Helenian",
    timezones: ["UTC+00:00"],
    nativeName: "Saint Helena",
    numericCode: "654",
    flags: {
      svg: "https://flagcdn.com/sh.svg",
      png: "https://flagcdn.com/w320/sh.png",
    },
    currencies: [
      {
        code: "SHP",
        name: "Saint Helena pound",
        symbol: "£",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
    ],
    translations: {
      br: "Santa Helena",
      pt: "Santa Helena",
      nl: "Sint-Helena",
      hr: "Sveta Helena",
      fa: "سنت هلنا، اسنشن و تریستان دا کونا",
      de: "Sankt Helena",
      es: "Santa Helena",
      fr: "Sainte-Hélène",
      ja: "セントヘレナ・アセンションおよびトリスタンダクーニャ",
      it: "Sant'Elena",
      hu: "Szent Ilona",
    },
    flag: "https://flagcdn.com/sh.svg",
    regionalBlocs: [
      {
        acronym: "AU",
        name: "African Union",
        otherNames: ["الاتحاد الأفريقي", "Union africaine", "União Africana", "Unión Africana", "Umoja wa Afrika"],
      },
    ],
    independent: false,
  },
  {
    name: "Saint Kitts and Nevis",
    topLevelDomain: [".kn"],
    alpha2Code: "KN",
    alpha3Code: "KNA",
    callingCodes: ["1"],
    capital: "Basseterre",
    altSpellings: ["KN", "Federation of Saint Christopher and Nevis"],
    subregion: "Caribbean",
    region: "Americas",
    population: 53192,
    latlng: [17.33333333, -62.75],
    demonym: "Kittian and Nevisian",
    area: 261,
    timezones: ["UTC-04:00"],
    nativeName: "Saint Kitts and Nevis",
    numericCode: "659",
    flags: {
      svg: "https://flagcdn.com/kn.svg",
      png: "https://flagcdn.com/w320/kn.png",
    },
    currencies: [
      {
        code: "XCD",
        name: "East Caribbean dollar",
        symbol: "$",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
    ],
    translations: {
      br: "São Cristóvão e Neves",
      pt: "São Cristóvão e Neves",
      nl: "Saint Kitts en Nevis",
      hr: "Sveti Kristof i Nevis",
      fa: "سنت کیتس و نویس",
      de: "St. Kitts und Nevis",
      es: "San Cristóbal y Nieves",
      fr: "Saint-Christophe-et-Niévès",
      ja: "セントクリストファー・ネイビス",
      it: "Saint Kitts e Nevis",
      hu: "Saint Kitts és Nevis",
    },
    flag: "https://flagcdn.com/kn.svg",
    regionalBlocs: [
      {
        acronym: "CARICOM",
        name: "Caribbean Community",
        otherNames: ["Comunidad del Caribe", "Communauté Caribéenne", "Caribische Gemeenschap"],
      },
    ],
    cioc: "SKN",
    independent: true,
  },
  {
    name: "Saint Lucia",
    topLevelDomain: [".lc"],
    alpha2Code: "LC",
    alpha3Code: "LCA",
    callingCodes: ["1"],
    capital: "Castries",
    altSpellings: ["LC"],
    subregion: "Caribbean",
    region: "Americas",
    population: 183629,
    latlng: [13.88333333, -60.96666666],
    demonym: "Saint Lucian",
    area: 616,
    gini: 51.2,
    timezones: ["UTC-04:00"],
    nativeName: "Saint Lucia",
    numericCode: "662",
    flags: {
      svg: "https://flagcdn.com/lc.svg",
      png: "https://flagcdn.com/w320/lc.png",
    },
    currencies: [
      {
        code: "XCD",
        name: "East Caribbean dollar",
        symbol: "$",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
    ],
    translations: {
      br: "Santa Lúcia",
      pt: "Santa Lúcia",
      nl: "Saint Lucia",
      hr: "Sveta Lucija",
      fa: "سنت لوسیا",
      de: "Saint Lucia",
      es: "Santa Lucía",
      fr: "Saint-Lucie",
      ja: "セントルシア",
      it: "Santa Lucia",
      hu: "Saint Lucia",
    },
    flag: "https://flagcdn.com/lc.svg",
    regionalBlocs: [
      {
        acronym: "CARICOM",
        name: "Caribbean Community",
        otherNames: ["Comunidad del Caribe", "Communauté Caribéenne", "Caribische Gemeenschap"],
      },
    ],
    cioc: "LCA",
    independent: true,
  },
  {
    name: "Saint Martin (French part)",
    topLevelDomain: [".mf", ".fr", ".gp"],
    alpha2Code: "MF",
    alpha3Code: "MAF",
    callingCodes: ["590"],
    capital: "Marigot",
    altSpellings: ["MF", "Collectivity of Saint Martin", "Collectivité de Saint-Martin"],
    subregion: "Caribbean",
    region: "Americas",
    population: 38659,
    latlng: [18.08333333, -63.95],
    demonym: "Saint Martin Islander",
    area: 53,
    timezones: ["UTC-04:00"],
    borders: ["SXM", "NLD"],
    nativeName: "Saint-Martin",
    numericCode: "663",
    flags: {
      svg: "https://flagcdn.com/mf.svg",
      png: "https://flagcdn.com/w320/mf.png",
    },
    currencies: [
      {
        code: "EUR",
        name: "Euro",
        symbol: "€",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
      {
        iso639_1: "fr",
        iso639_2: "fra",
        name: "French",
        nativeName: "français",
      },
      {
        iso639_1: "nl",
        iso639_2: "nld",
        name: "Dutch",
        nativeName: "Nederlands",
      },
    ],
    translations: {
      br: "Saint Martin",
      pt: "Ilha São Martinho",
      nl: "Saint-Martin",
      hr: "Sveti Martin",
      fa: "سینت مارتن",
      de: "Saint Martin",
      es: "Saint Martin",
      fr: "Saint-Martin",
      ja: "サン・マルタン（フランス領）",
      it: "Saint Martin",
      hu: "Saint-Martin",
    },
    flag: "https://flagcdn.com/mf.svg",
    independent: false,
  },
  {
    name: "Saint Pierre and Miquelon",
    topLevelDomain: [".pm"],
    alpha2Code: "PM",
    alpha3Code: "SPM",
    callingCodes: ["508"],
    capital: "Saint-Pierre",
    altSpellings: ["PM", "Collectivité territoriale de Saint-Pierre-et-Miquelon"],
    subregion: "Northern America",
    region: "Americas",
    population: 6069,
    latlng: [46.83333333, -56.33333333],
    demonym: "French",
    area: 242,
    timezones: ["UTC-03:00"],
    nativeName: "Saint-Pierre-et-Miquelon",
    numericCode: "666",
    flags: {
      svg: "https://flagcdn.com/pm.svg",
      png: "https://flagcdn.com/w320/pm.png",
    },
    currencies: [
      {
        code: "EUR",
        name: "Euro",
        symbol: "€",
      },
    ],
    languages: [
      {
        iso639_1: "fr",
        iso639_2: "fra",
        name: "French",
        nativeName: "français",
      },
    ],
    translations: {
      br: "Saint-Pierre e Miquelon",
      pt: "São Pedro e Miquelon",
      nl: "Saint Pierre en Miquelon",
      hr: "Sveti Petar i Mikelon",
      fa: "سن پیر و میکلن",
      de: "Saint-Pierre und Miquelon",
      es: "San Pedro y Miquelón",
      fr: "Saint-Pierre-et-Miquelon",
      ja: "サンピエール島・ミクロン島",
      it: "Saint-Pierre e Miquelon",
      hu: "Saint-Pierre és Miquelon",
    },
    flag: "https://flagcdn.com/pm.svg",
    independent: false,
  },
  {
    name: "Saint Vincent and the Grenadines",
    topLevelDomain: [".vc"],
    alpha2Code: "VC",
    alpha3Code: "VCT",
    callingCodes: ["1"],
    capital: "Kingstown",
    altSpellings: ["VC"],
    subregion: "Caribbean",
    region: "Americas",
    population: 110947,
    latlng: [13.25, -61.2],
    demonym: "Saint Vincentian",
    area: 389,
    timezones: ["UTC-04:00"],
    nativeName: "Saint Vincent and the Grenadines",
    numericCode: "670",
    flags: {
      svg: "https://flagcdn.com/vc.svg",
      png: "https://flagcdn.com/w320/vc.png",
    },
    currencies: [
      {
        code: "XCD",
        name: "East Caribbean dollar",
        symbol: "$",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
    ],
    translations: {
      br: "São Vicente e Granadinas",
      pt: "São Vicente e Granadinas",
      nl: "Saint Vincent en de Grenadines",
      hr: "Sveti Vincent i Grenadini",
      fa: "سنت وینسنت و گرنادین‌ها",
      de: "Saint Vincent und die Grenadinen",
      es: "San Vicente y Granadinas",
      fr: "Saint-Vincent-et-les-Grenadines",
      ja: "セントビンセントおよびグレナディーン諸島",
      it: "Saint Vincent e Grenadine",
      hu: "Saint Vincent és a Grenadine-szigetek",
    },
    flag: "https://flagcdn.com/vc.svg",
    regionalBlocs: [
      {
        acronym: "CARICOM",
        name: "Caribbean Community",
        otherNames: ["Comunidad del Caribe", "Communauté Caribéenne", "Caribische Gemeenschap"],
      },
    ],
    cioc: "VIN",
    independent: true,
  },
  {
    name: "Samoa",
    topLevelDomain: [".ws"],
    alpha2Code: "WS",
    alpha3Code: "WSM",
    callingCodes: ["685"],
    capital: "Apia",
    altSpellings: ["WS", "Independent State of Samoa", "Malo Saʻoloto Tutoʻatasi o Sāmoa"],
    subregion: "Polynesia",
    region: "Oceania",
    population: 198410,
    latlng: [-13.58333333, -172.33333333],
    demonym: "Samoan",
    area: 2842,
    gini: 38.7,
    timezones: ["UTC+13:00"],
    nativeName: "Samoa",
    numericCode: "882",
    flags: {
      svg: "https://flagcdn.com/ws.svg",
      png: "https://flagcdn.com/w320/ws.png",
    },
    currencies: [
      {
        code: "WST",
        name: "Samoan tālā",
        symbol: "T",
      },
    ],
    languages: [
      {
        iso639_1: "sm",
        iso639_2: "smo",
        name: "Samoan",
        nativeName: "gagana fa'a Samoa",
      },
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
    ],
    translations: {
      br: "Samoa",
      pt: "Samoa",
      nl: "Samoa",
      hr: "Samoa",
      fa: "ساموآ",
      de: "Samoa",
      es: "Samoa",
      fr: "Samoa",
      ja: "サモア",
      it: "Samoa",
      hu: "Szamoa",
    },
    flag: "https://flagcdn.com/ws.svg",
    cioc: "SAM",
    independent: true,
  },
  {
    name: "San Marino",
    topLevelDomain: [".sm"],
    alpha2Code: "SM",
    alpha3Code: "SMR",
    callingCodes: ["378"],
    capital: "City of San Marino",
    altSpellings: ["SM", "Republic of San Marino", "Repubblica di San Marino"],
    subregion: "Southern Europe",
    region: "Europe",
    population: 33938,
    latlng: [43.76666666, 12.41666666],
    demonym: "Sammarinese",
    area: 61,
    timezones: ["UTC+01:00"],
    borders: ["ITA"],
    nativeName: "San Marino",
    numericCode: "674",
    flags: {
      svg: "https://flagcdn.com/sm.svg",
      png: "https://flagcdn.com/w320/sm.png",
    },
    currencies: [
      {
        code: "EUR",
        name: "Euro",
        symbol: "€",
      },
    ],
    languages: [
      {
        iso639_1: "it",
        iso639_2: "ita",
        name: "Italian",
        nativeName: "Italiano",
      },
    ],
    translations: {
      br: "San Marino",
      pt: "São Marinho",
      nl: "San Marino",
      hr: "San Marino",
      fa: "سان مارینو",
      de: "San Marino",
      es: "San Marino",
      fr: "Saint-Marin",
      ja: "サンマリノ",
      it: "San Marino",
      hu: "San Marino",
    },
    flag: "https://flagcdn.com/sm.svg",
    cioc: "SMR",
    independent: true,
  },
  {
    name: "Sao Tome and Principe",
    topLevelDomain: [".st"],
    alpha2Code: "ST",
    alpha3Code: "STP",
    callingCodes: ["239"],
    capital: "São Tomé",
    altSpellings: ["ST", "Democratic Republic of São Tomé and Príncipe", "República Democrática de São Tomé e Príncipe"],
    subregion: "Middle Africa",
    region: "Africa",
    population: 219161,
    latlng: [1, 7],
    demonym: "Sao Tomean",
    area: 964,
    gini: 56.3,
    timezones: ["UTC"],
    nativeName: "São Tomé e Príncipe",
    numericCode: "678",
    flags: {
      svg: "https://flagcdn.com/st.svg",
      png: "https://flagcdn.com/w320/st.png",
    },
    currencies: [
      {
        code: "STD",
        name: "São Tomé and Príncipe dobra",
        symbol: "Db",
      },
    ],
    languages: [
      {
        iso639_1: "pt",
        iso639_2: "por",
        name: "Portuguese",
        nativeName: "Português",
      },
    ],
    translations: {
      br: "São Tomé e Príncipe",
      pt: "São Tomé e Príncipe",
      nl: "Sao Tomé en Principe",
      hr: "Sveti Toma i Princip",
      fa: "کواترو دو فرویرو",
      de: "São Tomé und Príncipe",
      es: "Santo Tomé y Príncipe",
      fr: "Sao Tomé-et-Principe",
      ja: "サントメ・プリンシペ",
      it: "São Tomé e Príncipe",
      hu: "São Tomé és Príncipe",
    },
    flag: "https://flagcdn.com/st.svg",
    regionalBlocs: [
      {
        acronym: "AU",
        name: "African Union",
        otherNames: ["الاتحاد الأفريقي", "Union africaine", "União Africana", "Unión Africana", "Umoja wa Afrika"],
      },
    ],
    cioc: "STP",
    independent: true,
  },
  {
    name: "Saudi Arabia",
    topLevelDomain: [".sa"],
    alpha2Code: "SA",
    alpha3Code: "SAU",
    callingCodes: ["966"],
    capital: "Riyadh",
    altSpellings: ["SA", "Kingdom of Saudi Arabia", "Al-Mamlakah al-‘Arabiyyah as-Su‘ūdiyyah"],
    subregion: "Western Asia",
    region: "Asia",
    population: 34813867,
    latlng: [25, 45],
    demonym: "Saudi Arabian",
    area: 2149690,
    timezones: ["UTC+03:00"],
    borders: ["IRQ", "JOR", "KWT", "OMN", "QAT", "ARE", "YEM"],
    nativeName: "العربية السعودية",
    numericCode: "682",
    flags: {
      svg: "https://flagcdn.com/sa.svg",
      png: "https://flagcdn.com/w320/sa.png",
    },
    currencies: [
      {
        code: "SAR",
        name: "Saudi riyal",
        symbol: "ر.س",
      },
    ],
    languages: [
      {
        iso639_1: "ar",
        iso639_2: "ara",
        name: "Arabic",
        nativeName: "العربية",
      },
    ],
    translations: {
      br: "Arábia Saudita",
      pt: "Arábia Saudita",
      nl: "Saoedi-Arabië",
      hr: "Saudijska Arabija",
      fa: "عربستان سعودی",
      de: "Saudi-Arabien",
      es: "Arabia Saudí",
      fr: "Arabie Saoudite",
      ja: "サウジアラビア",
      it: "Arabia Saudita",
      hu: "Szaúd-Arábia",
    },
    flag: "https://flagcdn.com/sa.svg",
    regionalBlocs: [
      {
        acronym: "AL",
        name: "Arab League",
        otherNames: ["جامعة الدول العربية", "Jāmiʻat ad-Duwal al-ʻArabīyah", "League of Arab States"],
      },
    ],
    cioc: "KSA",
    independent: true,
  },
  {
    name: "Senegal",
    topLevelDomain: [".sn"],
    alpha2Code: "SN",
    alpha3Code: "SEN",
    callingCodes: ["221"],
    capital: "Dakar",
    altSpellings: ["SN", "Republic of Senegal", "République du Sénégal"],
    subregion: "Western Africa",
    region: "Africa",
    population: 16743930,
    latlng: [14, -14],
    demonym: "Senegalese",
    area: 196722,
    gini: 40.3,
    timezones: ["UTC"],
    borders: ["GMB", "GIN", "GNB", "MLI", "MRT"],
    nativeName: "Sénégal",
    numericCode: "686",
    flags: {
      svg: "https://flagcdn.com/sn.svg",
      png: "https://flagcdn.com/w320/sn.png",
    },
    currencies: [
      {
        code: "XOF",
        name: "West African CFA franc",
        symbol: "Fr",
      },
    ],
    languages: [
      {
        iso639_1: "fr",
        iso639_2: "fra",
        name: "French",
        nativeName: "français",
      },
    ],
    translations: {
      br: "Senegal",
      pt: "Senegal",
      nl: "Senegal",
      hr: "Senegal",
      fa: "سنگال",
      de: "Senegal",
      es: "Senegal",
      fr: "Sénégal",
      ja: "セネガル",
      it: "Senegal",
      hu: "Szenegál",
    },
    flag: "https://flagcdn.com/sn.svg",
    regionalBlocs: [
      {
        acronym: "AU",
        name: "African Union",
        otherNames: ["الاتحاد الأفريقي", "Union africaine", "União Africana", "Unión Africana", "Umoja wa Afrika"],
      },
    ],
    cioc: "SEN",
    independent: true,
  },
  {
    name: "Serbia",
    topLevelDomain: [".rs"],
    alpha2Code: "RS",
    alpha3Code: "SRB",
    callingCodes: ["381"],
    capital: "Belgrade",
    altSpellings: ["RS", "Srbija", "Republic of Serbia", "Република Србија", "Republika Srbija"],
    subregion: "Southern Europe",
    region: "Europe",
    population: 6908224,
    latlng: [44, 21],
    demonym: "Serbian",
    area: 88361,
    gini: 36.2,
    timezones: ["UTC+01:00"],
    borders: ["BIH", "BGR", "HRV", "HUN", "UNK", "MKD", "MNE", "ROU"],
    nativeName: "Србија",
    numericCode: "688",
    flags: {
      svg: "https://flagcdn.com/rs.svg",
      png: "https://flagcdn.com/w320/rs.png",
    },
    currencies: [
      {
        code: "RSD",
        name: "Serbian dinar",
        symbol: "дин.",
      },
    ],
    languages: [
      {
        iso639_1: "sr",
        iso639_2: "srp",
        name: "Serbian",
        nativeName: "српски језик",
      },
    ],
    translations: {
      br: "Sérvia",
      pt: "Sérvia",
      nl: "Servië",
      hr: "Srbija",
      fa: "صربستان",
      de: "Serbien",
      es: "Serbia",
      fr: "Serbie",
      ja: "セルビア",
      it: "Serbia",
      hu: "Szerbia",
    },
    flag: "https://flagcdn.com/rs.svg",
    regionalBlocs: [
      {
        acronym: "CEFTA",
        name: "Central European Free Trade Agreement",
      },
    ],
    cioc: "SRB",
    independent: true,
  },
  {
    name: "Seychelles",
    topLevelDomain: [".sc"],
    alpha2Code: "SC",
    alpha3Code: "SYC",
    callingCodes: ["248"],
    capital: "Victoria",
    altSpellings: ["SC", "Republic of Seychelles", "Repiblik Sesel", "République des Seychelles"],
    subregion: "Eastern Africa",
    region: "Africa",
    population: 98462,
    latlng: [-4.58333333, 55.66666666],
    demonym: "Seychellois",
    area: 452,
    gini: 32.1,
    timezones: ["UTC+04:00"],
    nativeName: "Seychelles",
    numericCode: "690",
    flags: {
      svg: "https://flagcdn.com/sc.svg",
      png: "https://flagcdn.com/w320/sc.png",
    },
    currencies: [
      {
        code: "SCR",
        name: "Seychellois rupee",
        symbol: "₨",
      },
    ],
    languages: [
      {
        iso639_1: "fr",
        iso639_2: "fra",
        name: "French",
        nativeName: "français",
      },
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
    ],
    translations: {
      br: "Seicheles",
      pt: "Seicheles",
      nl: "Seychellen",
      hr: "Sejšeli",
      fa: "سیشل",
      de: "Seychellen",
      es: "Seychelles",
      fr: "Seychelles",
      ja: "セーシェル",
      it: "Seychelles",
      hu: "Seychelle-szigetek",
    },
    flag: "https://flagcdn.com/sc.svg",
    regionalBlocs: [
      {
        acronym: "AU",
        name: "African Union",
        otherNames: ["الاتحاد الأفريقي", "Union africaine", "União Africana", "Unión Africana", "Umoja wa Afrika"],
      },
    ],
    cioc: "SEY",
    independent: true,
  },
  {
    name: "Sierra Leone",
    topLevelDomain: [".sl"],
    alpha2Code: "SL",
    alpha3Code: "SLE",
    callingCodes: ["232"],
    capital: "Freetown",
    altSpellings: ["SL", "Republic of Sierra Leone"],
    subregion: "Western Africa",
    region: "Africa",
    population: 7976985,
    latlng: [8.5, -11.5],
    demonym: "Sierra Leonean",
    area: 71740,
    gini: 35.7,
    timezones: ["UTC"],
    borders: ["GIN", "LBR"],
    nativeName: "Sierra Leone",
    numericCode: "694",
    flags: {
      svg: "https://flagcdn.com/sl.svg",
      png: "https://flagcdn.com/w320/sl.png",
    },
    currencies: [
      {
        code: "SLL",
        name: "Sierra Leonean leone",
        symbol: "Le",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
    ],
    translations: {
      br: "Serra Leoa",
      pt: "Serra Leoa",
      nl: "Sierra Leone",
      hr: "Sijera Leone",
      fa: "سیرالئون",
      de: "Sierra Leone",
      es: "Sierra Leone",
      fr: "Sierra Leone",
      ja: "シエラレオネ",
      it: "Sierra Leone",
      hu: "Sierra Leone",
    },
    flag: "https://flagcdn.com/sl.svg",
    regionalBlocs: [
      {
        acronym: "AU",
        name: "African Union",
        otherNames: ["الاتحاد الأفريقي", "Union africaine", "União Africana", "Unión Africana", "Umoja wa Afrika"],
      },
    ],
    cioc: "SLE",
    independent: true,
  },
  {
    name: "Singapore",
    topLevelDomain: [".sg"],
    alpha2Code: "SG",
    alpha3Code: "SGP",
    callingCodes: ["65"],
    capital: "Singapore",
    altSpellings: ["SG", "Singapura", "Republik Singapura", "新加坡共和国"],
    subregion: "South-Eastern Asia",
    region: "Asia",
    population: 5685807,
    latlng: [1.36666666, 103.8],
    demonym: "Singaporean",
    area: 710,
    timezones: ["UTC+08:00"],
    nativeName: "Singapore",
    numericCode: "702",
    flags: {
      svg: "https://flagcdn.com/sg.svg",
      png: "https://flagcdn.com/w320/sg.png",
    },
    currencies: [
      {
        code: "BND",
        name: "Brunei dollar",
        symbol: "$",
      },
      {
        code: "SGD",
        name: "Singapore dollar",
        symbol: "$",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
      {
        iso639_1: "ms",
        iso639_2: "msa",
        name: "Malay",
        nativeName: "bahasa Melayu",
      },
      {
        iso639_1: "ta",
        iso639_2: "tam",
        name: "Tamil",
        nativeName: "தமிழ்",
      },
      {
        iso639_1: "zh",
        iso639_2: "zho",
        name: "Chinese",
        nativeName: "中文 (Zhōngwén)",
      },
    ],
    translations: {
      br: "Singapura",
      pt: "Singapura",
      nl: "Singapore",
      hr: "Singapur",
      fa: "سنگاپور",
      de: "Singapur",
      es: "Singapur",
      fr: "Singapour",
      ja: "シンガポール",
      it: "Singapore",
      hu: "Szingapúr",
    },
    flag: "https://flagcdn.com/sg.svg",
    regionalBlocs: [
      {
        acronym: "ASEAN",
        name: "Association of Southeast Asian Nations",
      },
    ],
    cioc: "SIN",
    independent: true,
  },
  {
    name: "Sint Maarten (Dutch part)",
    topLevelDomain: [".sx"],
    alpha2Code: "SX",
    alpha3Code: "SXM",
    callingCodes: ["1"],
    capital: "Philipsburg",
    altSpellings: ["SX"],
    subregion: "Caribbean",
    region: "Americas",
    population: 40812,
    latlng: [18.033333, -63.05],
    demonym: "Dutch",
    area: 34,
    timezones: ["UTC-04:00"],
    borders: ["MAF"],
    nativeName: "Sint Maarten",
    numericCode: "534",
    flags: {
      svg: "https://flagcdn.com/sx.svg",
      png: "https://flagcdn.com/w320/sx.png",
    },
    currencies: [
      {
        code: "ANG",
        name: "Netherlands Antillean guilder",
        symbol: "ƒ",
      },
    ],
    languages: [
      {
        iso639_1: "nl",
        iso639_2: "nld",
        name: "Dutch",
        nativeName: "Nederlands",
      },
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
    ],
    translations: {
      br: "Sint Maarten",
      pt: "São Martinho",
      nl: "Sint Maarten",
      hr: "Sint Maarten (Dutch part)",
      fa: "سینت مارتن",
      de: "Sint Maarten (niederl. Teil)",
      es: "Sint Maarten (Dutch part)",
      fr: "Saint Martin (partie néerlandaise)",
      ja: "Sint Maarten (Dutch part)",
      it: "Saint Martin (parte olandese)",
      hu: "Sint Maarten",
    },
    flag: "https://flagcdn.com/sx.svg",
    independent: false,
  },
  {
    name: "Slovakia",
    topLevelDomain: [".sk"],
    alpha2Code: "SK",
    alpha3Code: "SVK",
    callingCodes: ["421"],
    capital: "Bratislava",
    altSpellings: ["SK", "Slovak Republic", "Slovenská republika"],
    subregion: "Central Europe",
    region: "Europe",
    population: 5458827,
    latlng: [48.66666666, 19.5],
    demonym: "Slovak",
    area: 49037,
    gini: 25,
    timezones: ["UTC+01:00"],
    borders: ["AUT", "CZE", "HUN", "POL", "UKR"],
    nativeName: "Slovensko",
    numericCode: "703",
    flags: {
      svg: "https://flagcdn.com/sk.svg",
      png: "https://flagcdn.com/w320/sk.png",
    },
    currencies: [
      {
        code: "EUR",
        name: "Euro",
        symbol: "€",
      },
    ],
    languages: [
      {
        iso639_1: "sk",
        iso639_2: "slk",
        name: "Slovak",
        nativeName: "slovenčina",
      },
    ],
    translations: {
      br: "Eslováquia",
      pt: "Eslováquia",
      nl: "Slowakije",
      hr: "Slovačka",
      fa: "اسلواکی",
      de: "Slowakei",
      es: "República Eslovaca",
      fr: "Slovaquie",
      ja: "スロバキア",
      it: "Slovacchia",
      hu: "Szlovákia",
    },
    flag: "https://flagcdn.com/sk.svg",
    regionalBlocs: [
      {
        acronym: "EU",
        name: "European Union",
      },
    ],
    cioc: "SVK",
    independent: true,
  },
  {
    name: "Slovenia",
    topLevelDomain: [".si"],
    alpha2Code: "SI",
    alpha3Code: "SVN",
    callingCodes: ["386"],
    capital: "Ljubljana",
    altSpellings: ["SI", "Republic of Slovenia", "Republika Slovenija"],
    subregion: "Southern Europe",
    region: "Europe",
    population: 2100126,
    latlng: [46.11666666, 14.81666666],
    demonym: "Slovene",
    area: 20273,
    gini: 24.6,
    timezones: ["UTC+01:00"],
    borders: ["AUT", "HRV", "ITA", "HUN"],
    nativeName: "Slovenija",
    numericCode: "705",
    flags: {
      svg: "https://flagcdn.com/si.svg",
      png: "https://flagcdn.com/w320/si.png",
    },
    currencies: [
      {
        code: "EUR",
        name: "Euro",
        symbol: "€",
      },
    ],
    languages: [
      {
        iso639_1: "sl",
        iso639_2: "slv",
        name: "Slovene",
        nativeName: "slovenski jezik",
      },
    ],
    translations: {
      br: "Eslovênia",
      pt: "Eslovénia",
      nl: "Slovenië",
      hr: "Slovenija",
      fa: "اسلوونی",
      de: "Slowenien",
      es: "Eslovenia",
      fr: "Slovénie",
      ja: "スロベニア",
      it: "Slovenia",
      hu: "Szlovénia",
    },
    flag: "https://flagcdn.com/si.svg",
    regionalBlocs: [
      {
        acronym: "EU",
        name: "European Union",
      },
    ],
    cioc: "SLO",
    independent: true,
  },
  {
    name: "Solomon Islands",
    topLevelDomain: [".sb"],
    alpha2Code: "SB",
    alpha3Code: "SLB",
    callingCodes: ["677"],
    capital: "Honiara",
    altSpellings: ["SB"],
    subregion: "Melanesia",
    region: "Oceania",
    population: 686878,
    latlng: [-8, 159],
    demonym: "Solomon Islander",
    area: 28896,
    gini: 37.1,
    timezones: ["UTC+11:00"],
    nativeName: "Solomon Islands",
    numericCode: "090",
    flags: {
      svg: "https://flagcdn.com/sb.svg",
      png: "https://flagcdn.com/w320/sb.png",
    },
    currencies: [
      {
        code: "SBD",
        name: "Solomon Islands dollar",
        symbol: "$",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
    ],
    translations: {
      br: "Ilhas Salomão",
      pt: "Ilhas Salomão",
      nl: "Salomonseilanden",
      hr: "Solomonski Otoci",
      fa: "جزایر سلیمان",
      de: "Salomonen",
      es: "Islas Salomón",
      fr: "Îles Salomon",
      ja: "ソロモン諸島",
      it: "Isole Salomone",
      hu: "Salamon-szigetek",
    },
    flag: "https://flagcdn.com/sb.svg",
    cioc: "SOL",
    independent: true,
  },
  {
    name: "Somalia",
    topLevelDomain: [".so"],
    alpha2Code: "SO",
    alpha3Code: "SOM",
    callingCodes: ["252"],
    capital: "Mogadishu",
    altSpellings: ["SO", "aṣ-Ṣūmāl", "Federal Republic of Somalia", "Jamhuuriyadda Federaalka Soomaaliya", "Jumhūriyyat aṣ-Ṣūmāl al-Fiderāliyya"],
    subregion: "Eastern Africa",
    region: "Africa",
    population: 15893219,
    latlng: [10, 49],
    demonym: "Somali",
    area: 637657,
    gini: 36.8,
    timezones: ["UTC+03:00"],
    borders: ["DJI", "ETH", "KEN"],
    nativeName: "Soomaaliya",
    numericCode: "706",
    flags: {
      svg: "https://flagcdn.com/so.svg",
      png: "https://flagcdn.com/w320/so.png",
    },
    currencies: [
      {
        code: "SOS",
        name: "Somali shilling",
        symbol: "Sh",
      },
    ],
    languages: [
      {
        iso639_1: "so",
        iso639_2: "som",
        name: "Somali",
        nativeName: "Soomaaliga",
      },
      {
        iso639_1: "ar",
        iso639_2: "ara",
        name: "Arabic",
        nativeName: "العربية",
      },
    ],
    translations: {
      br: "Somália",
      pt: "Somália",
      nl: "Somalië",
      hr: "Somalija",
      fa: "سومالی",
      de: "Somalia",
      es: "Somalia",
      fr: "Somalie",
      ja: "ソマリア",
      it: "Somalia",
      hu: "Szomália",
    },
    flag: "https://flagcdn.com/so.svg",
    regionalBlocs: [
      {
        acronym: "AU",
        name: "African Union",
        otherNames: ["الاتحاد الأفريقي", "Union africaine", "União Africana", "Unión Africana", "Umoja wa Afrika"],
      },
      {
        acronym: "AL",
        name: "Arab League",
        otherNames: ["جامعة الدول العربية", "Jāmiʻat ad-Duwal al-ʻArabīyah", "League of Arab States"],
      },
    ],
    cioc: "SOM",
    independent: true,
  },
  {
    name: "South Africa",
    topLevelDomain: [".za"],
    alpha2Code: "ZA",
    alpha3Code: "ZAF",
    callingCodes: ["27"],
    capital: "Pretoria",
    altSpellings: ["ZA", "RSA", "Suid-Afrika", "Republic of South Africa"],
    subregion: "Southern Africa",
    region: "Africa",
    population: 59308690,
    latlng: [-29, 24],
    demonym: "South African",
    area: 1221037,
    gini: 63,
    timezones: ["UTC+02:00"],
    borders: ["BWA", "LSO", "MOZ", "NAM", "SWZ", "ZWE"],
    nativeName: "South Africa",
    numericCode: "710",
    flags: {
      svg: "https://flagcdn.com/za.svg",
      png: "https://flagcdn.com/w320/za.png",
    },
    currencies: [
      {
        code: "ZAR",
        name: "South African rand",
        symbol: "R",
      },
    ],
    languages: [
      {
        iso639_1: "af",
        iso639_2: "afr",
        name: "Afrikaans",
        nativeName: "Afrikaans",
      },
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
      {
        iso639_1: "nr",
        iso639_2: "nbl",
        name: "Southern Ndebele",
        nativeName: "isiNdebele",
      },
      {
        iso639_1: "st",
        iso639_2: "sot",
        name: "Southern Sotho",
        nativeName: "Sesotho",
      },
      {
        iso639_1: "ss",
        iso639_2: "ssw",
        name: "Swati",
        nativeName: "SiSwati",
      },
      {
        iso639_1: "tn",
        iso639_2: "tsn",
        name: "Tswana",
        nativeName: "Setswana",
      },
      {
        iso639_1: "ts",
        iso639_2: "tso",
        name: "Tsonga",
        nativeName: "Xitsonga",
      },
      {
        iso639_1: "ve",
        iso639_2: "ven",
        name: "Venda",
        nativeName: "Tshivenḓa",
      },
      {
        iso639_1: "xh",
        iso639_2: "xho",
        name: "Xhosa",
        nativeName: "isiXhosa",
      },
      {
        iso639_1: "zu",
        iso639_2: "zul",
        name: "Zulu",
        nativeName: "isiZulu",
      },
    ],
    translations: {
      br: "República Sul-Africana",
      pt: "República Sul-Africana",
      nl: "Zuid-Afrika",
      hr: "Južnoafrička Republika",
      fa: "آفریقای جنوبی",
      de: "Republik Südafrika",
      es: "República de Sudáfrica",
      fr: "Afrique du Sud",
      ja: "南アフリカ",
      it: "Sud Africa",
      hu: "Dél-afrikai Köztársaság",
    },
    flag: "https://flagcdn.com/za.svg",
    regionalBlocs: [
      {
        acronym: "AU",
        name: "African Union",
        otherNames: ["الاتحاد الأفريقي", "Union africaine", "União Africana", "Unión Africana", "Umoja wa Afrika"],
      },
    ],
    cioc: "RSA",
    independent: true,
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    topLevelDomain: [".gs"],
    alpha2Code: "GS",
    alpha3Code: "SGS",
    callingCodes: ["500"],
    capital: "King Edward Point",
    altSpellings: ["GS", "South Georgia and the South Sandwich Islands"],
    subregion: "South America",
    region: "Americas",
    population: 30,
    latlng: [-54.5, -37],
    demonym: "South Georgia and the South Sandwich Islander",
    timezones: ["UTC-02:00"],
    nativeName: "South Georgia",
    numericCode: "239",
    flags: {
      svg: "https://flagcdn.com/gs.svg",
      png: "https://flagcdn.com/w320/gs.png",
    },
    currencies: [
      {
        code: "FKP",
        name: "Falkland Islands Pound",
        symbol: "£",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
    ],
    translations: {
      br: "Ilhas Geórgias do Sul e Sandwich do Sul",
      pt: "Ilhas Geórgia do Sul e Sanduíche do Sul",
      nl: "Zuid-Georgia en Zuidelijke Sandwicheilanden",
      hr: "Južna Georgija i otočje Južni Sandwich",
      fa: "جزایر جورجیای جنوبی و ساندویچ جنوبی",
      de: "Südgeorgien und die Südlichen Sandwichinseln",
      es: "Islas Georgias del Sur y Sandwich del Sur",
      fr: "Géorgie du Sud-et-les Îles Sandwich du Sud",
      ja: "サウスジョージア・サウスサンドウィッチ諸島",
      it: "Georgia del Sud e Isole Sandwich Meridionali",
      hu: "Díli-Georgia ís Díli-Sandwich-szigetek",
    },
    flag: "https://flagcdn.com/gs.svg",
    regionalBlocs: [
      {
        acronym: "USAN",
        name: "Union of South American Nations",
        otherAcronyms: ["UNASUR", "UNASUL", "UZAN"],
        otherNames: ["Unión de Naciones Suramericanas", "União de Nações Sul-Americanas", "Unie van Zuid-Amerikaanse Naties", "South American Union"],
      },
    ],
    independent: false,
  },
  {
    name: "Korea (Republic of)",
    topLevelDomain: [".kr"],
    alpha2Code: "KR",
    alpha3Code: "KOR",
    callingCodes: ["82"],
    capital: "Seoul",
    altSpellings: ["KR", "Republic of Korea"],
    subregion: "Eastern Asia",
    region: "Asia",
    population: 51780579,
    latlng: [37, 127.5],
    demonym: "South Korean",
    area: 100210,
    gini: 31.4,
    timezones: ["UTC+09:00"],
    borders: ["PRK"],
    nativeName: "대한민국",
    numericCode: "410",
    flags: {
      svg: "https://flagcdn.com/kr.svg",
      png: "https://flagcdn.com/w320/kr.png",
    },
    currencies: [
      {
        code: "KRW",
        name: "South Korean won",
        symbol: "₩",
      },
    ],
    languages: [
      {
        iso639_1: "ko",
        iso639_2: "kor",
        name: "Korean",
        nativeName: "한국어",
      },
    ],
    translations: {
      br: "Coreia do Sul",
      pt: "Coreia do Sul",
      nl: "Zuid-Korea",
      hr: "Južna Koreja",
      fa: "کره شمالی",
      de: "Südkorea",
      es: "Corea del Sur",
      fr: "Corée du Sud",
      ja: "大韓民国",
      it: "Corea del Sud",
      hu: "Díl-Korea",
    },
    flag: "https://flagcdn.com/kr.svg",
    cioc: "KOR",
    independent: true,
  },
  {
    name: "Spain",
    topLevelDomain: [".es"],
    alpha2Code: "ES",
    alpha3Code: "ESP",
    callingCodes: ["34"],
    capital: "Madrid",
    altSpellings: ["ES", "Kingdom of Spain", "Reino de España"],
    subregion: "Southern Europe",
    region: "Europe",
    population: 47351567,
    latlng: [40, -4],
    demonym: "Spanish",
    area: 505992,
    gini: 34.7,
    timezones: ["UTC", "UTC+01:00"],
    borders: ["AND", "FRA", "GIB", "PRT", "MAR"],
    nativeName: "España",
    numericCode: "724",
    flags: {
      svg: "https://flagcdn.com/es.svg",
      png: "https://flagcdn.com/w320/es.png",
    },
    currencies: [
      {
        code: "EUR",
        name: "Euro",
        symbol: "€",
      },
    ],
    languages: [
      {
        iso639_1: "es",
        iso639_2: "spa",
        name: "Spanish",
        nativeName: "Español",
      },
    ],
    translations: {
      br: "Espanha",
      pt: "Espanha",
      nl: "Spanje",
      hr: "Španjolska",
      fa: "اسپانیا",
      de: "Spanien",
      es: "España",
      fr: "Espagne",
      ja: "スペイン",
      it: "Spagna",
      hu: "Spanyolország",
    },
    flag: "https://flagcdn.com/es.svg",
    regionalBlocs: [
      {
        acronym: "EU",
        name: "European Union",
      },
    ],
    cioc: "ESP",
    independent: true,
  },
  {
    name: "Sri Lanka",
    topLevelDomain: [".lk"],
    alpha2Code: "LK",
    alpha3Code: "LKA",
    callingCodes: ["94"],
    capital: "Sri Jayawardenepura Kotte",
    altSpellings: ["LK", "ilaṅkai", "Democratic Socialist Republic of Sri Lanka"],
    subregion: "Southern Asia",
    region: "Asia",
    population: 21919000,
    latlng: [7, 81],
    demonym: "Sri Lankan",
    area: 65610,
    gini: 39.3,
    timezones: ["UTC+05:30"],
    borders: ["IND"],
    nativeName: "śrī laṃkāva",
    numericCode: "144",
    flags: {
      svg: "https://flagcdn.com/lk.svg",
      png: "https://flagcdn.com/w320/lk.png",
    },
    currencies: [
      {
        code: "LKR",
        name: "Sri Lankan rupee",
        symbol: "Rs",
      },
    ],
    languages: [
      {
        iso639_1: "si",
        iso639_2: "sin",
        name: "Sinhalese",
        nativeName: "සිංහල",
      },
      {
        iso639_1: "ta",
        iso639_2: "tam",
        name: "Tamil",
        nativeName: "தமிழ்",
      },
    ],
    translations: {
      br: "Sri Lanka",
      pt: "Sri Lanka",
      nl: "Sri Lanka",
      hr: "Šri Lanka",
      fa: "سری‌لانکا",
      de: "Sri Lanka",
      es: "Sri Lanka",
      fr: "Sri Lanka",
      ja: "スリランカ",
      it: "Sri Lanka",
      hu: "Srí Lanka",
    },
    flag: "https://flagcdn.com/lk.svg",
    regionalBlocs: [
      {
        acronym: "SAARC",
        name: "South Asian Association for Regional Cooperation",
      },
    ],
    cioc: "SRI",
    independent: true,
  },
  {
    name: "Sudan",
    topLevelDomain: [".sd"],
    alpha2Code: "SD",
    alpha3Code: "SDN",
    callingCodes: ["249"],
    capital: "Khartoum",
    altSpellings: ["SD", "Republic of the Sudan", "Jumhūrīyat as-Sūdān"],
    subregion: "Northern Africa",
    region: "Africa",
    population: 43849269,
    latlng: [15, 30],
    demonym: "Sudanese",
    area: 1886068,
    gini: 34.2,
    timezones: ["UTC+03:00"],
    borders: ["CAF", "TCD", "EGY", "ERI", "ETH", "LBY", "SSD"],
    nativeName: "السودان",
    numericCode: "729",
    flags: {
      svg: "https://flagcdn.com/sd.svg",
      png: "https://flagcdn.com/w320/sd.png",
    },
    currencies: [
      {
        code: "SDG",
        name: "Sudanese pound",
        symbol: "ج.س.",
      },
    ],
    languages: [
      {
        iso639_1: "ar",
        iso639_2: "ara",
        name: "Arabic",
        nativeName: "العربية",
      },
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
    ],
    translations: {
      br: "Sudão",
      pt: "Sudão",
      nl: "Soedan",
      hr: "Sudan",
      fa: "سودان",
      de: "Sudan",
      es: "Sudán",
      fr: "Soudan",
      ja: "スーダン",
      it: "Sudan",
      hu: "Szudán",
    },
    flag: "https://flagcdn.com/sd.svg",
    regionalBlocs: [
      {
        acronym: "AU",
        name: "African Union",
        otherNames: ["الاتحاد الأفريقي", "Union africaine", "União Africana", "Unión Africana", "Umoja wa Afrika"],
      },
      {
        acronym: "AL",
        name: "Arab League",
        otherNames: ["جامعة الدول العربية", "Jāmiʻat ad-Duwal al-ʻArabīyah", "League of Arab States"],
      },
    ],
    cioc: "SUD",
    independent: true,
  },
  {
    name: "South Sudan",
    topLevelDomain: [".ss"],
    alpha2Code: "SS",
    alpha3Code: "SSD",
    callingCodes: ["211"],
    capital: "Juba",
    altSpellings: ["SS"],
    subregion: "Middle Africa",
    region: "Africa",
    population: 11193729,
    latlng: [7, 30],
    demonym: "South Sudanese",
    area: 619745,
    gini: 44.1,
    timezones: ["UTC+03:00"],
    borders: ["CAF", "COD", "ETH", "KEN", "SDN", "UGA"],
    nativeName: "South Sudan",
    numericCode: "728",
    flags: {
      svg: "https://flagcdn.com/ss.svg",
      png: "https://flagcdn.com/w320/ss.png",
    },
    currencies: [
      {
        code: "SSP",
        name: "South Sudanese pound",
        symbol: "£",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
    ],
    translations: {
      br: "Sudão do Sul",
      pt: "Sudão do Sul",
      nl: "Zuid-Soedan",
      hr: "Južni Sudan",
      fa: "سودان جنوبی",
      de: "Südsudan",
      es: "Sudán del Sur",
      fr: "Soudan du Sud",
      ja: "南スーダン",
      it: "Sudan del sud",
      hu: "Dél-Szudán",
    },
    flag: "https://flagcdn.com/ss.svg",
    regionalBlocs: [
      {
        acronym: "AU",
        name: "African Union",
        otherNames: ["الاتحاد الأفريقي", "Union africaine", "União Africana", "Unión Africana", "Umoja wa Afrika"],
      },
    ],
    cioc: "SSD",
    independent: true,
  },
  {
    name: "Suriname",
    topLevelDomain: [".sr"],
    alpha2Code: "SR",
    alpha3Code: "SUR",
    callingCodes: ["597"],
    capital: "Paramaribo",
    altSpellings: ["SR", "Sarnam", "Sranangron", "Republic of Suriname", "Republiek Suriname"],
    subregion: "South America",
    region: "Americas",
    population: 586634,
    latlng: [4, -56],
    demonym: "Surinamer",
    area: 163820,
    gini: 57.9,
    timezones: ["UTC-03:00"],
    borders: ["BRA", "FRA", "GUF", "GUY"],
    nativeName: "Suriname",
    numericCode: "740",
    flags: {
      svg: "https://flagcdn.com/sr.svg",
      png: "https://flagcdn.com/w320/sr.png",
    },
    currencies: [
      {
        code: "SRD",
        name: "Surinamese dollar",
        symbol: "$",
      },
    ],
    languages: [
      {
        iso639_1: "nl",
        iso639_2: "nld",
        name: "Dutch",
        nativeName: "Nederlands",
      },
    ],
    translations: {
      br: "Suriname",
      pt: "Suriname",
      nl: "Suriname",
      hr: "Surinam",
      fa: "سورینام",
      de: "Suriname",
      es: "Surinam",
      fr: "Surinam",
      ja: "スリナム",
      it: "Suriname",
      hu: "Suriname",
    },
    flag: "https://flagcdn.com/sr.svg",
    regionalBlocs: [
      {
        acronym: "CARICOM",
        name: "Caribbean Community",
        otherNames: ["Comunidad del Caribe", "Communauté Caribéenne", "Caribische Gemeenschap"],
      },
      {
        acronym: "USAN",
        name: "Union of South American Nations",
        otherAcronyms: ["UNASUR", "UNASUL", "UZAN"],
        otherNames: ["Unión de Naciones Suramericanas", "União de Nações Sul-Americanas", "Unie van Zuid-Amerikaanse Naties", "South American Union"],
      },
    ],
    cioc: "SUR",
    independent: true,
  },
  {
    name: "Svalbard and Jan Mayen",
    topLevelDomain: [".sj"],
    alpha2Code: "SJ",
    alpha3Code: "SJM",
    callingCodes: ["47"],
    capital: "Longyearbyen",
    altSpellings: ["SJ", "Svalbard and Jan Mayen Islands"],
    subregion: "Northern Europe",
    region: "Europe",
    population: 2562,
    latlng: [78, 20],
    demonym: "Norwegian",
    timezones: ["UTC+01:00"],
    nativeName: "Svalbard og Jan Mayen",
    numericCode: "744",
    flags: {
      svg: "https://flagcdn.com/sj.svg",
      png: "https://flagcdn.com/w320/sj.png",
    },
    currencies: [
      {
        code: "NOK",
        name: "Norwegian krone",
        symbol: "kr",
      },
    ],
    languages: [
      {
        iso639_1: "no",
        iso639_2: "nor",
        name: "Norwegian",
        nativeName: "Norsk",
      },
    ],
    translations: {
      br: "Svalbard",
      pt: "Svalbard",
      nl: "Svalbard en Jan Mayen",
      hr: "Svalbard i Jan Mayen",
      fa: "سوالبارد و یان ماین",
      de: "Svalbard und Jan Mayen",
      es: "Islas Svalbard y Jan Mayen",
      fr: "Svalbard et Jan Mayen",
      ja: "スヴァールバル諸島およびヤンマイエン島",
      it: "Svalbard e Jan Mayen",
      hu: "Spitzbergák ís Jan Mayen-szigetek",
    },
    flag: "https://flagcdn.com/sj.svg",
    independent: false,
  },
  {
    name: "Swaziland",
    topLevelDomain: [".sz"],
    alpha2Code: "SZ",
    alpha3Code: "SWZ",
    callingCodes: ["268"],
    capital: "Mbabane",
    altSpellings: ["SZ", "weSwatini", "Swatini", "Ngwane", "Kingdom of Swaziland", "Umbuso waseSwatini"],
    subregion: "Southern Africa",
    region: "Africa",
    population: 1160164,
    latlng: [-26.5, 31.5],
    demonym: "Swazi",
    area: 17364,
    gini: 54.6,
    timezones: ["UTC+02:00"],
    borders: ["MOZ", "ZAF"],
    nativeName: "Swaziland",
    numericCode: "748",
    flags: {
      svg: "https://flagcdn.com/sz.svg",
      png: "https://flagcdn.com/w320/sz.png",
    },
    currencies: [
      {
        code: "SZL",
        name: "Swazi lilangeni",
        symbol: "L",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
      {
        iso639_1: "ss",
        iso639_2: "ssw",
        name: "Swati",
        nativeName: "SiSwati",
      },
    ],
    translations: {
      br: "Suazilândia",
      pt: "Suazilândia",
      nl: "Swaziland",
      hr: "Svazi",
      fa: "سوازیلند",
      de: "Swasiland",
      es: "Suazilandia",
      fr: "Swaziland",
      ja: "スワジランド",
      it: "Swaziland",
      hu: "Szváziföld",
    },
    flag: "https://flagcdn.com/sz.svg",
    regionalBlocs: [
      {
        acronym: "AU",
        name: "African Union",
        otherNames: ["الاتحاد الأفريقي", "Union africaine", "União Africana", "Unión Africana", "Umoja wa Afrika"],
      },
    ],
    cioc: "SWZ",
    independent: true,
  },
  {
    name: "Sweden",
    topLevelDomain: [".se"],
    alpha2Code: "SE",
    alpha3Code: "SWE",
    callingCodes: ["46"],
    capital: "Stockholm",
    altSpellings: ["SE", "Kingdom of Sweden", "Konungariket Sverige"],
    subregion: "Northern Europe",
    region: "Europe",
    population: 10353442,
    latlng: [62, 15],
    demonym: "Swedish",
    area: 450295,
    gini: 30,
    timezones: ["UTC+01:00"],
    borders: ["FIN", "NOR"],
    nativeName: "Sverige",
    numericCode: "752",
    flags: {
      svg: "https://flagcdn.com/se.svg",
      png: "https://flagcdn.com/w320/se.png",
    },
    currencies: [
      {
        code: "SEK",
        name: "Swedish krona",
        symbol: "kr",
      },
    ],
    languages: [
      {
        iso639_1: "sv",
        iso639_2: "swe",
        name: "Swedish",
        nativeName: "svenska",
      },
    ],
    translations: {
      br: "Suécia",
      pt: "Suécia",
      nl: "Zweden",
      hr: "Švedska",
      fa: "سوئد",
      de: "Schweden",
      es: "Suecia",
      fr: "Suède",
      ja: "スウェーデン",
      it: "Svezia",
      hu: "Svídország",
    },
    flag: "https://flagcdn.com/se.svg",
    regionalBlocs: [
      {
        acronym: "EU",
        name: "European Union",
      },
    ],
    cioc: "SWE",
    independent: true,
  },
  {
    name: "Switzerland",
    topLevelDomain: [".ch"],
    alpha2Code: "CH",
    alpha3Code: "CHE",
    callingCodes: ["41"],
    capital: "Bern",
    altSpellings: ["CH", "Swiss Confederation", "Schweiz", "Suisse", "Svizzera", "Svizra"],
    subregion: "Central Europe",
    region: "Europe",
    population: 8636896,
    latlng: [47, 8],
    demonym: "Swiss",
    area: 41284,
    gini: 33.1,
    timezones: ["UTC+01:00"],
    borders: ["AUT", "FRA", "ITA", "LIE", "DEU"],
    nativeName: "Schweiz",
    numericCode: "756",
    flags: {
      svg: "https://flagcdn.com/ch.svg",
      png: "https://flagcdn.com/w320/ch.png",
    },
    currencies: [
      {
        code: "CHF",
        name: "Swiss franc",
        symbol: "Fr",
      },
    ],
    languages: [
      {
        iso639_1: "de",
        iso639_2: "deu",
        name: "German",
        nativeName: "Deutsch",
      },
      {
        iso639_1: "fr",
        iso639_2: "fra",
        name: "French",
        nativeName: "français",
      },
      {
        iso639_1: "it",
        iso639_2: "ita",
        name: "Italian",
        nativeName: "Italiano",
      },
      {
        iso639_2: "roh",
        name: "Romansh",
      },
    ],
    translations: {
      br: "Suíça",
      pt: "Suíça",
      nl: "Zwitserland",
      hr: "Švicarska",
      fa: "سوئیس",
      de: "Schweiz",
      es: "Suiza",
      fr: "Suisse",
      ja: "スイス",
      it: "Svizzera",
      hu: "Svájc",
    },
    flag: "https://flagcdn.com/ch.svg",
    regionalBlocs: [
      {
        acronym: "EFTA",
        name: "European Free Trade Association",
      },
    ],
    cioc: "SUI",
    independent: true,
  },
  {
    name: "Syrian Arab Republic",
    topLevelDomain: [".sy"],
    alpha2Code: "SY",
    alpha3Code: "SYR",
    callingCodes: ["963"],
    capital: "Damascus",
    altSpellings: ["SY", "Syrian Arab Republic", "Al-Jumhūrīyah Al-ʻArabīyah As-Sūrīyah"],
    subregion: "Western Asia",
    region: "Asia",
    population: 17500657,
    latlng: [35, 38],
    demonym: "Syrian",
    area: 185180,
    gini: 37.5,
    timezones: ["UTC+02:00"],
    borders: ["IRQ", "ISR", "JOR", "LBN", "TUR"],
    nativeName: "سوريا",
    numericCode: "760",
    flags: {
      svg: "https://flagcdn.com/sy.svg",
      png: "https://flagcdn.com/w320/sy.png",
    },
    currencies: [
      {
        code: "SYP",
        name: "Syrian pound",
        symbol: "£",
      },
    ],
    languages: [
      {
        iso639_1: "ar",
        iso639_2: "ara",
        name: "Arabic",
        nativeName: "العربية",
      },
    ],
    translations: {
      br: "Síria",
      pt: "Síria",
      nl: "Syrië",
      hr: "Sirija",
      fa: "سوریه",
      de: "Syrien",
      es: "Siria",
      fr: "Syrie",
      ja: "シリア・アラブ共和国",
      it: "Siria",
      hu: "Szőria",
    },
    flag: "https://flagcdn.com/sy.svg",
    regionalBlocs: [
      {
        acronym: "AL",
        name: "Arab League",
        otherNames: ["جامعة الدول العربية", "Jāmiʻat ad-Duwal al-ʻArabīyah", "League of Arab States"],
      },
    ],
    cioc: "SYR",
    independent: true,
  },
  {
    name: "Taiwan",
    topLevelDomain: [".tw"],
    alpha2Code: "TW",
    alpha3Code: "TWN",
    callingCodes: ["886"],
    capital: "Taipei",
    altSpellings: ["TW", "Táiwān", "Republic of China", "中華民國", "Zhōnghuá Mínguó"],
    subregion: "Eastern Asia",
    region: "Asia",
    population: 23503349,
    latlng: [23.5, 121],
    demonym: "Taiwanese",
    area: 36193,
    timezones: ["UTC+08:00"],
    nativeName: "臺灣",
    numericCode: "158",
    flags: {
      svg: "https://flagcdn.com/tw.svg",
      png: "https://flagcdn.com/w320/tw.png",
    },
    currencies: [
      {
        code: "TWD",
        name: "New Taiwan dollar",
        symbol: "$",
      },
    ],
    languages: [
      {
        iso639_1: "zh",
        iso639_2: "zho",
        name: "Chinese",
        nativeName: "中文 (Zhōngwén)",
      },
    ],
    translations: {
      br: "Taiwan",
      pt: "Taiwan",
      nl: "Taiwan",
      hr: "Tajvan",
      fa: "تایوان",
      de: "Taiwan",
      es: "Taiwán",
      fr: "Taïwan",
      ja: "台湾（中華民国）",
      it: "Taiwan",
      hu: "Tajvan",
    },
    flag: "https://flagcdn.com/tw.svg",
    cioc: "TPE",
    independent: true,
  },
  {
    name: "Tajikistan",
    topLevelDomain: [".tj"],
    alpha2Code: "TJ",
    alpha3Code: "TJK",
    callingCodes: ["992"],
    capital: "Dushanbe",
    altSpellings: ["TJ", "Toçikiston", "Republic of Tajikistan", "Ҷумҳурии Тоҷикистон", "Çumhuriyi Toçikiston"],
    subregion: "Central Asia",
    region: "Asia",
    population: 9537642,
    latlng: [39, 71],
    demonym: "Tadzhik",
    area: 143100,
    gini: 34,
    timezones: ["UTC+05:00"],
    borders: ["AFG", "CHN", "KGZ", "UZB"],
    nativeName: "Тоҷикистон",
    numericCode: "762",
    flags: {
      svg: "https://flagcdn.com/tj.svg",
      png: "https://flagcdn.com/w320/tj.png",
    },
    currencies: [
      {
        code: "TJS",
        name: "Tajikistani somoni",
        symbol: "ЅМ",
      },
    ],
    languages: [
      {
        iso639_1: "tg",
        iso639_2: "tgk",
        name: "Tajik",
        nativeName: "тоҷикӣ",
      },
      {
        iso639_1: "ru",
        iso639_2: "rus",
        name: "Russian",
        nativeName: "Русский",
      },
    ],
    translations: {
      br: "Tajiquistão",
      pt: "Tajiquistão",
      nl: "Tadzjikistan",
      hr: "Tađikistan",
      fa: "تاجیکستان",
      de: "Tadschikistan",
      es: "Tayikistán",
      fr: "Tadjikistan",
      ja: "タジキスタン",
      it: "Tagikistan",
      hu: "Tádzsikisztán",
    },
    flag: "https://flagcdn.com/tj.svg",
    cioc: "TJK",
    independent: true,
  },
  {
    name: "Tanzania, United Republic of",
    topLevelDomain: [".tz"],
    alpha2Code: "TZ",
    alpha3Code: "TZA",
    callingCodes: ["255"],
    capital: "Dodoma",
    altSpellings: ["TZ", "United Republic of Tanzania", "Jamhuri ya Muungano wa Tanzania"],
    subregion: "Eastern Africa",
    region: "Africa",
    population: 59734213,
    latlng: [-6, 35],
    demonym: "Tanzanian",
    area: 945087,
    gini: 40.5,
    timezones: ["UTC+03:00"],
    borders: ["BDI", "COD", "KEN", "MWI", "MOZ", "RWA", "UGA", "ZMB"],
    nativeName: "Tanzania",
    numericCode: "834",
    flags: {
      svg: "https://flagcdn.com/tz.svg",
      png: "https://flagcdn.com/w320/tz.png",
    },
    currencies: [
      {
        code: "TZS",
        name: "Tanzanian shilling",
        symbol: "Sh",
      },
    ],
    languages: [
      {
        iso639_1: "sw",
        iso639_2: "swa",
        name: "Swahili",
        nativeName: "Kiswahili",
      },
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
    ],
    translations: {
      br: "Tanzânia",
      pt: "Tanzânia",
      nl: "Tanzania",
      hr: "Tanzanija",
      fa: "تانزانیا",
      de: "Tansania",
      es: "Tanzania",
      fr: "Tanzanie",
      ja: "タンザニア",
      it: "Tanzania",
      hu: "Tádzsikisztán",
    },
    flag: "https://flagcdn.com/tz.svg",
    regionalBlocs: [
      {
        acronym: "AU",
        name: "African Union",
        otherNames: ["الاتحاد الأفريقي", "Union africaine", "União Africana", "Unión Africana", "Umoja wa Afrika"],
      },
    ],
    cioc: "TAN",
    independent: true,
  },
  {
    name: "Thailand",
    topLevelDomain: [".th"],
    alpha2Code: "TH",
    alpha3Code: "THA",
    callingCodes: ["66"],
    capital: "Bangkok",
    altSpellings: ["TH", "Prathet", "Thai", "Kingdom of Thailand", "ราชอาณาจักรไทย", "Ratcha Anachak Thai"],
    subregion: "South-Eastern Asia",
    region: "Asia",
    population: 69799978,
    latlng: [15, 100],
    demonym: "Thai",
    area: 513120,
    gini: 34.9,
    timezones: ["UTC+07:00"],
    borders: ["MMR", "KHM", "LAO", "MYS"],
    nativeName: "ประเทศไทย",
    numericCode: "764",
    flags: {
      svg: "https://flagcdn.com/th.svg",
      png: "https://flagcdn.com/w320/th.png",
    },
    currencies: [
      {
        code: "THB",
        name: "Thai baht",
        symbol: "฿",
      },
    ],
    languages: [
      {
        iso639_1: "th",
        iso639_2: "tha",
        name: "Thai",
        nativeName: "ไทย",
      },
    ],
    translations: {
      br: "Tailândia",
      pt: "Tailândia",
      nl: "Thailand",
      hr: "Tajland",
      fa: "تایلند",
      de: "Thailand",
      es: "Tailandia",
      fr: "Thaïlande",
      ja: "タイ",
      it: "Tailandia",
      hu: "Thaiföld",
    },
    flag: "https://flagcdn.com/th.svg",
    regionalBlocs: [
      {
        acronym: "ASEAN",
        name: "Association of Southeast Asian Nations",
      },
    ],
    cioc: "THA",
    independent: true,
  },
  {
    name: "Timor-Leste",
    topLevelDomain: [".tl"],
    alpha2Code: "TL",
    alpha3Code: "TLS",
    callingCodes: ["670"],
    capital: "Dili",
    altSpellings: ["TL", "East Timor", "Democratic Republic of Timor-Leste", "República Democrática de Timor-Leste", "Repúblika Demokrátika Timór-Leste"],
    subregion: "South-Eastern Asia",
    region: "Asia",
    population: 1318442,
    latlng: [-8.83333333, 125.91666666],
    demonym: "East Timorese",
    area: 14874,
    gini: 28.7,
    timezones: ["UTC+09:00"],
    borders: ["IDN"],
    nativeName: "Timor-Leste",
    numericCode: "626",
    flags: {
      svg: "https://flagcdn.com/tl.svg",
      png: "https://flagcdn.com/w320/tl.png",
    },
    currencies: [
      {
        code: "THB",
        name: "Thai baht",
        symbol: "฿",
      },
    ],
    languages: [
      {
        iso639_1: "pt",
        iso639_2: "por",
        name: "Portuguese",
        nativeName: "Português",
      },
    ],
    translations: {
      br: "Timor Leste",
      pt: "Timor Leste",
      nl: "Oost-Timor",
      hr: "Istočni Timor",
      fa: "تیمور شرقی",
      de: "Timor-Leste",
      es: "Timor Oriental",
      fr: "Timor oriental",
      ja: "東ティモール",
      it: "Timor Est",
      hu: "Kelet-Timor",
    },
    flag: "https://flagcdn.com/tl.svg",
    cioc: "TLS",
    independent: true,
  },
  {
    name: "Togo",
    topLevelDomain: [".tg"],
    alpha2Code: "TG",
    alpha3Code: "TGO",
    callingCodes: ["228"],
    capital: "Lomé",
    altSpellings: ["TG", "Togolese", "Togolese Republic", "République Togolaise"],
    subregion: "Western Africa",
    region: "Africa",
    population: 8278737,
    latlng: [8, 1.16666666],
    demonym: "Togolese",
    area: 56785,
    gini: 43.1,
    timezones: ["UTC"],
    borders: ["BEN", "BFA", "GHA"],
    nativeName: "Togo",
    numericCode: "768",
    flags: {
      svg: "https://flagcdn.com/tg.svg",
      png: "https://flagcdn.com/w320/tg.png",
    },
    currencies: [
      {
        code: "XOF",
        name: "West African CFA franc",
        symbol: "Fr",
      },
    ],
    languages: [
      {
        iso639_1: "fr",
        iso639_2: "fra",
        name: "French",
        nativeName: "français",
      },
    ],
    translations: {
      br: "Togo",
      pt: "Togo",
      nl: "Togo",
      hr: "Togo",
      fa: "توگو",
      de: "Togo",
      es: "Togo",
      fr: "Togo",
      ja: "トーゴ",
      it: "Togo",
      hu: "Togo",
    },
    flag: "https://flagcdn.com/tg.svg",
    regionalBlocs: [
      {
        acronym: "AU",
        name: "African Union",
        otherNames: ["الاتحاد الأفريقي", "Union africaine", "União Africana", "Unión Africana", "Umoja wa Afrika"],
      },
    ],
    cioc: "TOG",
    independent: true,
  },
  {
    name: "Tokelau",
    topLevelDomain: [".tk"],
    alpha2Code: "TK",
    alpha3Code: "TKL",
    callingCodes: ["690"],
    capital: "Fakaofo",
    altSpellings: ["TK"],
    subregion: "Polynesia",
    region: "Oceania",
    population: 1411,
    latlng: [-9, -172],
    demonym: "Tokelauan",
    area: 12,
    timezones: ["UTC+13:00"],
    nativeName: "Tokelau",
    numericCode: "772",
    flags: {
      svg: "https://flagcdn.com/tk.svg",
      png: "https://flagcdn.com/w320/tk.png",
    },
    currencies: [
      {
        code: "NZD",
        name: "New Zealand dollar",
        symbol: "$",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
    ],
    translations: {
      br: "Tokelau",
      pt: "Toquelau",
      nl: "Tokelau",
      hr: "Tokelau",
      fa: "توکلائو",
      de: "Tokelau",
      es: "Islas Tokelau",
      fr: "Tokelau",
      ja: "トケラウ",
      it: "Isole Tokelau",
      hu: "Tokelau-szigetek",
    },
    flag: "https://flagcdn.com/tk.svg",
    independent: false,
  },
  {
    name: "Tonga",
    topLevelDomain: [".to"],
    alpha2Code: "TO",
    alpha3Code: "TON",
    callingCodes: ["676"],
    capital: "Nuku'alofa",
    altSpellings: ["TO"],
    subregion: "Polynesia",
    region: "Oceania",
    population: 105697,
    latlng: [-20, -175],
    demonym: "Tongan",
    area: 747,
    gini: 37.6,
    timezones: ["UTC+13:00"],
    nativeName: "Tonga",
    numericCode: "776",
    flags: {
      svg: "https://flagcdn.com/to.svg",
      png: "https://flagcdn.com/w320/to.png",
    },
    currencies: [
      {
        code: "TOP",
        name: "Tongan paʻanga",
        symbol: "T$",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
      {
        iso639_1: "to",
        iso639_2: "ton",
        name: "Tonga (Tonga Islands)",
        nativeName: "faka Tonga",
      },
    ],
    translations: {
      br: "Tonga",
      pt: "Tonga",
      nl: "Tonga",
      hr: "Tonga",
      fa: "تونگا",
      de: "Tonga",
      es: "Tonga",
      fr: "Tonga",
      ja: "トンガ",
      it: "Tonga",
      hu: "Tonga",
    },
    flag: "https://flagcdn.com/to.svg",
    cioc: "TGA",
    independent: true,
  },
  {
    name: "Trinidad and Tobago",
    topLevelDomain: [".tt"],
    alpha2Code: "TT",
    alpha3Code: "TTO",
    callingCodes: ["1"],
    capital: "Port of Spain",
    altSpellings: ["TT", "Republic of Trinidad and Tobago"],
    subregion: "Caribbean",
    region: "Americas",
    population: 1399491,
    latlng: [11, -61],
    demonym: "Trinidadian",
    area: 5130,
    gini: 40.3,
    timezones: ["UTC-04:00"],
    nativeName: "Trinidad and Tobago",
    numericCode: "780",
    flags: {
      svg: "https://flagcdn.com/tt.svg",
      png: "https://flagcdn.com/w320/tt.png",
    },
    currencies: [
      {
        code: "TTD",
        name: "Trinidad and Tobago dollar",
        symbol: "$",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
    ],
    translations: {
      br: "Trinidad e Tobago",
      pt: "Trindade e Tobago",
      nl: "Trinidad en Tobago",
      hr: "Trinidad i Tobago",
      fa: "ترینیداد و توباگو",
      de: "Trinidad und Tobago",
      es: "Trinidad y Tobago",
      fr: "Trinité et Tobago",
      ja: "トリニダード・トバゴ",
      it: "Trinidad e Tobago",
      hu: "Trinidad ís Tobago",
    },
    flag: "https://flagcdn.com/tt.svg",
    regionalBlocs: [
      {
        acronym: "CARICOM",
        name: "Caribbean Community",
        otherNames: ["Comunidad del Caribe", "Communauté Caribéenne", "Caribische Gemeenschap"],
      },
    ],
    cioc: "TTO",
    independent: true,
  },
  {
    name: "Tunisia",
    topLevelDomain: [".tn"],
    alpha2Code: "TN",
    alpha3Code: "TUN",
    callingCodes: ["216"],
    capital: "Tunis",
    altSpellings: ["TN", "Republic of Tunisia", "al-Jumhūriyyah at-Tūnisiyyah"],
    subregion: "Northern Africa",
    region: "Africa",
    population: 11818618,
    latlng: [34, 9],
    demonym: "Tunisian",
    area: 163610,
    gini: 32.8,
    timezones: ["UTC+01:00"],
    borders: ["DZA", "LBY"],
    nativeName: "تونس",
    numericCode: "788",
    flags: {
      svg: "https://flagcdn.com/tn.svg",
      png: "https://flagcdn.com/w320/tn.png",
    },
    currencies: [
      {
        code: "TND",
        name: "Tunisian dinar",
        symbol: "د.ت",
      },
    ],
    languages: [
      {
        iso639_1: "ar",
        iso639_2: "ara",
        name: "Arabic",
        nativeName: "العربية",
      },
    ],
    translations: {
      br: "Tunísia",
      pt: "Tunísia",
      nl: "Tunesië",
      hr: "Tunis",
      fa: "تونس",
      de: "Tunesien",
      es: "Túnez",
      fr: "Tunisie",
      ja: "チュニジア",
      it: "Tunisia",
      hu: "Tunízia",
    },
    flag: "https://flagcdn.com/tn.svg",
    regionalBlocs: [
      {
        acronym: "AU",
        name: "African Union",
        otherNames: ["الاتحاد الأفريقي", "Union africaine", "União Africana", "Unión Africana", "Umoja wa Afrika"],
      },
      {
        acronym: "AL",
        name: "Arab League",
        otherNames: ["جامعة الدول العربية", "Jāmiʻat ad-Duwal al-ʻArabīyah", "League of Arab States"],
      },
    ],
    cioc: "TUN",
    independent: true,
  },
  {
    name: "Turkey",
    topLevelDomain: [".tr"],
    alpha2Code: "TR",
    alpha3Code: "TUR",
    callingCodes: ["90"],
    capital: "Ankara",
    altSpellings: ["TR", "Turkiye", "Republic of Turkey", "Türkiye Cumhuriyeti"],
    subregion: "Western Asia",
    region: "Asia",
    population: 84339067,
    latlng: [39, 35],
    demonym: "Turkish",
    area: 783562,
    gini: 41.9,
    timezones: ["UTC+03:00"],
    borders: ["ARM", "AZE", "BGR", "GEO", "GRC", "IRN", "IRQ", "SYR"],
    nativeName: "Türkiye",
    numericCode: "792",
    flags: {
      svg: "https://flagcdn.com/tr.svg",
      png: "https://flagcdn.com/w320/tr.png",
    },
    currencies: [
      {
        code: "TRY",
        name: "Turkish lira",
        symbol: "֏",
      },
    ],
    languages: [
      {
        iso639_1: "tr",
        iso639_2: "tur",
        name: "Turkish",
        nativeName: "Türkçe",
      },
    ],
    translations: {
      br: "Turquia",
      pt: "Turquia",
      nl: "Turkije",
      hr: "Turska",
      fa: "ترکیه",
      de: "Türkei",
      es: "Turquía",
      fr: "Turquie",
      ja: "トルコ",
      it: "Turchia",
      hu: "Törökország",
    },
    flag: "https://flagcdn.com/tr.svg",
    cioc: "TUR",
    independent: false,
  },
  {
    name: "Turkmenistan",
    topLevelDomain: [".tm"],
    alpha2Code: "TM",
    alpha3Code: "TKM",
    callingCodes: ["993"],
    capital: "Ashgabat",
    altSpellings: ["TM"],
    subregion: "Central Asia",
    region: "Asia",
    population: 6031187,
    latlng: [40, 60],
    demonym: "Turkmen",
    area: 488100,
    gini: 40.8,
    timezones: ["UTC+05:00"],
    borders: ["AFG", "IRN", "KAZ", "UZB"],
    nativeName: "Türkmenistan",
    numericCode: "795",
    flags: {
      svg: "https://flagcdn.com/tm.svg",
      png: "https://flagcdn.com/w320/tm.png",
    },
    currencies: [
      {
        code: "TMT",
        name: "Turkmenistan manat",
        symbol: "m",
      },
    ],
    languages: [
      {
        iso639_1: "tk",
        iso639_2: "tuk",
        name: "Turkmen",
        nativeName: "Türkmen",
      },
      {
        iso639_1: "ru",
        iso639_2: "rus",
        name: "Russian",
        nativeName: "Русский",
      },
    ],
    translations: {
      br: "Turcomenistão",
      pt: "Turquemenistão",
      nl: "Turkmenistan",
      hr: "Turkmenistan",
      fa: "ترکمنستان",
      de: "Turkmenistan",
      es: "Turkmenistán",
      fr: "Turkménistan",
      ja: "トルクメニスタン",
      it: "Turkmenistan",
      hu: "Türkmenisztán",
    },
    flag: "https://flagcdn.com/tm.svg",
    cioc: "TKM",
    independent: true,
  },
  {
    name: "Turks and Caicos Islands",
    topLevelDomain: [".tc"],
    alpha2Code: "TC",
    alpha3Code: "TCA",
    callingCodes: ["1"],
    capital: "Cockburn Town",
    altSpellings: ["TC"],
    subregion: "Caribbean",
    region: "Americas",
    population: 38718,
    latlng: [21.75, -71.58333333],
    demonym: "Turks and Caicos Islander",
    area: 948,
    timezones: ["UTC-04:00"],
    nativeName: "Turks and Caicos Islands",
    numericCode: "796",
    flags: {
      svg: "https://flagcdn.com/tc.svg",
      png: "https://flagcdn.com/w320/tc.png",
    },
    currencies: [
      {
        code: "USD",
        name: "United States dollar",
        symbol: "$",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
    ],
    translations: {
      br: "Ilhas Turcas e Caicos",
      pt: "Ilhas Turcas e Caicos",
      nl: "Turks- en Caicoseilanden",
      hr: "Otoci Turks i Caicos",
      fa: "جزایر تورکس و کایکوس",
      de: "Turks- und Caicosinseln",
      es: "Islas Turks y Caicos",
      fr: "Îles Turques-et-Caïques",
      ja: "タークス・カイコス諸島",
      it: "Isole Turks e Caicos",
      hu: "Turks- ís Caicos-szigetek",
    },
    flag: "https://flagcdn.com/tc.svg",
    independent: false,
  },
  {
    name: "Tuvalu",
    topLevelDomain: [".tv"],
    alpha2Code: "TV",
    alpha3Code: "TUV",
    callingCodes: ["688"],
    capital: "Funafuti",
    altSpellings: ["TV"],
    subregion: "Polynesia",
    region: "Oceania",
    population: 11792,
    latlng: [-8, 178],
    demonym: "Tuvaluan",
    area: 26,
    gini: 39.1,
    timezones: ["UTC+12:00"],
    nativeName: "Tuvalu",
    numericCode: "798",
    flags: {
      svg: "https://flagcdn.com/tv.svg",
      png: "https://flagcdn.com/w320/tv.png",
    },
    currencies: [
      {
        code: "AUD",
        name: "Australian dollar",
        symbol: "$",
      },
      {
        code: "TVD[G]",
        name: "Tuvaluan dollar",
        symbol: "$",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
    ],
    translations: {
      br: "Tuvalu",
      pt: "Tuvalu",
      nl: "Tuvalu",
      hr: "Tuvalu",
      fa: "تووالو",
      de: "Tuvalu",
      es: "Tuvalu",
      fr: "Tuvalu",
      ja: "ツバル",
      it: "Tuvalu",
      hu: "Tuvalu",
    },
    flag: "https://flagcdn.com/tv.svg",
    cioc: "TUV",
    independent: true,
  },
  {
    name: "Uganda",
    topLevelDomain: [".ug"],
    alpha2Code: "UG",
    alpha3Code: "UGA",
    callingCodes: ["256"],
    capital: "Kampala",
    altSpellings: ["UG", "Republic of Uganda", "Jamhuri ya Uganda"],
    subregion: "Eastern Africa",
    region: "Africa",
    population: 45741000,
    latlng: [1, 32],
    demonym: "Ugandan",
    area: 241550,
    gini: 42.8,
    timezones: ["UTC+03:00"],
    borders: ["COD", "KEN", "RWA", "SSD", "TZA"],
    nativeName: "Uganda",
    numericCode: "800",
    flags: {
      svg: "https://flagcdn.com/ug.svg",
      png: "https://flagcdn.com/w320/ug.png",
    },
    currencies: [
      {
        code: "UGX",
        name: "Ugandan shilling",
        symbol: "Sh",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
      {
        iso639_1: "sw",
        iso639_2: "swa",
        name: "Swahili",
        nativeName: "Kiswahili",
      },
    ],
    translations: {
      br: "Uganda",
      pt: "Uganda",
      nl: "Oeganda",
      hr: "Uganda",
      fa: "اوگاندا",
      de: "Uganda",
      es: "Uganda",
      fr: "Uganda",
      ja: "ウガンダ",
      it: "Uganda",
      hu: "Uganda",
    },
    flag: "https://flagcdn.com/ug.svg",
    regionalBlocs: [
      {
        acronym: "AU",
        name: "African Union",
        otherNames: ["الاتحاد الأفريقي", "Union africaine", "União Africana", "Unión Africana", "Umoja wa Afrika"],
      },
    ],
    cioc: "UGA",
    independent: true,
  },
  {
    name: "Ukraine",
    topLevelDomain: [".ua"],
    alpha2Code: "UA",
    alpha3Code: "UKR",
    callingCodes: ["380"],
    capital: "Kyiv",
    altSpellings: ["UA", "Ukrayina"],
    subregion: "Eastern Europe",
    region: "Europe",
    population: 44134693,
    latlng: [49, 32],
    demonym: "Ukrainian",
    area: 603700,
    gini: 26.6,
    timezones: ["UTC+02:00"],
    borders: ["BLR", "HUN", "MDA", "POL", "ROU", "RUS", "SVK"],
    nativeName: "Україна",
    numericCode: "804",
    flags: {
      svg: "https://flagcdn.com/ua.svg",
      png: "https://flagcdn.com/w320/ua.png",
    },
    currencies: [
      {
        code: "UAH",
        name: "Ukrainian hryvnia",
        symbol: "₴",
      },
    ],
    languages: [
      {
        iso639_1: "uk",
        iso639_2: "ukr",
        name: "Ukrainian",
        nativeName: "Українська",
      },
    ],
    translations: {
      br: "Ucrânia",
      pt: "Ucrânia",
      nl: "Oekraïne",
      hr: "Ukrajina",
      fa: "وکراین",
      de: "Ukraine",
      es: "Ucrania",
      fr: "Ukraine",
      ja: "ウクライナ",
      it: "Ucraina",
      hu: "Ukrajna",
    },
    flag: "https://flagcdn.com/ua.svg",
    cioc: "UKR",
    independent: true,
  },
  {
    name: "United Arab Emirates",
    topLevelDomain: [".ae"],
    alpha2Code: "AE",
    alpha3Code: "ARE",
    callingCodes: ["971"],
    capital: "Abu Dhabi",
    altSpellings: ["AE", "UAE"],
    subregion: "Western Asia",
    region: "Asia",
    population: 9890400,
    latlng: [24, 54],
    demonym: "Emirati",
    area: 83600,
    gini: 26,
    timezones: ["UTC+04"],
    borders: ["OMN", "SAU"],
    nativeName: "دولة الإمارات العربية المتحدة",
    numericCode: "784",
    flags: {
      svg: "https://flagcdn.com/ae.svg",
      png: "https://flagcdn.com/w320/ae.png",
    },
    currencies: [
      {
        code: "AED",
        name: "United Arab Emirates dirham",
        symbol: "د.إ",
      },
    ],
    languages: [
      {
        iso639_1: "ar",
        iso639_2: "ara",
        name: "Arabic",
        nativeName: "العربية",
      },
    ],
    translations: {
      br: "Emirados árabes Unidos",
      pt: "Emirados árabes Unidos",
      nl: "Verenigde Arabische Emiraten",
      hr: "Ujedinjeni Arapski Emirati",
      fa: "امارات متحده عربی",
      de: "Vereinigte Arabische Emirate",
      es: "Emiratos Árabes Unidos",
      fr: "Émirats arabes unis",
      ja: "アラブ首長国連邦",
      it: "Emirati Arabi Uniti",
      hu: "Egyesült Arab Emőrsígek",
    },
    flag: "https://flagcdn.com/ae.svg",
    regionalBlocs: [
      {
        acronym: "AL",
        name: "Arab League",
        otherNames: ["جامعة الدول العربية", "Jāmiʻat ad-Duwal al-ʻArabīyah", "League of Arab States"],
      },
    ],
    cioc: "UAE",
    independent: true,
  },
  {
    name: "United Kingdom of Great Britain and Northern Ireland",
    topLevelDomain: [".uk"],
    alpha2Code: "GB",
    alpha3Code: "GBR",
    callingCodes: ["44"],
    capital: "London",
    altSpellings: ["GB", "UK", "Great Britain"],
    subregion: "Northern Europe",
    region: "Europe",
    population: 67215293,
    latlng: [54, -2],
    demonym: "British",
    area: 242900,
    gini: 35.1,
    timezones: ["UTC-08:00", "UTC-05:00", "UTC-04:00", "UTC-03:00", "UTC-02:00", "UTC", "UTC+01:00", "UTC+02:00", "UTC+06:00"],
    borders: ["IRL"],
    nativeName: "United Kingdom",
    numericCode: "826",
    flags: {
      svg: "https://flagcdn.com/gb.svg",
      png: "https://flagcdn.com/w320/gb.png",
    },
    currencies: [
      {
        code: "GBP",
        name: "British pound",
        symbol: "£",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
    ],
    translations: {
      br: "Reino Unido",
      pt: "Reino Unido",
      nl: "Verenigd Koninkrijk",
      hr: "Ujedinjeno Kraljevstvo",
      fa: "بریتانیای کبیر و ایرلند شمالی",
      de: "Vereinigtes Königreich",
      es: "Reino Unido",
      fr: "Royaume-Uni",
      ja: "イギリス",
      it: "Regno Unito",
      hu: "Nagy-Britannia",
    },
    flag: "https://flagcdn.com/gb.svg",
    cioc: "GBR",
    independent: true,
  },
  {
    name: "United States of America",
    topLevelDomain: [".us"],
    alpha2Code: "US",
    alpha3Code: "USA",
    callingCodes: ["1"],
    capital: "Washington, D.C.",
    altSpellings: ["US", "USA", "United States of America"],
    subregion: "Northern America",
    region: "Americas",
    population: 329484123,
    latlng: [38, -97],
    demonym: "American",
    area: 9629091,
    gini: 41.4,
    timezones: ["UTC-12:00", "UTC-11:00", "UTC-10:00", "UTC-09:00", "UTC-08:00", "UTC-07:00", "UTC-06:00", "UTC-05:00", "UTC-04:00", "UTC+10:00", "UTC+12:00"],
    borders: ["CAN", "MEX"],
    nativeName: "United States",
    numericCode: "840",
    flags: {
      svg: "https://flagcdn.com/us.svg",
      png: "https://flagcdn.com/w320/us.png",
    },
    currencies: [
      {
        code: "USD",
        name: "United States dollar",
        symbol: "$",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
    ],
    translations: {
      br: "Estados Unidos",
      pt: "Estados Unidos",
      nl: "Verenigde Staten",
      hr: "Sjedinjene Američke Države",
      fa: "ایالات متحده آمریکا",
      de: "Vereinigte Staaten von Amerika",
      es: "Estados Unidos",
      fr: "États-Unis",
      ja: "アメリカ合衆国",
      it: "Stati Uniti D'America",
      hu: "Amerikai Egyesült Államok",
    },
    flag: "https://flagcdn.com/us.svg",
    regionalBlocs: [
      {
        acronym: "NAFTA",
        name: "North American Free Trade Agreement",
        otherNames: ["Tratado de Libre Comercio de América del Norte", "Accord de Libre-échange Nord-Américain"],
      },
    ],
    cioc: "USA",
    independent: true,
  },
  {
    name: "Uruguay",
    topLevelDomain: [".uy"],
    alpha2Code: "UY",
    alpha3Code: "URY",
    callingCodes: ["598"],
    capital: "Montevideo",
    altSpellings: ["UY", "Oriental Republic of Uruguay", "República Oriental del Uruguay"],
    subregion: "South America",
    region: "Americas",
    population: 3473727,
    latlng: [-33, -56],
    demonym: "Uruguayan",
    area: 181034,
    gini: 39.7,
    timezones: ["UTC-03:00"],
    borders: ["ARG", "BRA"],
    nativeName: "Uruguay",
    numericCode: "858",
    flags: {
      svg: "https://flagcdn.com/uy.svg",
      png: "https://flagcdn.com/w320/uy.png",
    },
    currencies: [
      {
        code: "UYU",
        name: "Uruguayan peso",
        symbol: "$",
      },
    ],
    languages: [
      {
        iso639_1: "es",
        iso639_2: "spa",
        name: "Spanish",
        nativeName: "Español",
      },
    ],
    translations: {
      br: "Uruguai",
      pt: "Uruguai",
      nl: "Uruguay",
      hr: "Urugvaj",
      fa: "اروگوئه",
      de: "Uruguay",
      es: "Uruguay",
      fr: "Uruguay",
      ja: "ウルグアイ",
      it: "Uruguay",
      hu: "Uruguay",
    },
    flag: "https://flagcdn.com/uy.svg",
    regionalBlocs: [
      {
        acronym: "USAN",
        name: "Union of South American Nations",
        otherAcronyms: ["UNASUR", "UNASUL", "UZAN"],
        otherNames: ["Unión de Naciones Suramericanas", "União de Nações Sul-Americanas", "Unie van Zuid-Amerikaanse Naties", "South American Union"],
      },
    ],
    cioc: "URU",
    independent: true,
  },
  {
    name: "Uzbekistan",
    topLevelDomain: [".uz"],
    alpha2Code: "UZ",
    alpha3Code: "UZB",
    callingCodes: ["998"],
    capital: "Tashkent",
    altSpellings: ["UZ", "Republic of Uzbekistan", "O‘zbekiston Respublikasi", "Ўзбекистон Республикаси"],
    subregion: "Central Asia",
    region: "Asia",
    population: 34232050,
    latlng: [41, 64],
    demonym: "Uzbekistani",
    area: 447400,
    gini: 35.3,
    timezones: ["UTC+05:00"],
    borders: ["AFG", "KAZ", "KGZ", "TJK", "TKM"],
    nativeName: "O‘zbekiston",
    numericCode: "860",
    flags: {
      svg: "https://flagcdn.com/uz.svg",
      png: "https://flagcdn.com/w320/uz.png",
    },
    currencies: [
      {
        code: "UZS",
        name: "Uzbekistani so'm",
        symbol: "so'm",
      },
    ],
    languages: [
      {
        iso639_1: "uz",
        iso639_2: "uzb",
        name: "Uzbek",
        nativeName: "Oʻzbek",
      },
      {
        iso639_1: "ru",
        iso639_2: "rus",
        name: "Russian",
        nativeName: "Русский",
      },
    ],
    translations: {
      br: "Uzbequistão",
      pt: "Usbequistão",
      nl: "Oezbekistan",
      hr: "Uzbekistan",
      fa: "ازبکستان",
      de: "Usbekistan",
      es: "Uzbekistán",
      fr: "Ouzbékistan",
      ja: "ウズベキスタン",
      it: "Uzbekistan",
      hu: "Üzbegisztán",
    },
    flag: "https://flagcdn.com/uz.svg",
    cioc: "UZB",
    independent: false,
  },
  {
    name: "Vanuatu",
    topLevelDomain: [".vu"],
    alpha2Code: "VU",
    alpha3Code: "VUT",
    callingCodes: ["678"],
    capital: "Port Vila",
    altSpellings: ["VU", "Republic of Vanuatu", "Ripablik blong Vanuatu", "République de Vanuatu"],
    subregion: "Melanesia",
    region: "Oceania",
    population: 307150,
    latlng: [-16, 167],
    demonym: "Ni-Vanuatu",
    area: 12189,
    gini: 37.6,
    timezones: ["UTC+11:00"],
    nativeName: "Vanuatu",
    numericCode: "548",
    flags: {
      svg: "https://flagcdn.com/vu.svg",
      png: "https://flagcdn.com/w320/vu.png",
    },
    currencies: [
      {
        code: "VUV",
        name: "Vanuatu vatu",
        symbol: "Vt",
      },
    ],
    languages: [
      {
        iso639_1: "bi",
        iso639_2: "bis",
        name: "Bislama",
        nativeName: "Bislama",
      },
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
      {
        iso639_1: "fr",
        iso639_2: "fra",
        name: "French",
        nativeName: "français",
      },
    ],
    translations: {
      br: "Vanuatu",
      pt: "Vanuatu",
      nl: "Vanuatu",
      hr: "Vanuatu",
      fa: "وانواتو",
      de: "Vanuatu",
      es: "Vanuatu",
      fr: "Vanuatu",
      ja: "バヌアツ",
      it: "Vanuatu",
      hu: "Vanuatu",
    },
    flag: "https://flagcdn.com/vu.svg",
    cioc: "VAN",
    independent: true,
  },
  {
    name: "Venezuela (Bolivarian Republic of)",
    topLevelDomain: [".ve"],
    alpha2Code: "VE",
    alpha3Code: "VEN",
    callingCodes: ["58"],
    capital: "Caracas",
    altSpellings: ["VE", "Bolivarian Republic of Venezuela", "República Bolivariana de Venezuela"],
    subregion: "South America",
    region: "Americas",
    population: 28435943,
    latlng: [8, -66],
    demonym: "Venezuelan",
    area: 916445,
    gini: 44.8,
    timezones: ["UTC-04:00"],
    borders: ["BRA", "COL", "GUY"],
    nativeName: "Venezuela",
    numericCode: "862",
    flags: {
      svg: "https://flagcdn.com/ve.svg",
      png: "https://flagcdn.com/w320/ve.png",
    },
    currencies: [
      {
        code: "VEF",
        name: "Venezuelan bolívar",
        symbol: "Bs S",
      },
    ],
    languages: [
      {
        iso639_1: "es",
        iso639_2: "spa",
        name: "Spanish",
        nativeName: "Español",
      },
    ],
    translations: {
      br: "Venezuela",
      pt: "Venezuela",
      nl: "Venezuela",
      hr: "Venezuela",
      fa: "ونزوئلا",
      de: "Venezuela",
      es: "Venezuela",
      fr: "Venezuela",
      ja: "ベネズエラ・ボリバル共和国",
      it: "Venezuela",
      hu: "Venezuela",
    },
    flag: "https://flagcdn.com/ve.svg",
    regionalBlocs: [
      {
        acronym: "USAN",
        name: "Union of South American Nations",
        otherAcronyms: ["UNASUR", "UNASUL", "UZAN"],
        otherNames: ["Unión de Naciones Suramericanas", "União de Nações Sul-Americanas", "Unie van Zuid-Amerikaanse Naties", "South American Union"],
      },
    ],
    cioc: "VEN",
    independent: true,
  },
  {
    name: "Vietnam",
    topLevelDomain: [".vn"],
    alpha2Code: "VN",
    alpha3Code: "VNM",
    callingCodes: ["84"],
    capital: "Hanoi",
    altSpellings: ["VN", "Socialist Republic of Vietnam", "Cộng hòa Xã hội chủ nghĩa Việt Nam"],
    subregion: "South-Eastern Asia",
    region: "Asia",
    population: 97338583,
    latlng: [16.16666666, 107.83333333],
    demonym: "Vietnamese",
    area: 331212,
    gini: 35.7,
    timezones: ["UTC+07:00"],
    borders: ["KHM", "CHN", "LAO"],
    nativeName: "Việt Nam",
    numericCode: "704",
    flags: {
      svg: "https://flagcdn.com/vn.svg",
      png: "https://flagcdn.com/w320/vn.png",
    },
    currencies: [
      {
        code: "VND",
        name: "Vietnamese đồng",
        symbol: "₫",
      },
    ],
    languages: [
      {
        iso639_1: "vi",
        iso639_2: "vie",
        name: "Vietnamese",
        nativeName: "Tiếng Việt",
      },
    ],
    translations: {
      br: "Vietnã",
      pt: "Vietname",
      nl: "Vietnam",
      hr: "Vijetnam",
      fa: "ویتنام",
      de: "Vietnam",
      es: "Vietnam",
      fr: "Viêt Nam",
      ja: "ベトナム",
      it: "Vietnam",
      hu: "Vietnám",
    },
    flag: "https://flagcdn.com/vn.svg",
    regionalBlocs: [
      {
        acronym: "ASEAN",
        name: "Association of Southeast Asian Nations",
      },
    ],
    cioc: "VIE",
    independent: true,
  },
  {
    name: "Wallis and Futuna",
    topLevelDomain: [".wf"],
    alpha2Code: "WF",
    alpha3Code: "WLF",
    callingCodes: ["681"],
    capital: "Mata-Utu",
    altSpellings: ["WF", "Territory of the Wallis and Futuna Islands", "Territoire des îles Wallis et Futuna"],
    subregion: "Polynesia",
    region: "Oceania",
    population: 11750,
    latlng: [-13.3, -176.2],
    demonym: "Wallis and Futuna Islander",
    area: 142,
    timezones: ["UTC+12:00"],
    nativeName: "Wallis et Futuna",
    numericCode: "876",
    flags: {
      svg: "https://flagcdn.com/wf.svg",
      png: "https://flagcdn.com/w320/wf.png",
    },
    currencies: [
      {
        code: "XPF",
        name: "CFP franc",
        symbol: "Fr",
      },
    ],
    languages: [
      {
        iso639_1: "fr",
        iso639_2: "fra",
        name: "French",
        nativeName: "français",
      },
    ],
    translations: {
      br: "Wallis e Futuna",
      pt: "Wallis e Futuna",
      nl: "Wallis en Futuna",
      hr: "Wallis i Fortuna",
      fa: "والیس و فوتونا",
      de: "Wallis und Futuna",
      es: "Wallis y Futuna",
      fr: "Wallis-et-Futuna",
      ja: "ウォリス・フツナ",
      it: "Wallis e Futuna",
      hu: "Wallis és Futuna",
    },
    flag: "https://flagcdn.com/wf.svg",
    independent: false,
  },
  {
    name: "Western Sahara",
    topLevelDomain: [".eh"],
    alpha2Code: "EH",
    alpha3Code: "ESH",
    callingCodes: ["212"],
    capital: "El Aaiún",
    altSpellings: ["EH", "Taneẓroft Tutrimt"],
    subregion: "Northern Africa",
    region: "Africa",
    population: 510713,
    latlng: [24.5, -13],
    demonym: "Sahrawi",
    area: 266000,
    timezones: ["UTC+00:00"],
    borders: ["DZA", "MRT", "MAR"],
    nativeName: "الصحراء الغربية",
    numericCode: "732",
    flags: {
      svg: "https://flagcdn.com/eh.svg",
      png: "https://flagcdn.com/w320/eh.png",
    },
    currencies: [
      {
        code: "MAD",
        name: "Moroccan dirham",
        symbol: "د.م.",
      },
      {
        code: "DZD",
        name: "Algerian dinar",
        symbol: "د.ج",
      },
    ],
    languages: [
      {
        iso639_1: "es",
        iso639_2: "spa",
        name: "Spanish",
        nativeName: "Español",
      },
    ],
    translations: {
      br: "Saara Ocidental",
      pt: "Saara Ocidental",
      nl: "Westelijke Sahara",
      hr: "Zapadna Sahara",
      fa: "جمهوری دموکراتیک عربی صحرا",
      de: "Westsahara",
      es: "Sahara Occidental",
      fr: "Sahara Occidental",
      ja: "西サハラ",
      it: "Sahara Occidentale",
      hu: "Nyugat-Szahara",
    },
    flag: "https://flagcdn.com/eh.svg",
    regionalBlocs: [
      {
        acronym: "AU",
        name: "African Union",
        otherNames: ["الاتحاد الأفريقي", "Union africaine", "União Africana", "Unión Africana", "Umoja wa Afrika"],
      },
    ],
    independent: false,
  },
  {
    name: "Yemen",
    topLevelDomain: [".ye"],
    alpha2Code: "YE",
    alpha3Code: "YEM",
    callingCodes: ["967"],
    capital: "Sana'a",
    altSpellings: ["YE", "Yemeni Republic", "al-Jumhūriyyah al-Yamaniyyah"],
    subregion: "Western Asia",
    region: "Asia",
    population: 29825968,
    latlng: [15, 48],
    demonym: "Yemeni",
    area: 527968,
    gini: 36.7,
    timezones: ["UTC+03:00"],
    borders: ["OMN", "SAU"],
    nativeName: "اليَمَن",
    numericCode: "887",
    flags: {
      svg: "https://flagcdn.com/ye.svg",
      png: "https://flagcdn.com/w320/ye.png",
    },
    currencies: [
      {
        code: "YER",
        name: "Yemeni rial",
        symbol: "﷼",
      },
    ],
    languages: [
      {
        iso639_1: "ar",
        iso639_2: "ara",
        name: "Arabic",
        nativeName: "العربية",
      },
    ],
    translations: {
      br: "Iêmen",
      pt: "Iémen",
      nl: "Jemen",
      hr: "Jemen",
      fa: "یمن",
      de: "Jemen",
      es: "Yemen",
      fr: "Yémen",
      ja: "イエメン",
      it: "Yemen",
      hu: "Jemen",
    },
    flag: "https://flagcdn.com/ye.svg",
    regionalBlocs: [
      {
        acronym: "AL",
        name: "Arab League",
        otherNames: ["جامعة الدول العربية", "Jāmiʻat ad-Duwal al-ʻArabīyah", "League of Arab States"],
      },
    ],
    cioc: "YEM",
    independent: true,
  },
  {
    name: "Zambia",
    topLevelDomain: [".zm"],
    alpha2Code: "ZM",
    alpha3Code: "ZMB",
    callingCodes: ["260"],
    capital: "Lusaka",
    altSpellings: ["ZM", "Republic of Zambia"],
    subregion: "Eastern Africa",
    region: "Africa",
    population: 18383956,
    latlng: [-15, 30],
    demonym: "Zambian",
    area: 752618,
    gini: 57.1,
    timezones: ["UTC+02:00"],
    borders: ["AGO", "BWA", "COD", "MWI", "MOZ", "NAM", "TZA", "ZWE"],
    nativeName: "Zambia",
    numericCode: "894",
    flags: {
      svg: "https://flagcdn.com/zm.svg",
      png: "https://flagcdn.com/w320/zm.png",
    },
    currencies: [
      {
        code: "ZMW",
        name: "Zambian kwacha",
        symbol: "ZK",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
    ],
    translations: {
      br: "Zâmbia",
      pt: "Zâmbia",
      nl: "Zambia",
      hr: "Zambija",
      fa: "زامبیا",
      de: "Sambia",
      es: "Zambia",
      fr: "Zambie",
      ja: "ザンビア",
      it: "Zambia",
      hu: "Zambia",
    },
    flag: "https://flagcdn.com/zm.svg",
    regionalBlocs: [
      {
        acronym: "AU",
        name: "African Union",
        otherNames: ["الاتحاد الأفريقي", "Union africaine", "União Africana", "Unión Africana", "Umoja wa Afrika"],
      },
    ],
    cioc: "ZAM",
    independent: true,
  },
  {
    name: "Zimbabwe",
    topLevelDomain: [".zw"],
    alpha2Code: "ZW",
    alpha3Code: "ZWE",
    callingCodes: ["263"],
    capital: "Harare",
    altSpellings: ["ZW", "Republic of Zimbabwe"],
    subregion: "Eastern Africa",
    region: "Africa",
    population: 14862927,
    latlng: [-20, 30],
    demonym: "Zimbabwean",
    area: 390757,
    gini: 50.3,
    timezones: ["UTC+02:00"],
    borders: ["BWA", "MOZ", "ZAF", "ZMB"],
    nativeName: "Zimbabwe",
    numericCode: "716",
    flags: {
      svg: "https://flagcdn.com/zw.svg",
      png: "https://flagcdn.com/w320/zw.png",
    },
    currencies: [
      {
        code: "ZMW",
        name: "Zambian kwacha",
        symbol: "K",
      },
    ],
    languages: [
      {
        iso639_1: "en",
        iso639_2: "eng",
        name: "English",
        nativeName: "English",
      },
      {
        iso639_1: "sn",
        iso639_2: "sna",
        name: "Shona",
        nativeName: "chiShona",
      },
      {
        iso639_1: "nd",
        iso639_2: "nde",
        name: "Northern Ndebele",
        nativeName: "isiNdebele",
      },
    ],
    translations: {
      br: "Zimbabwe",
      pt: "Zimbabué",
      nl: "Zimbabwe",
      hr: "Zimbabve",
      fa: "زیمباوه",
      de: "Simbabwe",
      es: "Zimbabue",
      fr: "Zimbabwe",
      ja: "ジンバブエ",
      it: "Zimbabwe",
      hu: "Zimbabwe",
    },
    flag: "https://flagcdn.com/zw.svg",
    regionalBlocs: [
      {
        acronym: "AU",
        name: "African Union",
        otherNames: ["الاتحاد الأفريقي", "Union africaine", "União Africana", "Unión Africana", "Umoja wa Afrika"],
      },
    ],
    cioc: "ZIM",
    independent: true,
  },
];
