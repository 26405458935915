import { replaceBlankWithDash } from "../../lib/helper";

export const reviewLogsColumns = [
  {
    Header: "Product Name",
    accessor: data => replaceBlankWithDash(data.productName),
    id: "productName",
    show: true
  },
  {
    Header: "Category Name",
    accessor: data => replaceBlankWithDash(data.categoryName),
    id: "categoryName",
    show: true
  },
  {
    Header: "Sub Category Name",
    accessor: data => replaceBlankWithDash(data.subCategoryName),
    id: "subCategoryName",
    show: true
  },
  {
    Header: "Sub Sub Category Name",
    accessor: data => replaceBlankWithDash(data.subSubCategoryName),
    id: "subSubCategoryName",
    show: true
  },
  {

    Header: "Create Date",
    accessor: data => replaceBlankWithDash(data.dateTime),
    id: "dateTime",
    minWidth: 110,
    show: true
  },
  {
    Header: "Full Name",
    accessor: date => replaceBlankWithDash(date.fullName),
    id: "fullName",
    show: true
  },

  {
    Header: "Review Title",
    accessor: date => replaceBlankWithDash(date.reviewTitle),
    id: "reviewTitle",
    show: true
  },
  {
    Header: "Review Description",
    accessor: date => replaceBlankWithDash(date.reviewDescription),
    id: "reviewDescription",
    show: true
  },

  {
    Header: "Platform Name",
    accessor: data => replaceBlankWithDash(data.platform),
    id: "platform",
    show: true
  },
  {
    Header: "IP Address",
    accessor: data => replaceBlankWithDash(data.ipAddress),
    id: "ipAddress",
    show: true
  },
  {
    Header: "Latitude",
    accessor: data => replaceBlankWithDash(data.lat),
    id: "lat",
    show: true
  },
  {
    Header: "Longitude",
    accessor: data => replaceBlankWithDash(data.long),
    id: "long",
    show: true
  },
  {
    Header: "City Name",
    accessor: data => replaceBlankWithDash(data.cityName),
    id: "cityName",
    show: true
  },
  {
    Header: "Country Name",
    accessor: data => replaceBlankWithDash(data.countryName),
    id: "countryName",
    show: true
  },

];
