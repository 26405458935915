import { get } from "../lib/request";
import moment from "moment";
export const fetchReviewLogs = (
  startDate = moment(),
  endDate = moment(),
  skip = 0,
  limit = 50
) => {
  return get(`/reviewLogs?skip=${skip}&limit=${limit}`, {
    startDate: startDate,
    endDate: endDate,
  });
};
