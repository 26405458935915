import React, { Component } from "react";
import Link from "../../../../components/Link/Link";
import RevLoader from "../../../../components/loader/RevLoader";
import ApexCharts from "react-apexcharts";
import getSymbolFromCurrency from "currency-symbol-map";

class salesActivity extends Component {
  render() {
    return (
      <div className="card">
        <div className="spaceBetween mt-3 ml-3 mr-3">
          <span className="label globalFontSize fontweightone">
            Total orders
          </span>
          <span className="link globalFontSize fontweightone">
            <Link url={`orders-report`}>View Activity</Link>
          </span>
        </div>
        <div className="spaceBetween ml-3 mr-3">
          <span className="numericValue globalFontSize">
            {this.props.loader ? (
              <RevLoader />
            ) : this.props.totalOrder ? (
              `${getSymbolFromCurrency(this.props.currency)} ${
                this.props.totalOrder
              }`
            ) : (
              `${getSymbolFromCurrency(this.props.currency)} ` + 0
            )}
            {/* {this.props.loader ? <RevLoader /> : this.props.totalSale ? `${getSymbolFromCurrency(this.props.currency)} ` + parseInt(this.props.totalSale) : `${getSymbolFromCurrency(this.props.currency)} ` + 0} */}
          </span>
        </div>
        {/* <div className="m-3 graphTitle">
                <span>Sales Activity</span>
              </div> */}
        <div className="chart">
          <ApexCharts
            options={this.props.config.options}
            series={this.props.config.series}
            type="area"
            height={350}
          />
        </div>
      </div>
    );
  }
}

export default salesActivity;
