import React, { Component } from 'react';

class Pagination extends Component {

    render() {
        const { count, rowSelected, pageNo, miscRowSelected, data } = this.props;   
        return (
            <div className="dimension-strip my-3 d-flex align-items-center justify-content-end f-145 px-3">
                <div className="d-flex align-items-center">
                    <div>No. of Rows: </div>
                    <input
                        type="text"
                        name="rowSelected"
                        value={rowSelected}
                        onChange={(e) => this.props.handleChange(e)}
                        className="brand-inputbox row-class ml-2"
                    />
                </div>
                <div className="d-flex ml-3">Out of: {count}</div>
                <div className="ml-4">Page Number: {pageNo + 1}</div>
                <div
                    className={`
                        d-flex searchIcon bl go-tag align-items-center justify-content-center ml-2
                        ${pageNo === 0 && "disable-page"}
                    `}
                    onClick={() => this.props.prevPage()}
                >
                    <i className="fa fa-chevron-left" aria-hidden="true"></i>
                </div>
                <div
                    className={`
                        d-flex searchIcon bl go-tag align-items-center justify-content-center ml-2
                        ${(pageNo === (Math.ceil(count/(miscRowSelected === 0 ? rowSelected : miscRowSelected)) - 1) || (data.length === 0)) && "disable-page"}
                    `}
                    onClick={() => this.props.nextPage()}
                >
                    <i className="fa fa-chevron-right" aria-hidden="true"></i>
                </div>
            </div>
        );
    }
}

export default Pagination;