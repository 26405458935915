let pieConfig = {
    series: [30, 30],
    options: {  
      chart: {
        type: 'pie',
        colors: ['#546E7A', '#E91E63'],
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            show: false,
            position: 'bottom'
          }
        }
      }],
      legend: {
          show: false
      },
    //   colors: ['#DBDB62', '#4D25E7'],
    },
    
    grid: {
        show: true,
        borderColor: '#90A4AE',
        strokeDashArray: 0,
        position: 'back',
        xaxis: {
            lines: {
                show: false
            }
        },   
        yaxis: {
            lines: {
                show: false
            }
        },  
        row: {
            colors: undefined,
            opacity: 0.5
        },  
        column: {
            colors: undefined,
            opacity: 0.5
        },  
        padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
        },  
    }
    
};

export { pieConfig };