import React, { Component } from "react";
import moment from "moment";
import DateRangePicker from "../../../components/DatePicker";
import ReactSelect from "../../../components/reactSelect/reactSelect";
import ReactToPrint from "react-to-print";
import Apexcharts from "react-apexcharts";
import Modal from "../../../components/modal/modal";
import Pbutton from "../../../components/button/Button";
import { CSVLink } from "react-csv";
import { storeIdApiCall } from "../../../services/storeId";
import { bookingPaymentActivityApiCall } from "../../../services/bookingPaymentActivity";

// import LineGraph from "./lineGraph.js";
import TableComponent from "./table.js";

import "../../../static/scss/globalComponent.scss";
import StoreIdDropDown from "../../../components/StoreIdDropdown";

class BookingPaymentActivity extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      date: {
        startDate:
          localStorage.getItem("startDate") != null
            ? localStorage.getItem("startDate")
            : moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
        endDate:
          localStorage.getItem("endDate") != null
            ? localStorage.getItem("endDate")
            : moment().format("YYYY-MM-DD HH:mm:ss"),
      },
      selected: localStorage.getItem("selectedGroupBy")
        ? JSON.parse(localStorage.getItem("selectedGroupBy"))
        : { value: 0, label: "Hour" },
      selectData: [{ value: 0, label: "Hour" }],
      dataLoaded: false,
      loadingScreen: true,
      storeIdValue: "0",
      storeData: [{ id: 0, name: "--No Data--" }],
      dataLoadedID: false,
    };
  }

  componentDidMount() {
    this.getAllStoreId();
    this.getData();
  }

  getAllStoreId = () => {
    storeIdApiCall()
      .then((res) => {
        this.setState({
          storeData: res.data.data,
          dataLoadedID: true,
        });
      })
      .catch((err) => {
        console.log("error store id", err);
        this.setState({
          dataLoadedID: false,
        });
      });
  };

  getData = () => {
    bookingPaymentActivityApiCall(
      this.state.date,
      this.state.selected,
      this.state.storeIdValue,
      ""
    )
      .then((res) => {
        let arrayCollection = [];
        let exportData = [];
        let data = {};
        let history = [];
        let format = {
          Hour: "ll H:00",
          Day: "ll",
          Week: "ll",
          "Hour Of Day": "HH:00",
        };
        let xCategories;
        if (this.state.selected.label === "Hour") {
          xCategories = res.data.data.graph.xaxis.categories.map((dateTime) => {
            return moment(dateTime).format(format[this.state.selected.label]);
          });
        } else if (
          this.state.selected.label === "Day" ||
          this.state.selected.label === "Week" ||
          this.state.selected.label === "Hour Of Day"
        ) {
          xCategories = res.data.data.graph.xaxis.categories.map((dateTime) => {
            return moment(dateTime).format(format[this.state.selected.label]);
          });
        } else {
          xCategories = res.data.data.graph.xaxis.categories;
        }

        res.data.data.graph.xaxis.categories = [...xCategories];
        res.data.data.table.totalAmount["Time Line"] = [...xCategories];
        res.data.data.table.totalAmount.Card.map((item, index) => {
          Object.keys(res.data.data.history).map((data, dataIndex) => {
            if (
              this.state.selected.value === 3 ||
              this.state.selected.value === 7
            ) {
              if (
                data === res.data.data.table.totalAmount["Time Line"][index]
              ) {
                history = res.data.data.history[data];
              }
            } else {
              if (index === dataIndex) {
                history = res.data.data.history[data];
              }
            }
            return history;
          });
          data = {
            card: item,
            cash: res.data.data.table.totalAmount.Cash[index],
            timeStamp: res.data.data.table.totalAmount["Time Line"][index],
            history,
          };
          return arrayCollection.push(data);
        });
        arrayCollection.map((object) =>
          object.history.map((historyObject) =>
            exportData.push({
              timeStamp: object.timeStamp,
              "Total Card": object.card,
              "Total Cash": object.cash,
              "Store Category": historyObject["Store Category"],
              Card: historyObject.card,
              Cash: historyObject.cash,
            })
          )
        );
        // graph fromatting
        let graphData = {
          series: [...res.data.data.graph.series],
          options: {
            plotOptions: { bar: { columnWidth: "20%" } },
            title: {
              text: "Device Type Install Report",
              align: "left",
              style: { fontSize: "18px", fontWeight: 500 },
            },
            dataLabels: { enabled: false },

            xaxis: {
              type: "category",
              categories: [...res.data.data.graph.xaxis.categories],
              title: {
                text: res.data.data.graph.xaxis.title,
                style: { fontSize: "11px", fontWeight: 700 },
                offsetY: -10,
              },
              labels: {
                show: true,
                rotate: -50,
                hideOverlappingLabels: true,
                showDuplicates: false,
              },
              tooltip: {
                enabled: false,
              },
            },
            grid: {
              padding: {
                left: 40,
              },
            },
            legend: {
              position: "top",
            },
            yaxis: {
              title: {
                text: res.data.data.graph.yaxis.title,
                style: { fontSize: "11px", fontWeight: 700 },
              },
              opposite: false,
              labels: {
                offsetX:15,
                show: true,
                style: { fontSize: "11px" },
                formatter: function (value) {
                  return parseInt(value);
                },
              },
            },
            tooltip: {
              enabled: true,
              followCursor: true,
            },
          },
        };
        this.setState({
          dataLoaded: true,
          loadingScreen: false,
          graphData,
          tableData: arrayCollection,
          logsData: exportData,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          dataLoaded: false,
          loadingScreen: false,
        });
      });
  };

  toggleModal = () => {
    this.setState({ modal: !this.state.modal });
  };

  exportData = () => {
    bookingPaymentActivityApiCall(
      this.state.date,
      this.state.selected,
      this.state.storeIdValue,
      "export"
    )
      .then((expoData) => {
        this.setState(
          {
            logsData: expoData.data.data.table,
          },
          () => this.toggleModal()
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleDateRange = (startDate, endDate, groupBy, selectedGroupBy) => {
    const { date } = this.state;
    if (startDate !== date.startDate || endDate !== date.endDate) {
      this.setState(
        {
          date: {
            startDate: startDate,
            endDate: endDate,
          },
          selectData: groupBy,
          selected: selectedGroupBy,
          dataLoaded: false,
          loadingScreen: true,
        },
        () => this.getData()
      );
    }
  };

  selectHandler = (option) => {
    this.setState(
      {
        selected: option,
        dataLoaded: false,
        loadingScreen: true,
      },
      () => this.getData()
    );
  };

  dropDownSelect = (e) => {
    this.setState(
      {
        storeIdValue: e.target.value,
      },
      () => this.getData()
    );
  };

  render() {
    return (
      <div className="containerDiv" ref={this.myRef}>
        <span
          className="hoverPathClass globalFontSize"
          onClick={() => this.props.history.push("overview")}
        >
          <i className="fas fa-angle-left mr-2"></i>Reports
        </span>
        <div className="title">Payment Activity</div>
        <div
          className="d-flex text-grey mt-2 globalFontSize"
          style={{ marginBottom: "" }}
        >
          <div className="mr-3 db_ptr">
            <ReactToPrint
              trigger={() => (
                <span>
                  <i className="fas fa-print mr-1"></i>Print
                </span>
              )}
              content={() => this.myRef.current}
            />
          </div>
          <div className="mr-3 db_ptr" onClick={this.toggleModal}>
            <i className="fas fa-download mr-1"></i>Export
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between mt-4 mb-4">
          <div className="d-flex align-items-center">
            <DateRangePicker
              id="datePicker"
              text={true}
              handleDateChange={this.handleDateRange}
              getGroupBy={true}
            />

            <span className="mr-2 ml-5">Group By: </span>
            <div className="selectBody">
              <ReactSelect
                city={this.state.selectData}
                change={this.selectHandler}
                selected={this.state.selected}
              />
            </div>
          </div>
          <div className="d-flex align-items-center globalFontSize">
            <StoreIdDropDown
              storeData={this.state.storeData}
              storeID={this.state.storeIdValue}
              dropDownSelect={this.dropDownSelect}
            />
          </div>
        </div>
        <div
          className="activeBarGraph d-flex align-items-center justify-content-center"
          style={{ padding: 0 }}
        >
          {this.state.loadingScreen ? (
            <h4 style={{ color: "grey" }}>Loading data...</h4>
          ) : this.state.dataLoaded ? (
            <div
              style={{
                height: "100%",
                padding: "10px 0px 10px 0px",
                width: "100%",
              }}
            >
              <Apexcharts
                series={this.state.graphData.series}
                options={this.state.graphData.options}
                type="line"
                height={"100%"}
              />
            </div>
          ) : (
            <h4 style={{ color: "grey" }}>No data for selected date range</h4>
          )}
        </div>
        <div
          className="d-flex align-items-center justify-content-center"
          style={{
            marginTop: "50px",
            backgroundColor: "white",
            borderRadius: "3px",
            minHeight: "100px",
            boxShadow: "0px 0px 2px 1px rgba(145, 145, 145, .5)",
          }}
        >
          {this.state.loadingScreen ? (
            <h4 style={{ color: "grey" }}>Loading data...</h4>
          ) : this.state.dataLoaded ? (
            <TableComponent tableData={this.state.tableData} />
          ) : (
            <h4 style={{ color: "grey" }}>No data for selected date range</h4>
          )}
        </div>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggleModal}
          width={"35%"}
        >
          <div className="col-12 px-0">
            <div className="py-3 reportModal-header pl-3 border-bottom">
              Export your report
            </div>
            <div className="py-3 reportModal-subText pl-3 border-bottom">
              Report will be exported as a CSV (comma separated values) table.
            </div>
            <div className="py-3 col-12">
              <div className="row justify-content-end">
                <Pbutton
                  onClick={this.toggleModal}
                  className="reportModal-cancelBtn"
                >
                  Cancel
                </Pbutton>
                <CSVLink
                  onClick={() => this.toggleModal()}
                  data={this.state.logsData}
                  filename={"my-file.csv"}
                  className="reportModal-exportBtn"
                  target="_blank"
                >
                  Export
                </CSVLink>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default BookingPaymentActivity;
