import React, { Component } from "react";
import Link from "../../../../components/Link/Link";
import RevLoader from "../../../../components/loader/RevLoader";
import ApexCharts from "react-apexcharts";

// currencyFormatter.format(10000, {code: "USD"})

class BookingPayment extends Component {
  render() {
    return (
      <div className="card">
        <div className="spaceBetween mt-3 ml-3 mr-3">
          <span className="label globalFontSize fontweightone">
            Payment Activity
          </span>
          <span className="link globalFontSize fontweightone">
            <Link url={`payment-activity`}>View Activity</Link>
          </span>
        </div>
        <div className="spaceBetween ml-3 mr-3">
          <span className="numericValue globalFontSize">
            {this.props.loader ? (
              <RevLoader />
            ) : this.props.totalOrder ? (
              `${this.props.totalOrder}`
            ) : (
              0
            )}
          </span>
        </div>
        <div className="chart">
          <ApexCharts
            options={this.props.config.options}
            series={this.props.config.series}
            type="area"
            height={350}
          />
        </div>
      </div>
    );
  }
}

export default BookingPayment;
