const moment = require("moment");

export const convertApiKeyToString = (key) => {
  switch (key) {
    case "actionBy":
      return "Action By";

    case "action":
      return "Action";

    case "productName":
      return "Product Name";

    case "openingQty":
      return "Opening Qty";

    case "unitPrice":
      return "Unit Price";

    case "cartId":
      return "Cart Id";

    case "actorBy":
      return "Actor By";

    case "finalPrice":
      return "Final Price";

    case "storeName":
      return "Store Name";

    case "supplierName":
      return "Supplier Name";

    case "closingQty":
      return "Closing Qty";
    
    default:
      return ""
  }
};

export const convertApiKeyToStringProductView = (key) => {
  switch (key) {
    case "catName":
      return "CatName";

    case "currency":
      return "Currency";

    case "currencySymbol":
      return "Currency Symbol";

    case "finalPrice":
      return "Final Price";

    case "productId":
      return "Product Id";

    case "reviewDate":
      return "Review Date";

    case "productName":
      return "Product Name";

    case "subCatName":
      return "SubCat Name";

    case "subSubCatName":
      return "Item Name";

    case "userName":
      return "User Name";

    default:
      return ""
  }
};


// TimeFormatting Like 24 minutes ago

export const TimeFormatToAgo = (time) => {
  let t1, t2;
  t1 = moment();
  t2 = moment(time);
  let diff = t1.diff(t2, "seconds");
  if (diff <= 3600) {
    return `${t1.diff(t2, "minutes")} ${
      t1.diff(t2, "minutes") === 1 ? "minute" : "minutes"
    }`;
  } else if (diff > 3600 && diff <= 86400) {
    return `${t1.diff(t2, "hours")} ${
      t1.diff(t2, "hours") === 1 ? "hour" : "hours"
    }`;
  } else if (diff > 86400 && diff <= 604800) {
    return `${t1.diff(t2, "days")} ${
      t1.diff(t2, "days") === 1 ? "day" : "days"
    }`;
  } else if (diff > 604800 && diff <= 26280023) {
    return `${t1.diff(t2, "weeks")} ${
      t1.diff(t2, "weeks") === 1 ? "week" : "weeks"
    }`;
  } else if (diff > 26280023 && diff <= 31536000) {
    return `${t1.diff(t2, "months")} ${
      t1.diff(t2, "months") === 1 ? "month" : "months"
    }`;
  } else {
    return `${t1.diff(t2, "years")} ${
      t1.diff(t2, "years") === 1 ? "year" : "years"
    }`;
  }
};
