import React, { Component } from 'react';

class Header extends Component {
    state = {
        renderColumns: [1, 2, 3, 4],
        order1: false,
        order2: true,
        order3: false,
        order4: false,
        order5: false,
        order6: false,
        order7: false,
    };

    sortingFunction = (e, from) => {
        const { tableDropDown } = this.props;
        if (e.target.nodeName === 'SELECT' || from === 3) {
        } else {
            let order = tableDropDown === "Product Revenue"
            ? 2
            : tableDropDown === "Unique Purchases"
                ? 3
                : tableDropDown === "Quantity"
                    ? 4
                    : tableDropDown === "Avg. Price"
                        ? 5
                        : tableDropDown === "Avg. QTY"
                            ? 6
                            : 7;
            this.orderFunction(tableDropDown, order)
        }
    };

    orderFunction = (sortBy, order) => {
        this.setState({
            [`order${order}`]: !this.state[`order${order}`],
        }, () => this.props.changeOrder(sortBy, this.state[`order${order}`]));
        
    }

    render() {

        const { dimension, tableDropDown, pieDropdown, sortBy, orderIconState } = this.props;
        const { renderColumns } = this.state;
        return (
            <div className="header-item-box d-flex mx-3 mt-3">
                {renderColumns.map((item, index) => (
                    <div
                        className={`header-item ${index === 0 || index === 1 ? "hover-header-item" : ""} px-2 f-135 d-flex flex-column justify-content-around`}
                        style={ (index === 0 || index === 3) ?  { width: "30%" } : { width: "20%" }}
                    >
                        {
                            index === 0
                                ? <div className="d-flex justify-content-between align-items-center"
                                    onClick={() => this.orderFunction(dimension, 1)}
                                >
                                    <span>{dimension}</span>
                                    <div>
                                        <i className="fas fa-question-circle"></i>
                                        {
                                            sortBy === dimension
                                                ? <span className="ml-3">
                                                    <i className={`fas fa-arrow-${orderIconState === 1 ? "up" : "down"}`}></i>
                                                </span>
                                                : ""
                                        }
                                    </div>
                                </div>
                                : index === 1 || index === 3
                                    ? <div className={`d-flex ${index === 1 ? "justify-content-between" : "justify-content-center"} align-items-center`} onClick={(e) => this.sortingFunction(e, index)}>
                                        <select name={`${index === 1 ? "tableDropDown" : "pieDropdown"}`} value={`${index === 1 ? tableDropDown : pieDropdown}`} onChange={(e) => this.props.setTableDropDown(e)}>
                                            <option value="Product Revenue">Product Revenue</option>
                                            <option value="Unique Purchases">Unique Purchases</option>
                                            <option value="Quantity">Quantity</option>
                                            <option value="Avg. Price">Avg. Price</option>
                                            <option value="Avg. QTY">Avg. QTY</option>
                                            <option value="Product Refund Amount">Product Refund Amount</option>
                                        </select>
                                        {
                                            index === 1 && <div>
                                                <i className="fas fa-question-circle"></i>
                                                {
                                                    sortBy !== dimension
                                                        ? <span className="ml-3">
                                                            <i className={`fas fa-arrow-${orderIconState === 1 ? "up" : "down"}`}></i>
                                                        </span>
                                                        : ""
                                                }
                                            </div>
                                        }
                                    </div>
                                    : pieDropdown
                        }
                    </div>
                ))}
            </div>
        );
    }
}

export default Header;