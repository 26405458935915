export const overviewData = [
  {
    label: "Today's Total Sales",
    numericValue: "$ 500",
    graphTitle: "Today's Total Sales",
    graphData: [],
  },

  // {
  //     label: "Product Review",
  //     numericValue: "$ 200",
  //     visitorLabel: "Visitors",
  //     VisitorsValue: "0",
  //     graphTitle: "Sales Over Time",
  //     graphData: [],
  // },
  // {
  //     label: "Product Rating",
  //     numericValue: "$ 100",
  //     visitorLabel: "Customers",
  //     VisitorsValue: "25",
  //     graphTitle: "Sales Over Time",
  //     graphData: [],
  // },
  // {
  //     label: "Category",
  //     numericValue: "$ 400",
  //     visitorLabel: "Customers",
  //     VisitorsValue: "25",
  //     graphTitle: "Sales Over Time",
  //     graphData: [],
  // },
  // {
  //     label: "Product Click",
  //     numericValue: "$ 500",
  //     graphTitle: "Sales Over Time",
  //     graphData: [],
  // }
];

export const overviewData2 = [
  {
    label: "Today's Total Orders",
    numericValue: "$ 500",
    graphTitle: "Today's Total Sales",
    graphData: [],
  },
];

export const overviewData3 = [
  {
    label: "Today's Top Selling Product",
    numericValue: "$ 500",
    graphTitle: "Today's  Top Selling Product",
    graphData: [],
  },
]

export const reportData = [
  {
    sub: "Sales",
    desc: "Make business decisions by comparing sales across products, staff, channels, and more.",
    subsub: "ORDERS LAST 30 DAYS",
    number: "0",
    show: 3,
    reportList: [
      {
        name: "Sales over time",
        value: "0 orders",
      },
      {
        name: "Sales by product",
        value: "0 products",
      },
      {
        name: "Sales by product variant SKU",
        value: "0 variants",
      },
      {
        name: "Sales by product vendor",
        value: "0 vendors",
      },
      {
        name: "Sales over time",
        value: "0 orders",
      },
      {
        name: "Sales by product",
        value: "0 products",
      },
      {
        name: "Sales by product variant SKU",
        value: "0 variants",
      },
      {
        name: "Sales by product vendor",
        value: "0 vendors",
      },
    ],
  },
  {
    sub: "Sales",
    desc: "Make business decisions by comparing sales across products, staff, channels, and more.",
    subsub: "ORDERS LAST 30 DAYS",
    number: "0",
    show: 3,
    reportList: [
      {
        name: "Sales over time",
        value: "0 orders",
      },
      {
        name: "Sales by product",
        value: "0 products",
      },
      {
        name: "Sales by product variant SKU",
        value: "0 variants",
      },
    ],
  },
  {
    sub: "Sales",
    desc: "Make business decisions by comparing sales across products, staff, channels, and more.",
    subsub: "ORDERS LAST 30 DAYS",
    number: "0",
    show: 3,
    reportList: [
      {
        name: "Sales over time",
        value: "0 orders",
      },
      {
        name: "Sales by product",
        value: "0 products",
      },
      {
        name: "Sales by product variant SKU",
        value: "0 variants",
      },
      {
        name: "Sales by product vendor",
        value: "0 vendors",
      },
      {
        name: "Sales over time",
        value: "0 orders",
      },
      {
        name: "Sales by product",
        value: "0 products",
      },
      {
        name: "Sales by product variant SKU",
        value: "0 variants",
      },
      {
        name: "Sales by product vendor",
        value: "0 vendors",
      },
    ],
  },
  {
    sub: "Sales",
    desc: "Make business decisions by comparing sales across products, staff, channels, and more.",
    subsub: "ORDERS LAST 30 DAYS",
    number: "0",
    show: 3,
    reportList: [
      {
        name: "Sales over time",
        value: "0 orders",
      },
      {
        name: "Sales by product",
        value: "0 products",
      },
      {
        name: "Sales by product variant SKU",
        value: "0 variants",
      },
      {
        name: "Sales by product vendor",
        value: "0 vendors",
      },
      {
        name: "Sales over time",
        value: "0 orders",
      },
      {
        name: "Sales by product",
        value: "0 products",
      },
      {
        name: "Sales by product variant SKU",
        value: "0 variants",
      },
      {
        name: "Sales by product vendor",
        value: "0 vendors",
      },
    ],
  },
  {
    sub: "Sales",
    desc: "Make business decisions by comparing sales across products, staff, channels, and more.",
    subsub: "ORDERS LAST 30 DAYS",
    number: "0",
    show: 3,
    reportList: [
      {
        name: "Sales over time",
        value: "0 orders",
      },
      {
        name: "Sales by product",
        value: "0 products",
      },
      {
        name: "Sales by product variant SKU",
        value: "0 variants",
      },
      {
        name: "Sales by product vendor",
        value: "0 vendors",
      },
      {
        name: "Sales over time",
        value: "0 orders",
      },
      {
        name: "Sales by product",
        value: "0 products",
      },
      {
        name: "Sales by product variant SKU",
        value: "0 variants",
      },
      {
        name: "Sales by product vendor",
        value: "0 vendors",
      },
    ],
  },
];
