import React, { Component } from "react";

// component import
import ReactSelect from "../../../components/reactSelect/reactSelect";

// scss import
import "../main.scss";

class GroupByComponent extends Component {
  selectHandler = (data) => {
    this.props.selectHandler(data);
  };

  render() {
    const { groupData, selectedLabel } = this.props;
    return (
      <div className="groupBy-container d-flex d-flex justify-content-center align-items-center mr-3">
        <div className="mr-2 f-146">Group By :</div>
        <ReactSelect
          city={groupData}
          change={this.selectHandler}
          selected={selectedLabel}
        />
      </div>
    );
  }
}

export default GroupByComponent;
