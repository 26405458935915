import React, { Component } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';

import Row from "./row";

class TableComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            rowArray: [
                { timeStamp: "Jan", cardPayment: 9, cashPayment: 8 },
                { timeStamp: "Feb", cardPayment: 6, cashPayment: 2 },
                { timeStamp: "Mar", cardPayment: 3, cashPayment: 0 },
                { timeStamp: "Apr", cardPayment: 3, cashPayment: 7 },
                { timeStamp: "May", cardPayment: 6, cashPayment: 8 },
                { timeStamp: "Jun", cardPayment: 4, cashPayment: 0 },
                { timeStamp: "Jul", cardPayment: 10, cashPayment: 0 },
                { timeStamp: "Aug", cardPayment: 11, cashPayment: 10 },
                { timeStamp: "Sep", cardPayment: 5, cashPayment: 3 },
                { timeStamp: "Oct", cardPayment: 3, cashPayment: 7 },
                { timeStamp: "Nov", cardPayment: 6, cashPayment: 8 },
                { timeStamp: "Dec", cardPayment: 10, cashPayment: 0 }
            ],
            rowsPerPage: 10,
            page: 0,
        };
    }

    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage,
        });
    };
    
    handleChangeRowsPerPage = (event) => {
        this.setState({
            rowsPerPage: +event.target.value,
        });
    };

    render() {
        const { tableData } = this.props;
        return (
            <div style={{ width: "100%" }}>
                <TableContainer component={Paper}>
                    <Table aria-label="collapsible table">
                        <TableHead style={{ backgroundColor: "whitesmoke" }}>
                            <TableRow>
                                <TableCell />
                                <TableCell  style={{ fontWeight: 500, fontSize: "14px" }}>Month</TableCell>
                                <TableCell  style={{ fontWeight: 500, fontSize: "14px" }} align="right">Card Payment</TableCell>
                                <TableCell  style={{ fontWeight: 500, fontSize: "14px" }} align="right">Cash Payment</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableData.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((row, index) => (
                                <Row row={row} key={index} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    style={{ backgroundColor: "white" }}
                    rowsPerPageOptions={[5, 10, 15]}
                    id="backgroundShadow"
                    component="div"
                    count={tableData.length}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
            </div>
        );
    }
}

export default TableComponent;