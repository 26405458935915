import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { NavLink } from "react-router-dom";

import Drawer from "@material-ui/core/Drawer";
import HomeIcon from "@material-ui/icons/Home";
import PollIcon from "@material-ui/icons/Poll";
import PostAddIcon from "@material-ui/icons/PostAdd";
import ListItemIcon from "@material-ui/core/ListItemIcon";

import "./header.scss";

class Header extends Component {
  state = {
    drawerCondition: false,
    logsArray: [],
    chartsArray: [],
    mainArray: [],
    toShow: "charts",
  };

  handleClick = () => {
    let open = this.state.open;
    this.setState({
      open: !open,
    });
  };

  componentDidMount() {
    const { data } = this.props;
    let logsArray = data.filter((item, index) => {
      return item.name.toLowerCase().indexOf("logs".toLowerCase()) !== -1;
    });
    let chartsArray = data.filter((item, index) => {
      return item.name.toLowerCase().indexOf("logs".toLowerCase()) === -1;
    });
    this.setState({
      logsArray,
      chartsArray,
      mainArray: chartsArray,
    });
  }

  typeSelect = (toShow) => {
    this.setState({
      mainArray:
        toShow === "charts" ? this.state.chartsArray : this.state.logsArray,
      drawerCondition: !this.state.drawerCondition,
    });
  };

  sidePanel = () => {
    const { mainArray } = this.state;
    let store_id = JSON.parse(window.localStorage.getItem("store_id"));
    const flag = store_id === "0";
    const side_panel_index = [2, 7, 10, 11, 12, 14];
    if (flag) {
      // If super admin login then will show all headers
      return mainArray.map((item, index) => (
        
        <NavLink
        activeClassName={"dashboard-menu-active"}
        className="render-dashboard-menu d-flex align-items-center mt-3 mr-2 py-1 pl-1 pr-2"
        key={index}
        to={item.url}
        onClick={() => this.setState({ drawerCondition: false })}
        >
          {/* {console.log("item", item.name,index)} */}
          <ListItemIcon>
            {item.icon && (
              <ListItemIcon>{<item.icon></item.icon>}</ListItemIcon>
            )}
          </ListItemIcon>
          {item.name}
        </NavLink>
      ));
    } else {
      return mainArray.map((item, index) => {
        // console.log("item", item.name,index)

        // If store admin login then will show this headers
        return !side_panel_index.includes(index) ? (
          <NavLink
            activeClassName={"dashboard-menu-active"}
            className="render-dashboard-menu d-flex align-items-center mt-3 mr-2 py-1 pl-1 pr-2"
            key={index}
            to={item.url}
            onClick={() => this.setState({ drawerCondition: false })}
          >
            <ListItemIcon>
              {item.icon && (
                <ListItemIcon>{<item.icon></item.icon>}</ListItemIcon>
              )}
            </ListItemIcon>
            {item.name}
          </NavLink>
        ) : null;
      });
    }
  };

  render() {
    const { drawerCondition } = this.state;
    return (
      <div className="dashboard-menu d-flex justify-content-around align-items-center">
        <div data-toggle="tooltip" data-placement="bottom" title="DashBoard">
          <HomeIcon
            style={{cursor:'pointer'}}
            onClick={() =>
              this.setState({ drawerCondition: false }, () =>
                this.props.history.push("overview")
              )
            }
          ></HomeIcon>
        </div>
        <PollIcon
            style={{cursor:'pointer'}}
            onClick={() => this.typeSelect("charts")}></PollIcon>
        {/* <PostAddIcon onClick={() => this.typeSelect("logs")}></PostAddIcon> */}
        <Drawer
          anchor={"top"}
          open={drawerCondition}
          onClose={() => this.setState({ drawerCondition: false })}
        >
          <div className="d-flex flex-wrap px-3 pb-3">{this.sidePanel()}</div>
        </Drawer>
      </div>
    );
  }
}

export default withRouter(Header);
