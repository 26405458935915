import { getForReport } from "../lib/request";
import jstz from "jstz";
import moment from "moment";

var timezone = jstz.determine();
let yourTimeZone = timezone.name();

const addUrlData = (apiInfo, start, end, storeId, component) => {
  if (component === "table") {
    return `?timezone=${yourTimeZone}&start_timestamp=${start}&end_timestamp=${end}&search=${apiInfo.brand}&skip=${apiInfo.pageNo}&limit=${apiInfo.pageLimit}&sort_by=${apiInfo.sortBy}&ascending=${apiInfo.order}&currency=${apiInfo.currency}&store_categories_id=${storeId}`;
  } else {
    return `?start_timestamp=${start}&end_timestamp=${end}&search=${apiInfo.brand}&skip=${apiInfo.pageNo}&limit=${apiInfo.pageLimit}&sort_by=${apiInfo.sortBy}&ascending=${apiInfo.order}&currency=${apiInfo.currency}&column=${apiInfo.column}&pie_column=${apiInfo.pieColumn}&store_categories_id=${storeId}`;
  }
};

// api calls
export const productPerformanceApi = (
  apiInfo,
  dimension,
  limit,
  component,
  storeId
) => {
  let start = moment(limit.startDate).unix();
  let end = moment(limit.endDate).unix();
  let dataUrl = "";
  let base = "";
  dataUrl = addUrlData(apiInfo, start, end, storeId, component);
  if (component === "table") {
    if (dimension === "Product") {
      base = `/top_product/product_data${dataUrl}`;
    } else if (dimension === "Product Category") {
      base = `/top_product/categories_data${dataUrl}`;
    } else {
      base = `/top_product/brand_data${dataUrl}`;
    }
  } else {
    if (dimension === "Product") {
      base = `/top_product/product_percent${dataUrl}`;
    } else if (dimension === "Product Category") {
      base = `/top_product/categories_percent${dataUrl}`;
    } else {
      base = `/top_product/brand_percent${dataUrl}`;
    }
  }
  return getForReport(base);
};

export const productPerformanceGraphApi = (
  apiInfo,
  limit,
  groupBy,
  storeId
) => {
  let start = moment(limit.startDate).unix();
  let end = moment(limit.endDate).unix();
  let URL = `/top_product/performance_graph?timezone=${yourTimeZone}&start_timestamp=${start}&end_timestamp=${end}&currency=${apiInfo.currency}&y_axis=${apiInfo.dropDownValue}&group_by=${groupBy.value}&store_categories_id=${storeId}`;
  return getForReport(URL);
};
