import React, { Component } from 'react';

// scss import
import "./header.scss"

class Header extends Component {
    render() {
        const { heading } = this.props;
        return (
            // <header className="d-flex flex-column heading-class f-245 px-3 py-2">
            <header className={`d-flex flex-column ${this.props.headingStyle ? this.props.headingStyle : ""  } f-245 py-2`}>
                <span className="hoverPathClass globalFontSize" style={{color: "dimgray"}} onClick={() => window.location.href = "/#/dashbord/overview"}><i className="fas fa-angle-left mr-2"></i>Reports</span>
                {heading}
            </header>
        );
    }
}

export default Header;