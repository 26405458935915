import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

import PieGraph from "./pieGraph";
import { getCurrencySymbol, getFromLocalStorage } from '../../../../lib/helper';

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: "#2A3F54",
        color: 'white',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}))(Tooltip);


class pieChat extends Component {
    constructor(props) {
        super(props)
        this.state = {
            order1: false,
            order2: false,
            order3: false,
            order4: false,
            order5: false,
            order6: false,
            columnsList: [
                { name: 'Transaction ID', title: "The transaction IDs of the ecommerce transactions." },
                { name: 'Revenue', title: "The total revenue from web ecommerce or in-app transactions. Depending on your implementation, this can include tax and shipping." },
                { name: 'Tax', title: "Tax is the total of tax charges for ecommerce transactions." },
                { name: 'Delivery', title: "Shipping is the total of shipping charges for ecommerce transactions." },
                { name: 'Refund Amount', title: "Currency amount refunded for a transaction." },
                { name: 'Quantity', title: "The number of units sold in ecommerce transactions." },
            ],
        };
    }

    dropDown(e, value, selectedDropDown) {
        let currentState = '';
        if (value !== '') {
            this.setState({ preState: value });
        } else {
            currentState = e.target.value;
            if (selectedDropDown === 'left') {
                this.props.pieGraph(this.state.preState, currentState, selectedDropDown, this.props.dropDownValuePie);
            } else {
                this.props.pieGraph(this.state.preState, currentState, selectedDropDown, this.props.dropDownValue);
            }
        }
    }

    check(e) {
        if (e.target.nodeName === 'SELECT') {
        } else {
            const { dropDownValue } = this.props;
            let order = dropDownValue === "Revenue"
                ? 2
                : dropDownValue === "Tax"
                    ? 3
                    : dropDownValue === "Delivery"
                        ? 4
                        : dropDownValue === "Refund Amount"
                            ? 5
                            : 6;
            this.orderFunction(this.props.dropDownValue, order);
        }
    }

    orderFunction(name, order) {
        this.setState({
            [`order${order}`]: !this.state[`order${order}`],
        }, () => this.props.callPie(name, this.state[`order${order}`]));
    }

    questionFunction = (name) => {
        name = name === 'Revenue'
            ? 1
            : name === 'Tax'
                ? 2
                : name === 'Delivery'
                    ? 3
                    : name === 'Refund Amount'
                        ? 4
                        : 5;
        return <LightTooltip title={this.state.columnsList[name].title} placement="bottom-start"><i className="fa fa-question-circle"></i></LightTooltip>
    }

    totalFunction = (dropDown, value) => {
        return (
            <div className="d-flex flex-column justify-content-center align-items-end" style={{ width: "100%", height: "100%" }}>
                <div className="fontsizeproduct" style={{ fontSize: "15px", marginLeft: "auto" }}>{`${dropDown === 'Quantity' ? value : `${getCurrencySymbol(getFromLocalStorage("currency"))}${value}`}`}</div>
                <div className="small fontsizeproducttwo" style={{ height: "20px", marginLeft: "auto" }}>{`% of Total: ${dropDown === 'Refund Amount' ? `0.00` : `100.00`}%`}</div>
                <div className="small fontsizeproducttwo" style={{ height: "20px", marginLeft: "auto" }}>{`(${dropDown === 'Quantity' ? `${value}` : `${getCurrencySymbol(getFromLocalStorage("currency"))}${value}`})`}</div>
            </div>
        );
    }

    render() {
        return (
            <div>
                <div className="heading d-flex">
                    <div style={{ width: "70%" }}>
                        <div className="d-flex" style={{ width: "100%", height: "50px" }}>
                            <div className="headingParts hoverClass" style={{ width: "40%", borderRight: "1px solid #ccc", paddingTop: "5px" }}
                                onClick={() => {
                                    if(this.props.groupBy === 2 || this.props.groupBy === "2") {
                                        return this.orderFunction("Country", 2)
                                    }
                                    if(this.props.groupBy === 3 || this.props.groupBy === "3") {
                                        return this.orderFunction("State", 3)
                                    }
                                   return this.orderFunction('Customer Name', 1)
                                }}
                            >
                                <div className="d-flex justify-content-between align-items-center" style={{ width: "100%", height: "100%" }}>
                                    <div className="fontsizeproduct" style={{ fontWeight: 500 }}>{parseInt(this.props.groupBy) === 1 ? 'Customer Name' : parseInt(this.props.groupBy) === 2 ? 'Country' : 'State'}</div>
                                    <div className="d-flex justify-content-between" style={{ width: "50px" }}>
                                        {
                                            this.props.show && this.props.apiInfo.sortBy === 'Customer Name'
                                                ? this.props.passOrder === 1
                                                    ? <i className="fas fa-arrow-down"></i>
                                                    : <i className="fas fa-arrow-up"></i>
                                                : ''
                                        }
                                        <LightTooltip title={parseInt(this.props.groupBy) === 1 ? this.state.columnsList[0].title : parseInt(this.props.groupBy) === 2 ? 'The country of the ecommerce transactions.' : 'The state of the ecommerce transactions.'} placement="bottom-start"><i className="fa fa-question-circle"></i></LightTooltip>
                                    </div>
                                </div>
                            </div>
                            <div className="headingParts hoverClass" style={{ width: "30%", borderRight: "1px solid #ccc", borderLeft: "none", paddingTop: "5px" }} onClick={(e) => this.check(e)}>
                                <div className="d-flex justify-content-between align-items-center" style={{ width: "100%", height: "100%" }}>
                                    <select className="dropDown fontsizeproduct" style={{ margin: 0 }} value={this.props.dropDownValue} name="dropDownValue"
                                        onFocus={() => this.dropDown('', this.props.dropDownValue, '')} onChange={(e) => this.dropDown(e, '', 'left')}
                                    >
                                        <option value="Revenue">Revenue</option>
                                        <option value="Tax">Tax</option>
                                        <option value="Shipping">Shipping</option>
                                        <option value="Refund Amount">Refund Amount</option>
                                        <option value="Quantity">Quantity</option>
                                    </select>
                                    <div className="d-flex justify-content-between" style={{ width: "50px" }}>
                                        {
                                            this.props.show && this.props.apiInfo.sortBy !== 'Customer Name'
                                                ? this.props.passOrder === 1
                                                    ? <i className="fas fa-arrow-down"></i>
                                                    : <i className="fas fa-arrow-up"></i>
                                                : ''
                                        }
                                        {this.questionFunction(this.props.apiInfo.sortBy)}
                                    </div>
                                </div>
                            </div>
                            <div className="headingParts hoverClass" style={{ width: "30%", borderRight: "1px solid #ccc", borderLeft: "none", paddingTop: "5px" }}>
                                <div className="d-flex justify-content-center align-items-center fontsizeproduct"
                                    style={{ width: "100%", height: "100%", fontWeight: 500 }}>
                                    {this.props.dropDownValuePie}
                                </div>
                            </div>
                        </div>
                        <div className="d-flex" style={{ width: "100%", height: "60px" }}>
                            <div className="headingParts" style={{ borderTop: "none", width: "40%", borderRight: "1px solid #ccc", paddingTop: "5px" }}>
                            </div>
                            <div className="headingParts" style={{ borderTop: "none", width: "30%", borderRight: "1px solid #ccc", borderLeft: "none", paddingTop: "5px" }}>
                                {this.totalFunction(this.props.dropDownValue, (this.props.pieData && this.props.pieData.summary && this.props.pieData.summary["column"].toFixed(2)) || 0   )}
                            </div>
                            <div className="headingParts" style={{ borderTop: "none", width: "30%", borderRight: "1px solid #ccc", borderLeft: "none", paddingTop: "5px" }}>
                                {this.totalFunction(this.props.dropDownValuePie, (this.props.pieData.summary['pie_column']).toFixed(2))}
                            </div>
                        </div>
                        <div style={{ width: "100%", borderRight: "1px solid #ccc" }}>
                            {
                                this.props.pieData.data.map((item, index) => (
                                    <div className="heading d-flex"  style={{ width: "100%", borderLeft: "1px solid #ccc", }} key={`${index}23`}>
                                        <div className="headingParts" style={{ width: "40%", borderTop: "none", borderLeft: "none", borderRight: "1px solid #ccc", paddingTop: "5px" }}>
                                            <div className="d-flex justify-content-start align-items-end fontsizeproduct" style={{ width: "100%", height: "100%" }}>
                                                <div style={{ width: "50px" }}>{this.props && this.props.page && this.props.page === 1 ? index + 1 : (parseInt(this.props.limit) * (this.props.page - 1) + index + 1 || index + 1)}.</div>
                                                <div>{item["Customer Name"] || item["Country"] || item["State"]}</div>
                                            </div>
                                        </div>
                                        <div className="headingParts" style={{ borderTop: "none", width: "30%", borderRight: "1px solid #ccc", borderLeft: "none", paddingTop: "5px" }}>
                                            <div className="d-flex flex-column justify-content-center align-items-end fontsizeproduct" style={{ width: "100%", height: "100%" }}>
                                                {this.props.dropDownValue === 'Quantity' ? item.column : `${getCurrencySymbol(getFromLocalStorage("currency"))}${(item.column).toFixed(2)}`}
                                            </div>
                                        </div>
                                        <div className="headingParts" style={{ borderTop: "none", width: "30%", borderRight: "none", borderLeft: "none", paddingTop: "5px" }}>
                                            <div className="d-flex flex-column justify-content-center align-items-end small fontsizeproducttwo" style={{ width: "100%", height: "100%" }}>
                                                {(item.pie_column).toFixed(2)}%
                                        </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div style={{ width: "30%", borderRight: "1px solid #ccc", borderBottom: "1px solid #ccc" }}>
                        <div className="headingParts hoverClass" style={{ width: "100%", height: "50px", borderLeft: "none", paddingTop: "5px" }}>
                            <div className="d-flex justify-content-center align-items-center" style={{ width: "100%", height: "100%" }}>
                                <select className="dropDown" value={this.props.dropDownValuePie} style={{ margin: 0 }} name="dropDownValuePie" onFocus={() => this.dropDown('', this.props.dropDownValuePie, '')} onChange={(e) => this.dropDown(e, '', 'right')}>
                                    <option value="Revenue">Revenue</option>
                                    <option value="Tax">Tax</option>
                                    <option value="Shipping">Shipping</option>
                                    <option value="Refund Amount">Refund Amount</option>
                                    <option value="Quantity">Quantity</option>
                                </select>
                            </div>
                        </div>
                        <div className="headingParts" style={{ borderTop: "none", height: "60px", width: "100%", borderLeft: "none", paddingTop: "5px" }}>
                        </div>
                        <div style={{ width: "100%" }}>
                            <PieGraph
                                graph={this.props.pieDataGraph}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default pieChat;