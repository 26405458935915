let orderPaymentConfig = {
  series: [
    {
      data: [], //xaxis data series point
    },
  ],
  options: {
    chart: {
      type: "area", // type of chart
      stacked: false,
      height: 350,
      zoom: {
        // zoom functionality handler in chart
        type: "x",
        enabled: false,
        autoScaleYaxis: true,
      },
      toolbar: {
        autoSelected: "zoom",
        show: false,
      },
    },
    dataLabels: {
      // to show the data point over the bar in graph
      enabled: false,
      background: {
        enabled: false,
      },
      // offsetY: -40
    },
    legend: {
      position: "top",
      horizontalAlign: "center",
      onItemClick: {
        toggleDataSeries: true,
      },
      onItemHover: {
        highlightDataSeries: true,
      },
    },
    // grid: {
    //   padding: {
    //     left: 40,
    //   },
    // },
    markers: {
      size: 0,
    },
    title: {
      text: "",
      align: "left",
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.5,
        opacityTo: 0,
        stops: [0, 90, 100],
      },
    },
    noData: {
      text: "Loading data ...",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: "12px",
      },
      xaxis: {
        show: false,
        style: {
          fontSize: "11px",
        },
      },
      yaxis: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
      title: {
        text: "",
        // offsetX: 120
      },
      style: {
        fontSize: "12px",
      },
    },
    xaxis: {
      type: "datetime",
      // tickPlacement: "on",
      categories: [],
      tooltip: {
        enabled: false,
      },
      labels: {
        style: {
          fontSize: "11px",
        },
      },
    },
    tooltip: {
      // shared: false,
      followCursor: true,
    },
  },
};

export { orderPaymentConfig };
