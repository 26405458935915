import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

import { Product, ProductCategory, ProductBrand } from "../../formatting/headingData";

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: "#2A3F54",
        color: 'white',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}))(Tooltip);

class THeader extends Component {
    state = {
        order1: false,
        order2: true,
        order3: false,
        order4: false,
        order5: false,
        order6: false,
        order7: false,
    };

    sortingFunction = (sortBy, order) => {
        this.setState({
            [`order${order}`]: !this.state[`order${order}`],
        }, () => this.props.changeOrder(sortBy, this.state[`order${order}`]));
    }

    render() {
        const { dimension, sortBy, orderIconState } = this.props;
        let headerData = "";
        if (dimension === "Product") {
            headerData = Product;
        } else if (dimension === "Product Category") {
            headerData = ProductCategory;
        } else {
            headerData = ProductBrand;
        }
        return (
            <div className="header-item-box d-flex mx-3 mt-3">
                {
                    headerData.map((item, index) => (
                        <div className={`header-item hover-header-item px-2 f-135 d-flex flex-column justify-content-around`}
                            style={index === 0 ? { width: "30%" } : { width: "11.67%" }}
                            key={`theader${index}`}
                            onClick={() => this.sortingFunction(item.name === "Product Refund" ? "Product Refund Amount" : item.name, index + 1)}
                        >
                            <div className={`${index !== 0 && "d-flex justify-content-end"}`}>{item.name}</div>
                            <div className={`d-flex ${index !== 0 && "justify-content-end"} align-items-center`}>
                                {index === 6 && <div style={{ padding: "0px 5px 4px 0px" }}>Amount</div>}
                                <LightTooltip
                                    title={item.title}
                                    placement="bottom-start"
                                    arrow
                                >
                                    <i className="fas fa-question-circle"></i>
                                </LightTooltip>
                                {
                                    sortBy === item.name
                                        ? <div className="pl-2">
                                            <i className={`fas fa-arrow-${orderIconState === 1 ? "up" : "down"}`}></i>
                                        </div>
                                        : ""
                                }
                            </div>
                        </div>
                    ))
                }
            </div>
        );
    }
}

export default THeader;