import { replaceBlankWithDash } from "../../lib/helper";
export const productSearchedLogsColumns = [
  {
    Header: "Search Item",
    accessor: data => replaceBlankWithDash(data.searchItem),
    id: "searchItem"
  },

  {
    Header: "Full Name",
    accessor: data => replaceBlankWithDash(data.fullName),
    id: "fullName"
  },

  {
    Header: "Create Date",
    accessor: data => replaceBlankWithDash(data.createDate),
    id: "createDate",
    minWidth: 110
  },

  {
    Header: "Platform Name",
    accessor: data => replaceBlankWithDash(data.platformName),
    id: "platformName"
  },

  {
    Header: "Ip Address",
    accessor: data => replaceBlankWithDash(data.ipAddress),
    id: "ipAddress"
  },

  {
    Header: "Latitude",
    accessor: data => replaceBlankWithDash(data.latitude),
    id: "latitude"
  },
  {
    Header: "Longitude",
    accessor: data => replaceBlankWithDash(data.longitude),
    id: "longitude"
  },
  {
    Header: "City Name",
    accessor: data => replaceBlankWithDash(data.cityName),
    id: "cityName"
  },

  {
    Header: "Country Name",
    accessor: data => replaceBlankWithDash(data.countryName),
    id: "countryName"
  },


];
