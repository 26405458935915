import React, { Component } from 'react';

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
// input component import
import Input from "../../InputBox/Input";
// countries fixtures
import { countries } from "../../../fixtures/dummyApi/countries";

// scss import
import "../main.scss";
import { getCurrencySymbol, getFromLocalStorage } from '../../../lib/helper';

class CurrencyComponent extends Component {
    state = {
        currencyFilter: {
            label: "Currency filter",
            input: {
                name: "currencyFilter",
                type: "text",
                value: "",
            },
        },
        filterData: [...countries],
        anchorEl: null,
        currencyInfo: {
            symbol: getCurrencySymbol(getFromLocalStorage("currency")),
            currency: getFromLocalStorage("currency"),
        },
    };

    componentDidUpdate(prevProp, prevState) {
        const { currencyInfo } = this.state;
        if (prevState.currencyInfo !== currencyInfo) {
            this.props.setCurrency(currencyInfo);
        }
    }

    filterCurrency = (e) => {
        this.setState({
            currencyFilter: {
                ...this.state.currencyFilter,
                input: {
                    ...this.state.currencyFilter.input,
                    value: e.target.value,
                }
            }
        });
        // let filterData = countries.filter((item, index) => {
        //     return item.alpha3Code.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1
        //         || item.name.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1;
        // });
        // this.setState({ filterData });

        let filterData = countries.filter((item, index) => {
            return (
              item.currencies.findIndex(item => item.code && item.code.toLowerCase().includes(e.target.value.toLowerCase())) !== -1 ||
              item.name.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1
            );
          });
        this.setState({ filterData });

    };


    currencySelected = (e) => {
        this.setState({ anchorEl: e.currentTarget });
    };

    selectCurrency = (symbol, currency) => {
        this.setState({
            currencyInfo: { symbol, currency },
        });
        this.handleClose();
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    render() {
        const { currencyInfo } = this.props;

        return (
            <div>
                <div className="d-flex justify-content-center align-items-center">
                    <div className="mr-2 f-16">Currency :</div>
                    <div className="currency-container d-flex justify-content-center align-items-center f-135"
                        onClick={(e) => this.currencySelected(e)}
                    >
                        {currencyInfo.currency}
                    </div>
                </div>
                <Menu
                    style={{ minHeight: "300px", maxHeight: "300px" }}
                    anchorEl={this.state.anchorEl}
                    open={Boolean(this.state.anchorEl)}
                    onClose={this.handleClose}
                >
                    <div className="currency-hover-class">
                        <MenuItem>
                            Currency:
                            <Input
                                id=""
                                className="filterCurrency"
                                {...this.state.currencyFilter.input}
                                onChange={(e) => this.filterCurrency(e)}
                            >
                            </Input>
                        </MenuItem>
                        {this.state.filterData.map((item, index) => (
                            <MenuItem key={index}
                                className="" onClick={() => this.selectCurrency(item.currencies[0].symbol, item.currencies[0].code)}>
                                <span>
                                    <img src={item.flags.svg} height={25} width={25} alt="currency" />
                                </span>
                                {/* <span>
                                    ^
                                </span> */}
                                <span className="pl-3 currencySymbol">{item.currencies[0].symbol}</span>
                                <span className="pl-3">{item.name.length > 15 ? `${(item.name).substring(0, 15)}....` : item.name}</span>
                            </MenuItem>
                        ))}
                    </div>
                </Menu>
            </div>
        );
    }
}

export default CurrencyComponent;