let config = {
    series: [],
    options: {
        labels: [],
        chart: {
            //Download Option with default Icon(three horizontal line) 
            toolbar: {
                show: false,
            },
            type: 'bar',
            height: 350,

            events: {
                dataPointSelection: (event, chartContext, config) => {
                    // A function will be called during the use of config
                }
            }
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '100%',
                // endingShape: 'rounded'
            },
        },
        noData: {
            text: "",
            align: "center",
            verticalAlign: "middle",
            offsetX: 0,
            offsetY: 0,
            style: {
               color: "#212121",
               fontSize: "18px",
               fontFamily: "robo",
            },
         },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        legend: {
            show: true,
            showForSingleSeries: false,
            position: "top",
            horizontalAlign: "right",
            // onItemClick: {
            //     toggleDataSeries: false
            // },
            onItemHover: {
                highlightDataSeries: false
            },
          },
        xaxis: {
            title: {
                text: ""
            },
            categories: [],
            
            labels: {
                style: {
                    fontSize: '11px'
                }
            }
        },
        grid: {
            padding: {
                left: 40
            }
        },
        yaxis: {
            labels: {
                formatter: function (val, index) {
                    return val.toFixed(0);
                }
            },
            title: {
                text: "",
             },
        },
        fill: {
            opacity: 1
        },
        responsive: [
            {
                breakpoint: 1000,
                options: {
                    plotOptions: {
                        bar: {
                            horizontal: false
                        }
                    },
                    legend: {
                        position: "top",
                        horizontalAlign: 'right'
                    }
                }
            }
        ],
        tooltip: {
            enabled: true,
            enabledOnSeries: true,
            y: {
                formatter: function (val) {
                    return val.toFixed(0)
                }
            },
            followCursor: true,
        }
    }
}

export { config }