//library import
import React from "react";
import moment from "moment";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
//custom import
import ReactSelect from "../../../components/reactSelect/reactSelect";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { dateFilter } from "../../../fixtures/dateFilter/dateFIlter";
import DateRangePicker from "../../../components/dateRangePicker/dateRangePicker";
import { countries } from "../../../fixtures/dummyApi/countries";
import "./Report.scss";
import PaginationJs from "../../../components/paginationJs/paginationJs";
import Modal from "../../../components/modal/modal";
import {
  getCurrentReportSubData,
  searchFilterWithColName,
  getFilteredData,
} from "../../../services/topSellingProductLogs";
import { fetchEditColumnData } from "../../../services/overview";
import { withRouter } from "react-router-dom";
import ReactToPrint from "react-to-print";
import Pbutton from "../../../components/button/Button";
import { CSVLink } from "react-csv";
import Snackbar from "../../../components/snackbar/snackbar";
import { getFromLocalStorage } from "../../../lib/helper";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  dataHeader: {
    width: "150px",
    fontSize: "10px",
    fontFamily: "Poppins",
    maxWidth: "170px",
    minWidth: "150px",
    textAlign: "center",
    fontWeight: 600,
    color: "#484848",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  data: {
    width: "150px",
    fontSize: "10px",
    fontFamily: "Poppins",
    maxWidth: "170px",
    minWidth: "150px",
    textAlign: "center",
  },
  menuItem: {
    width: "300px",
    paddingTop: "5px",
    paddingBottom: "5px",
  },
  selectCountryButton: {
    background: "#FFF",
    border: "1px solid #bbb",
    borderRadius: "4px",
    padding: "7px 12px",
    marginLeft: "10px",
  },
});

class Report extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      date: {
        //start date and end date
        startDate:
          localStorage.getItem("startDate") != null
            ? JSON.parse(localStorage.getItem("startDate"))
            : moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
        endDate:
          localStorage.getItem("endDate") != null
            ? JSON.parse(localStorage.getItem("endDate"))
            : moment().format("YYYY-MM-DD HH:mm:ss"),
      },
      storedIndex: "",
      loader: true, //loader state
      selected: { value: 0, label: "Hour" },
      selectData: [{ value: 0, label: "Hour" }],

      logsData: [], //table data
      columns: [], //table columns

      skip: 0, //starting point
      limit: 50, //ending point
      page: 1, //pagination page
      dataToShow: 50, //50 or 100 data to show
      //  totalData: Number,                              // total no of data

      summary: {},
      anchorEl: null,
      editColumnData: [],

      noData: false,
      modal: false,
      groupedOptions: [],
      filterArray: [
        {
          groupedOptions: [],
          groupedOptionsSelected: {},

          isIsNotData: [
            { label: "Is", value: "Is" },
            { label: "IsNot", value: "Is not" },
          ],
          isIsNotselected: [{ label: "Is", value: "Is" }],

          searchInput: "",
          searchOption: [{ label: "", value: "N/A" }],
          selectedOption: [],
        },
      ],
      filterSendToApi: [
        {
          column: "",
          condition_operator: 1,
          parameter_value: [],
        },
      ],
      seriesToRender: [],
      filterWindow: false,
      checklistDisplay: false,
      columnArray: [15, 16, 18, 19, 21, 22, 23, 25, 26, 27, 28],
      copyOfGroupedCols: [],
      showSnackbar: false,
      saveIndexOfDeletedObject: "",
      saveObjectOfDeletedObject: "",
      snackBarMessage: "",
      keysArr: [],
      currencyData: getFromLocalStorage("currency"),
      previousCurrencyData: getFromLocalStorage("currency"),
    };
  }

  searchHandler = (index, option) => {
    let filterArray = [...this.state.filterArray];
    let filter = [...this.state.filterSendToApi];
    for (let i in option) {
      if (option[i].label !== "N/A") {
        option[i].label =
          filterArray[index].isIsNotselected[0].value + " " + option[i].value;
      }
    }

    filterArray[index].selectedOption = option;
    let arr = [];
    if (option && option.length > 0) {
      // option.map(k => arr.push(k.label))
      option.map((k) => {
        let b = "";
        if (k.label.includes("Is not")) {
          b = k.label.substr(7, k.label.length - 1);
        } else {
          b = k.label.substr(3, k.label.length - 1);
        }
        return arr.push(b);
      });
    }
    this.setState({
      storedIndex: filterArray[index].groupedOptionsSelected.index,
    });
    filter[index].parameter_value = arr;
    this.setState({ filterArray, filterSendToApi: filter }, () => {});
  };

  createData = (obj) => {
    let a = [];
    let arr = Object.keys(obj[0]).map((k) => a.push(k));
    return arr;
  };

  // this function takes index of the box and values (text)
  changeOption = (index, value) => {
    let filterArray = [...this.state.filterArray];
    let filter = [...this.state.filterSendToApi];
    let cols = [...this.state.groupedOptions];

    let valSeached = [];
    // if(value) {
    if (value.length > 3) {
      searchFilterWithColName(
        filterArray[index].groupedOptionsSelected.index,
        value
      )
        .then((data) => {
          if (
            this.state.storedIndex !==
            filterArray[index].groupedOptionsSelected.index
          ) {
            filterArray[index].selectedOption = [];
            this.setState({ filterArray });
          }

          let optionsArr = [];
          Object.keys(data.data.data.search_result).map((k) =>
            data.data.data.search_result[k].map((x) =>
              optionsArr.push({ label: x, value: x })
            )
          );
          filterArray[index].searchOption = optionsArr;
          Object.keys(data.data.data.search_result).map((k) =>
            valSeached.push(...data.data.data.search_result[k])
          );
          this.setState({ filterArray });
          let indexToDelete;
          for (let i in cols)
            for (let j in cols[i].options)
              if (
                cols[i].options[j].index ===
                filterArray[index].groupedOptionsSelected.index
              )
                indexToDelete = { mainIndex: i, subIndex: j };

          cols[indexToDelete.mainIndex].options.splice(
            indexToDelete.subIndex,
            1
          );
          this.setState({ groupedOptions: cols });
        })
        .catch((err) => console.log("{changeOption err}", err));
    }
    // }
    this.setState({ filterSendToApi: filter });
  };

  checklistHandler = (event) => {
    let a = [...this.state.columnArray];
    if (a.includes(event)) {
      let index = a.indexOf(event);
      a.splice(index, 1);
    } else {
      a.splice(1, 0, event);
    }
    this.setState({ checklistDisplay: false, columnArray: a }, () => {
      this.getData(
        this.state.date.startDate,
        this.state.date.endDate,
        parseInt(this.state.selected.value),
        a
      );
    });
  };

  selectHandler = (option) => {
    this.setState({ selected: option, loader: true });
    this.dateRender();
  };

  dateRender = (date) => {
    if (this.state.selected.label === "Day") {
      return moment(date).format("Do MMMM");
    } else if (this.state.selected.label === "Month") {
      return moment(date).format("MMM GGGG");
    } else if (this.state.selected.label === "Quarter") {
      return date;
    } else if (this.state.selected.label === "Year") {
      return date;
    } else if (
      this.state.selected.label === "Hour" ||
      this.state.selected.label === "Hour of Day"
    ) {
      return moment(date).format("DD-MMMM-YYYY LT");
    }
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  returnDataWithTwoDecsOrInteger = (n) => {
    if (Number(n) === n && n % 1 === 0) {
      return n;
    } else if (Number(n) === n && n % 1 !== 0) {
      return parseFloat(n.toFixed(2));
    } else if (String(n)) {
      return n;
    }
  };

  groupBySortByDays = (arg1, arg2) => {
    // 86400000 => 1 day
    // 604800000 => week
    // 2592000000 => month
    // 7776000000 => quarter (3 months)

    // 31104000000 => 1 year
    let calc = new Date(arg2).getTime() - new Date(arg1).getTime();
    if (calc <= 86400000) {
      // 6 days 23 hr 59 sec
      let stateObject = { ...this.state };
      stateObject.selectData = [
        { value: "0", label: "Hour" },
        { value: "6", label: "Hour of Day" },
      ];
      this.setState(stateObject, () => {});
    } else if (calc >= 86400001 && calc <= 604800000) {
      let stateObject = { ...this.state };
      stateObject.selectData = [
        { value: "0", label: "Hour" },
        { value: "1", label: "Day" },
        { value: "7", label: "Day of week" },
        { value: "2", label: "Week" },
      ];
      this.setState(stateObject, () => {});
    } else if (calc >= 604800001 && calc <= 2592000000) {
      let stateObject = { ...this.state };
      stateObject.selectData = [
        { value: "0", label: "Hour" },
        { value: "1", label: "Day" },
        { value: "2", label: "Week" },
        { value: "3", label: "Month" },
      ];
      this.setState(stateObject, () => {});
    } else if (calc >= 2592000001 && calc <= 7776000000) {
      let stateObject = { ...this.state };
      stateObject.selectData = [
        { value: "0", label: "Hour" },
        { value: "1", label: "Day" },
        { value: "2", label: "Week" },
        { value: "3", label: "Month" },
        { value: "4", label: "Quarter" },
      ];
      this.setState(stateObject, () => {});
    } else if (calc >= 7776000001 && calc <= 31104000000) {
      let stateObject = { ...this.state };
      stateObject.selectData = [
        { value: "0", label: "Hour" },
        { value: "1", label: "Day" },
        { value: "2", label: "Week" },
        { value: "3", label: "Month" },
        { value: "4", label: "Quarter" },
        { value: "5", label: "Year" },
      ];
      this.setState(stateObject, () => {});
    } else if (calc >= 31104000001) {
      let stateObject = { ...this.state };
      stateObject.selectData = [
        { value: "0", label: "Hour" },
        { value: "1", label: "Day" },
        { value: "2", label: "Week" },
        { value: "3", label: "Month" },
        { value: "4", label: "Quarter" },
        { value: "5", label: "Year" },
      ];
      this.setState(stateObject, () => {});
    }
  };

  handleRange = (...arges) => {
    let startDate =
      arges[0].length !== 0 ? arges[0][0].format("YYYY-MM-DD HH:mm:ss") : null;
    let endDate =
      arges[0].length !== 0 ? arges[0][1].format("YYYY-MM-DD HH:mm:ss") : null;
    // let stateObject = { ...this.state };
    // let date = { ...stateObject.date };
    // date.startDate = startDate;
    // date.endDate = endDate;
    // stateObject.date = date;
    // stateObject.loader = true;

    this.setState({
      startDate: startDate,
      endDate: endDate,
    });
    // this.setState(stateObject, () => {
    //   this.groupBySortByDays(stateObject.date.startDate, stateObject.date.endDate);
    // });
    // this.getSubCategoty(startDate, endDate);

    // if (startDate === null || endDate === null)
    //   this.setState({ date: [startDate, endDate], compare: false });
    // else
    //   this.setState({ date: [startDate, endDate], compare: true });
  };

  //set data to show and respectively set SKIP and LIMIT
  dataToShowHandler = (newVal) => {
    let stateCopy = { ...this.state };

    let newPage;

    if (newVal === 100) {
      newPage = Math.trunc(
        stateCopy.page % 2 === 0 ? stateCopy.page / 2 : stateCopy.page / 2 + 1
      );
    } else {
      newPage = stateCopy.page * 2 - 1;
    }

    stateCopy.page = newPage;
    stateCopy.skip = stateCopy.page * newVal - newVal;
    stateCopy.limit = stateCopy.page * newVal;
    stateCopy.dataToShow = newVal;
    stateCopy.loader = true;
    this.setState(stateCopy);
  };

  // change pagination page  respectively set SKIP and LIMIT
  changeActivePage = (page) => {
    let state = { ...this.state };

    state.skip = page * state.dataToShow - state.dataToShow;
    state.limit = page * state.dataToShow;

    state.page = page;
    state.loader = true;
    this.setState(state);
  };

  //get new data
  componentDidUpdate = (prevProps, prevState) => {
    //old params and new params are diff than get new data
    if (
      JSON.stringify(this.state.date) !== JSON.stringify(prevState.date) ||
      this.state.page !== prevState.page ||
      this.state.dataToShow !== prevState.dataToShow ||
      JSON.stringify(this.state.selected) !==
        JSON.stringify(prevState.selected) ||
      this.state.currencyData !== prevState.currencyData ||
      prevState.startDate !== this.state.startDate ||
      prevState.endDate !== this.state.endDate

      //  || (JSON.stringify(this.state.selectedCity) !== JSON.stringify(prevState.selectedCity))
    ) {
      this.getData(
        this.state.startDate,
        this.state.endDate,
        this.state.currencyData
        // this.state.columnArray
        //  this.state.selectedCity.value
      );
    }

    //old params and new params are same ...close loader
    else if (this.state.loader === true) this.setState({ loader: false });
  };

  componentWillMount() {
    let sd =
      localStorage.getItem("startDate") != null
        ? JSON.parse(localStorage.getItem("startDate"))
        : this.state.date.startDate;
    let ed =
      localStorage.getItem("endDate") != null
        ? JSON.parse(localStorage.getItem("endDate"))
        : this.state.date.endDate;
    this.getData(sd, ed, this.state.currencyData);
    localStorage.removeItem("startDate");
    localStorage.removeItem("endDate");
    localStorage.removeItem("columns");
    localStorage.removeItem("sortBy");
  }

  //get initial data on didmount
  componentDidMount = () => {
    fetchEditColumnData().then((data) => {
      let anotherObj = [];
      Object.keys(data.data.columns).map((k, i) =>
        Object.keys(data.data.columns[k]).map((u, w) => {
          anotherObj.push({ label: u, options: [{ index: "", value: "" }] });
          anotherObj[k]["options"] = data.data.columns[k][u].map((x) => {
            return { label: x.value, value: x.value, index: x.index };
          });
        })
      );
      this.setState({
        editColumnData: data.data.columns,
        copyOfGroupedCols: anotherObj,
      });
    });
  };

  toggleModal = () => {
    this.setState({ modal: !this.state.modal });
  };

  closeFilterBox = () => {
    this.setState({
      filterWindow: false,
      saveIndexOfDeletedObject: "",
      saveObjectOfDeletedObject: "",
      groupedOptions: this.state.copyOfGroupedCols,
      filterArray: [
        {
          groupedOptions: [],
          groupedOptionsSelected: {},

          isIsNotData: [
            { label: "Is", value: "Is" },
            { label: "IsNot", value: "Is not" },
          ],
          isIsNotselected: [{ label: "Is", value: "Is" }],
          searchInput: "",
          searchOption: [{ label: "", value: "N/A" }],
          selectedOption: [],
        },
      ],
      filterSendToApi: [
        {
          column: "",
          condition_operator: 1,
          parameter_value: [],
        },
      ],
    });
  };

  applyFilter = () => {
    getFilteredData(
      moment(this.state.date.startDate).unix(),
      moment(this.state.date.endDate).unix(),
      this.state.filterSendToApi
    )
      .then((data) => {
        this.methodToPopulateDataAfterFilter(data.data.data);
      })
      .catch((err) => {
        console.log(err.message);
        this.setState({
          showSnackbar: true,
          snackBarMessage: "No data found for selected filter.",
        });
        setTimeout(() => {
          this.setState({ showSnackbar: false });
        }, 2000);
      });
    this.setState({
      filterWindow: false,
      groupedOptions: this.state.copyOfGroupedCols,
      filterArray: [
        {
          groupedOptions: [],
          groupedOptionsSelected: {},

          isIsNotData: [
            { label: "Is", value: "Is" },
            { label: "IsNot", value: "Is not" },
          ],
          isIsNotselected: [{ label: "Is", value: "Is" }],
          searchInput: "",
          searchOption: [{ label: "", value: "N/A" }],
          selectedOption: [],
        },
      ],
      filterSendToApi: [
        {
          column: "",
          condition_operator: 1,
          parameter_value: [],
        },
      ],
    });
  };

  // this method will copy the data which i have received with filtered API,
  // and display it on table and graph
  methodToPopulateDataAfterFilter = (bigObject) => {
    // let mgraph = bigObject.graph;
    let columns = Object.keys(bigObject.table[0]); // column name from api
    let filterColumns = this.state.editColumnData.map((item) => {
      return {
        value: item,
        label: item,
      };
    });

    let anotherObj = [];

    Object.keys(this.state.editColumnData).map((k, i) =>
      Object.keys(this.state.editColumnData[k]).map((u, w) => {
        anotherObj.push({ label: u, options: [{ index: "", value: "" }] });
        anotherObj[k]["options"] = this.state.editColumnData[k][u].map((x) => {
          return { label: x.value, value: x.value, index: x.index };
        });
      })
    );

    this.setState({ groupedOptions: anotherObj });
    const groupedOptionsSelected = filterColumns[0];
    let cold = columns.map((item, index) => {
      // modified according to table structure
      return {
        Header: item[0].toUpperCase() + item.slice(1),
        accessor: (data) => {
          if (
            item.toLowerCase().includes("date") &&
            this.state.selected.value < 3
          )
            return moment(Object.values(data)[index]).format("DD-MMMM-YYYY LT");
          else if (item.toLowerCase().includes("orders"))
            return Object.values(data)[index];
          else {
            let type = typeof Object.values(data)[index];
            if (type === "number")
              return (
                Object.values(data)[index] &&
                Object.values(data)[index].toFixed(2)
              );
            else return Object.values(data)[index];
          }
        },
        id: item,
        show: true,
        width: 150,
      };
    });

    let obj = bigObject.summary;
    obj["Summary"] = "Summary";

    this.setState({
      // seriesToRender: this.createNewArrayToRender(mgraph.series[0].data, getOnlyOrders),
      columns: cold,
      logsData: bigObject.table,
      // graph: mgraph,
      summary: bigObject.summary,
      // groupedOptions: anotherObj,
      groupedOptionsSelected,
      loader: false,
      noData: false,
    });
  };

  //method to fetch data
  getData = (startDate, endDate, currency) => {
    getCurrentReportSubData(
      moment(startDate).unix(),
      moment(endDate).unix(),
      currency
    )
      .then((data) => {
        // let cities = [{ value: "", label: "All Cities" }];

        // data.data.cityData.map((item) => {
        //   cities.push({ value: item, label: item })
        // })
        let columns = Object.keys(data.data.data.table[0]); // column name from api
        columns[0] = "DateTime";
        // let filterColumns = this.state.editColumnData.map((item) => {
        //   return {
        //     value: item,
        //     label: item,
        //   };
        // });
        // const groupedOptions = [
        //   {
        //     label: "All Rows",
        //     options: filterColumns,
        //   },
        // ];
        // let anotherObj = [];

        // Object.keys(this.state.editColumnData).map((k, i) => {
        //   Object.keys(this.state.editColumnData[k]).map((u, w) => {
        //     anotherObj.push({ label: u, options: [{ index: "", value: "" }] });
        //     anotherObj[k]["options"] = this.state.editColumnData[k][u].map((x) => {
        //       return { label: x.value, value: x.value, index: x.index };
        //     });
        //   });
        // });
        // copyOfGroupedOptions = [...anotherObj]
        // this.setState({ groupedOptions: anotherObj });
        // const groupedOptionsSelected = filterColumns[0];
        let cold = columns.map((item, index) => {
          // modified according to table structure
          return {
            Header: item[0].toUpperCase() + item.slice(1),
            accessor: (data) => {
              if (
                item.toLowerCase().includes("date") &&
                this.state.selected.value < 1
              )
                return moment(Object.values(data)[index]).format(
                  "DD-MMMM-YYYY LT"
                );
              else if (item.toLowerCase().includes("orders"))
                return Object.values(data)[index];
              else {
                let type = typeof Object.values(data)[index];
                if (type === "number")
                  return (
                    Object.values(data)[index] &&
                    Object.values(data)[index].toFixed(2)
                  );
                else return Object.values(data)[index];
              }
            },
            id: item,
            show: true,
            width: 150,
          };
        });
        // let obj = data.data.data.summary;
        // let getOnlyOrders = data.data.data.table.map((k) => k.order);

        this.setState({
          // seriesToRender: this.createNewArrayToRender(mgraph.series[0].data, getOnlyOrders),
          columns: cold,
          logsData: data.data.data.table,
          // graph: mgraph,
          summary: data.data.data.table[0],
          // groupedOptions: anotherObj,
          // groupedOptionsSelected,
          loader: false,
          noData: false,
          previousCurrencyData: this.state.currencyData,
        });
      })
      .catch((e) => {
        console.log(e, "errrrrrrrrrrrrr");
        this.setState({
          loader: false,
          noData: false,
          snackBarMessage: "Data not found for given query.",
          showSnackbar: true,
          currencyData: this.state.previousCurrencyData,
        });
        setTimeout(() => {
          this.setState({ snackBarMessage: "", showSnackbar: false });
        }, 1500);
      });

    localStorage.removeItem("startDate");
    localStorage.removeItem("endDate");
    localStorage.removeItem("columns");
    localStorage.removeItem("sortBy");
  };

  // onPageChange = arges => {
  // };

  createNewArrayToRender = (arr, anotherArray) => {
    let arrayToPush = [];
    let object = {};
    arr.map((k, i) => {
      return (
        (object = {}),
        (object["y"] = k),
        (object["Order"] = anotherArray[i]),
        arrayToPush.push(object)
      );
    });
    return arrayToPush;
  };

  addFilter = () => {
    let stateObj = { ...this.state };
    let filterArray = [...stateObj.filterArray];
    let filterObj = [...stateObj.filterSendToApi];
    filterObj.push({
      column: "",
      condition_operator: 1,
      parameter_value: [],
    });
    filterArray.push({
      isIsNotData: [
        { value: "Is", label: "Is" },
        { value: "IsNot", label: "Is not" },
      ],
      isIsNotselected: [{ value: "Is", label: "Is" }],
      searchInput: "",
      selectedOption: [],
      searchOption: [{ value: "", label: "N/A" }],
    });

    this.setState({ filterArray, filterSendToApi: filterObj });
  };

  getCurrencyData = (obj) => {
    this.setState({ currencyData: obj.currencies[0].code });
    this.handleClose();
  };

  removeFilter = (index) => {
    let filterArray = [...this.state.filterArray];
    let filter = [...this.state.filterSendToApi];

    filter.splice(index, 1);
    filterArray.splice(index, 1);

    this.setState({ filterArray, filterSendToApi: filter });
  };

  changeHandler = (index, option) => {
    let filterArray = [...this.state.filterArray];
    let filter = [...this.state.filterSendToApi];
    filterArray[index].isIsNotselected = [option];
    filter[index].condition_operator = option.label === "Is" ? 1 : 0;
    let option2 = filterArray[index].selectedOption;

    for (let i in option2) {
      if (option2[i].label !== "N/A") {
        option2[i].label =
          filterArray[index].isIsNotselected[0].value + " " + option2[i].value;
      }
    }

    filterArray[index].selectedOption = option2;

    this.setState({ filterArray, filterSendToApi: filter });
  };

  goBack = () => {
    this.props.history.push("report");
    this.props.changePage();
  };

  groupedOptionsSelectedHandler = (index, option) => {
    let filterArray = [...this.state.filterArray];
    let cols = [...this.state.groupedOptions];
    let filter = [...this.state.filterSendToApi];
    filterArray[index].groupedOptionsSelected = option;
    filter[index].column = option.index;

    searchFilterWithColName(option.index, "").then((data) => {
      if (this.state.storedIndex !== option.index) {
        filterArray[index].selectedOption = [];
        if (
          this.state.saveIndexOfDeletedObject.mainIndex &&
          this.state.saveObjectOfDeletedObject
        ) {
          cols[this.state.saveIndexOfDeletedObject.mainIndex].options.push(
            this.state.saveObjectOfDeletedObject
          );
        }
        this.setState({ filterArray, groupedOptions: cols });
      }

      let optionsArr = [];
      Object.keys(data.data.data.search_result).map((k) =>
        data.data.data.search_result[k].map((x) =>
          optionsArr.push({ label: x, value: x })
        )
      );
      filterArray[index].searchOption = optionsArr;
      let indexToDelete;
      let objectOfIndexWhichIsDeleted;
      for (let i in cols)
        for (let j in cols[i].options)
          if (
            cols[i].options[j].index ===
            filterArray[index].groupedOptionsSelected.index
          ) {
            indexToDelete = { mainIndex: i, subIndex: j };
            objectOfIndexWhichIsDeleted = cols[i].options[j];
          }
      cols[indexToDelete.mainIndex].options.splice(indexToDelete.subIndex, 1);
      this.setState({
        groupedOptions: cols,
        filterArray,
        saveIndexOfDeletedObject: indexToDelete,
        saveObjectOfDeletedObject: objectOfIndexWhichIsDeleted,
      });
    });

    this.setState({ filterSendToApi: filter });
  };

  // save button
  openNewUrl = () => {
    const url = window.location.href;
    localStorage.setItem("columns", JSON.stringify(this.state.columnArray));
    localStorage.setItem(
      "startDate",
      JSON.stringify(this.state.date.startDate)
    );
    localStorage.setItem("endDate", JSON.stringify(this.state.date.endDate));
    localStorage.setItem(
      "sortBy",
      JSON.stringify(parseInt(this.state.selected.value))
    );
    window.open(url, "_blank");
  };

  render() {
    const { classes } = this.props;
    return (
      <div className="reportWrapper" ref={this.myRef}>
        {/* <iframe id="ifmcontentstoprint" style="height: 0px; width: 0px; position: absolute"></iframe> */}
        <div className="head">
          <div
            className="text-grey cursor-pointer globalFontSize"
            onClick={this.goBack}
          >
            <i className="fas fa-angle-left mr-2"></i>Reports
          </div>
          <div className="title">Top Selling Products</div>
          <div className="d-flex text-grey mt-2">
            <div className="mr-3 db_ptr">
              <ReactToPrint
                trigger={() => (
                  <span>
                    <i className="fas fa-print mr-1"></i>Print
                  </span>
                )}
                content={() => this.myRef.current}
              />
            </div>
            <div className="mr-3 db_ptr" onClick={this.toggleModal}>
              <i className="fas fa-download mr-1"></i>Export
            </div>
            <div className="mr-3 db_ptr" onClick={this.openNewUrl}>
              <i className="far fa-copy mr-1"></i>Save as
            </div>
          </div>
          <div className="d-flex align-items-center globalFontSize">
            <span className="mr-2">Date Range</span>
            <div className="mt-2 mb-2">
              <DateRangePicker
                // onChange={this.onDateChange}
                ranges={{ ...dateFilter }}
                value={[this.state.date.startDate, this.state.date.endDate]}
                onChange={this.handleRange}
              />
            </div>

            {/* <span className="mr-2 ml-5 f-15">Group By</span>
            <div className="selectBody">
              <ReactSelect city={this.state.selectData} change={this.selectHandler} selected={this.state.selected} />
            </div> */}

            <div className="sortByCurrency">
              Currency
              <Button
                className={classes.selectCountryButton}
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={this.handleClick}
              >
                {this.state.currencyData}
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={this.state.anchorEl}
                keepMounted
                open={Boolean(this.state.anchorEl)}
                onClose={this.handleClose}
              >
                {countries.map((k) => (
                  <MenuItem
                    className={classes.menuItem}
                    onClick={() => this.getCurrencyData(k)}
                  >
                    <span>
                      <img src={k.flags.svg} height={25} width={25} alt="currency" />
                    </span>
                    {/* <span>^</span> */}
                    <span className="pl-3 currencySymbol">
                      {k.currencies[0].symbol}
                    </span>
                    <span className="pl-3">{k.name}</span>
                  </MenuItem>
                ))}
              </Menu>
            </div>
          </div>
        </div>
        <div className="body">
          {this.state.noData ? (
            <div className="noData">
              <div>No Data for selected Date</div>
            </div>
          ) : (
            <div>
              <div className="table productClickLogs">
                {/* {
                    //conditional rendering of loader
                    this.state.loader && <Loader />
                  } */}

                {/* table wrapper */}
                <div className="tableWrapper">
                  <div className="d-flex justify-content-between table-head">
                    <div
                      className="px-3 py-2 rounded filter-button f-15"
                      onClick={() => this.setState({ filterWindow: true })}
                    >
                      Manage Filters
                    </div>

                    <div className="edit-column-wrapper">
                      <div
                        className="px-3 py-2 rounded filter-button f-15"
                        onClick={() =>
                          this.setState({
                            checklistDisplay: !this.state.checklistDisplay,
                          })
                        }
                      >
                        Edit Columns<i className="fas fa-caret-down ml-2"></i>
                      </div>

                      {this.state.checklistDisplay && (
                        <div className="row-list py-1">
                          {this.state.editColumnData.map((k, i) => {
                            return (
                              <div key={i}>
                                {Object.keys(k).map((o) => {
                                  return (
                                    <div key={o}>
                                      <div
                                        className="border-bottom objectKey"
                                        key={k}
                                      >
                                        {o}
                                      </div>
                                      {k[o].map((v, w) => (
                                        <div
                                          className="px-4 py-2 list d-flex"
                                          key={v}
                                          onClick={() =>
                                            this.checklistHandler(v.index)
                                          }
                                        >
                                          {this.state.columnArray.length > 0 &&
                                            this.state.columnArray.map((k) =>
                                              k === v.index ? (
                                                <i className="fas fa-check px-2"></i>
                                              ) : (
                                                ""
                                              )
                                            )}
                                          {v.value}
                                        </div>
                                      ))}
                                    </div>
                                  );
                                })}
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </div>

                  {this.state.filterWindow && (
                    <div className="manage-filters-window">
                      <div className="manage-filters-box">
                        <div className="d-flex justify-content-between py-4 px-3 border-bottom manage-head">
                          <div>Manage Filters</div>
                          <div onClick={this.closeFilterBox}>
                            <i className="fas fa-times cursor-pointer"></i>
                          </div>
                        </div>
                        <div className="overflow">
                          <div className="p-3 filter-options">
                            {this.state.filterArray.length > 0 ? (
                              <div>
                                {this.state.filterArray.map((item, index) => {
                                  return (
                                    <div key={index}>
                                      <div className="d-flex">
                                        <div className="w-100">
                                          {/* <ReactSelectGrouped
                                            groupedOptions={this.state.groupedOptions && this.state.groupedOptions}
                                            groupedOptionsSelectedHandler={this.groupedOptionsSelectedHandler.bind(
                                              this,
                                              index
                                            )}
                                          /> */}
                                        </div>
                                        <div className="ml-2">
                                          <ReactSelect
                                            city={
                                              this.state.filterArray[index]
                                                .isIsNotData
                                            }
                                            isSearchable={false}
                                            selected={
                                              this.state.filterArray[index]
                                                .isIsNotselected
                                            }
                                            change={this.changeHandler.bind(
                                              this,
                                              index
                                            )}
                                          />
                                        </div>
                                        <div
                                          className="ml-2 delete cursor-pointer"
                                          onClick={() =>
                                            this.removeFilter(index)
                                          }
                                        >
                                          <i className="fas fa-trash-alt "></i>
                                        </div>
                                      </div>
                                      <div className="mt-2 search pb-3 border-bottom mb-3">
                                        <i className="fas fa-search"></i>
                                        <ReactSelect
                                          city={
                                            this.state.filterArray[index]
                                              .searchOption
                                          }
                                          isSearchable={true}
                                          selected={
                                            this.state.filterArray[index]
                                              .selectedOption
                                          }
                                          change={this.searchHandler.bind(
                                            this,
                                            index
                                          )}
                                          placeholder="Search"
                                          isMulti={true}
                                          changeOption={this.changeOption.bind(
                                            this,
                                            index
                                          )}
                                        />
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            ) : (
                              <div className="no-filter-text">
                                You currently have no filters set.
                              </div>
                            )}

                            <div className="mt-4">
                              <span
                                className="add-filter cursor-pointer"
                                onClick={this.addFilter}
                              >
                                Add Filter
                              </span>
                            </div>
                          </div>
                          <div className="mt-3 p-3 border-top d-flex justify-content-end">
                            <div></div>
                            <div
                              className="add-filter cursor-pointer"
                              onClick={this.closeFilterBox}
                            >
                              Cancel
                            </div>
                            <div
                              className="apply-filter ml-2 cursor-pointer"
                              onClick={this.applyFilter}
                            >
                              Apply Filters
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <Paper className={classes.root + " react-table"}>
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          {this.state.columns.map((k, i) => (
                            <TableCell className={classes.dataHeader} key={i}>
                              {k.Header}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.dataHeader}>
                            Summary
                          </TableCell>
                          {Object.values(this.state.summary)
                            .splice(1)
                            .map((k, i) => (
                              <TableCell className={classes.dataHeader} key={i}>
                                {this.returnDataWithTwoDecsOrInteger(k)}
                              </TableCell>
                            ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.logsData.map((row, i) => {
                          return (
                            <TableRow
                              key={i}
                              style={
                                i % 2 === 0
                                  ? { background: "#F6F6F6" }
                                  : { background: "#FFF" }
                              }
                            >
                              <TableCell
                                className={classes.data}
                                component="th"
                                scope="row"
                              >
                                {this.dateRender(row.dateTime)}
                              </TableCell>
                              {Object.values(row)
                                .splice(1)
                                .map((k) => (
                                  <TableCell
                                    className={classes.data}
                                    component="th"
                                    scope="row"
                                  >
                                    {this.returnDataWithTwoDecsOrInteger(k)}
                                  </TableCell>
                                ))}
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </Paper>
                  {/* <ReactTable
                    summary={this.state.summary}
                    // onPageChange={this.onPageChange.bind(this)}
                    lable="Total Sales"
                    columns={this.state.columns}
                    data={this.state.logsData}
                    noDataText="No review logs"
                    handleRange={this.handleRange.bind(this)}
                  /> */}
                </div>

                {/* paginationWrapper */}
                <div className="paginationWrapper">
                  <PaginationJs
                    changeActivePage={this.changeActivePage}
                    activePage={this.state.page}
                    // totalData={this.state.totalData}
                    dataToShowHandler={this.dataToShowHandler}
                    dataToShow={this.state.dataToShow}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggleModal}
          width={"35%"}
        >
          <div className="col-12 px-0">
            <div className="py-3 reportModal-header pl-3 border-bottom">
              Export your report
            </div>
            <div className="py-3 reportModal-subText pl-3 border-bottom">
              Report will be exported as a CSV (comma separated values) table.
            </div>
            <div className="py-3 col-12">
              <div className="row justify-content-end">
                <Pbutton
                  onClick={this.toggleModal}
                  className="reportModal-cancelBtn"
                >
                  Cancel
                </Pbutton>

                <CSVLink
                  onClick={() => this.toggleModal()}
                  data={this.state.logsData}
                  filename={"my-file.csv"}
                  className="reportModal-exportBtn"
                  target="_blank"
                >
                  Export
                </CSVLink>
              </div>
            </div>
          </div>
        </Modal>
        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackBarMessage}
        />
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(Report));
