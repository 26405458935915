import { getForReport } from "../lib/request";

// dashboardTopSellingProduct api
export const dashboardTopSellingProduct = () => {
  return getForReport(`http://www.mocky.io/v2/5eb14e21320000810028f5c6`);
};

export const fetchEditColumnData = () => {
  return getForReport(
    `/top-product/current-report?start_timestamp=1577729800&end_timestamp=1588755386&currency=USD`
  );
  // return getForReport(`http://13.127.140.33:8091/python-spark/column-name`);
};

export const searchFilterWithColName = (colName, string) => {
  return getForReport(
    `/python-spark/column-search?column_name=${colName}&search=${string}`
  );
};

export const getFilteredData = (timeStamp1, timeStamp2, filterObj) => {
  let filteredObj = encodeURIComponent(JSON.stringify(filterObj));
  return getForReport(
    `/python-spark/filter-report?start_timestamp=${timeStamp1}&end_timestamp=${timeStamp2}&filter=${filteredObj}`
  );
  // return getForReport(`/python-spark/filter?filter=${filterObj}`);
};

export const getCurrentReportSubData = (
  startTime,
  endTime,
  currency,
  columnArray = []
) => {
  if (columnArray.length === 1) {
    return getForReport(
      `/top-product/current-report?start_timestamp=${startTime}&end_timestamp=${endTime}&currency=${currency}`
      // `/total-order/current-report?start_timestamp=${startTime}&end_timestamp=${endTime}&group_by=${groupBy}&currency=${currency}`
    );
  } else {
    return getForReport(
      `/top-product/current-report?start_timestamp=1577729800&end_timestamp=1588755386&currency=${currency}`
      // `/top-product/current-report?start_timestamp=${startTime}&end_timestamp=${endTime}&currency=${currency}`
    );
  }
};
