/** @format */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
// import Loader from "../../../../components/loader/Loader";
// import { connect } from "react-redux";
import moment from "moment";

// name.charAt(0).toUpperCase() + name.slice(1)  capetilize firstLetter

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 600,
  },
});

function StickyHeadTable(props) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const formatDateTime = (dateTime) => {
    if (props.groupBy === 0) {
      return moment(dateTime).format("ll H:00");
    } else if (props.groupBy === 1) {
      return moment(dateTime).format("ll");
    } else if (props.groupBy === 2) {
      return moment(dateTime).format("ll");
    }
    // else if (props.groupBy === 3) {
    //    return moment(dateTime).format("MMMM YYYY");
    // }
    else if (props.groupBy === 6) {
      return moment(dateTime).format("H:00");
    } else {
      return dateTime;
    }
  };
  const handleChangePage = (event, newPage) => {
    event.preventDefault();
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  let columns;
  if (!props.loader && props.tableData.length) {
    // { id: 'name', label: 'Name', minWidth: 170 }
    columns = Object.keys(props.tableData[0]);
    // columns = columns.slice(0, columns.length-1);
    columns = columns.map((columnName) => {
      return {
        id: columnName,
        label: columnName.charAt(0).toUpperCase() + columnName.slice(1), //capetilize firstLetter
      };
    });
  }

  if (props.loader) {
    return <div></div>;
  } else if (props.tableData.length === 0) {
    return <div></div>;
  }
  return (
    <Paper className={classes.root}>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>{columns[0].label}</TableCell>
              {columns.map((column, index) => {
                if (index) {
                  return (
                    <TableCell key={index} align="center">
                      {column.label}
                    </TableCell>
                  );
                }
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.tableData
              .slice(0, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    key={index}
                    // style={
                    //    index % 2 == 0 ? { background: "#F6F6F6" } : { background: "#FFF" }
                    // }
                  >
                    {columns.map((column) => {
                      let value = row[column.id];
                      if (
                        props.groupBy <= 7 &&
                        column.id !== "Total Count" &&
                        column.id !== "Unique Count" &&
                        column.id !== "index_x" &&
                        column.id !== "index_y"
                      ) {
                        return (
                          <TableCell
                            // className={classes.data}
                            style={{ fontSize: "12px" }}
                            component="th"
                            align="left"
                            // style={{ minWidth: 200 }}
                            min
                            scope="row"
                          >
                            {formatDateTime(value)}
                          </TableCell>
                        );
                      }
                      return (
                        <TableCell
                          // className={classes.data}
                          style={{ fontSize: "12px" }}
                          key={column.id}
                          align="center"
                        >
                          {value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={props.tableData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Paper>
  );
}

export default StickyHeadTable;
