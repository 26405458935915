import React, { Component } from "react";
import Link from "../../../../components/Link/Link";
import CartTable from "../table/productCartTable";
import RevLoader from "../../../../components/loader/RevLoader";
// currencyFormatter.format(10000, {code: "USD"})

class topCartProduct extends Component {
  render() {
    return (
      <div className="card">
        <div className="spaceBetween mt-3 ml-3 mr-3">
          <span className="label globalFontSize fontweightone">
            Top products In cart
          </span>
          <span className="link globalFontSize fontweightone">
            <Link url={`cart-list`}>View Activity</Link>
          </span>
        </div>
        {/* <div className="spaceBetween ml-3 mr-3">
          <span className="numericValue">
            {this.props.loader ? <RevLoader /> : this.props.totalOrder ? `${currencyFormatter.format(parseInt(this.props.totalOrder), { code: this.props.currency})}` : `${getSymbolFromCurrency(this.props.currency)} ` + 0}
            {this.props.loader ? <RevLoader /> : this.props.totalSale ? `${getSymbolFromCurrency(this.props.currency)} ` + parseInt(this.props.totalSale) : `${getSymbolFromCurrency(this.props.currency)} ` + 0}
            {this.props.loader ? <RevLoader /> : <></>}
          </span>
        </div> */}
        {/* <div className="m-3 graphTitle">
                <span>Sales Activity</span>
              </div> */}
        <div className="chart">
          <CartTable
            className="top-n-table"
            table={this.props.table}
            loader={this.props.loader}
          />
        </div>
      </div>
    );
  }
}

export default topCartProduct;
