// import { getForReport } from "../lib/request";
// import axois from "axios";
import { getForReport } from "../lib/request";

export const HeatMapAPI = (
  startDate,
  endDate,
  group_by,
  StoreCategoryId,
  country_id,
  city_id,
  zone_id,
  skip,
  limit
) => {
  return getForReport(
    `/python-spark/heatmap?start_timestamp=${startDate}&end_timestamp=${endDate}&group_by=${group_by}&country_id=${country_id}&city_id=${city_id}&zone_id=${zone_id}&store_categories_id=${StoreCategoryId}&skip=${skip}&limit=${limit}`
  );
};

export const getCountries = () => {
  return getForReport("/python-spark/countries");
};

export const getCities = (country_id) => {
  return getForReport(`/python-spark/cities?country_id=${country_id}`);
};

export const getZones = (city_id) => {
  return getForReport(`/python-spark/zones?city_id=${city_id}`);
};
