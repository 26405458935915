import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import { TableContainer } from "@material-ui/core";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import getSymbolFromCurrency from "currency-symbol-map";

const useStyles = makeStyles({
  table: {
    // minWidth: 650,
    width: "100%",
    height: "100%",
  },
});

export default function SimpleTable(props) {
  const classes = useStyles();
  let columns;

  let valueFormatter = (value, index) => {
    if (value >= 10000 && value < 1000000) {
      let kValue = value / 1000;
      return kValue.toFixed(2) + " K";
    }
    if (value >= 100000) {
      let kValue = value / 1000000;
      return kValue.toFixed(2) + " M";
    }
    return Number(value.toFixed(2));
  };

  if (!props.table.length) {
    return (
      <div
        className="text-center no-data"
        style={{ fontSize: "12px", fontWeight: 400 }}
      >
        There were no top-brands in this date range.
      </div>
    );
  }
  if (props.table.length) {
    columns = Object.keys(props.table[0]);
  }

  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "#2A3F54",
      color: "white",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip);

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            {columns.map((columnName, index) => {
              return (
                <TableCell
                  style={{ fontSize: "12px", fontWeight: 500 }}
                  align={index ? "right" : "left"}
                >
                  {columnName.charAt(0).toUpperCase() +
                    columnName.slice(1, columnName.length)}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.table.map((dict, index) => (
            <TableRow key={index}>
              {columns.map((keys, index) => {
                if (keys.startsWith("%")) {
                  return (
                    <TableCell component="left">
                      <div
                        className="progress text-center"
                        data-toggle="tooltip"
                        title={dict[keys] + "%"}
                      >
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{ width: dict[keys] + "%" }}
                          aria-valuenow="50"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </TableCell>
                  );
                } else if (index === 1) {
                  // ${getSymbolFromCurrency(this.props.currency)}
                  return (
                    <TableCell
                      component="right"
                      align="right"
                      style={{ fontSize: "12px" }}
                    >
                      {keys === "Quantity"
                        ? ""
                        : getSymbolFromCurrency(props.currency)+" "}
                      {valueFormatter(dict[keys])}
                    </TableCell>
                  );
                }
                return (
                  <TableCell component="left" style={{ fontSize: "12px" }}>
                    <LightTooltip
                      title={dict[keys]}
                      placement="bottom-start"
                      arrow
                    >
                      <span>
                        {dict && dict[keys] && dict[keys].length > 25
                          ? dict[keys].slice(0, 25) + "..."
                          : dict[keys]}
                      </span>
                    </LightTooltip>
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
