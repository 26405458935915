import { get } from "../lib/request";
import moment from "moment";
export const fetchEmailLogs = (
  startDate = moment(),
  endDate = moment(),
  skip = 0,
  limit = 50
) => {
  return get(`/python/email/logs?skip=${skip}&limit=${limit}`, {
    startDate: startDate,
    endDate: endDate,
  });
};
