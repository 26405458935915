import React, { Component } from 'react';

class TotalData extends Component {
    constructor(props) {
        super(props)
        this.state = {

        };
    }

    render() {
        return (
            <div className="headingParts"
                style={this.props.index === 0
                    ? { width: "25%", flexDirection: "column", justifyContent: "space-between", alignItems: "flex-start", borderTop: "none", padding: 0 }
                    : this.props.index === 5
                        ? { borderRight: "1px solid #ccc", borderTop: "none", padding: 0 }
                        : { borderTop: "none", padding: 0 }}
            >
                {
                    this.props.index !== 0 &&
                    <div className="d-flex flex-column justify-content-between" style={{height: "100%", padding: "0px 5px 5px 0px"}}>
                        <div className="fontsizeproduct" style={{fontSize: "15px", marginLeft: "auto" }}>{this.props.index === 2 ? this.props.totalValue : `${this.props.symbol}${this.props.totalValue}`}</div>
                        <div className="small fontsizeproducttwo" style={{ height: "20px", marginLeft: "auto" }}>{`% of Total: ${this.props.index === 4 ? `0.00` : `100.00`}%`}</div>
                        <div className="small fontsizeproducttwo" style={{ height: "20px", marginLeft: "auto"}}>{`(${this.props.index === 2 ? this.props.totalValue : `${this.props.symbol}${this.props.totalValue}`})`}</div>
                    </div>
                }
            </div>
        );
    }
}

export default TotalData;