import React, { Component } from "react";
// import Loader from "../../../../components/loader/Loader";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import ReactSelect from "../../../../components/reactSelect/reactSelect";
import ReactChipSelect from "../../../../components/reactChipSelect";
import ReactSelectGrouped from "../../../../components/reactSelect/reactSelectGrouped";
import PaginationJs from "../../../../components/paginationJs/paginationJs";
import Tooltip from "@material-ui/core/Tooltip";
// import Loader from "../../../components/loader/Loader";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  dataHeader: {
    width: "150px",
    fontSize: "10px",
    fontFamily: "Poppins",
    maxWidth: "170px",
    minWidth: "150px",
    textAlign: "center",
    fontWeight: 600,
    color: "#484848",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  data: {
    width: "150px",
    fontSize: "10px",
    fontFamily: "Poppins",
    maxWidth: "170px",
    minWidth: "150px",
    textAlign: "center",
  },
  menuItem: {
    width: "300px",
    paddingTop: "5px",
    paddingBottom: "5px",
  },
  selectCountryButton: {
    background: "#FFF",
    border: "1px solid #bbb",
    borderRadius: "4px",
    padding: "7px 12px",
    marginLeft: "10px",
  },
});
class index extends Component {
  constructor() {
    super();
    this.state = {
      filterWindow: false,
    };
  }

  render() {
    const { classes } = this.props;
    return (
      <div className="table productClickLogs">
        {/* {
                    //conditional rendering of loader
                    this.props.loader && <Loader />
                } */}

        {/* table wrapper */}
        <div className="tableWrapper">
          <div className="d-flex justify-content-between table-head">
            <div
              className="px-3 py-2 rounded filter-button f-15"
              onClick={(e) => this.props.changeFilterWindowState(e)}
            >
              Manage Filters
            </div>

            <div className="edit-column-wrapper">
              <div
                className="px-3 py-2 rounded filter-button f-15"
                onClick={this.props.isCheckListDisplay}
              >
                Edit Columns<i className="fas fa-caret-down ml-2"></i>
              </div>

              {this.props.checklistDisplay && (
                <div className="row-list py-1">
                  {this.props.editColumnData.map((k, i) => {
                    return (
                      <div key={i}>
                        {Object.keys(k).map((o) => {
                          return (
                            <div key={o}>
                              <div className="border-bottom objectKey" key={k}>
                                {o}
                              </div>
                              {k[o].map((v, w) => (
                                <div
                                  className="px-4 py-2 list d-flex"
                                  key={v}
                                  onClick={() =>
                                    this.props.checklistHandler(v.index)
                                  }
                                >
                                  {this.props.columnArray.length > 0 &&
                                    this.props.columnArray.map((k) =>
                                      k === v.index ? (
                                        <i className="fas fa-check px-2"></i>
                                      ) : (
                                        ""
                                      )
                                    )}
                                  {v.value}
                                </div>
                              ))}
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>

          {this.props.filterWindow && (
            <div className="manage-filters-window">
              <div className="manage-filters-box">
                <div className="d-flex justify-content-between py-4 px-3 border-bottom manage-head">
                  <div>Manage Filters</div>
                  <div onClick={this.props.closeFilterBox}>
                    <i className="fas fa-times cursor-pointer"></i>
                  </div>
                </div>
                <div className="overflow">
                  <div className="p-3 filter-options">
                    {this.props.filterArray.length > 0 ? (
                      <div>
                        {this.props.filterArray.map((item, index) => {
                          return (
                            <div key={index}>
                              <div className="d-flex">
                                <div className="w-100">
                                  <ReactSelectGrouped
                                    groupedOptions={
                                      this.props.groupedOptions &&
                                      this.props.groupedOptions
                                    }
                                    groupedOptionsSelectedHandler={(option) =>
                                      this.props.groupedOptionsSelectedHandler(
                                        index,
                                        option
                                      )
                                    }
                                  />
                                </div>
                                <div className="ml-2">
                                  <ReactSelect
                                    city={
                                      this.props.filterArray[index].isIsNotData
                                    }
                                    isSearchable={false}
                                    selected={
                                      this.props.filterArray[index]
                                        .isIsNotselected
                                    }
                                    change={(option) =>
                                      this.props.changeHandler(index, option)
                                    }
                                  />
                                </div>
                                <div
                                  className="ml-2 delete cursor-pointer"
                                  onClick={() => this.props.removeFilter(index)}
                                >
                                  <i className="fas fa-trash-alt "></i>
                                </div>
                              </div>
                              <div className="mt-2 search pb-3 border-bottom mb-3">
                                <i className="fas fa-search"></i>
                                <ReactChipSelect
                                  city={
                                    this.props.filterArray[index].searchOption
                                  }
                                  isSearchable={true}
                                  selected={
                                    this.props.filterArray[index].selectedOption
                                  }
                                  change={(option) => this.props.searchHandler(index, option)}
                                  placeholder="Search"
                                  isMulti={true}
                                  changeOption={(value) =>
                                    this.props.changeOption(index, value)
                                  }
                                />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <div className="no-filter-text">
                        You currently have no filters set.
                      </div>
                    )}

                    <div className="mt-4">
                      <span
                        className="add-filter cursor-pointer"
                        onClick={this.props.addFilter}
                      >
                        Add Filter
                      </span>
                    </div>
                  </div>
                  <div className="mt-3 p-3 border-top d-flex justify-content-end">
                    <div></div>
                    <div
                      className="add-filter cursor-pointer"
                      onClick={this.props.closeFilterBox}
                    >
                      Cancel
                    </div>
                    <div
                      className="apply-filter ml-2 cursor-pointer"
                      onClick={this.props.applyFilter}
                    >
                      Apply Filters
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <Paper className={classes.root + " react-table"}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  {this.props.showTable &&
                    this.props.columns.map((k, i) => (
                      <Tooltip title={k.Header} placement="top">
                        <TableCell className={classes.dataHeader} key={i}>
                          {k.Header}
                        </TableCell>
                      </Tooltip>
                    ))}
                </TableRow>
              </TableHead>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.dataHeader}>Summary</TableCell>
                  {Object.values(this.props.summary)
                    .splice(1)
                    .map((k, i) => (
                      <TableCell className={classes.dataHeader} key={i}>
                        {this.props.returnDataWithTwoDecsOrInteger(k)}
                      </TableCell>
                    ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.logsData.map((row, i) => {
                  return (
                    <TableRow
                      key={i}
                      style={
                        i % 2 === 0
                          ? { background: "#F6F6F6" }
                          : { background: "#FFF" }
                      }
                    >
                      <TableCell
                        className={classes.data}
                        component="th"
                        scope="row"
                      >
                        {this.props.dateRender(row.dateTime)}
                      </TableCell>
                      {Object.values(row)
                        .splice(1)
                        .map((k) => (
                          <TableCell
                            className={classes.data}
                            component="th"
                            scope="row"
                          >
                            {this.props.returnDataWithTwoDecsOrInteger(k)}
                          </TableCell>
                        ))}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Paper>
          {/* <ReactTable
                    summary={this.state.summary}
                    // onPageChange={this.onPageChange.bind(this)}
                    lable="Total Sales"
                    columns={this.state.columns}
                    data={this.state.logsData}
                    noDataText="No review logs"
                    handleRange={this.handleRange.bind(this)}
                  /> */}
        </div>

        {/* paginationWrapper */}
        <div className="paginationWrapper">
          <PaginationJs
            changeActivePage={this.props.changeActivePage}
            activePage={this.props.page}
            // totalData={this.state.totalData}
            dataToShowHandler={this.props.dataToShowHandler}
            dataToShow={this.props.dataToShow}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(index);
