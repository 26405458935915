//library import
import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import Masonry from "react-masonry-css";

// Api Imports
import {
  dashboardTotalOrder,
  dashboardTotalSales,
  dashboardTopSellingProduct,
  dashboardTopWishlistProduct,
  dashboardTopCartProduct,
  dashboardAverageSales,
  dashboardTopBrands,
  dashboardOnlineStoreConversion,
  dashboardOrderPayment,
} from "../../../services/overview";
import { storeIdApiCall } from "../../../services/storeId";
import { salesConfig } from "./graphConfig/totalSales";
import { orderConfig } from "./graphConfig/totalOrders";
import { averageSaleConfig } from "./graphConfig/averageSaleConfig";
import { orderPaymentConfig } from "./graphConfig/orderPaymentConfig";
import Headers from "./header/index.js";
import { setIndex } from "../../../redux/actions/auth";
import TotalSalesGraph from "./graphs/totalSales";
import TotalOrdersGraph from "./graphs/totalOrders";
import TopNProductTable from "./graphs/topNProducts";
import TopProductCartTable from "./graphs/topProductInCart";
import TopWishlistProductTable from "./graphs/topProductInWishlist";
import TopBrandLoader from "./graphs/topBrand";
import AverageSales from "./graphs/averageSales";
import OnlineStoreConversion from "./graphs/onlineStoreConversion";
import async from "async";
import Snackbar from "../../../components/snackbar/snackbar";
import { tooltipFormatter, isFloat, setInLocalStorage, getFromLocalStorage } from " ../../../src/lib/helper";

// import css
import "./Overview.scss";
import "../global-font-css.css";
import StoreIdDropDown from "../../../components/StoreIdDropdown";

// import local-storage
import ls from "local-storage";
import BookingPayment from "./graphs/BookingPayment";

class CategoryLogs extends React.Component {
  state = {
    checked: false,
    loader: true, //loader state
    selected: { value: "previousPeriod", label: "Pervious Period" },
    selectData: [
      { value: "previousPeriod", label: "Pervious Period" },
      { value: "previousYear", label: "Pervious Year" },
    ],
    compare: true,
    dbData1: "",
    dbData2: "",
    dbData3: "",
    salesConfig: salesConfig,
    ordersConfig: orderConfig,
    averageSaleConfig: averageSaleConfig,
    orderPaymentConfig: orderPaymentConfig,
    topNProductTable: [],
    topBrandTable: [],
    topNLoader: true,
    salesLoader: true,
    orderLoader: true,
    onlineConversionStoreLoader: true,
    averageSaleLoader: true,
    orderPaymentLoader: true,
    totalSales: 0,
    totalOrders: 0,
    averageSale: 0,
    totalOrderPayment: 0,
    groupBy: 0,
    startDate: moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
    endDate: moment().format("YYYY-MM-DD HH:mm:ss"),
    selectedCurrency: getFromLocalStorage("currency"),
    previousCurrency: getFromLocalStorage("currency"),
    dataForCurrency: getFromLocalStorage("currency"),
    groupByName: { value: 0, label: "Hour" },
    isOpenSnackbar: false,
    snackbarMessage: "",
    top: 10,
    topWishLoader: true,
    topWishListProductTable: [],
    wishlistProductCount: 0,
    topCartProductTable: [],
    topCartLoader: true,
    topBrandLoader: true,
    storeIdValue: "0",
    storeData: [{ id: 0, name: "--No Data--" }],
    dataLoaded: false,
    sortBy: 1,
    onlineStoreConversionData: null,
  };

  valueFormatter = (value) => {
    if (value >= 10000 && value < 1000000) {
      let kValue = value / 1000;
      let isDecimal = kValue % 1;
      if (isDecimal) {
        return kValue.toFixed(0) + " K";
      }
      return kValue.toFixed(0) + " K";
    }
    if (value >= 100000) {
      let kValue = value / 1000000;

      return kValue.toFixed(2) + " M";
    }
    return value && Number(value.toFixed(2));
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.sortBy !== this.state.sortBy) {
      this.setState({ topBrandLoader: true });
      this.getTopBrandDataOnChangeSortBy(
        moment(this.state.startDate).unix(),
        moment(this.state.endDate).unix(),
        this.state.groupBy,
        this.state.selectedCurrency
      );
    }

    if (
      prevState.selectedCurrency !== this.state.selectedCurrency ||
      prevState.groupBy !== this.state.groupBy ||
      prevState.startDate !== this.state.startDate ||
      prevState.endDate !== this.state.endDate ||
      prevState.storeIdValue !== this.state.storeIdValue
    ) {
      this.setState({
        salesLoader: true,
        orderLoader: true,
        averageSaleLoader: true,
        orderPaymentLoader: true,
        topNLoader: true,
        topCartLoader: true,
        topCartProductTable: [],
        topWishLoader: true,
        topBrandLoader: true,
        onlineConversionStoreLoader: true,
      });
      this.getData(
        moment(this.state.startDate).unix(),
        moment(this.state.endDate).unix(),
        this.state.groupBy,
        this.state.selectedCurrency
      );
    }
  }

  componentDidMount = () => {
    let width = window.innerWidth;
    this.setState({ width });
    window.addEventListener("resize", this.heightHandler);
    const { startDate, endDate, groupByName } = this.state;
    localStorage.setItem("startDate", startDate);
    localStorage.setItem("endDate", endDate);
    localStorage.setItem("selectedGroupBy", JSON.stringify(groupByName));

    this.getData(
      moment(this.state.startDate).unix(),
      moment(this.state.endDate).unix(),
      this.state.groupBy,
      this.state.selectedCurrency
    );
    this.getAllStoreId();
  };

  getAllStoreId = () => {
    storeIdApiCall()
      .then((res) => {
        this.setState({
          storeData: res.data.data,
          dataLoaded: true,
        });
      })
      .catch((err) => {
        console.log("error store id", err);
        this.setState({
          dataLoaded: false,
        });
      });
  };

  getGraphConfigData = (response, config) => {
    let newConfig = { ...this.state.config };
    try {
      newConfig.series = response.data.graph.series;
      let format = {
        Hour: "H:00",
        Day: "ll",
        Week: "ll",
        Month: "MMM",
        // "Hour Of Day": "HH:00",
      };
      let xCategories;
      if (this.state.groupByName.label === "Hour") {
        xCategories = response.data.graph.xaxis.categories.map((dateTime) => {
          return moment(dateTime).format(format[this.state.groupByName.label]);
        });
      } else if (
        this.state.groupByName.label === "Day" ||
        this.state.groupByName.label === "Week"
      ) {
        xCategories = response.data.graph.xaxis.categories.map((dateTime) => {
          return moment(dateTime).format(format[this.state.groupByName.label]);
        });
      } else {
        xCategories = response.data.graph.xaxis.categories;
      }

      let label = this.state.groupByName.label;
      let tickAmount = xCategories.length > 7 ? 5 : xCategories.length;
      let xaxisType =
        xCategories.length < 8 ||
        label === "Hour" ||
        label !== "Day" ||
        label === "Year" ||
        label === "Quarter" ||
        label === "Day Of Week"
          ? "category"
          : "datetime";
      let tickPlacement = xaxisType !== "datetime" ? "between" : "on";
      let formatter =
      config === "sales"
        ? (value, index) => {
            if (value >= 10000 && value < 1000000) {
              let kValue = value / 1000;
              return Number(kValue.toFixed(2)) + " K";
            }
            if (value >= 1000000 && value < 1000000000 ) {
              let kValue = value / 1000000;
              return Number(kValue.toFixed(2)) + " M";
            }
            
            if (value >= 1000000000 && value < 1000000000000) {
              let kValue = value / 1000000000;
              return Number(kValue.toFixed(2)) + " B";
            }
            
            if (value >= 1000000000000) {
              let kValue = value / 1000000000000;
              return Number(kValue.toFixed(2)) + " T";
            }
            return value && Number.isInteger(value) ? Number(value.toFixed()) : isFloat(value) ? value.toFixed(2) : value;
          }
        : (val, index) => (isFloat(val) ? val.toFixed(2) : val);
              
      newConfig.options = {
        ...newConfig.options,
        dataLabels: {
          enabled: false,
          background: {
            enabled: false,
          },
        },
        legend: {
          position: "top",
          showForSingleSeries: true,
          horizontalAlign: "center",
          onItemClick: {
            toggleDataSeries: true,
          },
          onItemHover: {
            highlightDataSeries: true,
          },
        },
        chart: {
          toolbar: {
            show: false,
          },
          zoom: {
            type: "x",
            enabled: false,
            autoScaleYaxis: true,
          },
        },
        xaxis: {
          title: {
            offsetY: -5,
            text: response.data.graph.xaxis.title,
            style: {
              fontSize: "11px",
              fontWeight: 700,
            },
          },
          categories: xCategories,
          type: xaxisType,
          tickAmount: tickAmount,
          tickPlacement: tickPlacement,
          tooltip: {
            enabled: false,
          },

          labels: {
            // offsetX: 100,
            // offsetY: 20,
            show: true,
            rotate: -45,
            hideOverlappingLabels: true,
            rotateAlways: true,
            trim: false,
            style: { fontSize: "11px" },
            formatter: function (value, timestamp, index) {
              let isValid = moment(value).isValid() && label !== "Year";
              return isValid ? moment(value).format(format[label]) : value;
            },
          },
        },

        yaxis: {
          title: {
            text: response.data.graph.yaxis.title,
            style: {
              fontSize: "11px",
              fontWeight: 700,
            },
          },
          labels: {
            show: true,
            offsetX: 5,
            formatter: formatter,
          },
        },
        tooltip: {
          y: {
            formatter:
              config !== "payment"
                ? (val, series) => {
                    return tooltipFormatter(
                      val,
                      this.state.dataForCurrency,
                      true
                    );
                  }
                : undefined,
            title: {
              formatter: (seriesName) => seriesName,
            },
          },
        },
      };
      return newConfig;
    } catch (error) {
      console.log("Error in Chart Configuration ", error);
      return error;
    }
  };

  getData = (start_date, end_date, groupBy, selectedCurrency) => {
    setInLocalStorage("currency", selectedCurrency);
    async.parallel(
      {
        totalSales: (callback) => {
          dashboardTotalSales(
            start_date,
            end_date,
            groupBy,
            selectedCurrency,
            this.state.storeIdValue
          )
            .then((response) => {
              try {
                let config = this.getGraphConfigData(response, "sales");
                this.setState({
                  salesConfig: config,
                  totalSales: response.data.total,
                  salesLoader: false,
                  dataForCurrency: this.state.selectedCurrency,
                });
              } catch (error) {
                this.setState({
                  salesLoader: false,
                  totalSales: 0,
                  salesConfig: {
                    ...salesConfig,
                    options: {
                      ...salesConfig.options,
                      noData: {
                        ...salesConfig.options.noData,
                        title: "No data for selected date range",
                      },
                      xaxis: { labels: { show: false } },
                    },
                  },
                });
                setTimeout(() => {
                  this.setState({
                    salesLoader: false,
                  });
                }, 1000);
                callback(error, null);
              }
            })
            .catch((error) => {
              if (
                error &&
                error.response &&
                error.response.data &&
                error.response.data.message === "currency conversion not found"
              ) {
                this.setState({
                  isOpenSnackbar: true,
                  salesLoader: true,
                  snackbarMessage: "selected currency is not supported",
                  selectedCurrency: this.state.previousCurrency,
                });
                setTimeout(() => {
                  this.setState({
                    isOpenSnackbar: false,
                    salesLoader: false,
                    snackbarMessage: "",
                  });
                }, 1000);
              } else {
                this.setState({
                  salesLoader: true,
                  totalSales: 0,
                  isOpenSnackbar: true,
                  snackbarMessage:
                    error.response && error.response.status === 500
                      ? "Internal Server Error"
                      : "No data found!",
                  salesConfig: {
                    ...salesConfig,
                    options: {
                      ...salesConfig.options,
                      noData: {
                        ...salesConfig.options.noData,
                        text: "No data for selected date range",
                        style: {
                          fontSize: "12px",
                          fontWeight: 400,
                          fontFamily: "sans-serif",
                          color: "#263238",
                        },
                      },
                      xaxis: { labels: { show: false } },
                    },
                  },
                });
                setTimeout(() => {
                  this.setState({
                    salesLoader: false,
                    isOpenSnackbar: false,
                    snackbarMessage: "",
                  });
                }, 1000);
              }
              callback(error, null);
            });
        },
        totalOrders: (callback) => {
          dashboardTotalOrder(
            start_date,
            end_date,
            groupBy,
            selectedCurrency,
            this.state.storeIdValue
          )
            .then((response) => {
              try {
                let config = this.getGraphConfigData(response, "sales");
                this.setState({
                  orderLoader: false,
                  totalOrders: response.data.total,
                  ordersConfig: config,
                  dataForCurrency: this.state.selectedCurrency,
                });
              } catch (error) {
                this.setState({
                  orderLoader: true,
                  totalOrders: 0,
                  isOpenSnackbar: true,
                  snackbarMessage:
                    error.response && error.response.status === 500
                      ? "Internal Server Error"
                      : "No data found!",
                  ordersConfig: {
                    ...orderConfig,
                    options: {
                      ...orderConfig.options,
                      noData: {
                        ...orderConfig.options.noData,
                        text: "No data for selected date range",
                      },
                      xaxis: { labels: { show: false } },
                    },
                  },
                });
                setTimeout(() => {
                  this.setState({
                    orderLoader: false,
                    isOpenSnackbar: false,
                    snackbarMessage: "",
                  });
                }, 1000);
                callback(error, null);
              }
            })
            .catch((error) => {
              if (
                error &&
                error.response &&
                error.response.data &&
                error.response.data.message === "currency conversion not found"
              ) {
                this.setState({
                  isOpenSnackbar: true,
                  orderLoader: true,
                  snackbarMessage: "selected currency is not supported",
                  selectedCurrency: this.state.previousCurrency,
                });
                setTimeout(() => {
                  this.setState({
                    isOpenSnackbar: false,
                    orderLoader: false,
                    snackbarMessage: "",
                  });
                }, 1000);
              } else {
                this.setState({
                  orderLoader: true,
                  totalOrders: 0,
                  isOpenSnackbar: true,
                  snackbarMessage:
                    error.response && error.response.status === 500
                      ? "Internal Server Error"
                      : "No data found!",
                  ordersConfig: {
                    ...orderConfig,
                    options: {
                      ...orderConfig.options,
                      noData: {
                        ...orderConfig.options.noData,
                        text: "No data for selected date range",
                        style: {
                          fontSize: "12px",
                          fontWeight: 400,
                          fontFamily: "sans-serif",
                          color: "#263238",
                        },
                      },
                      xaxis: { labels: { show: false } },
                    },
                  },
                });
                setTimeout(() => {
                  this.setState({
                    orderLoader: false,
                    isOpenSnackbar: false,
                    snackbarMessage: false,
                  });
                }, 1000);
              }
              callback(error, null);
            });
        },
        topNProductTable: (callback) => {
          dashboardTopSellingProduct(
            start_date,
            end_date,
            groupBy,
            selectedCurrency,
            this.state.storeIdValue
          )
            .then((response) => {
              try {
                if (response && response.data.data) {
                  this.setState({
                    topNLoader: false,
                    topNProductTable: response.data.data,
                    dataForCurrency: this.state.selectedCurrency,
                  });
                }
              } catch (error) {
                this.setState({
                  topNLoader: true,
                  topNProductTable: [],
                });
                setTimeout(() => {
                  this.setState({
                    topNLoader: true,
                  });
                }, 1000);
                callback(error, null);
              }
            })
            .catch((error) => {
              if (
                error &&
                error.response &&
                error.response.data &&
                error.response.data.message === "currency conversion not found"
              ) {
                this.setState({
                  isOpenSnackbar: true,
                  snackbarMessage: "selected currency is not supported",
                });
                setTimeout(() => {
                  this.setState({
                    isOpenSnackbar: false,
                    snackbarMessage: "",
                  });
                }, 1000);
              } else {
                this.setState({
                  topNLoader: true,
                  topNProductTable: [],
                });
                setTimeout(() => {
                  this.setState({
                    topNLoader: true,
                  });
                }, 1000);
              }
              callback(error, null);
            });
        },
        topWishListProductTable: (callback) => {
          dashboardTopWishlistProduct(
            start_date,
            end_date,
            this.state.storeIdValue
          )
            .then((response) => {
              try {
                if (response && response.data.data) {
                  this.setState({
                    topWishLoader: false,
                    topWishListProductTable: response.data.data,
                    top: 10,
                    wishlistProductCount: response.data.product_count,
                  });
                }
              } catch (error) {
                this.setState({
                  topWishLoader: true,
                  topWishListProductTable: [],
                  wishlistProductCount: 0,
                });
                setTimeout(() => {
                  this.setState({
                    topWishLoader: true,
                  });
                }, 1000);
                callback(error, null);
              }
            })
            .catch((error) => {
              if (
                error &&
                error.response &&
                error.response.data &&
                error.response.data.message === "currency conversion not found"
              ) {
                this.setState({
                  isOpenSnackbar: true,
                  snackbarMessage: "No data for top WishList product",
                  wishlistProductCount: 0,
                });
                setTimeout(() => {
                  this.setState({
                    isOpenSnackbar: false,
                    snackbarMessage: "",
                  });
                }, 1000);
              } else {
                this.setState({
                  topWishLoader: true,
                  topWishListProductTable: [],
                  wishlistProductCount: 0,
                });
                setTimeout(() => {
                  this.setState({
                    topWishLoader: false,
                  });
                }, 1000);
              }
              callback(error, null);
            });
        },
        topCartProductTable: (callback) => {
          dashboardTopCartProduct(start_date, end_date, this.state.storeIdValue)
            .then((response) => {
              try {
                if (response && response.data.data) {
                  this.setState({
                    topCartLoader: false,
                    topCartProductTable: response.data.data,
                  });
                }
              } catch (error) {
                this.setState({
                  topCartLoader: false,
                  topCartProductTable: [],
                });
                setTimeout(() => {
                  this.setState({
                    topCartLoader: false,
                  });
                }, 1000);
                callback(error, null);
              }
            })
            .catch((error) => {
              if (
                error &&
                error.response &&
                error.response.data &&
                error.response.data.message === "currency conversion not found"
              ) {
                this.setState({
                  topCartLoader: false,
                  isOpenSnackbar: true,
                  snackbarMessage: "No data for top WishList product",
                });
                setTimeout(() => {
                  this.setState({
                    topCartLoader: false,
                    isOpenSnackbar: false,
                    snackbarMessage: "",
                  });
                }, 1000);
              } else {
                this.setState({
                  topCartLoader: false,
                  topCartProductTable: [],
                });
                setTimeout(() => {
                  this.setState({
                    topCartLoader: false,
                  });
                }, 1000);
              }
            });
        },
        averageSales: (callback) => {
          dashboardAverageSales(
            start_date,
            end_date,
            groupBy,
            selectedCurrency,
            this.state.storeIdValue
          )
            .then((response) => {
              try {
                let config = this.getGraphConfigData(response, "sales");
                this.setState({
                  averageSaleLoader: false,
                  averageSale: response.data.total,
                  averageSaleConfig: config,
                  dataForCurrency: this.state.selectedCurrency,
                });
              } catch (error) {
                this.setState({
                  averageSaleLoader: true,
                  averageSale: 0,
                  averageSaleConfig: {
                    ...averageSaleConfig,
                    options: {
                      ...averageSaleConfig.options,
                      noData: {
                        ...averageSaleConfig.options.noData,
                        text: "No data for selected date range",
                      },
                      xaxis: { labels: { show: false } },
                    },
                  },
                });
                setTimeout(() => {
                  this.setState({
                    orderLoader: false,
                  });
                }, 1000);
                callback(error, null);
              }
            })
            .catch((error) => {
              if (
                error &&
                error.response &&
                error.response.data &&
                error.response.data.message === "currency conversion not found"
              ) {
                this.setState({
                  isOpenSnackbar: true,
                  orderLoader: true,
                  snackbarMessage: "selected currency is not supported",
                  selectedCurrency: this.state.previousCurrency,
                });
                setTimeout(() => {
                  this.setState({
                    isOpenSnackbar: false,
                    orderLoader: false,
                    snackbarMessage: "",
                  });
                }, 1000);
              } else {
                this.setState({
                  averageSaleLoader: true,
                  averageSale: 0,
                  isOpenSnackbar: true,
                  snackbarMessage:
                    error.response && error.response.status === 500
                      ? "Internal Server Error"
                      : "No data found!",
                  averageSaleConfig: {
                    ...averageSaleConfig,
                    options: {
                      ...averageSaleConfig.options,
                      noData: {
                        ...averageSaleConfig.options.noData,
                        text: "No data for selected date range",
                        style: {
                          fontSize: "12px",
                          fontWeight: 400,
                          fontFamily: "sans-serif",
                          color: "#263238",
                        },
                      },
                      xaxis: { labels: { show: false } },
                    },
                  },
                });
                setTimeout(() => {
                  this.setState({
                    averageSaleLoader: false,
                    isOpenSnackbar: false,
                    snackbarMessage: false,
                  });
                }, 1000);
              }
              callback(error, null);
            });
        },
        topBrands: (callback) => {
          dashboardTopBrands(
            start_date,
            end_date,
            groupBy,
            selectedCurrency,
            this.state.storeIdValue,
            this.state.sortBy
          )
            .then((response) => {
              try {
                if (response && response.data.data) {
                  this.setState({
                    topBrandLoader: false,
                    topBrandTable: response.data.data,
                  });
                }
              } catch (error) {
                this.setState({
                  topBrandLoader: false,
                  topBrandTable: [],
                });
                setTimeout(() => {
                  this.setState({
                    topBrandLoader: false,
                  });
                }, 1000);
                callback(error, null);
              }
            })
            .catch((error) => {
              if (
                error &&
                error.response &&
                error.response.data &&
                error.response.data.message === "currency conversion not found"
              ) {
                this.setState({
                  topBrandLoader: false,
                  isOpenSnackbar: true,
                  snackbarMessage: "No data for top brand",
                });
                setTimeout(() => {
                  this.setState({
                    topBrandLoader: false,
                    isOpenSnackbar: false,
                    snackbarMessage: "",
                  });
                }, 1000);
              } else {
                this.setState({
                  topBrandLoader: false,
                  topBrandTable: [],
                });
                setTimeout(() => {
                  this.setState({
                    topBrandLoader: false,
                  });
                }, 1000);
              }
            });
        },
        onlineStoreConversion: (callback) => {
          dashboardOnlineStoreConversion(
            start_date,
            end_date,
            this.state.storeIdValue
          )
            .then((res) => {
              try {
                if (res.data) {
                  this.setState({
                    onlineStoreConversionData: res.data.data,
                    onlineConversionStoreLoader: false,
                  });
                }
              } catch (error) {
                this.setState({
                  onlineConversionStoreLoader: false,
                  onlineStoreConversionData: null,
                });
                setTimeout(() => {
                  this.setState({
                    onlineConversionStoreLoader: false,
                  });
                }, 1000);
                callback(error, null);
              }
            })
            .catch((error) => {
              if (
                error &&
                error.response &&
                error.response.data &&
                error.response.data.message
              ) {
                this.setState({
                  onlineConversionStoreLoader: false,
                  isOpenSnackbar: true,
                  snackbarMessage: error.response.data.message,
                });
                setTimeout(() => {
                  this.setState({
                    onlineConversionStoreLoader: false,
                    isOpenSnackbar: false,
                    snackbarMessage: "",
                  });
                }, 1000);
              } else {
                this.setState({
                  onlineConversionStoreLoader: false,
                  onlineStoreConversionData: null,
                });
                setTimeout(() => {
                  this.setState({
                    onlineConversionStoreLoader: false,
                  });
                }, 1000);
              }
            });
        },
        orderPayment: (callback) => {
          dashboardOrderPayment(
            start_date,
            end_date,
            groupBy,
            selectedCurrency,
            this.state.storeIdValue
          )
            .then((response) => {
              try {
                let config = this.getGraphConfigData(response.data, "payment");
                this.setState({
                  orderPaymentConfig: config,
                  totalOrderPayment: response.data.data.total,
                  orderPaymentLoader: false,
                  dataForCurrency: this.state.selectedCurrency,
                });
              } catch (error) {
                this.setState({
                  orderPaymentLoader: false,
                  totalOrderPayment: 0,
                  orderPaymentConfig: {
                    ...orderPaymentConfig,
                    options: {
                      ...orderPaymentConfig.options,
                      noData: {
                        ...orderPaymentConfig.options.noData,
                        title: "No data for selected date range",
                      },
                      xaxis: { labels: { show: false } },
                    },
                  },
                });
                setTimeout(() => {
                  this.setState({
                    orderPaymentLoader: false,
                  });
                }, 1000);
                callback(error, null);
              }
            })
            .catch((error) => {
              if (
                error &&
                error.response &&
                error.response.data &&
                error.response.data.message === "currency conversion not found"
              ) {
                this.setState({
                  isOpenSnackbar: true,
                  orderPaymentLoader: true,
                  snackbarMessage: "selected currency is not supported",
                  selectedCurrency: this.state.previousCurrency,
                });
                setTimeout(() => {
                  this.setState({
                    isOpenSnackbar: false,
                    orderPaymentLoader: false,
                    snackbarMessage: "",
                  });
                }, 1000);
              } else {
                this.setState({
                  orderPaymentLoader: true,
                  totalOrderPayment: 0,
                  orderPaymentConfig: {
                    ...orderPaymentConfig,
                    options: {
                      ...orderPaymentConfig.options,
                      noData: {
                        ...orderPaymentConfig.options.noData,
                        text: "No data for selected date range",
                        style: {
                          fontSize: "12px",
                          fontWeight: 400,
                          fontFamily: "sans-serif",
                          color: "#263238",
                        },
                      },
                      xaxis: { labels: { show: false } },
                    },
                  },
                });
                setTimeout(() => {
                  this.setState({
                    orderPaymentLoader: false,
                  });
                }, 1000);
              }
              callback(error, null);
            });
        },
      },

      (err, data) => {
        if (err) console.log(err);
      }
    );
  };

  getTopBrandDataOnChangeSortBy = (
    start_date,
    end_date,
    groupBy,
    selectedCurrency
  ) => {
    dashboardTopBrands(
      start_date,
      end_date,
      groupBy,
      selectedCurrency,
      this.state.storeIdValue,
      this.state.sortBy
    )
      .then((response) => {
        try {
          if (response && response.data.data) {
            this.setState({
              topBrandLoader: false,
              topBrandTable: response.data.data,
            });
          }
        } catch (error) {
          this.setState({
            topBrandLoader: false,
            topBrandTable: [],
          });
          setTimeout(() => {
            this.setState({
              topBrandLoader: false,
            });
          }, 1000);
        }
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message === "currency conversion not found"
        ) {
          this.setState({
            topBrandLoader: false,
            isOpenSnackbar: true,
            snackbarMessage: "No data for top brand",
          });
          setTimeout(() => {
            this.setState({
              topBrandLoader: false,
              isOpenSnackbar: false,
              snackbarMessage: "",
            });
          }, 1000);
        } else {
          this.setState({
            topBrandLoader: false,
            topBrandTable: [],
          });
          setTimeout(() => {
            this.setState({
              topBrandLoader: false,
            });
          }, 1000);
        }
      });
  };

  headerStateHandler = (stateName, stateValue) => {
    if (
      stateName === "selectedCurrency" &&
      stateValue !== this.state.selectedCurrency
    ) {
      this.setState({
        previousCurrency: this.state.selectedCurrency,
        selectedCurrency: stateValue,
      });
    }
    if (stateName === "date" && stateValue) {
      this.setState({
        startDate: stateValue.startDate,
        endDate: stateValue.endDate,
      });
    }
    if (stateName === "groupBy" && (stateValue === 0 || stateValue)) {
      this.setState({
        groupBy: stateValue,
      });
    }
    if (stateName === "groupByName" && stateValue) {
      this.setState({
        groupByName: stateValue,
      });
    }
  };

  dropDownSelect = (e) => {
    if (Number.isInteger(e)) {
      this.setState({ sortBy: e });
    } else {
      this.setState({
        storeIdValue: e.target.value,
      });
    }
  };

  render() {
    const breakpointColumnsObj = {
      default: 3,
      1100: 2,
      700: 1,
      500: 1,
    };
    let storeId = ls.get("store_id");
    return (
      <div className="overviewWrapper">
        <div className="head">
          <div className="title">
            <span>Overview Dashboard</span>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="mt-2">
              <Headers
                groupBy={this.state.groupBy}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                headerStateHandler={this.headerStateHandler}
                groupByName={this.state.groupByName}
                currency={this.state.selectedCurrency}
              />
            </div>
            <div className="d-flex align-items-center globalFontSize">
              <StoreIdDropDown
                storeData={this.state.storeData}
                storeID={this.state.storeIdValue}
                dropDownSelect={this.dropDownSelect}
              />
            </div>
          </div>
        </div>

        <div className="overviewBody" style={{ marginTop: "9rem" }}>
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            <TotalSalesGraph
              totalSale={this.valueFormatter(this.state.totalSales)}
              loader={this.state.salesLoader}
              config={this.state.salesConfig}
              currency={this.state.selectedCurrency}
            />
            <TotalOrdersGraph
              config={this.state.ordersConfig}
              totalOrder={this.valueFormatter(this.state.totalOrders)}
              loader={this.state.orderLoader}
              currency={this.state.selectedCurrency}
            />
            <AverageSales
              config={this.state.averageSaleConfig}
              averageSale={this.valueFormatter(this.state.averageSale)}
              loader={this.state.averageSaleLoader}
              currency={this.state.selectedCurrency}
            />
            <BookingPayment
              totalOrder={
                this.state.totalOrderPayment
                  ? this.valueFormatter(this.state.totalOrderPayment)
                  : 0
              }
              loader={this.state.orderPaymentLoader}
              config={this.state.orderPaymentConfig}
              currency={this.state.selectedCurrency}
            />


          {storeId === "0" ? (
            <TopNProductTable
              table={this.state.topNProductTable}
              loader={this.state.topNLoader}
              currency={this.state.selectedCurrency}
            />
            ) : (
              ""
            )}


            {storeId === "0" ? (
              <TopProductCartTable
                table={this.state.topCartProductTable}
                loader={this.state.topCartLoader}
              />
            ) : (
              ""
            )}
            {storeId === "0" ? (
              <TopWishlistProductTable
                table={this.state.topWishListProductTable}
                loader={this.state.topWishLoader}
                product={this.state.wishlistProductCount}
              />
            ) : (
              ""
            )}
            {storeId === "0" ? (
              <TopBrandLoader
                table={this.state.topBrandTable}
                loader={this.state.topBrandLoader}
                currency={this.state.selectedCurrency}
                sortByChangeHandler={(sortBy) => this.dropDownSelect(sortBy)}
              />
            ) : (
              ""
            )}
            <OnlineStoreConversion
              data={this.state.onlineStoreConversionData}
              loader={this.state.onlineConversionStoreLoader}
            />
          </Masonry>
        </div>
        <Snackbar
          open={this.state.isOpenSnackbar}
          message={this.state.snackbarMessage}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setIndex: (index) => dispatch(setIndex(index)),
  };
};

export default connect(null, mapDispatchToProps)(CategoryLogs);
