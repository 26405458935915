//moment import
import moment from "moment";

export const XAxisFormatting = (res, groupBy) => {
  let format = {
    Hour: "ll H:00",
    Day: "ll",
    Week: "ll",
    "Hour Of Day": "HH:00",
  };
  let xCategories;
  if (groupBy.label === "Hour") {
    xCategories = res.data.graph.xcat.map((dateTime) => {
      return moment(dateTime).format(format[groupBy.label]);
    });
  } else if (
    groupBy.label === "Day" ||
    groupBy.label === "Week" ||
    groupBy.label === "Hour Of Day"
  ) {
    xCategories = res.data.graph.xcat.map((dateTime) => {
      return moment(dateTime).format(format[groupBy.label]);
    });
  } else {
    xCategories = res.data.graph.xcat;
  }

  res.data.graph.series[0].data.map(
    (item, i) => (res.data.graph.series[0].data[i] = item.toFixed(2))
  );
  res.data.graph.xcat = xCategories;
  return res;
};
