import React from "react";
import { DatePicker } from "antd";
import moment from "moment";
import "./dateRangePicker.scss";


const { RangePicker } = DatePicker;

class DateRangePicker extends React.Component {

  disabledDate = (current) => {
    // Can not select days before today and today 
    return current && current > moment().endOf('day');

  }

  render() {
    return (
      <div>
        <RangePicker
          ranges={this.props.ranges}
          value={[this.props.value[0] ? moment(this.props.value[0]) : null, this.props.value[1] ? moment(this.props.value[1]) : null]}
          onChange={this.props.onChange}
          disabledDate={this.disabledDate}
          size="small"
          allowClear={false}
        />
      </div>
    );
  }
}
export default DateRangePicker;
