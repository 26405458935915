import { get } from "../lib/request";
import moment from "moment";

export const fetchSellerReviewLogs = (
  startDate = moment(),
  endDate = moment(),
  skip = 0,
  limit = 50,
  u_name
) => {
  return get(`/seller/review?skip=${skip}&limit=${limit}&u_name=${u_name}`, {
    startDate: startDate,
    endDate: endDate,
  });
};
