import { getForReport } from "../lib/request";
import moment from "moment";

export const deviceActivityBySessionApiCall = (
  timeLimit,
  device,
  storeId,
  from
) => {
  let start = moment(timeLimit.startDate).unix();
  let end = moment(timeLimit.endDate).unix();
  let URL = `/python-spark/session/device-log?start_timestamp=${start}&end_timestamp=${end}&device=${device}&activity=1&store_categories_id=${storeId}`;
  if (from === "export") {
    return getForReport(`${URL}&export=1`);
  } else {
    return getForReport(URL);
  }
};
