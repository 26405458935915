import React, { Component } from "react";
// import { config } from './graphConfig';
import Apexcharts from 'react-apexcharts';

class index extends Component {


   render() {
      return (
         <div className='overviewBody'>
            {this.props.loader
               ? <div className='card d-flex justify-content-center align-items-center' style={{ marginTop: "40px", minHeight: "400px" }}>
                  <h4 style={{ color: "grey"}}>Loading Data ...</h4>
               </div>
               : this.props.selected.label !== "None" &&
               this.props.overviewData.map((item, index) => (
                  <div className='' key={index}>
                     <div className='card'>
                        <div className='m-3 mt-4 graphTitle'>
                           <span>Today's Total Orders</span>
                        </div>
                        <div className='chart'>
                           {this.props.config.series.length
                              ? <Apexcharts series={this.props.config.series} options={this.props.config.options} type="bar" height={350} />
                              : <div
                                 style={{ height: "350px", textAlign: "center", paddingTop: "140px" }}
                              ><h4 style={{ color: "gray" }}>No data for selected date range</h4>
                              </div>
                           }
                        </div>
                     </div>
                  </div>
               ))}
         </div>
      );
   }
}

export default index;
