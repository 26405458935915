import React, { Component } from "react";
import ReactToPrint from "react-to-print";
import { CSVLink } from "react-csv";
import Pbutton from "../button/Button";

// component imports 
import Modal from "../modal/modal";

// scss import
import "../../static/scss/newVariable.scss";

class PrinAndExport extends Component {
  state = {
      modal: false,
  };

  render() {
    return (
      <div>
        <div className={`d-flex text-grey ${this.props.margin ? this.props.margin : "mt-3" } globalFontSize`}>
          <div className="mr-3 db_ptr f-16">
            <ReactToPrint
              trigger={() => (
                <span>
                  <i className="fas fa-print mr-1 "></i>Print
                </span>
              )}
              content={() => this.props.myRef.current}
            />
          </div>
          <div className="mr-3 db_ptr f-16" onClick={() => this.setState({modal: !this.state.modal})}>
            <i className="fas fa-download mr-1"></i>Export
          </div>
        </div>
        <Modal
          isOpen={this.state.modal}
          toggle={() => this.setState({modal: !this.state.modal})}
          width={"35%"}
        >
          <div className="col-12 px-0">
            <div className="py-3 reportModal-header pl-3 border-bottom">
              Export your report
            </div>
            <div className="py-3 reportModal-subText pl-3 border-bottom">
              Report will be exported as a CSV (comma separated values) table.
            </div>
            <div className="py-3 col-12">
              <div className="row justify-content-end">
                <Pbutton
                  onClick={() => this.setState({modal: !this.state.modal})}
                  className="reportModal-cancelBtn"
                >
                  Cancel
                </Pbutton>
                <CSVLink
                  onClick={() => this.setState({modal: !this.state.modal})}
                  data={this.props.data || []}
                  filename={"my-file.csv"}
                  className="reportModal-exportBtn"
                  target="_blank"
                >
                  Export
                </CSVLink>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default PrinAndExport;
