import { get } from "../lib/request";
import moment from "moment";
export const fetchFavouriteProductLogs = (
  startDate = moment(),
  endDate = moment(),
  skip = 0,
  limit = 50,
  city = "",
  fname = "",
  sname = "",
  tname = ""
) => {
  return get(
    `/favouriteProducts?skip=${skip}&limit=${limit}&cityName=${city}&fname=${fname}&sname=${sname}&tname=${tname}`,
    { startDate: startDate, endDate: endDate }
  );
};
