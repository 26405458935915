//An extract of address points from the LINZ bulk extract: http://www.linz.govt.nz/survey-titles/landonline-data/landonline-bde
//Should be this data set: http://data.linz.govt.nz/#/layer/779-nz-street-address-electoral/
export const addressPoints = [
  [12.97, 77.59],
  [13.058384, 77.605023],
  [13.061758, 77.583039],
  [17.38405, 78.45636],
  [31.1471, 75.3412],
  [12.9715987, 77.5945627],
  [13.028584299999999, 77.5894003],
  [13.028584299999999, 77.5894003],
  [13.0353557, 77.59878739999999],
  [12.9715987, 77.5945627],
  [13.028584299999999, 77.5894003],
  [13.028584299999999, 77.5894003],
  [13.028584299999999, 77.5894003],
  [13.028584299999999, 77.5894003],
  [19.432607650756836, -99.13320922851562],
  [19.432607650756836, -99.13320922851562],
  [19.432607650756836, -99.13320922851562],
  [19.432607650756836, -99.13320922851562],
  [19.432607650756836, -99.13320922851562],
  [12.9715684, 77.5945869],
  [12.9715684, 77.5945869],
  [13.0288253, 77.5896959],
  [12.9981542, 77.5530462],
  [13.028703689575195, 77.58958435058594],
  [19.432607650756836, -99.13320922851562],
  [12.9715684, 77.5945869],
  [19.432607650756836, -99.13320922851562],
  [12.9715684, 77.5945869],
  [13.031546503471105, 77.58134475390624],
  [19.432607650756836, -99.13320922851562],

  [19.432607650756836, -99.13320922851562],
  [19.432607650756836, -99.13320922851562],
  [12.9715684, 77.5945869],
  [12.9715684, 77.5945869],
  [13.0287034, 77.5895845],
  [13.02863597869873, 77.58951568603516],
  [13.028584299999999, 77.5894003],
  [13.02863597869873, 77.58951568603516],
  [13.02863597869873, 77.58951568603516],
  [13.02863597869873, 77.58951568603516],
  [13.028584299999999, 77.5894003],
  [13.028584299999999, 77.5894003],
  [13.0287034, 77.5895845],
  [13.028584299999999, 77.5894003],
  [13.028584299999999, 77.5894003],
  [13.028703689575195, 77.58958435058594],
  [13.0287034, 77.5895845],
  [13.0287034, 77.5895845],
  [13.0287034, 77.5895845],
  [13.0287034, 77.5895845],
  [13.0287034, 77.5895845],
  [13.0287034, 77.5895845],
  [13.028599739074707, 77.58949279785156],
  [13.0287034, 77.5895845],
  [13.0287034, 77.5895845],
  [13.0287034, 77.5895845],
  [13.034202575683594, 77.5971908569336],
  [12.968813899999999, 77.723869],
  [13.0287034, 77.5895845],
  [13.0287034, 77.5895845],
  [12.968813899999999, 77.723869],
  [13.028599739074707, 77.58949279785156],
  [13.028584299999999, 77.5894003],
  [13.028703689575195, 77.58958435058594],
  [13.028703689575195, 77.58958435058594],
  [13.028703689575195, 77.58958435058594],
  [13.028703689575195, 77.58958435058594],
  [13.028703689575195, 77.58958435058594],
  [13.028599739074707, 77.58949279785156],
  [13.028703689575195, 77.58958435058594],
  [13.028703689575195, 77.58958435058594],
  [13.028703689575195, 77.58958435058594],
  [13.0288253, 77.5896959],
  [12.9161951, 77.607007],
  [13.028584299999999, 77.5894003],
  [13.028703689575195, 77.58958435058594],
  [13.028584299999999, 77.5894003],
  [13.028584299999999, 77.5894003],
  [12.91657543182373, 77.61011505126953],
  [12.960662399999999, 77.59944879999999],
  [13.028584299999999, 77.5894003],
  [-33.92977, 18.4267804],
  [13.02860164642334, 77.5895004272461],
  [-33.92977, 18.4267804],
  [-33.92977, 18.4267804],
  [13.028017044067383, 77.58646392822266],
  [-33.92977, 18.4267804],
  [19.432607650756836, -99.13320922851562],
  [13.034202575683594, 77.5971908569336],
  [13.034202575683594, 77.5971908569336],
  [13.034202575683594, 77.5971908569336],
  [19.432607650756836, -99.13320922851562],
  [13.0287034, 77.5895845],
  [13.028599739074707, 77.58949279785156],
  [13.028584299999999, 77.5894003],
  [13.028584299999999, 77.5894003],
  [13.028584299999999, 77.5894003],
];
