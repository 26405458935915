import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";
import { tooltipFormatter } from "../../../../lib/helper";
class mainGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      series: [
        {
          name: "STOCK ABC",
          data: [100, 120, 90, 95, 250, 300, 50, 80, 170, 200, 400, 450],
        },
      ],
      options: {
        chart: {
          type: "area",
          height: 400,
          zoom: {
            enabled: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },

        title: {
          text: "Sales Performance",
          align: "left",
          style: {
            fontSize: "18px",
            fontWeight: 500,
          },
        },
        subtitle: {
          text: "",
          align: "left",
        },
        labels: this.props.mainGraphData.xcat,
        xaxis: {
          type: "dateTime",
          title: {
            text: "",
            style: {
              fontSize: "11px",
              fontWeight: 700,
            },
          },
          labels: {
            show: true,
            // offsetY: -10,
            style: {
              fontSize: "11px",
            },
          },
          tooltip: {
            enabled: false,
          },
        },
        grid: {
          padding: {
            left: 40,
          },
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        yaxis: {
          opposite: false,
          title: {
            text: "",
            style: {
              fontSize: "11px",
              fontWeight: 700,
            },
          },
          labels: {
            show: true,
            offsetX:15,
            formatter: (val, index) => {
              return tooltipFormatter(val, "INR", false);
            },
            style: {
              fontSize: "11px",
            },
          },
        },
        legend: {
          horizontalAlign: "left",
        },
        tooltip: {
          enabled: true,
          followCursor: true,
        },
      },
      preState: "",
    };
  }

  dropDown = (e, value) => {
    let currentState = "";
    if (value !== "") {
      this.setState({ preState: value });
    } else {
      currentState = e.target.value;
      this.props.dropDown(this.state.preState, currentState);
    }
  };

  static getDerivedStateFromProps(props, state) {
    return {
      options: {
        ...state.options,
        grid: {
          padding: {
            left: 60,
          },
        },
        labels: props.mainGraphData.xcat,
        yaxis: {
          ...state.options.yaxis,
          show: true,
          title: {text: props.mainGraphData.series[0].name },
        },
        xaxis: {
          ...state.options.xaxis,
          title: {
            text: props.title,
            style: {
              fontSize: "11px",
              fontWeight: 700,
            },
          },
        },
      },
    };
  }

  render() {
    return (
      <div
        id="chart"
        style={{ paddingTop: "20px", position: "relative", width: "100%" }}
      >
        <ReactApexChart
          options={this.state.options}
          series={this.props.mainGraphData.series}
          type="area"
          height={350}
        />
        <select
          className="mainDropDownSales fontsizeproduct"
          value={this.props.dropDownValueGraph}
          name="dropDownValue"
          onFocus={() => this.dropDown("", this.props.dropDownValueGraph)}
          onChange={(e) => this.dropDown(e, "")}
        >
          <option value="product_revenue">Product Revenue</option>
          <option value="unique_purchase">Unique Purchases</option>
          <option value="quantity">Quantity</option>
          <option value="avg_price">Avg. Price</option>
          <option value="avg_qty">Avg. QTY</option>
          <option value="refund_amount">Product Refund Amount</option>
        </select>
      </div>
    );
  }
}

export default mainGraph;
