import React, { Component } from "react";

//modular component import
import SelectionModule from "../../../components/Modules";

//StoreId component import
import StoreIdDropDown from "../../../components/StoreIdDropdown";

// import print export component
import PrintAndExport from "../../../components/PrintAndExport";

// import print export component
import Header from "../../../components/HeaderComponent/header";

// import main graph
import ProductPerformanceGraph from "./Graph/graph";

// store id api function import
import { storeIdApiCall } from "../../../services/storeId";

// main graph formatting function
import { XAxisFormatting } from "./formatting";

import {
  productPerformanceApi,
  productPerformanceGraphApi,
} from "../../../services/productPerformance";

// moment import
import moment from "moment";

// import react component table
import TableView from "./ReactTableView";

import { Paper } from "@material-ui/core";
import Snackbar from "../../../components/snackbar/snackbar";

import './index.scss';
import { getCurrencySymbol, getFromLocalStorage, removeFromLocalStorage, setInLocalStorage } from '../../../lib/helper';
import { api } from '../../../config/app';

class ProductPerformance extends Component {
    constructor(props) {
        super(props)
        this.myRef = React.createRef();
        this.storeTimeout = null;
        this.state = {
            date: {
                startdate:
                    localStorage.getItem("startDate") != null
                        ? localStorage.getItem("startDate")
                        : moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
                enddate:
                    localStorage.getItem("endDate") != null
                        ? localStorage.getItem("endDate")
                        : moment().format("YYYY-MM-DD HH:mm:ss"),
            },
            MiscDateGroupBy: {},
            search: {
                label: "Search",
                input: {
                    name: "search",
                    type: "text",
                    value: "",
                    placeholder: "Product Search",
                },
                dimension: "Product",
            },
            currencyInfo: {
                symbol: getCurrencySymbol(getFromLocalStorage("currency")),
                currency: getFromLocalStorage("currency"),
            },
            apiInfo: {
                brand: "",
                currency: getFromLocalStorage("currency"),
                pageLimit: 10,
                sortBy: "Product Revenue",
                order: 0,
                pageNo: 0,
                symbol: getCurrencySymbol(getFromLocalStorage("currency")),
                column: "Product Revenue",
                pieColumn: "Product Revenue",
                dropDownValue: 'product_revenue',
            },

            mainGraphLoading: true,
            gotData: false,
            mainGraphData: [],

            tableDataLoading: true,
            gotTableData: false,
            tableData: [],

            pieDataLoading: true,
            gotPieData: false,
            pieData: [],

            groupBy: getFromLocalStorage("selectedGroupBy") ? JSON.parse(localStorage.getItem("selectedGroupBy")) : { value: 0, label: "Hour" },

            storeID: "0",
            storeData: [{ '_id': 0, name: '--No Data--' }],

            dropDownGraphValue: "product_revenue",
            preDropdown: "",

            ErrorMessage: "",
            ErrorMessageShow: false,
            component: "table",
            dimension: getFromLocalStorage("product") || "Product",

            orderIconState: 0,
            sortBy: "Product Revenue",

            rowSelected: 0,
            miscRowSelected: 0,
            pageNo: 0,
            count: 0,
            indexArray: [],
            prevRowState: 0,
            setRow: false,

            typingTimeout: 0,
            brand: "",
        };
    }

    componentDidMount() {
        this.getAllStoreId();
        // this.mainGraph();
        // this.productInfo();
    }

    componentDidUpdate(prevProp, prevState) {
        const { MiscDateGroupBy, groupBy, storeID, apiInfo, dimension, component } = this.state;
        setInLocalStorage("currency", apiInfo.currency || "INR")
        if (prevState.MiscDateGroupBy !== MiscDateGroupBy
            || prevState.apiInfo.currency !== apiInfo.currency
            || prevState.storeID !== storeID
        ) {
            this.setState({ mainGraphLoading: true, tableDataLoading: true, pieDataLoading: true });
            this.mainGraph();
            if (component === "table") {
                this.setState({ tableDataLoading: true });
                this.productInfo();
            } else {
                this.setState({ pieDataLoading: true });
                this.pieInfo();
            }
        } else if (prevState.apiInfo.order !== apiInfo.order
            || prevState.apiInfo.brand !== apiInfo.brand
            || prevState.apiInfo.pageLimit !== apiInfo.pageLimit
            || prevState.apiInfo.pageNo !== apiInfo.pageNo
            || prevState.apiInfo.sortBy !== apiInfo.sortBy
            || prevState.dimension !== dimension
            // || prevState.apiInfo.column !== apiInfo.column
            || prevState.apiInfo.pieColumn !== apiInfo.pieColumn
            || prevState.component !== component
        ) {
            if (prevState.dimension !== dimension) {
                if (component === "table") {
                    this.setState({ tableDataLoading: true });
                    this.productInfo();
                } else {
                    this.setState({ pieDataLoading: true });
                    this.pieInfo();
                }
            } else {
                if (component === "table") {
                    this.productInfo();
                } else {
                    this.pieInfo();
                }
            }
        } else if (prevState.apiInfo.dropDownValue !== apiInfo.dropDownValue || prevState.groupBy !== groupBy) {
            this.setState({ mainGraphLoading: true });
            console.log('qwe update')
            this.mainGraph();
        }
    }

    getAllStoreId = () => {
        storeIdApiCall()
            .then((res) => {
                this.setState({
                    storeData: res.data.data,
                });
            })
            .catch((err) => {
                if (err.message === "Request failed with status code 406") {
                    this.setState({
                        ErrorMessage: "Please login again, the token has expired",
                        ErrorMessageShow: true,
                    });
                }
            })
    };
  


  mainGraph = () => {
    const { apiInfo, date, groupBy, storeID, preDropdown } = this.state;
    setInLocalStorage("currency", apiInfo.currency || "INR");
    productPerformanceGraphApi(apiInfo, date, groupBy, storeID)
      .then((res) => {
        let data = XAxisFormatting(res, groupBy);
        let exportData =
          data.data.graph.xcat.map((date, index) => {
            return {
              dateTime: date,
              [data.data.graph.series[0].name]:
                data.data.graph.series[0].data[index],
            };
          }) || [];
        this.setState({
          exportData,
          mainGraphLoading: false,
          gotData: true,
          mainGraphData: data.data.graph,
          dropDownGraphValue: apiInfo.dropDownValue,
        });
      })
      .catch((err) => {
        if (err && err.response && err.response.status === 422) {
          this.setState({
            ErrorMessageShow: true,
            ErrorMessage: "Unsupported Sort By",
          });
        }
        this.setState({
          mainGraphLoading: false,
          gotData: false,
          dropDownGraphValue: preDropdown,
        });
      });
  };

  setPaginationNumbering = () => {
    const { apiInfo, count, rowSelected, prevRowState } = this.state;
    let Array = [];
    let check = 0;
    let condition = null;
    if (this.state.setRow) {
      this.setState({
        setRow: false,
      });
      condition = rowSelected;
    } else {
      if (prevRowState > rowSelected) {
        this.setState({
          miscRowSelected: prevRowState,
        });
        condition = prevRowState;
      } else {
        condition = rowSelected;
      }
    }
    if (apiInfo.pageNo !== 0) {
      for (let index = 1; index <= count; index++) {
        if (index > apiInfo.pageNo * condition && check < condition) {
          Array.push(index);
          check++;
        }
      }
      return Array;
    } else {
      for (let index = 1; index <= rowSelected; index++) {
        Array.push(index);
      }
      return Array;
    }
  };

  productInfo = () => {
    const { apiInfo, dimension, date, component, storeID } = this.state;
    setInLocalStorage("currency", apiInfo.currency || "INR");
    productPerformanceApi(apiInfo, dimension, date, component, storeID)
      .then((res) => {
        this.setState(
          {
            tableDataLoading: false,
            gotTableData: true,
            tableData: res.data,
            orderIconState: apiInfo.order,
            sortBy:
              apiInfo.sortBy === "Product Refund Amount"
                ? "Product Refund"
                : apiInfo.sortBy,
            rowSelected: res.data.data.length,
            count: res.data.count,
          },
          () => {
            this.setState((prevState) => ({
              prevRowState: prevState.rowSelected,
              indexArray: this.setPaginationNumbering(),
            }));
          }
        );
      })
      .catch((err) => {
        if (err && err.response && err.response.status === 422) {
          this.setState({
            ErrorMessageShow: true,
            ErrorMessage: "Unsupported Sort By",
          });
        }
        this.setState({
          tableDataLoading: false,
          gotTableData: false,
        });
      });
  };

  componentWillUnmount() {
    removeFromLocalStorage("product");
  }

  pieInfo = () => {
    const { apiInfo, dimension, date, component, storeID } = this.state;
    setInLocalStorage("currency", apiInfo.currency || "INR");
    productPerformanceApi(apiInfo, dimension, date, component, storeID)
      .then((res) => {
        this.setState({
          pieDataLoading: false,
          gotPieData: true,
          orderIconState: apiInfo.order,
          sortBy: apiInfo.sortBy,
          pieData: res.data,
        });
      })
      .catch((err) => {
        if (err && err.response && err.response.status === 422) {
          this.setState({
            ErrorMessageShow: true,
            ErrorMessage: "Unsupported Sort By",
          });
        }
        this.setState({
          pieDataLoading: false,
          gotPieData: false,
        });
      });
  };

  // to select the date and default group by
  selectedDate = (date, groupBy) => {
    this.setState({
      date,
      groupBy: groupBy,
      MiscDateGroupBy: {
        date,
        groupBy,
      },
    });
  };

  // to set the selected
  storeIdSelect = (e) => {
    this.setState({
      storeID: e.target.value,
    });
  };

  // to set the group by value
  passGroupBy = (groupBy) => {
    this.setState({
      groupBy,
    });
  };

  // to set the currency
  setCurrency = (currencyInfo) => {
    this.setState({
      currencyInfo,
      apiInfo: {
        ...this.state.apiInfo,
        currency: currencyInfo.currency,
        symbol: currencyInfo.symbol,
      },
    });
  };

  // setting main graph dropdown value
  dropDown = (e) => {
    let dropdown = e.target.value;
    this.setState({
      apiInfo: {
        ...this.state.apiInfo,
        dropDownValue: dropdown,
      },
    });
  };

  // to get the previous state of main graph dropdown in case of error
  prevDropDown = (e) => {
    this.setState({
      preDropdown: e.target.value,
    });
  };

  // export function
  setExportData = () => {};

  // to close the snackbar after 3 seconds
  hideSnackBar = () => {
    setTimeout(() => {
      this.setState({
        ErrorMessageShow: false,
      });
    }, 3000);
  };

  // to select the primary dimension
  selectedDimension = (dimension) => {
    let apiInfo = {
      brand: "",
      currency: "INR",
      pageLimit: 10,
      sortBy: "Product Revenue",
      order: 0,
      pageNo: 0,
      symbol: "₹",
      column: "Product Revenue",
      pieColumn: "Product Revenue",
      dropDownValue: "product_revenue",
    };
    this.setState({
      dimension,
      apiInfo,
    });
  };

  // to select the input brand
  onInputChange = (e) => {
    const self = this;
    if (self.state.typingTimeout) {
      clearTimeout(self.state.typingTimeout);
    }

    self.setState({
      search: {
        ...this.state.search,
        input: {
          ...this.state.search.input,
          value: e.target.value,
        },
      },
      typingTimeout: setTimeout(() => {
        this.setState({
          apiInfo: {
            ...this.state.apiInfo,
            brand: this.state.search.input.value,
          },
        });
      }, 1000),
    });
  };

  // to toggle between table and pie chart
  renderComponent = (component) => {
    let apiInfo = {
      brand: "",
      currency: this.state.apiInfo.currency || "INR",
      pageLimit: 10,
      sortBy: "Product Revenue",
      order: 0,
      pageNo: 0,
      symbol: "₹",
      column: "Product Revenue",
      pieColumn: "Product Revenue",
      dropDownValue: "product_revenue",
    };
    setInLocalStorage("currency", apiInfo.currency);
    this.setState({
      component,
      apiInfo,
      rowSelected: 10,
    });
  };

  // to change the order
  changeOrder = (sortBy, order) => {
    this.setState({
      apiInfo: {
        ...this.state.apiInfo,
        order: order ? 1 : 0,
        sortBy,
      },
    });
  };

  // onchange number of rows
  handleChange = (e) => {
    const self = this;
    if (self.state.typingTimeout) {
      clearTimeout(self.state.typingTimeout);
    }

    self.setState({
      rowSelected: e.target.value,
      miscRowSelected: e.target.value,
      setRow: true,
      typingTimeout: setTimeout(() => {
        if (this.state.rowSelected !== "") {
          this.setState({
            apiInfo: {
              ...this.state.apiInfo,
              pageLimit: this.state.rowSelected,
            },
          });
        }
      }, 1000),
    });
  };

  //next page function
  nextPage = () => {
    const {
      count,
      miscRowSelected,
      rowSelected,
      pageNo,
      tableData,
    } = this.state;
    if (
      pageNo !==
        Math.ceil(
          count / (miscRowSelected === 0 ? rowSelected : miscRowSelected)
        ) -
          1 &&
      tableData.data.length !== 0
    ) {
      this.setState({
        apiInfo: {
          ...this.state.apiInfo,
          pageNo: this.state.pageNo + 1,
        },
        pageNo: this.state.pageNo + 1,
        rowSelected: this.state.rowSelected,
      });
    }
  };

  //prev page function
  prevPage = () => {
    if (this.state.pageNo !== 0) {
      this.setState({
        apiInfo: {
          ...this.state.apiInfo,
          pageNo: this.state.pageNo - 1,
        },
        pageNo: this.state.pageNo - 1,
      });
    }
  };

  // select the left dropdown on pie chart side
  setTableDropDown = (e) => {
    const { name } = e.target;
    if (name === "tableDropDown") {
      this.setState({
        apiInfo: {
          ...this.state.apiInfo,
          column: e.target.value,
          sortBy: e.target.value,
        },
      });
    } else {
      this.setState({
        apiInfo: {
          ...this.state.apiInfo,
          pieColumn: e.target.value,
        },
      });
    }
  };

  render() {
    const {
      mainGraphLoading,
      gotData,
      date,
      storeData,
      storeID,
      currencyInfo,
      mainGraphData,
      dropDownGraphValue,
      groupBy,
      sortBy,
      ErrorMessage,
      ErrorMessageShow,
      component,
      tableData,
      dimension,
      apiInfo,
      orderIconState,
      tableDataLoading,
      gotTableData,
      rowSelected,
      miscRowSelected,
      pageNo,
      indexArray,
      pieDataLoading,
      gotPieData,
      search,
      pieData,
    } = this.state;

    if (ErrorMessageShow) {
      this.hideSnackBar();
    }

    return (
      <div className="product-container" ref={this.myRef}>
        <Snackbar open={ErrorMessageShow} message={ErrorMessage} />
        <Header heading={"Product Performance"}  headingStyle = {`px-3`}  />
        <div className="px-3">
          <PrintAndExport myRef={this.myRef} margin={"mt-0"} data={this.state.exportData} />
        </div>
        <div className="d-flex justify-content-between w-100 px-3 my-3">
          <SelectionModule
            DatePicker={true}
            date={date}
            selectedDate={this.selectedDate}
            Currency={true}
            currencyInfo={currencyInfo}
            setCurrency={this.setCurrency}
            GroupBy={true}
            passGroupBy={this.passGroupBy}
          />
          <StoreIdDropDown
            storeData={storeData}
            storeID={storeID}
            dropDownSelect={this.storeIdSelect}
          />
        </div>
        <Paper className="mx-3 mt-3" style={{ minHeight: "400px" }}>
          {mainGraphLoading === false ? (
            gotData ? (
              <ProductPerformanceGraph
                mainGraphData={mainGraphData}
                dropDownGraphValue={dropDownGraphValue}
                dropDown={this.dropDown}
                prevDropDown={this.prevDropDown}
                groupBy={groupBy}
              />
            ) : (
              <h4
                className="d-flex justify-content-center align-items-center"
                style={{ height: "400px", color: "gray" }}
              >
                No data for selected date range
              </h4>
            )
          ) : (
            <h4
              className="d-flex justify-content-center align-items-center"
              style={{ height: "400px", color: "gray" }}
            >
              Loading data...
            </h4>
          )}
        </Paper>
        <TableView
          // table chart side functions
          tableDataLoading={tableDataLoading}
          gotTableData={gotTableData}
          selectedDimension={this.selectedDimension}
          component={component}
          dimension={dimension}
          sortBy={sortBy}
          search={search}
          onInputChange={this.onInputChange}
          apiInfo={apiInfo}
          orderIconState={orderIconState}
          tableData={tableData}
          renderComponent={this.renderComponent}
          changeOrder={this.changeOrder}
          rowSelected={rowSelected}
          miscRowSelected={miscRowSelected}
          rowSelect={this.rowSelect}
          handleChange={this.handleChange}
          pageNo={pageNo}
          indexArray={indexArray}
          nextPage={this.nextPage}
          prevPage={this.prevPage}
          // pie chart side functions
          pieDataLoading={pieDataLoading}
          gotPieData={gotPieData}
          tableDropDown={apiInfo.column}
          pieDropdown={apiInfo.pieColumn}
          setTableDropDown={this.setTableDropDown}
          pieData={pieData}
        />
      </div>
    );
  }
}

export default ProductPerformance;
