import React from "react";
// import { render } from "react-dom";
import { Map, TileLayer } from "react-leaflet";
import HeatmapLayer from "./src/HeatmapLayer";
import { addressPoints } from "./realworld";
import ReactToPrint from "react-to-print";
import { storeIdApiCall } from "../../../services/storeId"; // API calling for store category
import moment from "moment"; // change the date format
import Headers from "./header/index.js"; // drop down icons for daterange and location filters
import StoreIdDropDown from "../../../components/StoreIdDropdown"; // dropdown for store category
import Snackbar from "../../../components/snackbar/snackbar"; // danger popup for error message (Data Not Found) etc
import _ from "lodash";
import { dateTimeFormatForGraph } from "../../../lib/helper";
import TableView from "./Table";

//
import { CSVLink } from "react-csv";
import Modal from "../../../components/modal/modal";
import Pbutton from "../../../components/button/Button";
import {
  HeatMapAPI,
  getCountries,
  getCities,
  getZones,
} from "../../../services/heatMap";
import { CircularProgress } from "@material-ui/core";
/**
 * @Author Jai
 * @Date 23 April, 2021
 * @Description Created a Heatmap along with filters such as location [country city zone], date range, and store category filter
 */

class HeatMap extends React.Component {
  myRef = React.createRef();
  state = {
    //state for heatmap
    mapHidden: false,
    layerHidden: false,
    addressPoints,
    radius: 25,
    blur: 15,
    max: 1.0,
    limitAddressPoints: true,

    date: {
      //start date and end date
      startDate:
        localStorage.getItem("startDate") != null
          ? localStorage.getItem("startDate")
          : moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
      endDate:
        localStorage.getItem("endDate") != null
          ? localStorage.getItem("endDate")
          : moment().format("YYYY-MM-DD HH:mm:ss"),
    },

    groupBy: 0,
    groupByName: { value: 0, label: "Hour" },

    dataLoaded: false,
    loadingScreen: true,
    // store category id
    storeIdValue: "0",
    storeData: [{ id: 0, name: "--No Data--" }],

    //snackbar error msg
    showSnackbar: false,
    snackBarMessage: "",

    logsData: [], //table data
    loading: true,

    center :{
      lat:20.5937,
      lng:78.9629
    },

    //country filter
    country: "",
    zone: "",
    city: "",
    countryId: "",
    cityId: "",
    zoneId: "",
    countries: [],
    cities: [],
    zones: [],

    modal: false,

    //pagination
    page: 0,
    limit: 10,
  };

  componentDidMount = () => {
    this.handleFilterData("", "initialCall");
    this.getAllStoreId();
    this.getData(
      this.state.date.startDate,
      this.state.date.endDate,
      this.state.groupBy,
      this.state.storeIdValue,
      this.state.countryId,
      this.state.cityId,
      this.state.zoneId,
      this.state.page,
      this.state.limit
    );
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.groupBy !== this.state.groupBy ||
      prevState.date.startDate !== this.state.date.startDate ||
      prevState.date.endDate !== this.state.date.endDate ||
      prevState.storeIdValue !== this.state.storeIdValue ||
      prevState.countryId !== this.state.countryId ||
      prevState.cityId !== this.state.cityId ||
      prevState.zoneId !== this.state.zoneId
    ) {
      this.setState({
        loadingScreen: true,
      });
      this.getData(
        this.state.date.startDate,
        this.state.date.endDate,
        this.state.groupBy,
        this.state.storeIdValue,
        this.state.countryId,
        this.state.cityId,
        this.state.zoneId,
        this.state.page,
        this.state.limit
      );
    }

    if (this.state.countryId !== prevState.countryId) {
      this.setState({
        cityId: "",
        city: [],
        cities: [],
        zoneId: "",
        zones: [],
        zone: "",
      });
    }
    if (this.state.cityId !== prevState.cityId) {
      this.setState({
        zone: "",
        zoneId: "",
        zones: [],
      });
    }
  }
  // fetching a data from the API
  getData = (
    startDate,
    endDate,
    groupBy,
    StoreCategoryId,
    countryId,
    cityId,
    zoneId,
    page,
    limit
  ) => {
    HeatMapAPI(
      moment(startDate).unix(),
      moment(endDate).unix(),
      groupBy || 0,
      StoreCategoryId || 0,
      countryId || "",
      cityId || "",
      zoneId || "",
      page,
      limit
    )
      .then((res) => {
        if (
          res &&
          res.data &&
          res.data.data &&
          res.data.data.graph &&
          res.data.data.graph.xaxis
        ) {
          res.data.data.graph.xaxis.categories = dateTimeFormatForGraph(
            this.state.selectedGroupBy || "Hour",
            res.data.data.graph.xaxis.categories
          );
        }

        const heatmapCoordinate = [];
        const heatmapData = res.data.data.heatmap;
        const center = res.data.data.centroid
        heatmapData.map((data, index) => {
          return heatmapCoordinate.push([data.lat, data.lng, data.intensity]);
        });

        this.setState({
          dataLoaded: true,
          loadingScreen: false,
          center,
          heatmapData: heatmapCoordinate,
          logsData: res.data.data.table,
          // getheatmapData: true,
        });
      })
      .catch((err) => {
        if (!this.state.date.startDate) {
          this.setState({
            showSnackbar: true,
            snackBarMessage: "Data Not Found For the Given Date Range!",
          });
        } else if ( err.response &&  err.response.status === 404) {
          this.setState({
            dataLoaded: false,
            loadingScreen: false,
            logsData: [],
            heatmapData: [],
            showSnackbar: true,
            snackBarMessage: err.response.data.message,
            noDataText: err.response.data.message,
          });
        } else if ( err.response && err.response.status === 500) {
          this.setState({
            dataLoaded: false,
            loadingScreen: false,
            logsData: [],
            heatmapData: [],
            showSnackbar: true,
            snackBarMessage: "Internal Server Error",
            noDataText: "Internal Server Error",
          });
        } else {
          let message =
            err &&
            err.response &&
            err.response.data &&
            err.response.data.message
              ? err.response.data.message
              : "Data Not Found For the Given Date Range!";
          this.setState({
            dataLoaded: false,
            loadingScreen: false,
            logsData: [],
            heatmapData: [],
            showSnackbar: true,
            snackBarMessage: message,
            noDataText: "No data for the selected date range",
          });
        }
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            // tableLoader: false,
          });
        }, 1000);
      });
  };

  /**
   * Toggle limiting the address points to test behavior with refocusing/zooming when data points change
   */
  toggleLimitedAddressPoints() {
    if (this.state.limitAddressPoints) {
      this.setState({
        addressPoints: addressPoints.slice(500, 1000),
        limitAddressPoints: false,
      });
    } else {
      this.setState({ addressPoints, limitAddressPoints: true });
    }
  }

  // redirecting a page on Dashboard
  goBack = () => {
    this.props.history.push("overview");
  };

  toggleModal = () => {
    this.setState({ modal: !this.state.modal });
  };

  getAllStoreId = () => {
    storeIdApiCall()
      .then((res) => {
        this.setState({
          storeData: res.data.data,
          dataLoaded: true,
        });
      })
      .catch((err) => {
        console.log("error store id", err);
        this.setState({
          dataLoaded: false,
        });
      });
  };

  headerStateHandler = (stateName, stateValue) => {
    if (stateName === "date" && stateValue) {
      this.setState({
        date: {
          startDate: stateValue.startDate,
          endDate: stateValue.endDate,
        },
      });
    }
    if (stateName === "groupBy" && (stateValue === 0 || stateValue)) {
      this.setState({
        groupBy: stateValue,
      });
    }
    if (stateName === "groupByName" && stateValue) {
      this.setState({
        groupByName: stateValue,
      });
    }
  };

  handleFilterData = (value, name) => {
    try {
      if (name === "initialCall") {
        getCountries()
          .then((response) => {
            if (response && response.data && response.data.data) {
              this.setState({
                countries: response.data.data,
              });
            }
          })
          .catch((error) => {
            console.log("Countries api error", error);
          });
      }
      if (name === "countries") {
        this.setState({
          country: name,
          countryId: value === null ? "" : value,
        });
        getCities(value)
          .then((response) => {
            if (response && response.data && response.data.data) {
              this.setState({
                cities: response.data.data,
                countryId: value,
              });
            }
          })
          .catch((error) => {
            if (
              error &&
              error.data &&
              error.data.status === 204 &&
              name &&
              value
            ) {
              this.setState({
                countryId: value || "",
              });
            }
            console.log("cities api error ", error);
          });
      } else if (name === "cities") {
        this.setState({ cityId: value === null ? "" : value });
        getZones(value)
          .then((response) => {
            if (response && response.data && response.data.data) {
              this.setState({
                zones: response.data.data,
                cityId: value,
              });
            }
          })
          .catch((error) => {
            if (
              error &&
              error.data &&
              error.data.status === 204 &&
              name &&
              value
            ) {
              this.setState({
                cityId: value || "",
              });
            }
            console.log("Your zones error", error);
          });
      }
      if (name === "zones") {
        this.setState({
          zoneId: value
            ? _.result(
                _.find(this.state.zones, function (obj) {
                  return obj.id === value;
                }),
                "id"
              )
            : "" || "",
        });
      }
      let key =
        name === "countries"
          ? "country"
          : name === "cities"
          ? "city"
          : name === "zones"
          ? "zone"
          : "";
      if (key) {
        const data = this.state[name];
        this.setState({
          [key]: _.result(
            _.find(data, function (obj) {
              return obj.id === value;
            }),
            "name"
          ),
        });
      }
    } catch (err) {
      console.log("Something went wrong here", err);
    }
  };

  dropDownSelect = (e) => {
    this.setState({
      storeIdValue: e.target.value,
    });
  };

  handlePagination = (name, data) => {
    if (name === "rows" && data) {
      this.setState({
        limit: +data,
      });
    }
    if (name === "page") {
      this.setState({
        page: +data,
      });
    }
  };

  render() {
    if (this.state.mapHidden) {
      return (
        <div>
          <input
            type="button"
            value="Toggle Map"
            onClick={() => this.setState({ mapHidden: !this.state.mapHidden })}
          />
        </div>
      );
    }

    const gradient = {
      // 0.1: "#89BDE0",
      // 0.2: "#96E3E6",
      // 0.4: "#82CEB6",
      // 0.6: "#FAF3A5",
      // 0.8: "#F5D98B",
      // "1.0": "#DE9A96",


      // 0.1: "red",
      // 0.3: "green",
      // 0.4: "#82CEB6",
      // 0.6: "#FAF3A5",
      // 0.8: "#F5D98B",
      // 1.0: "#DE9A96",
      // 0.4: 'blue', 0.65: 'lime', 1: 'red'

      '0.2': 'Black',
      '0.4': 'Purple',
      '0.6': 'Red',
      '0.8': 'Yellow',
      '1': 'White'

    };
    const { zone, country, city,  center} = this.state;
    return (
      <div className="overviewWrapper" style={{ top: "0px" }} ref={this.myRef}>
        <div className="head">
          <div className="hoverPathClass globalFontSize" onClick={this.goBack}>
            <i className="fas fa-angle-left mr-2"></i>Reports
          </div>
          <div className="title">Heatmap</div>
          <div className="d-flex text-grey mt-2 globalFontSize">
            <div className="mr-3 db_ptr">
              <ReactToPrint
                trigger={() => (
                  <span>
                    <i className="fas fa-print mr-1"></i>Print
                  </span>
                )}
                content={() => this.myRef.current}
              />
            </div>
            <div className="mr-3 db_ptr" onClick={this.toggleModal}>
              <i className="fas fa-download mr-1"></i>Export
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="mt-2">
              <Headers
                groupBy={this.state.groupBy}
                startDate={this.state.date.startDate}
                endDate={this.state.date.endDate}
                headerStateHandler={this.headerStateHandler}
                groupByName={this.state.groupByName}
                countries={this.state.countries}
                cities={this.state.cities}
                zones={this.state.zones}
                selectHandle={this.handleFilterData}
                country={country}
                zone={zone}
                city={city}
              />
            </div>
            <div className="d-flex align-items-center globalFontSize">
              <StoreIdDropDown
                storeData={this.state.storeData}
                storeID={this.state.storeIdValue}
                dropDownSelect={this.dropDownSelect}
              />
            </div>
          </div>
        </div>
        <div
          className="d-flex justify-content-center align-items-center mr-2 ml-2 bg-white border"
          style={{ minHeight: "500px", marginTop: "10rem" }}
        >
          {
          // this.state.loadingScreen ? (
          //   <h4 style={{ color: "grey" }}>
          //     {/* <CircularProgress /> */}
          //     Loading data...
          //   </h4>
          // ) :
           this.state.dataLoaded ? (
            <div
              style={{
                height: "100%",
                padding: "10px 0px 10px 0px",
                width: "100%",
                boxShadow: "0px 0px 2px 1px rgba(145, 145, 145, .5)",
              }}
            >
            { this.state.loadingScreen ?  // added loader on the map
              <div style={{ 
                position: "absolute",
                left:"50%",
                top:"50%",
                zIndex:"2"

              } } >
              
              <CircularProgress />
              </div> : <></>}

            <Map center={[center.lat, center.lng]} zoom={6} minZoom={3} scrollWheelZoom={true}> 
                {!this.state.layerHidden && (
                  <HeatmapLayer
                    fitBoundsOnLoad
                    fitBoundsOnUpdate
                    // points={this.state.addressPoints}
                    points={this.state.heatmapData}
                    longitudeExtractor={(m) => m[1]}
                    latitudeExtractor={(m) => m[0]}
                    gradient={gradient}
                    intensityExtractor={(m) => parseFloat(m[2])}
                    radius={Number(this.state.radius)}
                    blur={Number(this.state.blur)}
                    max={Number.parseFloat(this.state.max)}
                  />
                )}
                <TileLayer
                  noWrap={true}
                  url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
                />
              </Map>
            </div>
           ) : (
            <>
            { this.state.loadingScreen ? 
              <div style={{ 
                position: "absolute",
                left:"50%",
                top:"50%",
                padding:"20px",
                transform: "translate(-50%, -50%)",
                zIndex:"2"
          } } >
          
          <CircularProgress />
          </div> 
           : <h4 style={{ color: "grey" }}>No data for selected date range</h4>} 

         </>
          )} 
        </div>
        
        <div
          className="d-flex align-items-center mr-2 ml-2 justify-content-center"
          style={{
            // height:"200px",
            marginTop: "50px",
            backgroundColor: "white",
            borderRadius: "3px",
            minHeight: "100px",
            boxShadow: "0px 0px 2px 1px rgba(145, 145, 145, .5)",
          }}
        >
          {this.state.loadingScreen ? ( // added loaded on the heat map
            <CircularProgress/>
          ) : this.state.dataLoaded ? (
            <TableView
              tableData={this.state.logsData.length ? this.state.logsData : []}
              loader={this.state.loadingScreen}
              groupBy={this.state.groupBy || 0}
              handlePagination={this.handlePagination}
            />
          ) : (
            <h4 style={{ color: "grey" }}>No data for selected date range</h4>
          )}
        </div>
        {/* To Export the Report this modal will open and will confirm either user wants to export the data as csv or not */}
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggleModal}
          width={"35%"}
        >
          <div className="col-12 px-0">
            <div className="py-3 reportModal-header pl-3 border-bottom">
              Export your report
            </div>
            <div className="py-3 reportModal-subText pl-3 border-bottom">
              Report will be exported as a CSV (comma separated values) table.
            </div>
            <div className="py-3 col-12">
              <div className="row justify-content-end">
                <Pbutton
                  onClick={this.toggleModal}
                  className="reportModal-cancelBtn"
                >
                  Cancel
                </Pbutton>

                <CSVLink
                  onClick={() => this.toggleModal()}
                  data={this.state.logsData}
                  filename={"my-file.csv"}
                  className="reportModal-exportBtn"
                  target="_blank"
                >
                  Export
                </CSVLink>
              </div>
            </div>
          </div>
        </Modal>
        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackBarMessage}
        />
      </div>
    );
  }
}

export default HeatMap;
