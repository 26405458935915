import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';

class graph extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // series: this.props.apiPieData.graph.series,
            options: {
                chart: {
                    width: 380,
                    type: 'pie',
                },
                labels: this.props.data.label,
                legend: {
                    show: false,
                },
                stroke: {
                    show: false,
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                    }
                }]
            },
        };
    }

    render() {
        this.state.options = {
            ...this.state.options,
            labels: this.props.data.label,
        };
        return (
            <div id="chart">
                <ReactApexChart
                options={this.state.options}
                series={this.props.data.series} type="pie" width={380}
                />
            </div>
        );
    }
}

export default graph;