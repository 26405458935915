import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';

class PieGraph extends Component {
    constructor(props) {
        super(props);
  
        this.state = {
          series: [44, 55, 13, 43, 22],
          options: {
            chart: {
              width: 380,
              type: 'pie',
            },
            labels: this.props.graph.label,
            legend: {
                show: false,
            },
            stroke: {
                show: false,
            },
            responsive: [{
              breakpoint: 480,
              options: {
                chart: {
                  width: 200
                },
                legend: {
                  position: 'bottom'
                }
              }
            }]
          },
        };
      }

    render() {
      this.state.options = {
        ...this.state.options,
        labels: this.props.graph.label,
      };
        return (
            <div id="">
            <ReactApexChart
              options={this.state.options}
              series={this.props.graph.series} type="pie" width={380} />
            </div>
        );
    }
}

export default PieGraph;