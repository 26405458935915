import React, { Component } from 'react';

class Total extends Component {
    state = {};

    precision = (value, index, symbol) => {
        return value.toFixed(2);
    };

    render() {
        const { summary, symbol } = this.props;
        
        return (
            <div className="header-item-box bt-none d-flex mx-3" style={{ height: "70px" }}>
                <div className={`header-item`} style={{ width: "30%" }}></div>
                {
                    Object.keys(summary).map((item, index) => (
                        <div className={`header-item px-2 f-135 d-flex flex-column align-items-end`}
                            style={{ width: "11.67%" }}
                            key={`Ttotal${index}`}
                        >
                            <div className="f-165">{this.precision(summary[item], index, symbol)}</div>
                            <div className="f-134">
                                {
                                    index === 0 || index === 1 || index === 2
                                        ? "% of Total:"
                                        : index === 3
                                            ? `Avg for View:`
                                            : index === 4
                                                ? `Avg for View:`
                                                : "% of Total:"
                                }
                            </div>
                            <div className="f-134">
                                {
                                    index === 0 || index === 1 || index === 2
                                        ? `100.00% (${this.precision(summary[item], index, symbol)})`
                                        : index === 3
                                            ? `${this.precision(summary[item], index, symbol)} (0.00%)`
                                            : index === 4
                                                ? `${this.precision(summary[item], index, symbol)} (0.00%)`
                                                : "0.00% (0.00%)"
                                }
                            </div>
                        </div>
                    ))
                }
            </div>
        );
    }
}

export default Total;