//library import
import { connect } from "react-redux";
import React, { Component } from "react";
import { setIndex } from "../../../redux/actions/auth";
import ReactToPrint from "react-to-print";

// ! React table import
import SellerStasticsListData from "./table/seller_detail";
import EnhancedTable from "./table/seller_overview";

import Modal from "../../../components/modal/modal";
import Pbutton from "../../../components/button/Button";
import { CSVLink } from "react-csv";

// Alter (snackbar or toster) 
import Snackbar from "../../../components/snackbar/snackbar";


// spinner loader
import ClipLoader from "react-spinners/ClipLoader";


// css file
import "./index.css";
import "./Overview.scss";
import "../Common.scss";
import "../global-font-css.css";
import "../../../static/scss/globalComponent.scss";

// Dropdown filter

import _ from "lodash";

// API Imports
import {
  fetchSellerStatistics
} from "../../../services/sellerStatestics";

// helper 
import { getCurrencySymbol, replaceBlankWithDash } from "../../../lib/helper";



// Default API params

/**
 * @Author Jai
 * @Date 20 Apr, 2022
 * @Description Created a Demo dashboard with location filter, status, and date range
 */

class SellerStatistics extends Component {
  myRef = React.createRef();
  state = {
    dataLoaded: false,
    isOpenSnackbar: false,
    snackbarMessage: "",


    noDataText: "",

    showpPagination: true,
    // hidePagination : true,
    showSec: true,

    skip: 0,            //starting point
    limit: 5,
    rowsPerPage: 5,          //ending point
    page: 0,            //pagination page
    totalData: 0,  // total no of data

    loader: true,       //loader state


    logsData: [],           //table data
    thirdlogsData: [],      //table data
    logsColumn: [],

    fstColumnData: [],
    table_data: [],
    isOpen: false,
  };

  componentDidMount = () => {
    let width = window.innerWidth;
    this.setState({ width });
    window.addEventListener("resize", this.heightHandler);

    this.getData(
      this.state.skip,
      this.state.limit
    );

  };

  //method to fetch data
  getData = (skip, limit) => {
    // this.setState({
    //   loader: true
    // })
    fetchSellerStatistics(
      skip,
      limit
    )
      .then(data => {
        let firstColumnKey = Object.keys(data.data.data)
        // console.log("firstColumnKey", firstColumnKey)
        firstColumnKey.pop()

        // ! First Column Header
        const fstColumnData = firstColumnKey.map((key) => {
          return {
            Header: key,
            accessor: data => replaceBlankWithDash(data[key]),
            id: key,
            // minWidth: key === "From" || key === "To" ? 250 : 160,
          }
        })


        let table_data = { ...data.data.data }
        delete table_data['data']
        
        let array = Object.keys(data.data.data.data[0])
          
        // Using filter method to create a remove method
        function arrayRemove(arr, value) {
        
          return arr.filter(function(key){
              return key != value;
          });
        
        }
        let secColumnKey = arrayRemove(array, "storeId");
        const logsColumn = secColumnKey.map((key) => {
          return {
            Header: key,
            accessor: data => replaceBlankWithDash(data[key]),
            id: key,
            // minWidth: key === "From" || key === "To" ? 250 : 160,
          }
        })

        this.setState({
          fstColumnData,
          table_data,
          logsColumn,
          thirdlogsData: data.data.data.data,
          totalData: data.data.count,
          dataLoaded: true,
          loader: false,
        })
      })
      .catch(error => {
        console.log("error", error.response)
        if (!this.state.startDate) {
          this.setState({
            loader:false,
            isOpenSnackbar: true,
            snackbarMessage: "Data Not Found",
          });
        } else {
          let message =
            error &&
              error.response &&
              error.response.statusText
              ? error.response.statusText
              : "Data Not Found!";
          this.setState({
            dataLoaded: false,
            loader: false,
            isOpenSnackbar: true,
            snackbarMessage: message,
          });
        }
        setTimeout(() => {
          this.setState({
            isOpenSnackbar: false,
          });
        }, 2000);
      })
  };

  handleRowsPerPage = (rowsPerPage) => {
    // console.log("rowsPerPage ===> ", rowsPerPage)

    this.setState({
      // loader: true,
      rowsPerPage: rowsPerPage
    })

    this.getData(
      this.state.page,
      rowsPerPage
    );

  }
  handlePagination = (newPage) => {
    // console.log("skip ===> ",  newPage*this.state.rowsPerPage, "limit ===>", this.state.rowsPerPage )
    this.setState({
      // loader: true,
      page: newPage,
    })
    this.getData(
      newPage*this.state.rowsPerPage,
      this.state.rowsPerPage
    );

  }

  toggleModal = () => {
    this.setState({ modal: !this.state.modal });
  };
  render() {

    const { country, city } = this.state;
    return (
      <div className="containerDiv" ref={this.myRef}>
        <span
          className="hoverPathClass globalFontSize"
          onClick={() => this.props.history.push("overview")}
        >
          <i className="fas fa-angle-left mr-2"></i>Reports
        </span>
        <div className="title">Seller Statistics</div>
        <div
          className="d-flex text-grey mt-2 globalFontSize"
          style={{ marginBottom: "" }}
        >
          <div className="mr-3 db_ptr">
            <ReactToPrint
              trigger={() => (
                <span>
                  <i className="fas fa-print mr-1"></i>Print
                </span>
              )}
              content={() => this.myRef.current}
            />
          </div>
          <div className="mr-3 db_ptr" onClick={this.toggleModal}>
            <i className="fas fa-download mr-1"></i>Export
          </div>
        </div>
        {/* <div className="d-flex align-items-center justify-content-between mt-4 mb-4">
        </div> */}

        <div
          className="d-flex mt-4 align-items-center justify-content-center"
          style={{
            backgroundColor: "white",
            borderRadius: "3px",
            minHeight: "350",
            height: '150px',
            boxShadow: "0px 0px 2px 1px rgba(145, 145, 145, .5)",
          }}
        >

          {this.state.loader ? (
            <ClipLoader color="blue" size={40} />
            // <h4 style={{ color: "grey" }}>Loading data...</h4>
          ) : this.state.dataLoaded ? (

            <EnhancedTable
              showpPagination={this.state.showpPagination ? false : true}
              columnName={this.state.fstColumnData}
              // loader = {this.state.loader}
              rows={[this.state.table_data]}
            />

          ) : (
            <h4 style={{ color: "grey" }}>No data found </h4>
          )}
        </div>

        <div
          className="activeBarGraph d-flex align-items-center justify-content-center"
          style={{ padding: 0, marginTop: '4rem', height:"100%", minHeight: "400px" }}
        >
          {this.state.loader ? (
            <ClipLoader color="blue" size={40} />
            // <h4 style={{ color: "grey" }}>Loading data...</h4>
          ) : this.state.dataLoaded ? (
            <div
              style={{
                // marginTop: "4rem",
                height: "100%",
                padding: "10px 0px 10px 0px",
                width: "100%",
              }}
            >
              <SellerStasticsListData
                showpPagination={this.state.showpPagination ? true : true}
                columnName={this.state.logsColumn}
                rows={this.state.thirdlogsData}
                totalData={this.state.totalData}
                tablerRowPerPage={this.handleRowsPerPage}
                tablePagination={this.handlePagination}
              />
            </div>
          ) : (
            <h4 style={{ color: "grey" }}>No data found</h4>
          )}
        </div>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggleModal}
          width={"35%"}
        >
          <div className="col-12 px-0">
            <div className="py-3 reportModal-header pl-3 border-bottom">
              Export your report
            </div>
            <div className="py-3 reportModal-subText pl-3 border-bottom">
              Report will be exported as a CSV (comma separated values) table.
            </div>
            <div className="py-3 col-12">
              <div className="row justify-content-end">
                <Pbutton
                  onClick={this.toggleModal}
                  className="reportModal-cancelBtn"
                >
                  Cancel
                </Pbutton>
                <CSVLink
                  onClick={() => this.toggleModal()}
                  data={this.state.thirdlogsData}
                  filename={"my-file.csv"}
                  className="reportModal-exportBtn"
                  target="_blank"
                >
                  Export
                </CSVLink>
              </div>
            </div>
          </div>
        </Modal>
        <div>
        <Snackbar
          open={this.state.isOpenSnackbar}
          message={this.state.snackbarMessage}
        />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setIndex: (index) => dispatch(setIndex(index)),
  };
};

export default connect(null, mapDispatchToProps)(SellerStatistics);
