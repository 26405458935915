//library import
import React from "react";
import ReactTable from "react-table";
import DescriptionIcon from "@material-ui/icons/Description";
import EventNoteIcon from "@material-ui/icons/EventNote";
import moment from "moment";
import { DatePicker } from "antd";
//custom import

import { dateFilter } from "../../fixtures/dateFilter/dateFIlter";
import "antd/dist/antd.css";
//css import
import "./reactTable.scss";
import "react-table/react-table.css";

const { RangePicker } = DatePicker;
class reactTable extends React.Component {
  intDate = {
    startDate: moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
    endDate: moment().format("YYYY-MM-DD HH:mm:ss"),
  };

  state = {
    filtered: [],
  };

  handlerDialog = (flag) => {
    this.setState({ open: flag });
  };

  handleRange = (...arges) => {
    let startDate =
      arges[0].length !== 0
        ? arges[0][0].format("YYYY-MM-DD HH:mm:ss")
        : this.intDate.startDate;
    let endDate =
      arges[0].length !== 0
        ? arges[0][1].format("YYYY-MM-DD HH:mm:ss")
        : this.intDate.endDate;
    if (this.props.handleRange) {
      this.props.handleRange(startDate, endDate);
    }
  };

  render() {
    return (
      <div>
        {/* <Dialog
          type={"dateRangePicker"}
          open={this.state.open}
          range={this.state.range}
          handleClose={this.handlerDialog.bind(this, false)}
          handlerRange={this.handleRange.bind(this)}
        ></Dialog> */}
        {/* <Filter></Filter> */}
        <div className="react-table" id="react-table-temp">
          <div className="table-lable-div">
            <div className="table-icon">
              <DescriptionIcon></DescriptionIcon>
            </div>
            <div className="table-lable">{this.props.lable || ""}</div>
            <div className="table-filters ">
              <div className="filter-div">
                <div
                  className="d-flex time-range-div"
                  onClick={this.handlerDialog.bind(this, true)}
                >
                  <div className="filter-lable">
                    <EventNoteIcon></EventNoteIcon>
                  </div>
                  <div className="date-filter-lable">
                    <RangePicker
                      ranges={{
                        ...dateFilter,
                      }}
                      defaultValue={[
                        moment(moment().startOf("day")),
                        moment(moment().endOf("day")),
                      ]}
                      onChange={this.handleRange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ReactTable
            data={this.props.data}
            pageSize={this.props.data && this.props.data.length}
            showPagination={false}
            // row={[{ rows: this.props.summary }]}
            // showPagination={true}
            // pageSizeOptions={[5, 10, 20, 25, 50, 100]}
            // defaultPageSize={20}
            // showPaginationBottom={this.props.data.length != 0 ? true : false}
            className="-striped -highlight"
            columns={[
              { columns: this.props.columns },
              {
                accessor: "order",
                Cell: ({ row, original }) => {
                  return <div>{original.value}</div>;
                },
              },
            ]}
            minRows={9}
            noDataText={this.props.noDataText || "No rows found"}
            // onPageChange={
            //   this.props.onPageChange ? this.props.onPageChange : () => { }
            // }
            // PaginationComponent={Pagination}
          ></ReactTable>
        </div>
      </div>
    );
  }
}
export default reactTable;
