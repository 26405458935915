import { replaceBlankWithDash } from "../../lib/helper";

export const ratingLogsColumns = [
  {
    Header: "User Name",
    accessor: data => replaceBlankWithDash(data.userName),
    id: "userName"
  },
  {
    Header: "Rating",
    accessor: data => replaceBlankWithDash(data.rating),
    id: "rating"
  },
  {

    Header: "Create Date",
    accessor: data => replaceBlankWithDash(data.createDate),
    id: "createDate",
    minWidth: 110
  },

  {
    Header: "Platform",
    accessor: data => replaceBlankWithDash(data.platform),
    id: "platform"
  },
  {
    Header: "IP Address",
    accessor: data => replaceBlankWithDash(data.ipAddress),
    id: "ipAddress"
  },
  {
    Header: "Latitude",
    accessor: data => replaceBlankWithDash(data.latitude),
    id: "latitude"
  },
  {
    Header: "Longitude",
    accessor: data => replaceBlankWithDash(data.longitude),
    id: "longitude"
  },
  {
    Header: "City",
    accessor: data => replaceBlankWithDash(data.city),
    id: "city"
  },
  {
    Header: "Country",
    accessor: data => replaceBlankWithDash(data.country),
    id: "country"
  },

];
