/** @format */

import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";

class userBarGraph extends Component {
   constructor(props) {
      super(props);
      this.state = {
         status: null,
         sortByDevice: 3,
         redirect: false,
      };
   }

   render() {
      return (
         <div className='overviewBody'>
            <ReactApexChart
               options={this.props.config.options}
               series={this.props.config.series}
               type='bar'
               height={350}
            />
         </div>
      );
   }
}

export default (userBarGraph);
