import { getForReport } from "../lib/request";
import jstz from "jstz";
import moment from "moment";

var timezone = jstz.determine();
let yourTimeZone = timezone.name();

// api calls
export const salesPerformanceApi = (
  apiInfoData,
  dimension,
  component,
  date,
  groupBy = "",
  dropDownValue = "",
  expo,
  storeId,
  skip,
  limit
) => {
  let URL = ``;
  let start = moment(date.startDate).unix();
  let end = moment(date.endDate).unix();
  URL =
    dimension === "Transaction ID"
      ? component
        ? `/sales-performance/transaction-data?timezone=${yourTimeZone}&start_timestamp=${start}&end_timestamp=${end}&search=${apiInfoData.searchValue}&skip=${skip}&limit=${limit}&sort_by=${apiInfoData.sortBy}&ascending=${apiInfoData.order}&group_by=${apiInfoData.groupBy}&currency=${apiInfoData.currency}&store_categories_id=${storeId}`
        : `/sales-performance/transaction-percent?timezone=${yourTimeZone}&start_timestamp=${start}&end_timestamp=${end}&search=${apiInfoData.searchValue}&skip=${skip}&limit=${limit}&sort_by=${apiInfoData.sortBy}&ascending=${apiInfoData.order}&group_by=${apiInfoData.groupBy}&currency=${apiInfoData.currency}&column=${apiInfoData.column}&pie_column=${apiInfoData.pieColumn}&store_categories_id=${storeId}`
      : dimension === "mainGraph"
      ? `/top_product/performance_graph?timezone=${yourTimeZone}&start_timestamp=${start}&end_timestamp=${end}&currency=${apiInfoData.currency}&y_axis=${apiInfoData.dropDownValue}&group_by=${groupBy.value}&store_categories_id=${storeId}`
      : component
      ? "Table Date"
      : "Pie Date";
  if (expo === "export") {
    URL = `${URL}&export=1`;
  }
  return getForReport(URL);
};
