import React, { Component } from "react";
import ReactToPrint from "react-to-print";
import moment from "moment";
import DateRangePicker from "../../../components/DatePicker";
import Modal from "../../../components/modal/modal";
import Pbutton from "../../../components/button/Button";
import { CSVLink } from "react-csv";
import Apexcharts from "react-apexcharts";
import { storeIdApiCall } from "../../../services/storeId";
import { deviceInstallReportApiCall } from "../../../services/deviceInstallationReport";

import Dropdown from "./dropdown.js";

import "../../../static/scss/globalComponent.scss";
// import StoreIdDropDown from "../../../components/StoreIdDropdown";

class DeviceInstallationReport extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      date: {
        startDate:
          localStorage.getItem("startDate") != null
            ? localStorage.getItem("startDate")
            : moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
        endDate:
          localStorage.getItem("endDate") != null
            ? localStorage.getItem("endDate")
            : moment().format("YYYY-MM-DD HH:mm:ss"),
      },
      dataLoaded: false,
      loadingScreen: true,
      storeIdValue: "0",
      storeData: [{ id: 0, name: "--No Data--" }],
      modal: false,
    };
  }

  componentDidMount() {
    // this.getAllStoreId();
    this.getData();
  }

  // getAllStoreId = () => {
  //   storeIdApiCall()
  //     .then((res) => {
  //       this.setState({
  //         storeData: res.data.data,
  //         dataLoaded: true,
  //       });
  //     })
  //     .catch((err) => {
  //       console.log("error store id", err);
  //       this.setState({
  //         dataLoaded: false,
  //       });
  //     });
  // };

  getData = () => {
    deviceInstallReportApiCall(this.state.date, this.state.storeIdValue, "")
      .then((res) => {
        res.data.data.table.totalAmount.map((item, index) =>
          Object.keys(res.data.data.table.history).map((data, i) =>
            item.make === data
              ? (item.history = res.data.data.table.history[data])
              : ""
          )
        );
        // graph fromatting
        let graphData = {
          series: [...res.data.data.graph.series],
          options: {
            plotOptions: { bar: { columnWidth: "20%" } },
            title: {
              text: "",
              align: "left",
              style: { fontSize: "20px", fontWeight: 700 },
            },
            dataLabels: { enabled: false },

            xaxis: {
              categories: [...res.data.data.graph.labels],
              title: {
                text: res.data.data.graph.xaxis,
                style: { fontSize: "11px", fontWeight: 700 },
                // offsetX: 0,
                // offsetY: 10,
              },
              labels: {
                show: true,
                style: { fontSize: "11px" },
              },
            },
            yaxis: {
              title: {
                text: "Installation Count",
                style: { fontSize: "11px", fontWeight: 700 },
              },
              opposite: false,
              labels: {
                show: true,
                offsetX:15,
                style: { fontSize: "11px" },
                formatter: function (value) {
                  return parseInt(value);
                },
              },
            },
          },
        };
        this.setState({
          dataLoaded: true,
          loadingScreen: false,
          graphData,
          tableData: res.data.data.table,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          dataLoaded: false,
          loadingScreen: false,
        });
      });
  };

  handleDateRange = (startDate, endDate) => {
    let { date } = this.state;
    date.startDate = startDate;
    date.endDate = endDate;
    this.setState(
      {
        date: {
          startDate: date.startDate,
          endDate: date.endDate,
        },
        loadingScreen: true,
        dataLoaded: false,
      },
      () => this.getData()
    );
  };

  dropDownSelect = (e) => {
    this.setState(
      {
        storeIdValue: e.target.value,
      },
      () => this.getData()
    );
  };

  toggleModal = () => {
    this.setState({ modal: !this.state.modal });
  };

  exportData = () => {
    deviceInstallReportApiCall(
      this.state.date,
      this.state.storeIdValue,
      "export"
    )
      .then((expoData) => {
        let exportData = [];
        if (
          expoData &&
          expoData.data &&
          expoData.data.data &&
          expoData.data.data.table
        ) {
          expoData.data.data.table.totalAmount.map((dataObject) =>
            expoData.data.data.table.history[dataObject.make].map(
              (makeDetails) =>
                exportData.push({
                  deviceModel: makeDetails.deviceModel,
                  make: dataObject.make,
                  total: makeDetails.Count,
                })
            )
          );
        }

        this.setState(
          {
            logsData: exportData,
          },
          () => this.toggleModal()
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    return (
      <div className="containerDiv" ref={this.myRef}>
        <span
          className="hoverPathClass globalFontSize"
          onClick={() => this.props.history.push("overview")}
        >
          <i className="fas fa-angle-left mr-2"></i>Reports
        </span>
        <div className="title">Device Type Install Report</div>
        <div
          className="d-flex text-grey mt-2 globalFontSize"
          style={{ marginBottom: "" }}
        >
          <div className="mr-3 db_ptr">
            <ReactToPrint
              trigger={() => (
                <span>
                  <i className="fas fa-print mr-1"></i>Print
                </span>
              )}
              content={() => this.myRef.current}
            />
          </div>
          <div className="mr-3 db_ptr" onClick={this.exportData}>
            <i className="fas fa-download mr-1"></i>Export
          </div>
        </div>
        <div
          className="d-flex align-items-center"
          style={{ marginBottom: "20px", marginTop: "20px" }}
        >
          <div
            className="d-flex align-items-center globalFontSize justify-content-between"
            style={{ width: "100%" }}
          >
            <div className="d-flex align-items-center">
              <div style={{ marginRight: "10px" }}>Date Range : </div>
              <DateRangePicker
                id="datePicker"
                handleDateChange={this.handleDateRange}
              />
            </div>
          </div>
        </div>
        <div className="activeBarGraph d-flex align-items-center justify-content-center">
          {this.state.loadingScreen ? (
            <h4 style={{ color: "grey" }}>Loading data...</h4>
          ) : this.state.dataLoaded ? (
            <div
              style={{
                height: "100%",
                padding: "10px 0px 10px 0px",
                width: "100%",
              }}
            >
              <Apexcharts
                series={this.state.graphData.series}
                options={this.state.graphData.options}
                type="bar"
                height={"100%"}
              />
            </div>
          ) : (
            <h4 style={{ color: "grey" }}>No data for selected date range</h4>
          )}
        </div>
        <div
          className="d-flex align-items-center justify-content-center"
          style={{
            marginTop: "50px",
            backgroundColor: "white",
            borderRadius: "3px",
            minHeight: "100px",
            boxShadow: "0px 0px 2px 1px rgba(145, 145, 145, .5)",
          }}
        >
          {this.state.loadingScreen ? (
            <h4 style={{ color: "grey" }}>Loading data...</h4>
          ) : this.state.dataLoaded ? (
            <Dropdown tableData={this.state.tableData} />
          ) : (
            <h4 style={{ color: "grey" }}>No data for selected date range</h4>
          )}
        </div>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggleModal}
          width={"35%"}
        >
          <div className="col-12 px-0">
            <div className="py-3 reportModal-header pl-3 border-bottom">
              Export your report
            </div>
            <div className="py-3 reportModal-subText pl-3 border-bottom">
              Report will be exported as a CSV (comma separated values) table.
            </div>
            <div className="py-3 col-12">
              <div className="row justify-content-end">
                <Pbutton
                  onClick={this.toggleModal}
                  className="reportModal-cancelBtn"
                >
                  Cancel
                </Pbutton>
                <CSVLink
                  onClick={() => this.toggleModal()}
                  data={this.state.logsData}
                  filename={"my-file.csv"}
                  className="reportModal-exportBtn"
                  target="_blank"
                >
                  Export
                </CSVLink>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default DeviceInstallationReport;
