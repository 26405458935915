import React, { Component } from "react";
import Pagination from "react-js-pagination";

import "./paginationJs.scss";

class PaginationJs extends Component {
  handlePageChange = (pageNumber) => {
    this.props.changeActivePage(pageNumber);
  };

  changeSize = (newVal) => {
    this.props.dataToShowHandler(newVal);
  };

  render() {
    return this.props.totalData > 50 ? (
      <div>
        <div className="limitWrapper">
          {/* <div className="count">Count:</div> */}
          <div
            className={`size ${this.props.dataToShow === 50 ? "selected" : ""}`}
            onClick={() => this.changeSize(50)}
          >
            50
          </div>
          <div className="divider">|</div>
          <div
            className={`size ${
              this.props.dataToShow === 100 ? "selected" : ""
            }`}
            onClick={() => this.changeSize(100)}
          >
            100
          </div>
        </div>
        <Pagination
          activePage={this.props.activePage}
          itemsCountPerPage={this.props.dataToShow}
          totalItemsCount={this.props.totalData}
          pageRangeDisplayed={5}
          onChange={this.handlePageChange}
        />
      </div>
    ) : null;
  }
}

export default PaginationJs;
