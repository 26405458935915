import React, { Component } from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';

class Row extends Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
        };
    }
    render() {
        const { open } = this.state;
        const { row } = this.props;
        return (
            <React.Fragment>
                <TableRow className={""}>
                    <TableCell>
                        <IconButton aria-label="expand row" size="small" onClick={() => this.setState({ open: !this.state.open })}>
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                    <TableCell component="th" scope="row" style={{ fontWeight: 500, fontSize: "14px" }}>{row.make}</TableCell>
                    <TableCell align="right" style={{ fontWeight: 500, fontSize: "14px" }}>{row.Count}</TableCell>
                    <TableCell />
                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box margin={1}>
                                <Typography variant="h6" gutterBottom component="div">
                                    History
                                </Typography>
                                <Table size="small" aria-label="purchases">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Device Models</TableCell>
                                            <TableCell align="right">Count</TableCell>
                                            <TableCell />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.props.row.history.map((historyRow, index) => (
                                            <TableRow key={`id${index}`}>
                                                <TableCell component="th" scope="row">{historyRow.deviceModel}</TableCell>
                                                <TableCell align="right">{historyRow.Count}</TableCell>
                                                <TableCell />
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        );
    }
}

export default Row;