import { Select } from "antd";
import React, { Component } from "react";
import "./select.scss";
const { Option } = Select;

/**
 * @Author Jai
 * @Date 23 April, 2021
 * @Description Using this component in location filter for the Country, City, Zone, GroupBy, and Datetime dropdown
 */

class OptionComponent extends Component {
  render() {
    const onChange = (name) => {
      // console.log(`selected ${name}`);
    };

    const onBlur = () => {
      // console.log("blur");
    };

    const onFocus = () => {
      // console.log("focus");
    };

    const onSearch = (val) => {
      // console.log("search:", val);
    };

    let key = "";
    switch (this.props.type) {
      case "countries":
        key = "country";
        break;
      case "cities":
        key = "city";
        break;
      case "zones":
        key = "zone";
        break;
      default:
        key = "";
    }
    return (
      <div>
        <Select
          showSearch
          style={{ width: "100%" }}
          value={this.props.selected || undefined}
          placeholder={this.props.placeholder}
          optionFilterProp="children"
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          onSearch={onSearch}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          <Option
            value={null}
            onClick={() => this.props.handleSelectChange(null)}
          >
            Clear data
          </Option>
          {this.props.data.map((Item, index) => {
            return (
              <Option
                key={index}
                value={Item["id"] || Item["value"]}
                onClick={() => this.props.handleSelectChange(Item["id"])}
              >
                {Item.name || Item.label}
              </Option>
            );
          })}
        </Select>
      </div>
    );
  }
}

export default OptionComponent;
