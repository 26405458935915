import React, { Component } from "react";
import Link from "../../../../components/Link/Link";
import TopNTable from "../table/topNTable";
import RevLoader from "../../../../components/loader/RevLoader";

// currencyFormatter.format(10000, {code: "USD"})

class topNProduct extends Component {
  render() {
    return (
      <div className="card">
        <div className="spaceBetween mt-3 ml-3 mr-3">
          <span className="label globalFontSize fontweightone">
            Top products by units sold
          </span>
          <span className="link globalFontSize fontweightone">
            <Link url={`product-performance`}>View Activity</Link>
          </span>
        </div>
        
        <div className="spaceBetween ml-3 mr-3">
          <span className="numericValue">
            {/* {this.props.loader ? <RevLoader /> : this.props.totalOrder ? `${currencyFormatter.format(parseInt(this.props.totalOrder), { code: this.props.currency})}` : `${getSymbolFromCurrency(this.props.currency)} ` + 0}
            {this.props.loader ? <RevLoader /> : this.props.totalSale ? `${getSymbolFromCurrency(this.props.currency)} ` + parseInt(this.props.totalSale) : `${getSymbolFromCurrency(this.props.currency)} ` + 0} */}
            {this.props.loader ? <RevLoader /> : <></>}

          </span>
        </div>
        {/* <div className="m-3 graphTitle">
                <span>Sales Activity</span>
              </div> */}

              
        <div className="chart">
          <TopNTable
            className="top-n-table"
            table={this.props.table}
            loader={this.props.loader}
            currency={this.props.currency}
          />
        </div>
      </div>
    );
  }
}

export default topNProduct;
