import React, { Component } from "react";
import Link from "../../../../components/Link/Link";
import RevLoader from "../../../../components/loader/RevLoader";
import WishlistTable from "../table/wishlistTable";

class topNProduct extends Component {
  render() {
    return (
      <div className="card">
        <div className="spaceBetween mt-3 ml-3 mr-3">
          <span className="label globalFontSize fontweightone">
            Top products In wishlist
          </span>
          <span className="link globalFontSize fontweightone">
            <Link url={`wish-list`}>View Activity</Link>
          </span>
        </div>
        <div className="spaceBetween ml-3 mr-3">
          <span className="numericValue">
            {/* <span className="label2" >Product count</span> */}

            {/* {this.props.loader ? <RevLoader /> : this.props.totalOrder ? `${currencyFormatter.format(parseInt(this.props.totalOrder), { code: this.props.currency})}` : `${getSymbolFromCurrency(this.props.currency)} ` + 0} */}
            {/* {this.props.loader ? <RevLoader /> : this.props.product ? parseInt(this.props.product) : 0} */}
            {this.props.loader ? <RevLoader /> : <></>}
          </span>
        </div>
        {/* <div className="m-3 graphTitle">
                <span>Sales Activity</span>
              </div> */}
        <div className="chart">
          <WishlistTable
            className="top-n-table"
            table={this.props.table}
            product={this.props.product}
            loader={this.props.loader}
          />
        </div>
      </div>
    );
  }
}

export default topNProduct;
