import React, { Component } from 'react';
import TablePagination from '@material-ui/core/TablePagination';



class Dropdown extends Component {
    constructor(props) {
        super(props)
        this.state = {
            rowsPerPage: 10,
            page: 0,
        };
    }

    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage,
        });
    };
    
    handleChangeRowsPerPage = (event) => {
        this.setState({
            rowsPerPage: +event.target.value,
        });
    };

    render() {
        return (
            <div style={{ width: "100%" }}>
                <div className="activityShadowBox d-flex justify-content-between align-items-center">
                    <span style={{ fontWeight: 500, fontSize: "14px" }}>{this.props.tableData.header[0]}</span>
                    <span style={{ fontWeight: 500, fontSize: "14px" }}>{this.props.tableData.header[1]}</span>
                </div>
                {
                    this.props.tableData.data.count.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((item, index) => (
                        <div className="activityShadowBox d-flex justify-content-between align-items-center" id="border" style={{ backgroundColor: "white", borderRadius: 0 }} key={index}>
                            <span style={{ fontSize: "14px" }}>{this.props.tableData.data.osVersion[index]}</span>
                            <span style={{ fontSize: "14px" }}>{item}</span>
                        </div>
                    ))
                }
                <TablePagination
                    style={{ backgroundColor: "white", marginTop: "10px" }}
                    rowsPerPageOptions={[5, 10, 15, 20, 25]}
                    id="backgroundShadow"
                    component="div"
                    count={this.props.tableData.data.count.length}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
            </div>
        );
    }
}

export default Dropdown;