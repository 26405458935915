import React, { Component } from 'react';
import { getCurrencySymbol, getFromLocalStorage } from '../../../../../lib/helper';

class RenderData extends Component {

    precision = (data, symbol, index) => {
        if (index === 1 || index === 6) {
            return `${symbol}${data.toFixed(1)}`;
        } else if (index === 2 || index === 3) {
            return data;
        }
    };

    render() {
        const { data, outerIndex, apiInfo, indexArray } = this.props;
        apiInfo.symbol = getCurrencySymbol(getFromLocalStorage("currency"));
        return (
            <div
                className="header-item-box bt-none d-flex mx-3" style={{ height: "40px" }}
            >
                {
                    Object.keys(data).map((item, index) => (
                        <div
                            className={`header-item px-2 f-134 d-flex
                            ${index === 0 ?  "justify-content-start" : "justify-content-end"} align-items-center`}
                            style={index === 0 ? { width: "30%" } : { width: "11.67%" }}
                            key={`ttag${index}`}
                        >
                            {
                                index === 0 || index === 4 || index === 5
                                    ? <div className={`d-flex`}>
                                       {index === 0 && <div style={{ width: "30px" }}>{indexArray[outerIndex]}.</div>}
                                        <div>
                                            {
                                                index === 4
                                                    ? `${apiInfo.symbol} ${(data[item]).toFixed(1)}`
                                                    : index === 5
                                                        ? `${(data[item]).toFixed(1)}`
                                                        : <div
                                                                data-toggle="tooltip"
                                                                data-placement="bottom"
                                                                title={data[item]}
                                                            >
                                                                {
                                                                    data[item].length > 50
                                                                        ? `${data[item].substring(0, 50)}...`
                                                                        : data[item]
                                                                }
                                                            </div>
                                            }
                                        </div>
                                    </div>
                                    : <div className="d-flex">
                                        <div>{this.precision(data[item].value, apiInfo.symbol, index)}</div>
                                        <div className="d-flex justify-content-end" style={{ width: "55px" }}>
                                            ({(data[item].percent).toFixed(1)}%)
                                        </div>
                                    </div>
                            }
                        </div>
                    ))
                }
            </div>
        );
    }
}

export default RenderData;