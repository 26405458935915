import { replaceBlankWithDash } from "../../lib/helper";

export const productClickLogsColumns = [
  {
    Header: "Product Name",
    accessor: data => replaceBlankWithDash(data.productName),
    id: "productName"
  },
  {

    Header: "Create Date",
    accessor: data => replaceBlankWithDash(data.createDate),
    id: "createDate",
    minWidth: 110
  },
  {
    Header: "Seller Name",
    accessor: data => replaceBlankWithDash(data.sellerName),
    id: "sellerName"
  },
  {
    Header: "Category Name",
    accessor: data => replaceBlankWithDash(data.subCategoryName),
    id: "subCategoryName"
  },
  {
    Header: "Sub Category Name",
    accessor: data => replaceBlankWithDash(data.subCategoryName),
    id: "subCategoryName"
  },
  {
    Header: "Sub Sub Category Name",
    accessor: data => replaceBlankWithDash(data.subSubCategoryName),
    id: "subSubCategoryName"
  },
  {
    Header: "Full Name",
    accessor: date => replaceBlankWithDash(date.fullName),
    id: "fullName",
  },
  {
    Header: "Retailer Price",
    accessor: data => replaceBlankWithDash(data.currencySymbol) + " " + replaceBlankWithDash(data.retailerPrice),
    id: "retailerPrice"
  },
  {
    Header: "Distributor Price",
    accessor: data => replaceBlankWithDash(data.currencySymbol) + " " + replaceBlankWithDash(data.distributorPrice),
    id: "distributorPrice"
  },
  {
    Header: "Platform",
    accessor: data => replaceBlankWithDash(data.platformName),
    id: "platformName"
  },
  {
    Header: "IP Address",
    accessor: data => replaceBlankWithDash(data.ipAddress),
    id: "ipAddress"
  },
  {
    Header: "Latitude",
    accessor: data => replaceBlankWithDash(data.latitude),
    id: "latitude"
  },
  {
    Header: "Longitude",
    accessor: data => replaceBlankWithDash(data.longitude),
    id: "longitude"
  },
  {
    Header: "City Name",
    accessor: data => replaceBlankWithDash(data.cityName),
    id: "cityName"
  },
  {
    Header: "Country Name",
    accessor: data => replaceBlankWithDash(data.countryName),
    id: "countryName"
  },
];
