import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';
import { tooltipFormatter } from "../../../../lib/helper"

class ProductPerformanceGraph extends Component {
    constructor(props) {
        super(props);

        this.state = {
            series: [],
            options: {
                chart: {
                    type: 'area',
                    height: 350,
                    zoom: {
                        enabled: true,
                    }
                },
                grid: {
                    padding: {
                        left: 40,
                    },
                },
                dataLabels: {
                    enabled: false
                },
                xaxis: {
                    title: {
                        text: "Time Line",
                        offsetY: -10,
                        style: {
                            fontSize: "11px",
                            fontWeight: 700,
                        },
                    },
                    tooltip: {
                        enabled: false
                    }
                },
                yaxis: {
                    labels: {
                        show: true,
                        formatter: function (value) {
                            return parseInt(value);
                        },
                    },
                    title: {
                        style: {
                            fontSize: "11px",
                            fontWeight: 700,
                        },
                    }
                },
                tooltip: {
                    enabled: true,
                    followCursor: true
                }
            }
        };
    }


    static getDerivedStateFromProps(props, state) {
        return {
            series: props.mainGraphData.series,
            options: {
                ...state.options,
                xaxis: {
                    ...state.options.xaxis,
                    categories: props.mainGraphData.xcat,
                    title: {
                        ...state.options.xaxis.title,
                        text: props.groupBy.label,
                    }
                },
                yaxis: {
                    ...state.options.yaxis,
                    title: {
                        ...state.options.yaxis.title,
                        text: props.mainGraphData.series[0].name
                    },
                    labels: {
                        formatter: (val, index) => {
                            return tooltipFormatter(val, "INR", false)
                        }
                    }
                },
            }
        }
    }


    render() {
        const { dropDownGraphValue } = this.props;
        return (
            <div className="px-2 pb-2 pt-3" id="chart" style={{ position: "relatives" }}>
                <ReactApexChart options={this.state.options} series={this.state.series} type="area" height={350} />
                <select
                    className="mainDropDown"
                    value={dropDownGraphValue}
                    name="dropDownGraphValue"
                    onClick={(e) => this.props.prevDropDown(e)}
                    onChange={(e) => this.props.dropDown(e)}>
                    <option value="product_revenue">Product Revenue</option>
                    <option value="unique_purchase">Unique Purchases</option>
                    <option value="quantity">Quantity</option>
                    <option value="avg_price">Avg. Price</option>
                    <option value="avg_qty">Avg. QTY</option>
                    <option value="refund_amount">Product Refund Amount</option>
                </select>
            </div>
        );
    }
}

export default ProductPerformanceGraph;