import { replaceBlankWithDash } from "../../lib/helper";

export const emailLogsColumns = [
  {
    Header: "User Name",
    accessor: date => replaceBlankWithDash(date.userName),
    id: "userName",
  },
  {
    Header: "Email Id",
    accessor: date => replaceBlankWithDash(date.emailId),
    id: "emailId",
    minWidth: 150
  },
  {

    Header: "Subject",
    accessor: data => replaceBlankWithDash(data.subject),
    id: "subject",
  },
  {
    Header: "Trigger",
    accessor: data => replaceBlankWithDash(data.trigger),
    id: "trigger"
  },



  {
    Header: "Create Date",
    accessor: data => replaceBlankWithDash(data.createDate),
    id: "createDate"
  },
  // {
  //   Header: "Id",
  //   accessor: data => replaceBlankWithDash(data._id),
  //   id: "_id"
  // },
  // {
  //   Header: "Request Id",
  //   accessor: data => replaceBlankWithDash(data.requestId),
  //   id: "requestId"
  // },
  // {
  //   Header: "Message Id",
  //   accessor: data => replaceBlankWithDash(data.messageId),
  //   id: "messageId"
  // },

];
