import React, { Component } from "react";
import { Paper } from "@material-ui/core";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import TablePagination from "@material-ui/core/TablePagination";
import { getWishListData } from "../../../services/wishlist";
import { withStyles } from "@material-ui/core/styles";
import { storeIdApiCall } from "../../../services/storeId";
import LinearProgress from "@material-ui/core/LinearProgress";

import "../../../static/scss/globalComponent.scss";
import StoreIdDropDown from "../../../components/StoreIdDropdown";
import DatePicker from "../../../components/DatePicker";
import Snackbar from "../../../components/snackbar/snackbar";
import { TimeFormatToAgo } from "../../../lib/string-format";
import { getFromLocalStorage } from "../../../lib/helper";
import Header from "../../../components/HeaderComponent/header";

class WishList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apiData: [],
      loading: true,
      dataLoaded: false,
      rowsPerPage: 10,
      page: 0,
      show: false,
      sort: 0,
      order: 0,
      storeIdValue: "0",
      storeData: [{ id: 0, name: "--No Data--" }],
      dataLoadedID: false,
      startDate: getFromLocalStorage("startDate")
        ? getFromLocalStorage("startDate")
        : moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
      endDate: getFromLocalStorage("endDate")
        ? getFromLocalStorage("endDate")
        : moment().format("YYYY-MM-DD HH:mm:ss"),
      isOpenSnackbar: false,
      snackbarMessage: "",
    };
  }

  componentDidMount() {
    this.getAllStoreId();
    this.getData();
  }

  getAllStoreId = () => {
    storeIdApiCall()
      .then((res) => {
        this.setState({
          storeData: res.data.data,
          dataLoadedID: true,
        });
      })
      .catch((err) => {
        console.log("error store id", err);
        this.setState({
          dataLoadedID: false,
        });
      });
  };

  getData = () => {
    getWishListData(
      moment(this.state.startDate).unix(),
      moment(this.state.endDate).unix(),
      parseInt(this.state.sort),
      this.state.order,
      this.state.storeIdValue,
      this.state.page,
      this.state.rowsPerPage
    )
      .then((res) => {
        this.setState({
          loading: false,
          dataLoaded: true,
          apiData: res.data.data,
          totalCount: res.data.total_count,
          totalProduct: res.data.total_product,
          totalWishlist: res.data.total_wishlist,
        });
      })
      .catch((err) => {
        if (err && err.data && err.data.status === 204) {
          this.setState({
            isOpenSnackbar: true,
            snackbarMessage: "No data for the given filters!",
          });
        }
        if (err.response) {
          if (err.response.status && err.response.status === 500) {
            this.setState({
              isOpenSnackbar: true,
              snackbarMessage: "Internal Server Error!",
            });
          }
        }
        setTimeout(() => {
          this.setState({
            isOpenSnackbar: false,
            snackbarMessage: "",
          });
        }, 1000);
        console.log(err);
        this.setState({
          loading: false,
          dataLoaded: false,
        });
      });
  };

  progress = (value) => {
    return ((value - 0) * 100) / (this.state.totalCount - 0);
  };

  handleChangePage = (event, newPage) => {
    this.setState(
      {
        page: newPage,
        dataLoaded: false,
        loading: true,
      },
      () => this.getData()
    );
  };

  handleChangeRowsPerPage = (event) => {
    this.setState(
      {
        rowsPerPage: +event.target.value,
        dataLoaded: false,
        loading: true,
      },
      () => this.getData()
    );
  };

  showAll = () => {
    this.state.show
      ? this.setState({
          rowsPerPage: this.state.totalProduct,
        })
      : this.setState({
          rowsPerPage: 5,
        });
  };

  sorting = (e) => {
    this.setState(
      {
        sort: e.target.value,
        loading: true,
        dataLoaded: false,
      },
      () => this.getData()
    );
  };

  dropDownSelect = (e) => {
    this.setState(
      {
        storeIdValue: e.target.value,
        loading: true,
        dataLoaded: false,
      },
      () => this.getData()
    );
  };

  handleDateChange = (startDate, endDate) => {
    if (startDate !== this.state.startDate || endDate !== this.state.endDate) {
      this.setState(
        {
          startDate,
          endDate,
          dataLoaded: false,
          loading: true,
        },
        () => this.getData()
      );
    }
  };

  render() {
    const BorderLinearProgress = withStyles((theme) => ({
      root: {
        height: 10,
      },
      colorPrimary: {
        backgroundColor: "#ccc",
      },
      bar: {
        backgroundColor: "#3a3abf",
      },
    }))(LinearProgress);

    const LightTooltip = withStyles((theme) => ({
      tooltip: {
        backgroundColor: "#2A3F54",
        color: "white",
        boxShadow: theme.shadows[1],
        fontSize: 11,
      },
    }))(Tooltip);

    return (
      <div className="containerDiv">
        <Header heading="Wishlist" />
        <div
          className="d-flex justify-content-between"
          style={{ marginTop: "10px", width: "700px", marginBottom: "20px" }}
        >
          <Paper square={true} className="box d-flex">
            <div
              className="d-flex justify-content-center align-items-center"
              style={{
                width: "100px",
                height: "100%",
                backgroundColor: "#2A3F54",
              }}
            >
              <i
                className="fa fa-heart fa-2x"
                aria-hidden="true"
                style={{ color: "white" }}
              ></i>
            </div>
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ width: "200px", height: "100%", position: "relative" }}
            >
              {this.state.loading ? (
                <span style={{ color: "gray" }}>Loading...</span>
              ) : this.state.dataLoaded ? (
                <div>
                  <div
                    className="wish-listfont"
                    style={{ fontWeight: "700", textAlign: "center" }}
                  >
                    {this.state.totalWishlist}
                  </div>
                  <div className="wish-listfonttwo">WISHLISTS</div>
                  <div className="infoIcon" style={{ position: "absolute" }}>
                    <LightTooltip
                      title="User created  Wishlists"
                      placement="bottom-start"
                    >
                      <i className="fa fa-question-circle"></i>
                    </LightTooltip>
                  </div>
                </div>
              ) : (
                <span
                  style={{ color: "gray", fontSize: "14px", fontWeight: "500" }}
                >
                  No Data
                </span>
              )}
            </div>
          </Paper>
          <Paper square={true} className="box d-flex">
            <div
              className="d-flex justify-content-center align-items-center"
              style={{
                width: "100px",
                height: "100%",
                backgroundColor: "#2A3F54",
              }}
            >
              <i
                className="fa fa-tag fa-2x"
                aria-hidden="true"
                style={{ color: "white" }}
              ></i>
            </div>
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ width: "200px", height: "100%", position: "relative" }}
            >
              {this.state.loading ? (
                <span style={{ color: "gray" }}>Loading...</span>
              ) : this.state.dataLoaded ? (
                <div>
                  <div
                    className="wish-listfont"
                    style={{ fontWeight: "700", textAlign: "center" }}
                  >
                    {this.state.totalProduct}
                  </div>
                  <div className="wish-listfonttwo">TOTAL ITEMS</div>
                  <div className="infoIcon" style={{ position: "absolute" }}>
                    <LightTooltip
                      title="Total number of Items"
                      placement="bottom-start"
                    >
                      <i className="fa fa-question-circle"></i>
                    </LightTooltip>
                  </div>
                </div>
              ) : (
                <span
                  style={{ color: "gray", fontSize: "14px", fontWeight: "500" }}
                >
                  No Data
                </span>
              )}
            </div>
          </Paper>
        </div>
        <div className="d-flex mt-2 mb-4">
          <DatePicker handleDateChange={this.handleDateChange} text={true} />
          <div
            className="d-flex align-items-center globalFontSize"
            style={{ marginLeft: "10px" }}
          >
            <StoreIdDropDown
              storeData={this.state.storeData}
              storeID={this.state.storeIdValue}
              dropDownSelect={this.dropDownSelect}
            />
          </div>
        </div>
        <Paper
          square={true}
          style={{ padding: "40px 10px 10px 10px", minHeight: "390px" }}
        >
          <div className="d-flex justify-content-between">
            <div style={{ fontSize: "18px", fontWeight: "500" }}>
              Customer Wishlist
            </div>
            {this.state.dataLoaded ? (
              <div className="d-flex justify-content-between" style={{}}>
                <div
                  onClick={() =>
                    this.setState(
                      {
                        order: this.state.order === 0 ? 1 : 0,
                        dataLoaded: false,
                        loading: true,
                      },
                      () => this.getData()
                    )
                  }
                  style={{
                    backgroundColor: "#3a3abf",
                    color: "white",
                    width: "30px",
                    height: "25px",
                    borderRadius: "3px",
                    marginRight: "10px",
                  }}
                  className="d-flex justify-content-center align-items-center"
                >
                  {this.state.order === 0 ? (
                    <i className="fa fa-arrow-down" aria-hidden="true"></i>
                  ) : (
                    <i className="fa fa-arrow-up" aria-hidden="true"></i>
                  )}
                </div>
                <div>
                  <select
                    className="d-flex justify-content-center"
                    name="sorting"
                    value={this.state.sort}
                    onChange={(e) => this.sorting(e)}
                    style={{
                      borderRadius: "3px",
                      border: "1px solid #ccc",
                      height: "26.4px",
                    }}
                  >
                    <option value={0}>Items</option>
                    <option value={1}>Date</option>
                  </select>
                </div>
                <div
                  className="show-button d-flex justify-content-center align-items-center"
                  style={{
                    fontSize: "",
                    fontWeight: "500",
                    cursor: "pointer",
                    marginLeft: "10px",
                    height: "26px",
                  }}
                  onClick={() =>
                    this.setState({ show: !this.state.show }, () =>
                      this.showAll()
                    )
                  }
                >
                  {this.state.show ? "SHOW LESS" : "SHOW ALL"}
                </div>
              </div>
            ) : (
              <div></div>
            )}
          </div>
          <div className="" style={{ marginTop: "20px" }}>
            {this.state.loading ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "280px" }}
              >
                <h4 style={{ color: "gray" }}>Loading...</h4>
              </div>
            ) : this.state.dataLoaded ? (
              <div>
                {this.state.apiData.map((item, index) => (
                  <div
                    key={item.parentproductid}
                    className="d-flex flex-column justify-content-center"
                    style={{ height: "50px" }}
                  >
                    <div className="d-flex" style={{ fontSize: "14px" }}>
                      <div style={{ color: "#3a3abf" }}>{item.productname}</div>
                      <div style={{ color: "black", marginLeft: "5px" }}>
                        (in {item["count(parentproductid)"]} wishlists)
                      </div>
                      <div style={{ color: "black", marginLeft: "5px" }}>
                        ({TimeFormatToAgo(item["max(createdtimestamp)"])} ago)
                      </div>
                    </div>
                    <div>
                      <BorderLinearProgress
                        thickness={4}
                        variant="determinate"
                        value={this.progress(item["count(parentproductid)"])}
                      />
                    </div>
                  </div>
                ))}
                <TablePagination
                  style={{ borderTop: "1px solid #ccc" }}
                  rowsPerPageOptions={[10, 25, 75, 150]}
                  id=""
                  component="div"
                  count={this.state.totalProduct}
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
              </div>
            ) : (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "280px" }}
              >
                <h4 style={{ color: "gray" }}>
                  No data for this selected date range
                </h4>
              </div>
            )}
          </div>
        </Paper>
        <Snackbar
          open={this.state.isOpenSnackbar}
          message={this.state.snackbarMessage}
        />
      </div>
    );
  }
}

export default WishList;
