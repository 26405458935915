// Product
export const Product = [
    { name: 'Product', title: "The product names of items sold." },
    { name: 'Product Revenue', title: "Revenue from individual product sales." },
    { name: 'Unique Purchases', title: "The total number of times a specified product (or set of products) was a part of a transaction." },
    { name: 'Quantity', title: "The number of units sold in ecommerce transactions." },
    { name: 'Avg. Price', title: "The average ecommerce revenue per product." },
    { name: 'Avg. QTY', title: "Average number of products sold per transaction." },
    { name: 'Product Refund', title: "Total refund amount associated with the product." },
];

// Product Category
export const ProductCategory = [
    { name: 'Product Category', title: "The product Category names of items sold." },
    { name: 'Product Revenue', title: "Revenue from individual product Category sales." },
    { name: 'Unique Purchases', title: "The total number of times a specified product Category (or set of product Category) was a part of a transaction." },
    { name: 'Quantity', title: "The number of units sold in ecommerce transactions." },
    { name: 'Avg. Price', title: "The average ecommerce revenue per product Category." },
    { name: 'Avg. QTY', title: "Average number of product Category sold per transaction." },
    { name: 'Product Refund', title: "Total refund amount associated with the product Category." },
];

// Product Brand
export const ProductBrand = [
    { name: 'Product Brand', title: "The Product brand names of items sold." },
    { name: 'Product Revenue', title: "Revenue from individual Product brand sales." },
    { name: 'Unique Purchases', title: "The total number of times a specified Product brand (or set of Product brand) was a part of a transaction." },
    { name: 'Quantity', title: "The number of units sold in ecommerce transactions." },
    { name: 'Avg. Price', title: "The average ecommerce revenue per Product brand." },
    { name: 'Avg. QTY', title: "Average number of Product brand sold per transaction." },
    { name: 'Product Refund', title: "Total refund amount associated with the Product brand." },
];