import { get } from "../lib/request";
import moment from "moment";

export const fetchCategoryLogs = (
  startDate = moment(),
  endDate = moment(),
  skip = 0,
  limit = 50,
  city = ""
) => {
  return get(`/category/logs?skip=${skip}&limit=${limit}&cityName=${city}`, {
    startDate: startDate,
    endDate: endDate,
  });
};

export const fetchSubCategoryLogs = (
  startDate = moment(),
  endDate = moment(),
  skip = 0,
  limit = 50,
  city = ""
) => {
  return get(`/subcategory/logs?skip=${skip}&limit=${limit}&cityName=${city}`, {
    startDate: startDate,
    endDate: endDate,
  });
};

export const fetchSubSubCategoryLogs = (
  startDate = moment(),
  endDate = moment(),
  skip = 0,
  limit = 50,
  city = ""
) => {
  return get(
    `/subsubcategory/logs?skip=${skip}&limit=${limit}&cityName=${city}`,
    { startDate: startDate, endDate: endDate }
  );
};
