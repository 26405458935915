import React, { Component } from 'react';
import { getCurrencySymbol, getFromLocalStorage } from '../../../../lib/helper';

import Heading from "./heading";
import TotalData from "./totalData";

class tableChat extends Component {
    constructor(props) {
        super(props)
        this.state = {
            columnsList: [
                { name: 'Transaction ID', title: "The customer name of the ecommerce transactions." },
                { name: 'Revenue', title: "The total revenue from web ecommerce or in-app transactions. Depending on your implementation, this can include tax and shipping." },
                { name: 'Quantity', title: "The number of units sold in ecommerce transactions." },
                { name: 'Refund Amount', title: "Currency amount refunded for a transaction." },
                { name: 'Tax', title: "Tax is the total of tax charges for ecommerce transactions." },
                { name: 'Shipping', title: "Shipping is the total of shipping charges for ecommerce transactions." },
            ],
        };
    }

    totalData = (value) => {
        let total;
        total = value === 1
            ? (this.props.tableData.summary['Revenue']).toFixed(2)
            : value === 2
                ? this.props.tableData.summary['Quantity']
                : value === 3
                    ? (this.props.tableData.summary['Refund Amount']).toFixed(2)
                    : value === 4
                        ? (this.props.tableData.summary['Tax']).toFixed(2)
                        : (this.props.tableData.summary['Shipping']).toFixed(2);
        return total;
    }

    render() {
        return (
            <div>
                <div className="heading d-flex">
                    {
                        this.state.columnsList.map((item, index) => (
                            <Heading
                                headingInfo={item}
                                index={index}
                                passOrder={this.props.passOrder}
                                apiInfo={this.props.apiInfo}
                                callTable={this.props.callTable}
                                show={this.props.show}
                                key={`heading${index}`}
                                groupBy={this.props.groupBy}
                            />
                        ))
                    }
                </div>
                <div className="heading d-flex" style={{ height: "65px" }}>
                    {
                        this.state.columnsList.map((item, index) => (
                            <TotalData
                                index={index}
                                key={`total${index}`}
                                totalValue={index !== 0 ? this.props.tableData && this.props.tableData.summary && this.totalData(index) : ''}
                                symbol={getCurrencySymbol(getFromLocalStorage("currency"))}
                            />
                        ))
                    }
                </div>
                {
                    this.props.tableData && this.props.tableData.data && this.props.tableData.data.map((item, index) => (
                        <div className="heading d-flex" style={{ height: "45px" }} key={index}>
                            {
                                Object.keys(item).map((info, objectIndex) => (
                                    <div className="headingParts"
                                        key={`data${objectIndex}`}
                                        style={objectIndex === 0
                                            ? { width: "25%", borderTop: "none", padding: 0, height: "45px" }
                                            : objectIndex === 5
                                                ? { borderRight: "1px solid #ccc", borderTop: "none", padding: 0, height: "45px" }
                                                : { borderTop: "none", padding: 0, height: "45px" }}
                                    >
                                        <div style={{ height: "100%", width: "100%", padding: "5px" }}>
                                            {
                                                info === "Customer Name" || info === "Country" || info === "State"
                                                    ? <div style={{ height: "100%", width: "100%", display: "flex", alignItems: "center" }}>
                                                        <div className="fontsizeproduct" style={{ width: "50px" }}>{this.props && this.props.page && this.props.page === 1 ? index + 1 : (parseInt(this.props.limit) * (this.props.page - 1) + index + 1 || index + 1) }.</div>
                                                        <div className="fontsizeproduct">{item[info]}</div>
                                                    </div>
                                                    : <div style={{ height: "100%", width: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                                            <div className="fontsizeproduct">{info === 'Quantity' ? item[info].value : `${getCurrencySymbol(getFromLocalStorage("currency"))}${(item[info].value).toFixed(2)}` }</div>
                                                            <div className="small fontsizeproducttwo" style={{ width: "60px", display: "flex", justifyContent: "flex-end" }}>{`(${(item[info].percent).toFixed(2)}%)`}</div>
                                                        </div>
                                            }
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    ))
                }
            </div>
        );
    }
}

export default tableChat;