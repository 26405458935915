import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

class Heading extends Component {
    constructor(props) {
        super(props)
        this.state = {
            order1: false,
            order2: false,
            order3: false,
            order4: false,
            order5: false,
            order6: false,
        };
    }

    orderFunction(name, constant) {
        this.setState({
            [`order${constant}`]: !this.state[`order${constant}`]
        },() => this.props.callTable(name, this.state[`order${constant}`]));
    }

    render() {

        const LightTooltip = withStyles((theme) => ({
            tooltip: {
                backgroundColor: "#2A3F54",
                color: 'white',
                boxShadow: theme.shadows[1],
                fontSize: 11,
            },
        }))(Tooltip);
        return (
            <div className="headingParts hoverClass"
                style={this.props.index === 0
                    ? { width: "25%", flexDirection: "column", justifyContent: "space-between", alignItems: "flex-start", borderTopLeftRadius: "5px" }
                    : this.props.index === 5
                        ? {borderRight: "1px solid #ccc", borderTopRightRadius: "5px"}
                        : {}}
                onClick={() => this.orderFunction(this.props.index === 0 ? parseInt(this.props.groupBy) === 1 ? 'Customer Name' : parseInt(this.props.groupBy) === 2 ? 'Country' : 'State' : this.props.headingInfo.name, this.props.index + 1)}
            >
                <div className="fontsizeproduct" style={{ fontWeight: 500 }}>{this.props.index === 0 ? parseInt(this.props.groupBy) === 1 ? 'Customer Name' : parseInt(this.props.groupBy) === 2 ? 'Country' : 'State' : this.props.headingInfo.name}</div>
                {
                    this.props.headingInfo.name === "Transaction ID"
                        ? <div className="d-flex justify-content-between">
                            <LightTooltip title={parseInt(this.props.groupBy) === 1 ? this.props.headingInfo.title : parseInt(this.props.groupBy) === 2 ? 'The country of the ecommerce transactions.' : 'The state of the ecommerce transactions.'} style={{width: "50px"}} placement="bottom-start"><i className="fa fa-question-circle"></i></LightTooltip>
                            {
                                this.props.show && (this.props.apiInfo.sortBy === "Customer Name" || this.props.apiInfo.sortBy === "Country" || this.props.apiInfo.sortBy === "State")
                                    ? this.props.passOrder === 0
                                        ? <i className="fas fa-arrow-down"></i>
                                        : <i className="fas fa-arrow-up"></i>
                                    : ''
                            }
                        </div>
                        : <div style={{height: "20px"}}>
                            {
                                this.props.show && this.props.headingInfo.name === this.props.apiInfo.sortBy
                                    ? this.props.passOrder === 0
                                        ? <i className="fas fa-arrow-down" style={{ marginRight: "25px" }}></i>
                                        : <i className="fas fa-arrow-up" style={{ marginRight: "25px" }}></i>
                                    : ''
                            }
                            <LightTooltip title={this.props.headingInfo.title} placement="bottom-start"><i className="fa fa-question-circle"></i></LightTooltip>
                        </div>
                }
            </div>
        );
    }
}

export default Heading;