import React, { Component } from 'react';

import Header from "./Header/header";
import TotalData from "./Total/totalData";
import Pagination from "../Pagination/pagination";

class PieChartRender extends Component {
    render() {
        const {
            dimension,
            tableDropDown,
            pieDropdown,
            sortBy,
            orderIconState,
            summary,
            symbol,
            pieData,
            props,
        } = this.props;
        
        return (
            <div>
                <Header
                    dimension={dimension}
                    tableDropDown={tableDropDown}
                    pieDropdown={pieDropdown}
                    setTableDropDown={this.props.setTableDropDown}
                    changeOrder={this.props.changeOrder}
                    sortBy={sortBy}
                    orderIconState={orderIconState}
                />
                <TotalData
                    summary={summary}
                    currency={symbol}
                    data={pieData}
                    pageNo={props.pageNo}
                    rows={props.rowSelected}
                    sortBy={sortBy}
                    pieDropdown={pieDropdown}
                />
                <Pagination
                    rowSelected={props.rowSelected}
                    miscRowSelected={props.miscRowSelected}
                    count={props.tableData.count}
                    data={props.tableData.data}
                    handleChange={props.handleChange}
                    nextPage={props.nextPage}
                    prevPage={props.prevPage}
                    pageNo={props.pageNo}
                />
            </div>
        );
    }
}

export default PieChartRender;