let averageSaleConfig = {
   series: [],
   options: {
      chart: {
         type: "area",
         stacked: false,
         height: 350,
         zoom: {
            type: "x",
            enabled: false,
            autoScaleYaxis: true,
         },
         toolbar: {
            show: false,
            tools: {
               download: false
            }
         },
      },
      dataLabels: {
         enabled: false,
         background: {
            enabled: false
         },
         // offsetY: -40
      },
      legend: {
         position: "top",
         horizontalAlign: "center",
      },
      // grid: {
      //    padding: {
      //       left: 40,
      //    },
      // },
      markers: {
         size: 0,
      },
      title: {
         text: "",
         align: "left",
      },
      fill: {
         type: "gradient",
         gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.5,
            opacityTo: 0,
            stops: [0, 90, 100],
         },
      },
      noData: {
         text: "Loading data ...",
         align: "center",
         verticalAlign: "middle",
         offsetX: 0,
         offsetY: 0,
         style: {
            fontSize: "12px",
         },
         xaxis: {
            show: false,
            style: {
               fontSize: '11px'
           }
         },
         yaxis: {
            show: false,
         },
      },
      yaxis: {
         labels: {
            show: false,
         },
         title: {
            text: "",
         },
         style: {
            fontSize: "11px",
         },
      },
      xaxis: {
         type: "datetime",
         tickPlacement: "on",
         categories: [],
         tooltip: {
            enabled: false,
         },
         // offsetY: 10,
         labels: {
            style: {
               fontSize: "11px",
            }
         },
      },
      tooltip: {
         shared: false,
         followCursor: true,
      },
   },
};

export { averageSaleConfig };