import React, { Component } from "react";
import { storeIdApiCall } from "../../../services/storeId";
import AnyChart from "anychart-react";

import "./index.scss";
import { funnelApi } from "../../../services/funnelChart";
import StoreIdDropDown from "../../../components/StoreIdDropdown";
import DateRangePicker from "../../../components/DatePicker";
// import { dateFilter } from "../../../fixtures/dateFilter/dateFIlter";
import ReactToPrint from "react-to-print";

import Snackbar from "../../../components/snackbar/snackbar";

import moment from "moment";
import { getFromLocalStorage } from "../../../lib/helper";

class Funnel extends Component {
  myRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      funnelData: [],
      funnelTableData: [],
      length: 3,
      dataLoaded: false,
      loadingScreen: true,
      snackBarMessage: "",
      showSnackbar: false,

      heading: [
        { heading: "Session by source" },
        { step: "Step 1", heading: "cart" },
        { step: "Step 2", heading: "Billing and Shipping" },
        { step: "Step 3", heading: "Payment" },
      ],
      dropDown: "platform",
      storeIdValue: "0",
      storeData: [{ id: 0, name: "--No Data--" }],
      dataLoadedId: false,

      startDate: getFromLocalStorage("startDate")
        ? getFromLocalStorage("startDate")
        : moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
      endDate: getFromLocalStorage("endDate")
        ? getFromLocalStorage("endDate")
        : moment().format("YYYY-MM-DD HH:mm:ss"),
    };
  }

  componentDidMount() {
    this.getAllStoreId();
    this.funnelApiCall();
  }

  getAllStoreId = () => {
    storeIdApiCall()
      .then((res) => {
        this.setState({
          storeData: res.data.data,
          dataLoadedId: true,
          loadingScreen: false,
        });
      })
      .catch((err) => {
        console.log("error store id", err);
        this.setState({
          dataLoadedId: false,
          loadingScreen: false,
        });
      });
  };

  funnelApiCall = () => {
    let date = {
      start_date: moment(this.state.startDate).unix(),
      end_date: moment(this.state.endDate).unix(),
    };

    funnelApi(
      this.state.dropDown,
      this.state.storeIdValue,
      date.start_date,
      date.end_date
    )
      .then((res) => {
        this.setState({
          funnelData: res.data.graph,
          funnelTableData: res.data.data,
          dataLoaded: true,
          loadingScreen: false,
        });
      })
      .catch((error) => {
        if (!this.state.startDate) {
          this.setState({
            showSnackbar: true,
            snackBarMessage: "Please select a date range",
          });
        } else {
          let message =
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
              ? error.response.data.message
              : "Data Not Found For the Given Date Range!";
          this.setState({
            dataLoaded: false,
            loadingScreen: false,
            showSnackbar: true,
            snackBarMessage: message,
            noDataText: "No data for the selected date range",
          });
        }
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            dataLoaded: false,
          });
        }, 1000);
        console.log("Api error", error);
      });
  };

  fixingDecimal = (value, percent, total) => {
    percent = percent.toFixed(1);
    return (
      <div
        className="d-flex align-items-center"
        style={{ width: "100%", height: "100%" }}
      >
        <div className="fontsizeproduct" style={{ width: "50px" }}>
          {value}
        </div>
        <div
          className="small fontsizeproducttwo"
          style={{ fontSize: "15px" }}
        >{`${percent}% of ${total}`}</div>
      </div>
    );
  };

  selectGroupByPeriodHandler = (startDate, endDate) => {
    this.setState(
      {
        startDate: startDate,
        endDate: endDate,
        dataLoaded: false,
        loadingScreen: true,
      },
      () => this.funnelApiCall()
    );
  };

  dropDown = (e) => {
    const { value } = e.target;
    this.setState(
      {
        dropDown: value,
        dataLoaded: false,
      },
      () => this.funnelApiCall()
    );
  };

  heading = (main, sub) => {
    return (
      <div
        className="d-flex justify-content-between align-items-center headingClass"
        style={{ width: "100%", height: "100%" }}
      >
        <div className="fontsizeproduct">{main}</div>
        <div className="small fontsizeproduct">{sub}</div>
      </div>
    );
  };

  dropDownSelect = (e) => {
    this.setState(
      {
        storeIdValue: e.target.value,
      },
      () => this.funnelApiCall()
    );
  };

  render() {
    return (
      <div className="main-container" style={{ left: 0 }} ref={this.myRef}>
        <div className="inner-container" style={{}}>
          <div
            className="align-items-center justify-content-between"
            style={{
              width: "100%",
              height: "54px",
              marginBottom: "20px",
              //   marginTop: "32px",
            }}
          >
            <span
              className="hoverPathClass globalFontSize"
              onClick={() => this.props.history.push("overview")}
            >
              <i className="fas fa-angle-left mr-2"></i>Reports
            </span>
            <h4 className="title">Flow Diagram</h4>

            <ReactToPrint
              trigger={() => (
                <span className="hoverPathClass globalFontSize">
                  <i className="fas fa-print mr-2"></i>Print
                </span>
              )}
              content={() => this.myRef.current}
            />
          </div>

          <div className="d-flex align-items-center justify-content mb-2">
            <div className="mt-3 mb-2">
              <DateRangePicker
                text={true}
                handleDateChange={this.selectGroupByPeriodHandler}
              />
            </div>
            <div className="d-flex align-items-center globalFontSize ml-2">
              <StoreIdDropDown
                storeData={this.state.storeData}
                storeID={this.state.storeIdValue}
                dropDownSelect={this.dropDownSelect}
              />
            </div>
          </div>
          <div
            className="d-flex align-items-center justify-content-center"
            style={{
              width: "100%",
              height: "550px",
              minHeight: "550px",
              borderRadius: "5px",
              backgroundColor: "white",
              padding: "10px 10px 10px 10px",
              boxShadow: "0px 0px 5px 1px rgba(145, 145, 145, .5)",
            }}
          >
            {this.state.loadingScreen ? (
              <h4 style={{ color: "grey" }}>Loading data...</h4>
            ) : this.state.dataLoaded ? (
              <div
                style={{ width: "100%", height: "500px", position: "relative" }}
              >
                <AnyChart
                  type="sankey"
                  data={this.state.funnelData}
                  title="Funnel Flow"
                  nodePadding={100}
                  nodeWidth={"30%"}
                />
                <select
                  className="selection fontsizeproduct"
                  name="dropdDown"
                  value={this.state.dropDown}
                  id=""
                  onChange={(e) => this.dropDown(e)}
                >
                  <option value="platform">Web Platform</option>
                  <option value="device">Devices</option>
                </select>
              </div>
            ) : (
              <h4 style={{ color: "grey" }}>No data for selected date range</h4>
            )}
          </div>
          <div className="dataTable">
            <div
              className="d-flex"
              style={{ width: "100%", background: "whitesmoke" }}
            >
              {this.state.heading.map((item, index) => (
                <div
                  key={index}
                  className="headingClass"
                  style={
                    index !== 0
                      ? {
                          width: "25%",
                          height: "40px",
                          border: "1px solid #ccc",
                          borderLeft: "none",
                          fontWeight: 700,
                        }
                      : {
                          width: "25%",
                          height: "40px",
                          border: "1px solid #ccc",
                          fontWeight: 700,
                        }
                  }
                >
                  {index === 0
                    ? this.heading(item.heading, "")
                    : index === 1
                    ? this.heading(item.step, "cart")
                    : index === 2
                    ? this.heading(item.step, "order")
                    : this.heading(item.step, "Payment")}
                </div>
              ))}
            </div>
            <div className="d-flex flex-column" style={{ width: "100%" }}>
              {this.state.loadingScreen ? (
                <h4
                  className="d-flex justify-content-center align-items-center"
                  style={{ color: "grey", paddingTop: "110px" }}
                >
                  Loading data...
                </h4>
              ) : this.state.dataLoaded ? (
                this.state.funnelTableData &&
                this.state.funnelTableData.map((item, index) => (
                  <div
                    key={index}
                    className="d-flex"
                    style={{ width: "100%", height: "30px" }}
                  >
                    <div className="borderClassData d-flex justify-content-between">
                      <div
                        className="d-flex justify-content-between align-items-center headingClass"
                        style={{ width: "100%", height: "100%" }}
                      >
                        <div className="fontsizeproduct">
                          {item.make || item.device}
                        </div>
                        <div className="small fontsizeproducttwo">{`${item.session} sessions`}</div>
                      </div>
                    </div>
                    <div className="borderClassData">
                      {this.fixingDecimal(
                        item.step1.value,
                        item.step1.percent,
                        item.session
                      )}
                    </div>
                    <div className="borderClassData">
                      {this.fixingDecimal(
                        item.step2.value,
                        item.step3.percent,
                        item.session
                      )}
                    </div>
                    <div
                      className="borderClassData"
                      style={{ borderRight: "1px solid #ccc" }}
                    >
                      {this.fixingDecimal(
                        item.step3.value,
                        item.step3.percent,
                        item.session
                      )}
                    </div>
                    {/* <div className="borderClassData" style={{ borderRight: "1px solid #ccc  " }}>{this.fixingDecimal(item.step4.value, item.step4.percent, item.session)}</div> */}
                  </div>
                ))
              ) : (
                <h4
                  className="d-flex justify-content-center align-items-center"
                  style={{ color: "grey", paddingTop: "100px" }}
                >
                  No data for selected date range
                </h4>
              )}
            </div>
          </div>
        </div>
        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackBarMessage}
        />
      </div>
    );
  }
}

export default Funnel;
