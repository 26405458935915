import React, { Component } from 'react';
import { Paper } from '@material-ui/core';

// input component import
import Input from "../../../../components/InputBox/Input";

import THeader from "./Theader/theader";
import Total from "./Total/total";
import RenderData from "./RenderData/renderData";
import PieChartRender from "./PieChartRender/pieChartRender";
import Pagination from "./Pagination/pagination";
import { getCurrencySymbol, getFromLocalStorage } from '../../../../lib/helper';

class ReactTableView extends Component {
    state = {
            dimension: "Product",
        };

    render() {
        const {
            tableData,
            dimension,
            component,
            sortBy,
            orderIconState,
            tableDataLoading,
            gotTableData,
            apiInfo,
            rowSelected,
            pageNo,
            indexArray,
            pieDataLoading,
            gotPieData,
            search,
            tableDropDown,
            pieDropdown,
            miscRowSelected,
            pieData,
        } = this.props;
    
        return (
            <Paper className="mx-3 my-3 py-2">
                <div className="dimension-class f-145 d-flex justify-content-between px-3 py-3">
                    <span className="">Primary Dimension :</span>
                    <span
                        className={`${dimension === "Product" && "dimension-highlight"}`}
                        onClick={() => this.props.selectedDimension("Product")}
                    >
                        Product
                    </span>
                    <span
                        className={`${dimension === "Product Category" && "dimension-highlight"}`}
                        onClick={() => this.props.selectedDimension("Product Category")}
                    >
                        Product Category
                    </span>
                    <span
                        className={`${dimension === "Product Brand" && "dimension-highlight"}`}
                        onClick={() => this.props.selectedDimension("Product Brand")}
                    >
                        Product Brand
                    </span>
                </div>
                <div className="dimension-strip d-flex align-items-center justify-content-end px-3">
                    <div className="brand-class d-flex">
                        <Input
                            id=""
                            className="brand-inputbox f-134"
                            {...search.input}
                            onChange={(e) => this.props.onInputChange(e)}
                        >
                        </Input>
                    </div>
                    <div className="iconsDiv d-flex ml-3">
                        <div className={`iconSwap d-flex align-items-center justify-content-center
                            ${component === "table" && "box-shadow-class"}`}
                            onClick={() => this.props.renderComponent("table")}
                        >
                            <i className="fa fa-table" aria-hidden="true"></i>
                        </div>
                        <div className={`iconSwap d-flex align-items-center justify-content-center bl-none
                            ${component === "chart" && "box-shadow-class"}`}
                            onClick={() => this.props.renderComponent("chart")}
                        >
                            <i className="fas fa-chart-pie" aria-hidden="true"></i>
                        </div>
                    </div>
                </div>
                {
                    component === "table"
                        ? tableDataLoading === false
                            ? gotTableData
                                ? <div>
                                    <THeader
                                        dimension={dimension}
                                        sortBy={sortBy}
                                        orderIconState={orderIconState}
                                        changeOrder={this.props.changeOrder}
                                    />
                                    <Total
                                        summary={tableData.summary}
                                        symbol={getCurrencySymbol(getFromLocalStorage("currency"))}
                                    />
                                    {
                                        tableData.data.length !== 0
                                            ? tableData.data.map((item, index) => (
                                                <RenderData
                                                    data={item}
                                                    key={`tbody${index}`}
                                                    outerIndex={index}
                                                    apiInfo={apiInfo}
                                                    indexArray={indexArray}
                                                />
                                            ))
                                            : <div className="header-item-box mx-3 bt-none br d-flex justify-content-center align-items-center">
                                                No more Data to Fetch
                                        </div>
                                    }
                                    <Pagination
                                        rowSelected={rowSelected}
                                        miscRowSelected={miscRowSelected}
                                        count={tableData.count}
                                        data={tableData.data}
                                        handleChange={this.props.handleChange}
                                        nextPage={this.props.nextPage}
                                        prevPage={this.props.prevPage}
                                        pageNo={pageNo}
                                    />
                                </div>
                                : <h4
                                    className="d-flex justify-content-center align-items-center"
                                    style={{ height: "400px", color: "gray" }}>No data for selected date range</h4>
                            : <h4
                                className="d-flex justify-content-center align-items-center"
                                style={{ height: "400px", color: "gray" }}>Loading data...</h4>
                        : pieDataLoading === false
                            ? gotPieData
                                ? <PieChartRender
                                    dimension={dimension}
                                    tableDropDown={tableDropDown}
                                    pieDropdown={pieDropdown}
                                    setTableDropDown={this.props.setTableDropDown}
                                    changeOrder={this.props.changeOrder}
                                    orderIconState={orderIconState}
                                    sortBy={sortBy}
                                    summary={pieData.summary}
                                    symbol={apiInfo.currency}
                                    pieData={pieData}
                                    props={this.props}
                                />
                                : <h4
                                    className="d-flex justify-content-center align-items-center"
                                    style={{ height: "400px", color: "gray" }}>No data for selected date range</h4>
                            : <h4
                                className="d-flex justify-content-center align-items-center"
                                style={{ height: "400px", color: "gray" }}>Loading data...</h4>
                }
            </Paper>
        );
    }
}

export default ReactTableView;
