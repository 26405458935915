import { get } from "../lib/request";
import moment from "moment";
export const fetchProductSearchedLogs = (
  startDate = moment(),
  endDate = moment(),
  skip = 0,
  limit = 50,
  city = ""
) => {
  return get(`/productSearch?skip=${skip}&limit=${limit}&cityName=${city}`, {
    startDate: startDate,
    endDate: endDate,
  });
};
