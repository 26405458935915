import React, { Component } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import Graph from "../Graph/pieGraph";
import {getCurrencySymbol} from "../../../../../../lib/helper"

class TotalData extends Component {
  state = {
    renderColumns: [1, 2, 3, 4],
  };

  precision = (value, index, symbol) => {
    if (index === 1 || index === 2) {
      return value;
    } else if (index === 0 || index === 3 || index === 5) {
      return `${symbol}${value.toFixed(2)}`;
    } else {
      return value.toFixed(2);
    }
  };

  mathFunction = (value) => {
    return value.toFixed(2);
  };

  hoverValue = (value) => {
    let hover = value === 'Product Revenue'
        ? this.state.columnsList[1].title
        : value === 'Unique Purchase'
            ? this.state.columnsList[2].title
            : value === 'Quantity'
                ? this.state.columnsList[3].title
                : value === 'Avg. Price'
                    ? this.state.columnsList[4].title
                    : value === 'Avg. QTY'
                        ? this.state.columnsList[5].title
                        : this.state.columnsList[6].title
    return hover;
}

  render() {
    const { renderColumns } = this.state;
    const { summary, currency, data, rows, pageNo, sortBy, pieDropdown } = this.props;
    data.data.map((item) => {
        item.hover = item.Product || item["Product Brand"] || item["Product Category"];
        return item.hover = item.hover && item.hover.length > 35 ? `${item.hover.substring(0, 30)}...` : `${item.hover}`;
    });
    
    const LightTooltip = withStyles((theme) => ({
      tooltip: {
        backgroundColor: "#2A3F54",
        color: "white",
        boxShadow: theme.shadows[1],
        fontSize: 11,
      },
    }))(Tooltip);
    return (

        <div className="leftDiv">
          <div
            className="header-item-box bt-none d-flex mx-3"
            style={{ height: "70px" }}
          >
            {renderColumns.map((item, index) => (
              <div
                className="header-item"
                style={
                  index === 0 || index === 3 ? { width: "30%" } : { width: "20%" }
                }
              >
                {index > 0 && index <= 2 ? (
                  <div>
                    <div
                      className="insideTotal"
                      style={{ paddingRight: "20px", borderRight: "none" }}
                    >
                      <div
                        className="fontsizeproduct"
                        style={{ textAlign: "end" }}
                      >{`${
                        index === 1
                          ?`${sortBy === "Quantity" || sortBy === "Unique Purchases" || sortBy === "Avg. QTY" ? "" : getCurrencySymbol(currency)} ${summary.column.toFixed(2)}`
                          : `${pieDropdown === "Quantity" || pieDropdown === "Unique Purchases" || pieDropdown === "Avg. QTY" ? "" : getCurrencySymbol(currency)} ${summary.pie_column.toFixed(2)}`
                      }`}</div>
                      <div
                        className="small fontsizeproducttwo"
                        style={{ textAlign: "end", width: "100%", margin: "0px" }}
                      >
                        % of Total: 100.00%
                      </div>
                      <div
                        className="small fontsizeproducttwo"
                        style={{ textAlign: "end", width: "100%", margin: "0px" }}
                      >{`(${
                        index === 1
                          ?`${sortBy === "Quantity" || sortBy === "Unique Purchases" || sortBy === "Avg. QTY" ? "" : getCurrencySymbol(currency)} ${summary.column.toFixed(2)}`
                          : `${pieDropdown === "Quantity" || pieDropdown === "Unique Purchases" || pieDropdown === "Avg. QTY" ? "" : getCurrencySymbol(currency)} ${summary.pie_column.toFixed(2)}`
                      })`}</div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            ))}
          </div>
          <div className="d-flex">
              <div className="headingPie borderRemove loadingDiv" style={{width: "70%"}}>
                {data.data.map((item, index) => (
                  <div
                    className="header-item-box2 bt-none d-flex mx-3"
                    style={{ height: 40 }}
                  >
                    <div
                      className="header-item px-2 f-134 d-flex justify-content-start align-items-center"
                      style={{ width: "43.4%" }}
                    >
                      <div className="productPie d-flex">
                          <div className="heightTable fontsizeproduct" style={{ width: "40px" }}>
                              {`${pageNo === 0 ? (index + 1) : (parseInt(pageNo)  * parseInt(rows) + index + 1 || index + 1)}.`}
                          </div>
                          <div className="heightTable">
                              <LightTooltip title={item.Product || item["Product Brand"] || item["Product Category"]} placement="bottom-start" arrow style={{ marginRight: "10px", fontSize: "14px" }}><span>{item.hover}</span></LightTooltip>
                          </div>
                      </div>
                    </div>
                    <div
                      className="header-item px-2 f-134 d-flex
                            justify-content-end align-items-center"
                      style={{ width: "29%" }}
                    >
                        <div className="generalWidth fontsizeproduct" style={{justifyContent: "flex-end", paddingRight: "20px"}}>{`${sortBy === "Quantity" || sortBy === "Unique Purchases" || sortBy === "Avg. QTY" ? "" : getCurrencySymbol(currency)} ${this.mathFunction(item.column)}`}</div>
                    </div>

                    <div
                      className="header-item2 px-2 f-134 d-flex
                            justify-content-end align-items-center"
                      style={{ width: "20%" }}
                    >
                      <div className="small generalWidth revenue fontsizeproducttwo" style={{ borderRight: "", width: "20%", justifyContent: "flex-end", paddingRight: "20px", borderBottom: "none" }}>{`${this.mathFunction(item.pie_column)}%`}</div>
                    </div>
                    
                </div>
                ))}
              </div>
              <div>
                  <Graph data={data.graph} />
              </div>
          </div>
      </div>
    );
  }
}

export default TotalData;
